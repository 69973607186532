import React from 'react';

export const CrossCircle = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM11.0303 4.96967C11.3232 5.26256 11.3232 5.73744 11.0303 6.03033L9.06066 8L11.0303 9.96967C11.3232 10.2626 11.3232 10.7374 11.0303 11.0303C10.7374 11.3232 10.2626 11.3232 9.96967 11.0303L8 9.06066L6.03033 11.0303C5.73744 11.3232 5.26256 11.3232 4.96967 11.0303C4.67678 10.7374 4.67678 10.2626 4.96967 9.96967L6.93934 8L4.96967 6.03033C4.67678 5.73744 4.67678 5.26256 4.96967 4.96967C5.26256 4.67678 5.73744 4.67678 6.03033 4.96967L8 6.93934L9.96967 4.96967C10.2626 4.67678 10.7374 4.67678 11.0303 4.96967Z"
    />
  </svg>
);
