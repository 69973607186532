import {useContext} from 'react';

import {useLayer} from '../layers/layerContext';
import {RepositionBoundaryContext} from './repositionBoundaryContext';

/*
 * Hook.
 */

export function useRepositionBoundary(ignoreBoundary?: boolean) {
  const {container} = useLayer();
  const {boundaryRef} = useContext(RepositionBoundaryContext);

  if (ignoreBoundary) {
    return container;
  }

  return boundaryRef.current ?? container;
}
