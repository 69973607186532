import {globalWindow} from '../../../libs/shared/core/src/helpers/browser/browserHelpers';

/*
 * Api.
 */

export function randomString(length: number = 32) {
  // If the crypto API is not available, fallback.
  const {crypto} = globalWindow;

  // Find how many random values we need.
  const halfLength = Math.ceil(length / 2);
  const randomValues = new Uint8Array(halfLength);

  // Generate the random values.
  crypto.getRandomValues(randomValues);

  // Convert to base16 and return.
  let result = '';
  for (let i = 0; i < halfLength; i++) {
    const randomValue = randomValues[i];
    result += (randomValue < 16 ? '0' : '') + randomValue.toString(16);
  }

  return result;
}
