import _ from 'lodash';

import {PaletteColorsEnum} from '../../../core/src/models/api/paletteModel';
import {collectEnumValues} from '../../../core/src/models/api/types/enumTypes';
import {compareColors, tryParseColor} from '../../../core/src/models/helpers/colorHelpers';
import {UserTheme} from './themeTypes';

export type PaletteColorShadeFinder = (theme: UserTheme, color: PaletteColorsEnum) => string;

// Convenience methods for looking up a shade by PaletteColorEnum. Useful with
// semantic styles if you need to support multiple palette colors (see tagSemanticStyles.)
export const findColorShade10: PaletteColorShadeFinder = (theme, color) => theme.palette[color].shade10;
export const findColorShade20: PaletteColorShadeFinder = (theme, color) => theme.palette[color].shade20;
export const findColorShade30: PaletteColorShadeFinder = (theme, color) => theme.palette[color].shade30;
export const findColorShade40: PaletteColorShadeFinder = (theme, color) => theme.palette[color].shade40;
export const findColorShade50: PaletteColorShadeFinder = (theme, color) => theme.palette[color].shade50;
const legacyToPaletteColorsEnumMap: Record<
  string,
  (theme: UserTheme) => {key: PaletteColorsEnum; ref: string}
> = {
  '#FFFFFF': (theme) => ({key: PaletteColorsEnum.GREY, ref: theme.palette[PaletteColorsEnum.GREY].shade40}),
  '#E15151': (theme) => ({key: PaletteColorsEnum.RED, ref: theme.palette[PaletteColorsEnum.RED].shade40}),
  '#E19951': (theme) => ({
    key: PaletteColorsEnum.ORANGE,
    ref: theme.palette[PaletteColorsEnum.ORANGE].shade40,
  }),
  '#E1E151': (theme) => ({
    key: PaletteColorsEnum.YELLOW,
    ref: theme.palette[PaletteColorsEnum.YELLOW].shade40,
  }),
  '#99E151': (theme) => ({key: PaletteColorsEnum.GREEN, ref: theme.palette[PaletteColorsEnum.GREEN].shade40}),
  '#51E151': (theme) => ({key: PaletteColorsEnum.GREEN, ref: theme.palette[PaletteColorsEnum.GREEN].shade40}),
  '#51E199': (theme) => ({key: PaletteColorsEnum.GREEN, ref: theme.palette[PaletteColorsEnum.GREEN].shade40}),
  '#51E1E1': (theme) => ({key: PaletteColorsEnum.TEAL, ref: theme.palette[PaletteColorsEnum.TEAL].shade40}),
  '#5199E1': (theme) => ({key: PaletteColorsEnum.BLUE, ref: theme.palette[PaletteColorsEnum.BLUE].shade40}),
  '#5151E1': (theme) => ({key: PaletteColorsEnum.BLUE, ref: theme.palette[PaletteColorsEnum.BLUE].shade40}),
  '#9951E1': (theme) => ({
    key: PaletteColorsEnum.PURPLE,
    ref: theme.palette[PaletteColorsEnum.PURPLE].shade40,
  }),
  '#E151E1': (theme) => ({key: PaletteColorsEnum.PINK, ref: theme.palette[PaletteColorsEnum.PINK].shade40}),
  '#E15199': (theme) => ({key: PaletteColorsEnum.PINK, ref: theme.palette[PaletteColorsEnum.PINK].shade40}),
};

export function legacyInboxColorToPaletteColor(inboxColor: string, theme: UserTheme): PaletteColorsEnum {
  const exactMatch = legacyToPaletteColorsEnumMap[inboxColor.toUpperCase()];

  if (exactMatch) {
    return exactMatch(theme).key;
  }

  const parsedColor = tryParseColor(inboxColor);

  if (!parsedColor) {
    return PaletteColorsEnum.GREY;
  }

  const closestColor = _(legacyToPaletteColorsEnumMap)
    .values()
    .sortBy((val) => compareColors(tryParseColor(val(theme).ref)!, parsedColor))
    .first();

  if (!closestColor) {
    return PaletteColorsEnum.GREY;
  }

  return closestColor(theme).key;
}

export function colorStringToPaletteColor(
  colorString: string,
  theme: UserTheme,
  colorValues?: ReadonlyArray<PaletteColorsEnum>,
): PaletteColorsEnum | undefined {
  return (colorValues ?? collectEnumValues(PaletteColorsEnum)).find(
    (value) => colorString.toLowerCase() === theme.palette[value].shade40,
  );
}
