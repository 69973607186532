import normalized from 'normalize.css';
import React, {FC, memo} from 'react';
import {createGlobalStyle} from 'styled-components';

import {customFonts} from '../components/fonts/customFonts';
import {layerClassName} from '../components/layers/layer';
import {useCss} from '../css/cssContext';
import {fonts, fontSizes} from './fontStyles';
import {addThemedScrollbarStyles, getHighlighterCss} from './mixins';

// Avoid removing and re-inserting global styles unnecessarily,
// because it may cause major style recalculation issues.
const GlobalStyle = memo(createGlobalStyle`
  /* This is used for both the main window and for child windows, so we repeat some of what's in index.hbs. */
  html {
    /* Disable scroll bounce on the root element. */
    /* For Safari < 16.0. */
    overflow: hidden;
    overflow: clip;

    font-family: ${fonts.system};
    font-size: ${fontSizes.medium};
    color: ${(p) => p.theme.greys.shade80};
    line-height: 1.2;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;

    ${addThemedScrollbarStyles()};
  }

  body {
    margin: 0;
  }

  /* For backward compatibility, in case anything is measuring window.document.body. */
  html, body {
    width: 100vw;
    width: 100svw;
    height: 100vh;
    height: 100svh;
  }

  div[contenteditable], input {
    text-rendering: auto !important;
  }

  /*
   * Reset.
   */

  * {
    user-select: none;
    cursor: default;
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  *:focus {
    outline: none;
  }

  *:not(body) {
    background-repeat: no-repeat;
  }

  a {
    text-decoration: none;
  }

  /*
   * Layers.
   */

  .${layerClassName} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    /* Creates a new stacking context on browsers that don't yet support contain: strict. */
    isolation: isolate;
    /* Optimizes rendering, creates a new stacking context, and fixes a flickering issue in Safari 17.2+. */
    contain: strict;
  }

  body > .${layerClassName} {
    width: 100vw;
    width: 100svw;
    height: 100vh;
    height: 100svh;
  }

  grammarly-editor-plugin {
    --grammarly-button-position-right: 60px;
    --grammarly-button-position-bottom: 60px;
  }

  ${customFonts}


  [class*="-active-highlight"] {
    position: relative;

    ${getHighlighterCss()}

    &.button-highlighter {
      &:before {
        border-radius: 15px;
      }
    }

    &.dropdown-item-highlighter {
      &:before{
        border-radius: 8px;
      }
    }
  }
`);

export const GlobalStyles: FC = () => {
  useCss(normalized);
  return <GlobalStyle />;
};
