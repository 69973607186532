/* eslint-env node */
import {findFrontEnvironment} from './envConfig';
import {FrontEnvironmentsEnum} from './environments';

export function isProduction() {
  return findFrontEnvironment() === FrontEnvironmentsEnum.PRODUCTION;
}

export function isStaging() {
  return findFrontEnvironment() === FrontEnvironmentsEnum.STAGING;
}

export function isPreview() {
  return findFrontEnvironment() === FrontEnvironmentsEnum.PREVIEW;
}

export function isDevelopment() {
  return findFrontEnvironment() === FrontEnvironmentsEnum.DEVELOPMENT;
}

/** Using this in non-test code is a code smell. Use it only as a last resort. */
export function isTest() {
  return process.env.NODE_ENV === 'test';
}
