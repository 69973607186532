import React, {FC, PropsWithChildren, useMemo} from 'react';

import {pushParentInteractionId} from '../../../core/src/helpers/interaction/interactionHelpers';
import {InteractionContext, useLegacyInteractionContext} from './interactionContext';

/*
 * Props.
 */

export interface LegacyInteractionParentProps {
  interactionId: string;
}

/*
 * Component.
 */

export const LegacyInteractionParent: FC<PropsWithChildren<LegacyInteractionParentProps>> = ({
  interactionId,
  children,
}) => {
  const context = useLegacyInteractionContext();
  const providedContext = useMemo(
    () => pushParentInteractionId(context, interactionId),
    [context, interactionId],
  );

  return <InteractionContext.Provider value={providedContext}>{children}</InteractionContext.Provider>;
};
