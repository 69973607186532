import {greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';

export const lightBannerSemanticStyles = {
  primaryText: '',
  action: greys.shade80,

  successBackground: palette.green.shade40,
  warningBackground: palette.orange.shade40,
  neutralBackground: palette.grey.shade40,
};

export type BannerSemanticStyles = typeof lightBannerSemanticStyles;

export const darkBannerSemanticStyles = {
  primaryText: greysDark.black,
  action: greysDark.black,

  successBackground: paletteDark.green.shade20,
  warningBackground: paletteDark.orange.shade20,
  neutralBackground: paletteDark.grey.shade20,
};
