import {alphas, greys} from '../../styles/colorStyles';

// Styles which are shared between the dark and light themes. Most of the top
// bar does not change appearance between the themes.
export const sharedTopBarSemanticStyles = {
  buttonColor: alphas.white40,
  buttonHoverColor: alphas.white50,
  buttonHoverBackground: alphas.white20,
  buttonSelectedColor: greys.shade10,
  buttonSelectedBackground: alphas.white30,
  buttonDisabledColor: alphas.white30,
  availabilityIconColor: greys.white,
};

export type TopBarSemanticStyles = typeof sharedTopBarSemanticStyles;
