import * as t from 'io-ts';

import {makeApiType} from '../helpers/apiType';
import {parseSchedulingLink, RSchedulingLink} from './schedulingLinkModel';
import {
  parseSchedulingLinkQuestionAnswer,
  RSchedulingLinkQuestionAnswer,
} from './schedulingLinkQuestionAnswerModel';

/*
 * Runtime type.
 */

export const RSchedulingLinkBooking = t.readonly(
  t.type({
    schedulingLink: RSchedulingLink,
    token: t.string,
    uid: t.string,
    email: t.string,
    name: t.string,
    start: t.number,
    end: t.number,
    questionAnswers: t.readonlyArray(RSchedulingLinkQuestionAnswer),
    cancelled: t.boolean,
    disabled: t.boolean,
    isDraft: t.literal(false),
  }),
);

/*
 * Type.
 */

export interface SchedulingLinkBookingModel extends t.TypeOf<typeof RSchedulingLinkBooking> {}

export type SchedulingLinkDraftBookingModel = Partial<Omit<SchedulingLinkBookingModel, 'isDraft'>> & {
  isDraft: true;
};

/*
 * IO methods.
 */

export function parseSchedulingLinkBooking(src: any): SchedulingLinkBookingModel {
  return {
    schedulingLink: parseSchedulingLink(src.scheduling_link),
    token: src.token,
    uid: src.uid,
    email: src.email,
    name: src.name,
    start: src.start,
    end: src.end,
    questionAnswers: src.question_answers.map(parseSchedulingLinkQuestionAnswer),
    cancelled: src.cancelled,
    disabled: src.disabled,
    isDraft: false,
  };
}

/*
 * API type.
 */

export const ApiSchedulingLinkBooking = makeApiType(
  'schedulingLinkBooking',
  RSchedulingLinkBooking,
  parseSchedulingLinkBooking,
);

/*
 * Helpers.
 */

export function isDraftBooking(
  booking: SchedulingLinkBookingModel | SchedulingLinkDraftBookingModel,
): booking is SchedulingLinkDraftBookingModel {
  return booking.isDraft;
}
