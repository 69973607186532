import {isUndefined} from 'lodash';
import {useRef} from 'react';

import {generateUniqueElementId} from '../../accessibility/accessibilityHelpers';

/** Generate a unique Element ID that will be stable during the life of the component. */
export function useUniqueElementId(): string {
  const idRef = useRef<string | undefined>();

  if (isUndefined(idRef.current)) {
    idRef.current = generateUniqueElementId();
  }

  return idRef.current;
}
