import React, {forwardRef} from 'react';
import {Diff} from 'utility-types';

import {Renderer} from '../../../../core/src/helpers/react/reactHelpers';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {TooltipCoordinator} from '../tooltips/tooltipCoordinator';
import {ButtonChevron, ButtonChevronProps} from './buttonChevron';
import {ButtonChrome, ButtonInheritableProps, defaultButtonProps} from './buttonChrome';
import {ButtonContent, ButtonContentProps} from './buttonContent';
import {ChromelessButton, ChromelessButtonProps} from './chromelessButton';

/*
 * Props.
 */

export interface ButtonProps
  extends ButtonInheritableProps,
    ButtonContentProps,
    ChromelessButtonProps,
    ButtonChevronProps {
  renderTooltip?: Renderer;
  isTooltipMultiline?: boolean;
  isDropdownDisabled?: boolean;
  tooltipMaxWidth?: number;
}

/*
 * Component.
 */

export const Button = forwardRef<
  HTMLDivElement,
  Diff<ButtonProps, typeof defaultButtonProps> & Partial<typeof defaultButtonProps>
>(({className, children, ...buttonProps}, ref) => (
  <ButtonChrome ref={ref} className={className} {...(buttonProps as ButtonProps)}>
    {() => (
      <>
        <TooltipCoordinator
          render={buttonProps.renderTooltip}
          isMultiline={buttonProps.isTooltipMultiline}
          maxWidth={buttonProps.tooltipMaxWidth}
        >
          <ChromelessButton {...buttonProps}>
            <ButtonContent {...(buttonProps as ButtonProps)}>{children}</ButtonContent>
          </ChromelessButton>
        </TooltipCoordinator>
        {maybeRenderChevron(buttonProps as ButtonProps)}
      </>
    )}
  </ButtonChrome>
));
Button.defaultProps = defaultButtonProps;

function maybeRenderChevron(props: ButtonProps) {
  if (!props.renderDropdown || props.size === VisualSizesEnum.LARGE) {
    return null;
  }

  return <ButtonChevron {...props} isDisabled={props.isDropdownDisabled} />;
}
