import {greys, greysDark} from '../../styles/colorStyles';

export const lightBetaFeaturePillSemanticStyles = {
  backgroundColor: greys.shade90,
  color: greys.white,
};

export type BetaFeaturePillSemanticStyles = typeof lightBetaFeaturePillSemanticStyles;

export const darkBetaFeaturePillSemanticStyles: BetaFeaturePillSemanticStyles = {
  backgroundColor: greysDark.shade90,
  color: greysDark.white,
};
