import {greys, palette, paletteDark} from '../../styles/colorStyles';

export const lightNewFeaturePillSemanticStyles = {
  backgroundColor: palette.teal.shade40,
  color: greys.white,
};

export type NewFeaturePillSemanticStyles = typeof lightNewFeaturePillSemanticStyles;

export const darkNewFeaturePillSemanticStyles: NewFeaturePillSemanticStyles = {
  backgroundColor: paletteDark.teal.shade40,
  color: greys.white,
};
