import {alphas, alphaWhite, brandColor, greys, palette} from '../../styles/colorStyles';
import {lightAnnotationSemanticStyles} from '../semantic/annotationSemanticStyles';
import {lightAvatarSemanticStyles} from '../semantic/avatarSemanticStyles';
import {lightBannerSemanticStyles} from '../semantic/bannerSemanticStyles';
import {lightButtonSemanticStyles} from '../semantic/buttonSemanticStyles';
import {lightCellSemanticStyles} from '../semantic/cellSemanticStyles';
import {lightCommentComposerSemanticStyles} from '../semantic/commentComposerSemanticStyles';
import {lightMessageSemanticStyles} from '../semantic/messageSemanticStyles';
import {sharedSearchBarSemanticStyles} from '../semantic/searchBarSemanticStyles';
import {lightTagSemanticStyles} from '../semantic/tagSemanticStyles';
import {sharedTopBarSemanticStyles} from '../semantic/topBarSemanticStyles';
import {SemanticStyles} from '../themeTypes';

export const lightSemanticStyles: SemanticStyles = {
  brand: brandColor,

  appBackground: greys.shade20,
  foreground: greys.white,

  text: greys.shade80,
  composerText: greys.shade100,
  composerPlaceholder: greys.shade60,

  interactiveContainer: palette.blue.shade40,
  interactiveText: palette.blue.shade40,
  interactiveTextHover: palette.blue.shade50,
  interactiveIcon: palette.blue.shade40,
  interactiveBorders: palette.blue.shade40,

  invertedText: greys.white,
  invertedSubtext: alphas.white50,
  invertedIcon: alphas.white50,

  modalBackdrop: alphas.black50,

  colorScheme: 'normal',

  urlTooltipMinorText: alphaWhite(0.7),
  inputBackground: greys.shade20,
  windowsButtonHover: alphas.white20,

  highlightText: greys.shade90,

  refinedP2ResultsIndicator: palette.blue.shade40,

  cell: lightCellSemanticStyles,
  topBar: sharedTopBarSemanticStyles,
  searchBar: sharedSearchBarSemanticStyles,
  tag: lightTagSemanticStyles,
  avatar: lightAvatarSemanticStyles,
  message: lightMessageSemanticStyles,
  banner: lightBannerSemanticStyles,
  button: lightButtonSemanticStyles,
  annotation: lightAnnotationSemanticStyles,
  commentComposer: lightCommentComposerSemanticStyles,
};
