import {darken} from 'polished';
import {css} from 'styled-components';

import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {fontSizes} from '../../styles/fontStyles';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {ThemeContextType} from '../../theme/userThemeProvider';

export interface BoxedInputChildProps {
  size: VisualSizesEnum;
  isDisabled?: boolean;
  inputId: string;
}

export interface BoxedInputChildStyleProps {
  $size: VisualSizesEnum;
  $color?: string;
  $noMarginLeft?: boolean;
  $noMarginRight?: boolean;
}

/** Shared styles for all input contents. */
export const inputContentStyles = makeSizeConstants(
  css`
    font-size: ${fontSizes.small};
    line-height: 16px;
    margin: 5px 8px;
  `,
  css`
    font-size: ${fontSizes.medium};
    line-height: 20px;
    margin: 5px 8px 5px 8px;
  `,
  css`
    font-size: ${fontSizes.large};
    line-height: 20px;
    margin: 10px 12px;
  `,
);

/** Colors to use throughout boxed input components. */
export const boxedInputColorReaders = {
  background: (p: ThemeContextType) => p.theme.inputBackground,
  backgroundHover: (p: ThemeContextType) => p.theme.greys.shade30,
  backgroundFocused: (p: ThemeContextType) => p.theme.greys.white,
  backgroundError: (p: ThemeContextType) => p.theme.palette[PaletteColorsEnum.RED].shade20,
  // TODO: Change when the design team has chosen a real color for this.
  backgroundErrorHover: (p: ThemeContextType) => darken(0.05, p.theme.palette[PaletteColorsEnum.RED].shade20),
  backgroundErrorFocused: (p: ThemeContextType) =>
    darken(0.05, p.theme.palette[PaletteColorsEnum.RED].shade20),
  backgroundDisabled: (p: ThemeContextType) => p.theme.greys.white,
  text: (p: ThemeContextType) => p.theme.greys.shade90,
  textDisabled: (p: ThemeContextType) => p.theme.greys.shade80,
  textPlaceholder: (p: ThemeContextType) => p.theme.greys.shade60,
  textLabel: (p: ThemeContextType) => p.theme.greys.shade70,
  textError: (p: ThemeContextType) => p.theme.palette[PaletteColorsEnum.RED].shade40,
};
