import styled, {css} from 'styled-components';

import {buildHoverSelector} from '../../../../core/src/helpers/browser/hoverHelpers';
import {blendColors} from '../../../../core/src/models/helpers/colorHelpers';
import {center} from '../../styles/mixins';
import {ThemeContextType} from '../../theme/userThemeProvider';
import {findButtonColors, isVariantWithBorder} from './buttonConstants';
import {ButtonContent, ButtonContentProps} from './buttonContent';

/*
 * Style.
 */

const StyledButtonContent = styled(ButtonContent)`
  ${center()};
  padding: 0 12px 0 8px;
  position: relative;

  ${(p) => addSeparatorStyles(p)};
  ${(p) => addHoverStyles(p)}
`;

// Use a pseudo element because we don't want the separator to overlap with the button border.
// - Leave 1px so we don't draw over the border (only if the variant has a border).
// - "left: -2px" so the separator highlights when the button is hovered.
function addSeparatorStyles(props: ButtonContentProps & ThemeContextType) {
  const buttonColors = findButtonColors(props.theme);
  const chevronColor = buttonColors[props.variant].chevronShadow(props.color);
  const hasBorder = isVariantWithBorder(props.variant);
  const topBottom = hasBorder ? '1px' : '0';

  return css`
    &:before {
      content: '';
      position: absolute;
      left: -2px;
      top: ${topBottom};
      bottom: ${topBottom};
      width: 2px;
      background-color: ${chevronColor};
    }
  `;
}

function addHoverStyles(props: ButtonContentProps & ThemeContextType) {
  const buttonColors = findButtonColors(props.theme);
  const backgroundColor = buttonColors[props.variant].backgroundHover(props.color);
  const chevronColor = buttonColors[props.variant].chevronShadow(props.color);
  const hoverColor = blendColors(backgroundColor, chevronColor);

  if (props.isDisabled) {
    return null;
  }

  return css`
    ${buildHoverSelector(':before')} {
      top: 0;
      bottom: 0;
      background-color: ${hoverColor};
    }
  `;
}

/*
 * Component.
 */

export const ButtonChevronContent = StyledButtonContent;
