import {isFunction} from 'lodash';
import React, {forwardRef, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {
  buildDirectHoverSelector,
  buildHoverSelector,
} from '../../../../core/src/helpers/browser/hoverHelpers';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {fonts, fontWeights} from '../../styles/fontStyles';
import {flexCenter} from '../../styles/mixins';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {ThemeContextType} from '../../theme/userThemeProvider';
import {Icon, IconName} from '../icon/icon';
import {ButtonInheritableProps, ButtonInheritableStyleProps, defaultButtonProps} from './buttonChrome';
import {findButtonColors} from './buttonConstants';

/*
 * Props.
 */

export interface ButtonContentProps extends ButtonInheritableProps {
  className?: string;
  leftIcon?: IconName;
  leftIconColor?: string;
  rightIcon?: IconName;
  rightIconColor?: string;
  children?: ReactNode;
}

/*
 * Style.
 */

export const mainButtonHorizontalPadding = makeSizeConstants(7, 12, 33);

export const mainButtonContentStyles = makeSizeConstants(
  css`
    font-size: 13px;
    line-height: 16px;
    padding: 5px ${mainButtonHorizontalPadding[VisualSizesEnum.SMALL]}px 5px
      ${mainButtonHorizontalPadding[VisualSizesEnum.SMALL]}px;
  `,
  css`
    font-size: 14px;
    line-height: 17px;
    padding: 6px ${mainButtonHorizontalPadding[VisualSizesEnum.MEDIUM]}px 7px
      ${mainButtonHorizontalPadding[VisualSizesEnum.MEDIUM]}px;
  `,
  css`
    font-size: 16px;
    line-height: 20px;
    padding: 13px ${mainButtonHorizontalPadding[VisualSizesEnum.LARGE]}px;
    text-align: center;
  `,
);

// This is intended to match the Figma spec for buttons:
// https://www.figma.com/file/qmN1rpf6Sza2iz7kXyQQGn/%E2%9C%A8-%F0%9F%96%A5-Buttons
export const roundedMainButtonContentStyles = makeSizeConstants(
  css`
    font-size: 13px;
    line-height: 16px;
    padding: 5px 12px;
  `,
  css`
    font-size: 14px;
    line-height: 17px;
    padding: 6px 14px 7px 14px;
  `,
  css`
    font-size: 16px;
    line-height: 20px;
    padding: 9px 16px;
    text-align: center;
  `,
);

export const commonButtonStyles = css`
  font-family: ${fonts.system};
  font-weight: ${fontWeights.semibold};
`;

const StyledContentDiv = styled.div<ButtonInheritableStyleProps>`
  flex: 1;
  ${(p) => (p.$isRounded ? roundedMainButtonContentStyles[p.$size] : mainButtonContentStyles[p.$size])};

  ${(p) => colorStyles(p)};
  background-color: ${(p) => {
    const buttonColors = findButtonColors(p.theme);
    return buttonColors[p.$variant].background(p.$color);
  }};

  ${commonButtonStyles}

  /* Other states. */
  ${(p) => extraStateStyle(p)}
  
  
  ${(p) =>
    (p.$hasRightIcon || p.$hasLeftIcon) &&
    css`
      ${flexCenter};
    `}
  
  ${(p) =>
    p.$hasRightIcon &&
    css`
      padding-right: 10px;
    `}
`;

const leftIconStyles = makeSizeConstants(
  css`
    margin-right: 4px;
  `,
  css`
    margin-right: 8px;
  `,
  css`
    margin-right: 8px;
  `,
);

interface LeftIconStyleProps {
  $size: VisualSizesEnum;
}

const StyledLeftIcon = styled(Icon)<LeftIconStyleProps>`
  ${(p) => leftIconStyles[p.$size]};
`;

const StyledRightIcon = styled(Icon)`
  margin-left: 4px;
`;

function colorStyles(props: ButtonInheritableStyleProps & ThemeContextType) {
  const colors = findButtonColors(props.theme);
  const {text} = colors[props.$variant];

  if (isFunction(text)) {
    return css`
      color: ${text(props.$color)};
    `;
  }

  return css`
    color: ${text};
  `;
}

function extraStateStyle(props: ButtonInheritableStyleProps & ThemeContextType) {
  const colors = findButtonColors(props.theme);
  // Disabled state.
  if (props.$isDisabled) {
    const {textDisabled, backgroundDisabled} = colors[props.$variant];
    return css`
      ${textDisabled && `color: ${textDisabled};`}
      ${backgroundDisabled && `background-color: ${backgroundDisabled};`}
      ${props.$shouldDisallowCursorIfDisabled && `cursor: not-allowed`}
    `;
  }

  // Hover support.
  return css`
    ${props.$shouldUseDirectHover ? buildDirectHoverSelector() : buildHoverSelector()} {
      background-color: ${(p) => colors[props.$variant].backgroundHover(props.$color)};
    }
  `;
}

/*
 * Component.
 */

export const ButtonContent = forwardRef<HTMLDivElement, ButtonContentProps>(
  ({className, children, leftIcon, leftIconColor, rightIcon, rightIconColor, ...buttonProps}, ref) => (
    <StyledContentDiv
      ref={ref}
      className={className}
      $size={buttonProps.size}
      $variant={buttonProps.variant}
      $isRounded={buttonProps.isRounded ?? defaultButtonProps.isRounded}
      $color={buttonProps.color}
      $isDisabled={buttonProps.isDisabled}
      $shouldDisallowCursorIfDisabled={buttonProps.shouldDisallowCursorIfDisabled}
      $shouldUseDirectHover={buttonProps.shouldUseDirectHover}
      $hasLeftIcon={Boolean(leftIcon)}
      $hasRightIcon={Boolean(rightIcon)}
    >
      {leftIcon && <StyledLeftIcon name={leftIcon} color={leftIconColor} $size={buttonProps.size} />}
      {children}
      {rightIcon && <StyledRightIcon name={rightIcon} color={rightIconColor} />}
    </StyledContentDiv>
  ),
);
