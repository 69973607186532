import {isMobile} from '../environment/mobileEnvHelpers';
import {isMacOS} from '../environment/platformHelpers';

export enum ModifierKeysEnum {
  ALT = 'alt',
  COMMAND = 'command',
  CTRL = 'ctrl',
  OPTION = 'option',
}

export const altKey = findAltKey();
export const metaKey = findMetaKey();

export function findAltKey(): ModifierKeysEnum.ALT | ModifierKeysEnum.OPTION {
  if (isMobile()) {
    throw Error('No modifier key on mobile (alt key)');
  }

  if (isMacOS()) {
    return ModifierKeysEnum.OPTION;
  }

  return ModifierKeysEnum.ALT;
}

export function findMetaKey(): ModifierKeysEnum.COMMAND | ModifierKeysEnum.CTRL {
  if (isMobile()) {
    throw Error('No modifier key on mobile (meta key)');
  }

  if (isMacOS()) {
    return ModifierKeysEnum.COMMAND;
  }

  return ModifierKeysEnum.CTRL;
}
