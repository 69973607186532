import {CancelToken} from 'axios';
import {Type} from 'io-ts';

import {ApiType, ModelType} from './apiType';
import {deleteAsync, fetchApiAsync, HttpSendOptions, patchApiAsync, postApiAsync} from './http';
import {BaseUrlsEnum} from './httpConstants';

export async function deleteSchedulingApiAsync<RTYPE extends Type<any>>(
  path: string,
  cancelToken?: CancelToken,
): Promise<ModelType<RTYPE>> {
  return deleteAsync({
    baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL,
    path,
    cancelToken,
  });
}

export async function fetchSchedulingApiAsync<RTYPE extends Type<any>>(
  apiType: ApiType<RTYPE>,
  path: string,
  cancelToken?: CancelToken,
): Promise<ModelType<RTYPE>> {
  return fetchApiAsync(apiType, {
    baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL,
    path,
    cancelToken,
  });
}

export async function postSchedulingApiAsync<RTYPE extends Type<any>>(
  apiType: ApiType<RTYPE>,
  options: HttpSendOptions,
): Promise<ModelType<RTYPE>> {
  return postApiAsync(apiType, {...options, baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL});
}

export async function patchSchedulingApiAsync<RTYPE extends Type<any>>(
  apiType: ApiType<RTYPE>,
  options: HttpSendOptions,
): Promise<ModelType<RTYPE>> {
  return patchApiAsync(apiType, {...options, baseURL: BaseUrlsEnum.SCHEDULING_API_BASE_URL});
}
