import {noop} from 'lodash';
import {createContext, useContext, useEffect} from 'react';

import {Unsubscriber} from '../../../core/src/helpers/react/reactHelpers';

/*
 * Props.
 */

interface CssContextProps {
  addCssToDocument(css: string): Unsubscriber;
}

/*
 * Context.
 */

const CssContext = createContext<CssContextProps>({
  addCssToDocument: () => noop,
});

export const CssContextProvider = CssContext.Provider;

/*
 * Hook.
 */

export function useCss(css: string) {
  const {addCssToDocument} = useContext(CssContext);
  useEffect(() => addCssToDocument(css), [addCssToDocument, css]);
}
