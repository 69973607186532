import * as t from 'io-ts';

import {isDefined} from '../../../libs/shared/core/src/helpers/typescript/typeHelpers';
import {PaletteColorsEnum} from '../../../libs/shared/core/src/models/api/paletteModel';
import {makeEnumRuntimeType} from '../../../libs/shared/core/src/models/api/types/enumTypes';
import {makeOptional} from '../../../libs/shared/core/src/models/api/types/optionalType';
import {makeApiType} from '../helpers/apiType';
import {RConferenceType} from './conferenceTypeModel';
import {parseSchedulingLinkQuestion, RSchedulingLinkQuestion} from './schedulingLinkQuestionModel';

/*
 * Runtime type.
 */

const RPaletteColorsType = makeEnumRuntimeType(PaletteColorsEnum);

export const RSchedulingLink = t.readonly(
  t.type({
    redirectUrl: t.string,
    logoUrl: t.string,
    displayName: t.string,
    greeting: t.string,
    organizerAddress: t.string,
    additionalGuests: t.readonlyArray(t.string),
    conferenceType: makeOptional(RConferenceType),
    questions: t.readonlyArray(RSchedulingLinkQuestion),
    schedulingUrl: t.string,
    availabilityRangeStart: makeOptional(t.number),
    meetingSummary: t.string,
    durationMinutes: t.number,
    color: makeOptional(RPaletteColorsType),
  }),
);

/*
 * Type.
 */

export interface SchedulingLinkModel extends t.TypeOf<typeof RSchedulingLink> {}

/*
 * IO methods.
 */

export function parseSchedulingLink(src: any): SchedulingLinkModel {
  return {
    redirectUrl: src.redirect_url || '',
    logoUrl: src.logo_url || '',
    displayName: src.display_name || '',
    greeting: src.greeting || '',
    organizerAddress: src.organizer_address,
    additionalGuests: src.additional_guests,
    conferenceType: src.conference_type || undefined,
    questions: src.questions.map(parseSchedulingLinkQuestion).filter(isDefined),
    schedulingUrl: src.scheduling_url,
    availabilityRangeStart: src.availability_range_start || undefined,
    meetingSummary: src.meeting_summary || '',
    durationMinutes: src.duration_minutes,
    color: RPaletteColorsType.is(src.color) ? src.color : undefined,
  };
}

/*
 * API type.
 */

export const ApiSchedulingLink = makeApiType('schedulingLink', RSchedulingLink, parseSchedulingLink);
