import {createContext, RefObject} from 'react';

/*
 * Types.
 */

export interface RepositionBoundaryContextValue {
  /** Prevent overflow of dropdowns, tooltips, etc. outside of the bounds of this element. */
  boundaryRef: RefObject<HTMLElement | null>;
}

/*
 * Constants.
 */

const nullRef: RefObject<HTMLElement | null> = Object.freeze({current: null});
const defaultContextValue: RepositionBoundaryContextValue = Object.freeze({boundaryRef: nullRef});

/*
 * Component.
 */

export const RepositionBoundaryContext = createContext<RepositionBoundaryContextValue>(defaultContextValue);
