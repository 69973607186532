import React, {FC} from 'react';
import styled from 'styled-components';

import {center} from '../../styles/mixins';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {Icon, IconName} from '../icon/icon';
import {BoxedInputChildProps, BoxedInputChildStyleProps} from './boxedInputConstants';

/*
 * Props.
 */

interface BoxedInputIconProps extends BoxedInputChildProps {
  /** The icon to display. */
  iconName: IconName;
  /** The color of the icon. */
  color?: string;
  className?: string;
}

/*
 * Style.
 */

const iconWidths = makeSizeConstants('26px', '30px');

const StyledIconDiv = styled.div<BoxedInputChildStyleProps>`
  flex-shrink: 0;
  ${center()}
  width: ${(p) => iconWidths[p.$size]};
  color: ${(p) => p.$color || p.theme.greys.shade60};
`;

/*
 * Component.
 */

/** Side-icon to use within a BoxedInput. */
export const BoxedInputIcon: FC<BoxedInputIconProps> = (props) => (
  <StyledIconDiv className={props.className} $size={props.size} $color={props.color}>
    <Icon name={props.iconName} />
  </StyledIconDiv>
);
