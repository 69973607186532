import * as t from 'io-ts';

/*
 * Runtime type.
 */

export const RSchedulingLinkQuestionAnswer = t.readonly(
  t.type({
    question: t.string,
    answer: t.string,
  }),
);

/*
 * Type.
 */

export type SchedulingLinkQuestionAnswerModel = t.TypeOf<typeof RSchedulingLinkQuestionAnswer>;

/*
 * IO methods.
 */

export function parseSchedulingLinkQuestionAnswer(src: any): SchedulingLinkQuestionAnswerModel {
  return {
    question: src.question,
    answer: src.answer,
  };
}

export function serializeSchedulingLinkQuestionAnswer(src: SchedulingLinkQuestionAnswerModel): any {
  return src;
}
