import {debounce} from 'lodash';
import React, {FC, MutableRefObject, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {TextBox} from '../../../../../libs/shared/ui/src/components/boxedInputs/textBox';
import {greys} from '../../../../../libs/shared/ui/src/styles/colorStyles';
import {VisualSizesEnum} from '../../../../../libs/shared/ui/src/styles/commonStyles';
import {fontSizes} from '../../../../../libs/shared/ui/src/styles/fontStyles';
import {reportToBugsnag} from '../../../helpers/bugsnag';
import {SchedulingLinkModel} from '../../../models/schedulingLinkModel';
import {SchedulingLinkQuestionAnswerModel} from '../../../models/schedulingLinkQuestionAnswerModel';
import {isSelectionQuestion} from '../../../models/schedulingLinkQuestionModel';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepProps, StepUpdater} from '../../common/step/step';
import {BookingSummary} from '../../common/summary/bookingSummary';
import {DeviceTypesEnum, PlatformProps, withPlatform} from '../../platform/platformContext';
import {useBookQueryData} from '../bookRoutesHelpers';
import {BookStepTypesEnum} from '../bookStep';
import {BookSelectionQuestion} from './bookSelectionQuestion';

/*
 * Style.
 */

const StyledContentDiv = withPlatform(styled.div<PlatformProps>`
  width: ${(props) => (props.device === DeviceTypesEnum.MOBILE ? '100%' : '400px')};
`);

const StyledLabelSpan = styled.span`
  font-size: ${fontSizes.medium};
  color: ${greys.shade80};
`;

/*
 * Component.
 */

export const BookStepAnswerQuestions: FC<StepProps> = (props) => {
  const {metadata, onNext, onPrevious, booking, schedulingLink} = props;
  const queryData = useBookQueryData();
  const navigate = useNavigate();

  const [isFormIncomplete, setIsFormIncomplete] = useState(true);

  const nameRef = useRef<string>('');
  const emailRef = useRef<string>('');
  const questionAnswersRef = useRef<ReadonlyArray<SchedulingLinkQuestionAnswerModel>>([]);

  const onNextClick = useMemo(() => {
    // If there isn't another step, disable next.
    if (!onNext) {
      return undefined;
    }

    // If the the form isn't complete, disable next.
    if (isFormIncomplete) {
      return undefined;
    }

    return () =>
      onNext((data) => ({
        ...data,
        booking: {
          ...data.booking,
          name: nameRef.current,
          email: emailRef.current,
          questionAnswers: questionAnswersRef.current,
        },
      }));
  }, [onNext, isFormIncomplete]);

  const updateFormDebounced = useMemo(
    () =>
      debounce(() => {
        const isIncomplete =
          Boolean(!nameRef.current || !isEmail(emailRef.current)) ||
          questionAnswersRef.current.some((questionAnswer) => !questionAnswer.answer);
        setIsFormIncomplete(isIncomplete);
      }, 100),
    [],
  );

  useEffect(
    () =>
      // Cancel debounced function on unmount.
      () =>
        updateFormDebounced.cancel(),
    [updateFormDebounced],
  );

  useEffect(() => {
    // Reset fields if scheduling link changes.
    nameRef.current = '';
    emailRef.current = '';
    questionAnswersRef.current = makeEmptyAnswersForLink(schedulingLink);
  }, [schedulingLink]);

  const onModifyTimeSlot = useCallback(() => {
    const {slotStart, pathname} = queryData;
    if (slotStart) {
      // There is a time slot in the url query. Navigate to the url without the query.
      navigate(pathname);
      return;
    }

    const removeTimeSlotUpdater: StepUpdater = (data) => ({
      ...data,
      booking: {
        ...data.booking,
        isDraft: true,
        start: undefined,
        end: undefined,
      },
    });
    if (onPrevious) {
      // Go to the previous step and remove the time from the booking.
      onPrevious(removeTimeSlotUpdater);
      return;
    }

    reportToBugsnag(new Error('Unable to handle modify click.'));
  }, [onPrevious, queryData, navigate]);

  const renderSidebar = useCallback(
    () => <BookingSummary booking={booking} onModifyTimeSlot={onModifyTimeSlot} />,
    [booking, onModifyTimeSlot],
  );

  return (
    <StepFrame
      metadata={metadata}
      nextButton={{onClick: onNextClick, title: 'Confirm meeting'}}
      onPrevious={props.onPrevious}
      title="Add your information"
      renderSidebar={renderSidebar}
    >
      <StyledContentDiv>
        <TextBox
          placeholder="First and last"
          label={<StyledLabelSpan>Name</StyledLabelSpan>}
          size={VisualSizesEnum.LARGE}
          onChange={(value) => {
            nameRef.current = value;
            updateFormDebounced();
          }}
        />
        <TextBox
          placeholder="you@yourcompany.com"
          label={<StyledLabelSpan>Email</StyledLabelSpan>}
          size={VisualSizesEnum.LARGE}
          onChange={(value) => {
            emailRef.current = value;
            updateFormDebounced();
          }}
        />
        {renderAdditionalQuestions(props, questionAnswersRef, updateFormDebounced)}
      </StyledContentDiv>
    </StepFrame>
  );
};

/*
 * Step.
 */

export const bookStepAnswerQuestions: Step<BookStepTypesEnum> = {
  type: BookStepTypesEnum.ANSWER_QUESTIONS,
  renderStep: (props) => React.createElement(BookStepAnswerQuestions, props),
};

/*
 * Helpers.
 */

function renderAdditionalQuestions(
  props: StepProps,
  questionAnswersRef: MutableRefObject<ReadonlyArray<SchedulingLinkQuestionAnswerModel>>,
  updateFormDebounced: () => void,
) {
  const {questions} = props.schedulingLink;

  return (
    <>
      {questions.map((question) => {
        const updateAnswer = (answer: string) => {
          // eslint-disable-next-line no-param-reassign
          questionAnswersRef.current = makeUpdatedAnswersForQuestion(
            questionAnswersRef.current,
            question.question,
            answer,
          );
          updateFormDebounced();
        };

        if (isSelectionQuestion(question)) {
          return (
            <BookSelectionQuestion
              key={question.question}
              question={question}
              onSelectAnswer={updateAnswer}
            />
          );
        }

        return (
          <TextBox
            key={question.question}
            placeholder="Your response"
            label={<StyledLabelSpan>{question.question}</StyledLabelSpan>}
            size={VisualSizesEnum.LARGE}
            onChange={updateAnswer}
          />
        );
      })}
    </>
  );
}

function isEmail(value: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
}

/*
 * Helpers.
 */

function makeEmptyAnswersForLink(
  schedulingLink: SchedulingLinkModel,
): ReadonlyArray<SchedulingLinkQuestionAnswerModel> {
  return schedulingLink.questions.map((question) => {
    // For selection questions, automatically use the first answer choice.
    if (isSelectionQuestion(question)) {
      return {
        question: question.question,
        answer: question.selectionOptions[0],
      };
    }

    return {
      question: question.question,
      answer: '',
    };
  });
}

function makeUpdatedAnswersForQuestion(
  currentAnswers: ReadonlyArray<SchedulingLinkQuestionAnswerModel> | undefined,
  question: string,
  answer: string,
): ReadonlyArray<SchedulingLinkQuestionAnswerModel> {
  if (!currentAnswers || !currentAnswers.some((questionAnswer) => questionAnswer.question === question)) {
    throw new Error('Unexpected currentAnswers');
  }

  return currentAnswers.map((questionAnswer) => {
    // The answer for this question is unchanged.
    if (questionAnswer.question !== question) {
      return questionAnswer;
    }

    return {
      question,
      answer,
    };
  });
}
