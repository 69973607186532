import React, {ComponentType, FC, ReactNode, useMemo} from 'react';

import {createMandatoryContext} from '../../react/reactHelpers';
import {I18n} from '../i18n';

/*
 * Context.
 */

export interface I18nContextValue {
  i18n: I18n;
}

const [I18nContext, useI18nContext] = createMandatoryContext<I18nContextValue>();

/*
 * Props.
 */

interface I18nProviderProps {
  i18n: I18n;
  children: ReactNode;
}

/*
 * Components.
 */

export const I18nProvider: FC<I18nProviderProps> = ({i18n, children}) => {
  const value = useMemo(() => ({i18n}), [i18n]);

  return <I18nContext.Provider value={value}>{children}</I18nContext.Provider>;
};

/*
 * Hooks.
 */

export const useI18n = useI18nContext;

/*
 * HOC.
 */

/** @deprecated */
export function withI18n<T>(Component: ComponentType<T>): ComponentType<Omit<T, keyof I18nContextValue>> {
  // eslint-disable-next-line func-names
  return function (props) {
    return (
      // eslint-disable-next-line react/jsx-props-no-spreading, @typescript-eslint/consistent-type-assertions
      <I18nContext.Consumer>{(value) => <Component {...(props as T)} {...value} />}</I18nContext.Consumer>
    );
  };
}

/*
 * Helpers.
 */

/** Ensures we have a translation context for future-proofing. */
export function useEnsureI18nContext() {
  if (process.env.NODE_ENV === 'production') {
    return;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useI18n();
}
