import {blendColors} from '../../../../core/src/models/helpers/colorHelpers';
import {alphas, greys, greysDark} from '../../styles/colorStyles';

export const lightCommentComposerSemanticStyles = {
  text: greys.black,
  placeholder: greys.shade70,
  background: blendColors(greys.shade20, alphas.gray10),
  backgroundHover: blendColors(greys.shade20, alphas.gray20),
  backgroundFocused: greys.white,
};

export type CommentComposerSemanticStyles = typeof lightCommentComposerSemanticStyles;

export const darkCommentComposerSemanticStyles: CommentComposerSemanticStyles = {
  text: greysDark.black,
  placeholder: greysDark.shade70,
  background: greysDark.shade20,
  backgroundHover: greysDark.shade30,
  backgroundFocused: greysDark.shade20,
};
