import {ButtonVariantsEnum} from '../components/buttons/buttonConstants';
import {alphas, greys, palette} from '../styles/colorStyles';
import {AnnotationSemanticStyles} from './semantic/annotationSemanticStyles';
import {AvatarSemanticStyles} from './semantic/avatarSemanticStyles';
import {BannerSemanticStyles} from './semantic/bannerSemanticStyles';
import {BetaFeaturePillSemanticStyles} from './semantic/betaFeaturePillSemanticStyles';
import {ButtonSemanticStyles} from './semantic/buttonSemanticStyles';
import {CellSemanticStyles} from './semantic/cellSemanticStyles';
import {CommentComposerSemanticStyles} from './semantic/commentComposerSemanticStyles';
import {MessageSemanticStyles} from './semantic/messageSemanticStyles';
import {NewFeaturePillSemanticStyles} from './semantic/newFeaturePillSemanticStyles';
import {SearchBarSemanticStyles} from './semantic/searchBarSemanticStyles';
import {TagSemanticStyles} from './semantic/tagSemanticStyles';
import {TopBarSemanticStyles} from './semantic/topBarSemanticStyles';
import {themeConstants} from './themeConstants';

export enum ThemeTypesEnum {
  LIGHT = 'light',
  DARK = 'dark',
  EXPANDED_DARK = 'expandedDark',
  DARK_SIDEBAR = 'darkSidebar',
}

export interface ThemeType {
  type: ThemeTypesEnum;
}

/*
 * Semantic styles implemented as a part of the dark mode expansion. These
 * use the new dark palette in dark mode.
 */
export interface SemanticStyles {
  brand: string;

  appBackground: string;
  foreground: string;

  text: string;
  composerText: string;
  composerPlaceholder: string;

  interactiveContainer: string;
  interactiveText: string;
  interactiveTextHover: string;
  interactiveIcon: string;
  interactiveBorders: string;

  invertedText: string;
  invertedSubtext: string;
  invertedIcon: string;

  urlTooltipMinorText: string;
  modalBackdrop: string;

  colorScheme: string;

  inputBackground: string;
  windowsButtonHover: string;

  highlightText: string;

  refinedP2ResultsIndicator: string;

  cell: CellSemanticStyles;
  topBar: TopBarSemanticStyles;
  searchBar: SearchBarSemanticStyles;
  tag: TagSemanticStyles;
  avatar: AvatarSemanticStyles;
  message: MessageSemanticStyles;
  banner: BannerSemanticStyles;
  button: ButtonSemanticStyles;
  annotation: AnnotationSemanticStyles;
  commentComposer: CommentComposerSemanticStyles;
}

/*
 * These semantic styles were implemented during the initial rollout of Dark Mode,
 * which only affected P1. At the time, there was no dark palette, so they use the
 * light mode palette for their implementation. These should be updated to use the
 * dark palette in dark mode.
 */
export interface LegacySemanticStyles {
  // Workspaces.
  background: string;
  separator: string;
  itemSeparator: string;
  header: string;
  headerHover: string;
  headerMore: string;
  headerMoreHover: string;

  // Workspaces items.
  item: string;
  itemBold: string;
  itemSelected: string;
  icon: string;
  iconHover: string;
  itemBackgroundHover: string;
  itemBackgroundSelected: string;
  itemPrivateLock: string;
  itemAdd: string;
  itemAddHover: string;
  itemAddDismiss: string;
  itemAddDismissHover: string;
  itemDot: string;
  itemDotHover: string;
  itemCheckBox: string;

  // Workspaces Tab bar.
  itemIcon: string;
  itemIconHover: string;
  itemIconSelected: string;
  itemIconBackground: string;
  itemIconBackgroundHover: string;

  // Pill.
  betaFeaturePill: BetaFeaturePillSemanticStyles;
  newFeaturePill: NewFeaturePillSemanticStyles;

  // Workspaces tag more icon.
  itemMoreIcon: string;
  itemMoreIconHover: string;
  itemMoreIconSelected: string;
  itemMoreIconBackground: string;
  itemMoreIconBackgroundHover: string;

  // Workspaces scrollbar.
  scrollbarTrack: string;
  scrollbarThumb: string;
  scrollbarThumbHover: string;

  // Banners.
  bannerText: string;
  bannerBackground: string;
  bannerSecondaryText: string;
  bannerActionHover: string;
  bannerIcon: string;
  bannerTimeRemaining: string;

  // Trial banner.
  trialBackground: string;
  trialPrompt: string;
  trialNumber: string;
  trialChat: string;
  trialChatHover: string;

  // Date picker.
  datePickerPrimary: string;
  datePickerSecondary: string;
  datePickerDisabled: string;
  datePickerBackgroundHover: string;

  // Disabled checkbox.
  disabledCheckboxBoxColor: string;
  disabledCheckboxCheckColor: string;

  // P1 declutter Auto Recommendations
  p1BannerBackground: string;
  p1BannerTitle: string;
  p1BannerSubtitle: string;
  p1BannerIcon: string;
  p1RarelyViewedBadgeBackground: string;
  p1RarelyViewedBadgeText: string;
  p1HelpTipSubtitle: string;
  p1HelpTipClose: string;
  p1HelpTipCloseHover: string;
  p1HelpTipCloseBackgroundHover: string;
  p1BannerButtonVariant: ButtonVariantsEnum;
  p1BannerLinkVariant: ButtonVariantsEnum;

  // Settings menu switcher
  settingsMenuSwitcherBackground: string;
  settingsMenuSwitcherBlackForeground: string;
  settingsMenuSwitcherBlackForegroundHover: string;
  settingsMenuSwitcherBlackSelectedForeground: string;
  settingsMenuSwitcherBlackSelectedBackground: string;
}

export interface ColorStyles {
  greys: typeof greys;
  alphas: typeof alphas;
  palette: typeof palette;
}

export interface ColorConstants {
  constants: typeof themeConstants;
}

export type UserTheme = Readonly<
  SemanticStyles & LegacySemanticStyles & ColorStyles & ThemeType & ColorConstants
>;
