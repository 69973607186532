import {compact, isInteger, uniq} from 'lodash';
import slugify from 'slugify';

import {globalThis} from '../browser/globalThis';

export const maxCharCode = '\uffff';

/** Generate a cryptographically-strong random string. */
export function randomString(length: number = 32) {
  const {crypto} = globalThis;

  // Find how many random values we need.
  const halfLength = Math.ceil(length / 2);
  const randomValues = new Uint8Array(halfLength);

  // Generate the random values.
  crypto.getRandomValues(randomValues);

  // Convert to base16 and return.
  let result = '';
  for (let i = 0; i < halfLength; i++) {
    const randomValue = randomValues[i];
    result += (randomValue < 16 ? '0' : '') + randomValue.toString(16);
  }

  return result;
}

export function urlize(text: string) {
  return slugify(text);
}

export function makeVariableFriendly(text: string) {
  return slugify(text, {
    lower: true,
    replacement: '_',
    strict: true,
  });
}

export function escapeRegex(text: string) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

/** Returns an array of regexp matches even if the regex has the `g` flag */
export function getRegexpMatches(regexp: RegExp, text: string) {
  // We need the provided regexp to have the G flag.
  if (!regexp.flags.includes('g')) {
    throw new Error('The provided regexp needs to be global.');
  }

  const results: Array<RegExpExecArray> = [];
  let match;

  // eslint-disable-next-line
  while ((match = regexp.exec(text))) results.push(match);

  return results;
}

const initialsRegexp = /(\w)\w*(?:[\s.]+(\w))?/;
/** Extract the first two initials from any string. */
export function extractInitials(src: string) {
  const initials = src.match(initialsRegexp);
  if (!initials) {
    return '';
  }

  // Format as a single uppercase string.
  return `${initials[1]}${initials[2] || ''}`.toUpperCase();
}

export function sanitizeFilename(filename: string) {
  const replacement = '';
  const illegalRe = /[/?<>\\:*|":]/g;
  const reservedRe = /^\.+$/;
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
  const windowsTrailingRe = /[. ]+$/;
  const maxLength = 160; // Needed on Windows

  const sanitized = filename
    .replace(illegalRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)
    .slice(0, maxLength);

  return sanitized;
}

export function isHtml(text: string): boolean {
  return Boolean(text && /<\s*[a-zA-Z]+[^>]*>/.test(text));
}

export function parsePercent(text: string): number {
  return parseFloat(text);
}

const basicLinkRegexp = /^(?:https?|mailto):\S+$/;
const basicLinkSplitRegexp = /((?:https?|mailto):\S+)/;

/** Very crude way of detecting possible links. */
export function splitBasicLinks(text: string) {
  return text.split(basicLinkSplitRegexp);
}

export function isBasicLink(text: string) {
  return basicLinkRegexp.test(text);
}

/*
 * Email address.
 */

// A slash-escaped character. (RFC 2822 quoted-pair)
const quotedPairPattern = '\\\\.';
// Slash-escaped text in double quotes. (RFC 2822 quoted-string)
const doubleQuotedStringPattern = `"(?:[^\\\\"]+|${quotedPairPattern})+"`;
// Slash-escaped text in square brackets. (RFC 2822 domain-literal)
const squareBracketQuotedStringPattern = `\\[(?:[^\\\\\\[\\]]+|${quotedPairPattern})\\]`;

// Any character except whitespace and specials. (simplified version of RFC 2822 atom-text)
const atomTextPattern = '[^\\s\\(\\)<>\\[\\]:;@\\\\,."]+';
// Atom text delimited by dots. (RFC 2822 dot-atom)
const dotAtomPattern = `(?:${atomTextPattern}(?:\\.${atomTextPattern})*)`;
const permissiveDotAtomPattern = '[^:\\s]+';
// The local part of an email address. (RFC 2822 local-part)
const localPartPattern = `(?:${dotAtomPattern}|${doubleQuotedStringPattern})`;
const permissiveLocalPartPattern = `(?:${permissiveDotAtomPattern}|${doubleQuotedStringPattern})`;
// The domain part of an email address. (RFC 2822 domain)
const domainPattern = `(?:${dotAtomPattern}|${squareBracketQuotedStringPattern})`;
const permissiveDomainPattern = '\\S+';

// Matches an email address, used for validation. (RFC 2822 addr-spec)
const emailRegExp = new RegExp(`^(${localPartPattern})@${domainPattern}$`);

// Matches an email address, used for finding a substring.
const permissiveEmailPattern = `${permissiveLocalPartPattern}@${permissiveDomainPattern}`;
const permissiveEmailRegExp = new RegExp(`(${permissiveEmailPattern})`);
const nameAndEmailRegExp = new RegExp(`"?([^<"]*)"?\\s*<(${permissiveEmailPattern})>`);

/**
 * Split emails on commas, tabs, newlines, or semicolons. Users type in one of
 * these symbols to indicate they want to type in a new email. We also include
 * space, since the tokens editor converts Enter key presses into spaces, and we
 * want Enter to trigger tokenization.
 */
const delimiterRegexp = /[,;\t\n ]+/;

/**
 * Determines whether the given string looks like a valid email address.
 *
 * Use this for validating an email input field.
 */
export function isValidEmailAddress(text: string): boolean {
  return emailRegExp.test(text);
}

/**
 * Returns a substring that looks like an email address.
 *
 * Use this for finding an email address in a string.
 */
export function matchEmail(text: string) {
  const match = text.match(permissiveEmailRegExp);
  return match && match[1];
}

/**
 * Takes a string and tries to parse out email tokens.
 *
 * Returns any parsed email tokens plus the remaining text which we couldn't
 * parse emailsToInvite tokens out of.
 */
export function parseEmailsFromText(text: string): [emails: ReadonlyArray<string>, rest: string] {
  const tokens = text.split(delimiterRegexp);

  const parsedEmails = tokens.map(matchEmail);

  const emailsToAdd = compact(parsedEmails);
  if (emailsToAdd.length < 1) {
    return [[], text];
  }

  const invalidTokens = tokens.filter((_t, index) => !parsedEmails[index]);
  const remainingText = invalidTokens.join(',');

  return [emailsToAdd, remainingText];
}

export function parseUniqueEmailsFromText(text: string): [emails: ReadonlyArray<string>, rest: string] {
  const [result, rest] = parseEmailsFromText(text);
  return [uniq(result), rest];
}

export function matchNameAndEmail(text: string) {
  const match = text.match(nameAndEmailRegExp);
  if (!match) {
    return undefined;
  }

  const [, name, email] = match;
  return {name: name.trim(), email};
}

export function extractEmailDomain(email: string) {
  const domain = email.toLowerCase().split('@')[1];
  return domain ?? null;
}

export function isNonEmptyString(maybeStr: string | null | undefined): maybeStr is string {
  return Boolean(maybeStr);
}

/*
 * Onboarding.
 */

export function isValidName(value: string) {
  return value.length > 0 && value.length < 30;
}

export function isValidCompanyName(value: string) {
  return value.length > 0 && value.length < 50;
}

export function isValidPhoneNumber(value: string) {
  const nb = value.replace(/[\u200B-\u200C\u202C-\u202D\uFEFF\s]/g, '');
  return /^\+?[0-9()-]{7,14}$/.test(nb);
}

export class InvalidPhoneNumberError extends Error {}

export function padStart(n: number, padCount: number, padCharacter: string) {
  const strN = n.toString();

  let padding = '';
  const steps = Math.max(0, padCount - strN.length);
  for (let i = 0; i < steps; i++) {
    padding += padCharacter;
  }

  return padding + strN;
}

// This pattern will match any string of the pattern "url(...)", where "..." is
// zero or more whitespace characters, followed by one or more non-whitespace
// characters, ending with zero or more whitespace characters.
const CSSURLTestRegex = /url\(\s*([^\s)])+\s*\)/i;

export function stringIsCssUrlString(text: string) {
  return CSSURLTestRegex.test(text);
}

export function sanitizeCssUrl(text: string): string {
  const cssURLSanitizeRegex = new RegExp(CSSURLTestRegex, 'ig');
  return text.replace(cssURLSanitizeRegex, '');
}

export function stringHasCssUrl(text: string) {
  return CSSURLTestRegex.test(text);
}

const domiainNameRegExp = /([a-zA-Z0-9]+(-[a-zA-Z0-9]+)*\.)+[a-zA-Z]{2,}/;
export function parseDomainName(value: string) {
  const match = value.match(domiainNameRegExp);
  return match && match[0];
}

const endsWithWhiteSpaceRegExp = /\s+$/;
export function endsWithWhiteSpace(text: string) {
  return endsWithWhiteSpaceRegExp.test(text);
}

const endsWithLatinCharacterRegExp = /[\w@%#&()/*_<>\\-]$/;
export function endsWithLatinCharacter(text: string) {
  const textWithoutZeroWidthSpace = replaceZeroWidthSpace(text);
  return endsWithLatinCharacterRegExp.test(textWithoutZeroWidthSpace);
}

const zeroWidthSpaceRegExp = /[\u200B-\u200D\uFEFF]/g;
function replaceZeroWidthSpace(text: string) {
  return text.replace(zeroWidthSpaceRegExp, '');
}

export function safeJsonStringify(blob: any): string {
  try {
    return JSON.stringify(blob);
  } catch (e) {
    return '{}';
  }
}

export function previewText(text: string, length: number) {
  return text.length < length ? text : `${text.slice(0, length)}\u2026`;
}

/*
 * Host validation.
 */

// This is a crude way of detecting obvious private addresses.
// The backend does a real resolution, we just want to catch obvious cases in the frontend.
export function isLikePrivateAddress(value: string) {
  return value === '127.0.0.1' || value.endsWith('localhost');
}

/*
 * CSV.
 */

const CSV_QUOTE = '"';

// See https://github.com/zemirco/json2csv/blob/master/lib/formatters/string.js
export function formatStringForCsv(value?: string) {
  if (value === undefined) {
    return value;
  }
  const escapedValue = value.includes(CSV_QUOTE)
    ? value.replace(new RegExp(CSV_QUOTE, 'g'), `${CSV_QUOTE}${CSV_QUOTE}`)
    : value;
  return `${CSV_QUOTE}${escapedValue}${CSV_QUOTE}`;
}

export function lowerAlpha(input: number) {
  if (!isInteger(input) || input < 1) {
    throw new Error('`lowerAlpha()` input must be an integer greater than 0.');
  }

  const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  const letterCount = alphabet.length;

  let offset = input - 1;
  let letters = alphabet[offset % letterCount];

  // eslint-disable-next-line no-cond-assign
  while ((offset = Math.floor(offset / letterCount)) > 0) {
    letters = alphabet[--offset % letterCount] + letters;
  }

  return letters;
}

export function upperCaseFirst(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1);
}

const digitsRegex = /^\d+$/;
const alphaNumbericRegex = /^[a-zA-Z\d]+$/;

export function isAlphaNumeric(value: string) {
  return alphaNumbericRegex.test(value);
}

export function isDigit(value: string) {
  return digitsRegex.test(value);
}
