import {sample} from 'lodash';

import {collectEnumValues, makeEnumRuntimeType} from './types/enumTypes';

export enum PaletteColorsEnum {
  GREY = 'grey',
  PINK = 'pink',
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  TEAL = 'teal',
  BLUE = 'blue',
  PURPLE = 'purple',
}

export const RPaletteColorsEnum = makeEnumRuntimeType(PaletteColorsEnum);

export const paletteColors = collectEnumValues(PaletteColorsEnum);

export function getRandomPaletteColor(): PaletteColorsEnum {
  const randomColor = sample(paletteColors);

  // This should not happen.
  if (!randomColor) {
    throw new Error();
  }

  return randomColor;
}
