import React, {FC} from 'react';
import styled from 'styled-components';

import {AsyncStatusesEnum} from '../../../../../../libs/shared/core/src/helpers/types/asyncStatuses';
import {PaletteColorsEnum} from '../../../../../../libs/shared/core/src/models/api/paletteModel';
import {Button} from '../../../../../../libs/shared/ui/src/components/buttons/button';
import {ButtonVariantsEnum} from '../../../../../../libs/shared/ui/src/components/buttons/buttonConstants';
import {Loader} from '../../../../../../libs/shared/ui/src/components/loaders/loader';
import {VisualSizesEnum} from '../../../../../../libs/shared/ui/src/styles/commonStyles';
import {roundedBorder} from '../../../../helpers/style';
import {Header} from '../../header';
import {useTheme} from '../../theme/themeContext';
import {StepError} from '../error/stepError';
import {StepFrameProps} from './stepFrameProps';

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  display: grid;
  grid-template-areas:
    'header header'
    'content sidebar'
    'buttons sidebar'
    '. sidebar';
  max-height: 100vh;
`;

const StyledHeader = styled(Header)`
  grid-area: header;
  margin-top: 40px;
`;

const StyledContentDiv = styled.div`
  grid-area: content;
`;

const StyledSidebarWrapperDiv = styled.div`
  grid-area: sidebar;
  margin-left: 24px;
`;

const StyledSidebarContentDiv = styled.div`
  padding: 20px;
  width: 260px;
  ${roundedBorder()};
`;

const StyledButtonRow = styled.div`
  grid-area: buttons;
  display: flex;
  margin-bottom: 40px;
`;

const StyledLoaderRow = styled.div`
  grid-area: buttons;
  display: flex;
  height: 30px;
  margin-top: 10px;

  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 10px 10px 0px 0px;
`;

/*
 * Component.
 */

export const StepFrameDesktop: FC<StepFrameProps> = (props) => {
  const {title, children} = props;
  const {color} = useTheme();

  return (
    <>
      <StyledWrapperDiv>
        <StyledHeader>{title}</StyledHeader>
        <StyledContentDiv>{children}</StyledContentDiv>
        {renderSidebar(props)}
        {renderButtonRow(props, color)}
      </StyledWrapperDiv>
      {maybeRenderError(props)}
    </>
  );
};

/*
 * Helpers.
 */

function renderButtonRow(props: StepFrameProps, color: PaletteColorsEnum) {
  const {nextButton, metadata} = props;
  const {asyncStatus} = metadata;

  if (asyncStatus === AsyncStatusesEnum.LOADING) {
    return (
      <StyledLoaderRow>
        <Loader size={VisualSizesEnum.MEDIUM} />
      </StyledLoaderRow>
    );
  }

  return (
    <StyledButtonRow>
      {nextButton && (
        <StyledButton
          variant={ButtonVariantsEnum.CUSTOM_DISABLED}
          isRounded
          size={VisualSizesEnum.MEDIUM}
          onClick={nextButton.onClick}
          isDisabled={!nextButton.onClick}
          color={color}
        >
          {nextButton.title}
        </StyledButton>
      )}
    </StyledButtonRow>
  );
}

function renderSidebar(props: StepFrameProps) {
  if (!props.renderSidebar) {
    return null;
  }

  return (
    <StyledSidebarWrapperDiv>
      <StyledSidebarContentDiv>{props.renderSidebar()}</StyledSidebarContentDiv>
    </StyledSidebarWrapperDiv>
  );
}

function maybeRenderError(props: StepFrameProps) {
  const {error, onDismissError} = props.metadata;
  if (!error) {
    return null;
  }

  return <StepError error={error} onDismiss={onDismissError} />;
}
