import * as t from 'io-ts';

/*
 * Runtime type.
 */

export enum SchedulingLinkQuestionTypesEnum {
  FREEFORM = 'freeform',
  SELECTION = 'selection',
}

const RSchedulingLinkFreeformQuestionDirty = t.readonly(
  t.type({
    question: t.string,
    type: t.literal(SchedulingLinkQuestionTypesEnum.FREEFORM),
  }),
);

const RSchedulingLinkSelectionQuestionDirty = t.readonly(
  t.type({
    question: t.string,
    type: t.literal(SchedulingLinkQuestionTypesEnum.SELECTION),
    selectionOptions: t.readonlyArray(t.string),
  }),
);

export const RSchedulingLinkQuestion = t.union([
  RSchedulingLinkFreeformQuestionDirty,
  RSchedulingLinkSelectionQuestionDirty,
]);

/*
 * Type.
 */

export type SchedulingLinkFreeformQuestionModel = t.TypeOf<typeof RSchedulingLinkFreeformQuestionDirty>;
export type SchedulingLinkSelectionQuestionModel = t.TypeOf<typeof RSchedulingLinkSelectionQuestionDirty>;
export type SchedulingLinkQuestionModel = t.TypeOf<typeof RSchedulingLinkQuestion>;

/*
 * IO methods.
 */

export function parseSchedulingLinkQuestion(src: any): SchedulingLinkQuestionModel | undefined {
  switch (src.question_type) {
    case 'freeform':
      return {
        question: src.question,
        type: SchedulingLinkQuestionTypesEnum.FREEFORM,
      };
    case 'selection':
      return {
        question: src.question,
        type: SchedulingLinkQuestionTypesEnum.SELECTION,
        selectionOptions: src.selection_options,
      };
    default:
      return undefined;
  }
}

/*
 * Helpers.
 */

export function isSelectionQuestion(
  question: SchedulingLinkQuestionModel,
): question is SchedulingLinkSelectionQuestionModel {
  return question.type === SchedulingLinkQuestionTypesEnum.SELECTION;
}
