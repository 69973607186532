/**
 * @file
 * These are explicit exports of common globals, to make use of those globals a little more explicit.
 */

import {
  buildInstrumentedAnimationFrameProvider,
  InstrumentedAnimationFrameProvider,
  isInstrumentedAnimationFrameProvider,
} from '../instrumentation/animationFrame';
import {
  buildInstrumentedIdleCallbackProvider,
  IdleCallbackProvider,
  InstrumentedIdleCallbackProvider,
  isInstrumentedIdleCallbackProvider,
} from '../instrumentation/idleCallback';
import {
  buildInstrumentedTimeoutProvider,
  InstrumentedTimeoutProvider,
  isInstrumentedTimeoutProvider,
  TimeoutProvider,
} from '../instrumentation/timeout';

type DefaultCallbacksProvider = AnimationFrameProvider & IdleCallbackProvider & TimeoutProvider;
type InstrumentedCallbacksProvider = InstrumentedAnimationFrameProvider &
  InstrumentedIdleCallbackProvider &
  InstrumentedTimeoutProvider;

/** Let's use a custom FrontWindow type with timing functions replaced with instrumented variants. */
export type FrontWindow = Omit<Window, keyof DefaultCallbacksProvider> & InstrumentedCallbacksProvider;

const globalScope = window;

export const globalWindow = buildFrontWindow(globalScope);
export const globalDocument = globalScope.document;
export const {setTimeout, setInterval, clearTimeout, clearInterval} = globalScope;

/** Find the origin of the global window. */
export function findGlobalOrigin() {
  const {protocol, host} = globalWindow.location;
  return `${protocol}//${host}`;
}

export enum IframeFeaturePoliciesEnum {
  CAMERA = 'camera',
  FULLSCREEN = 'fullscreen',
  MICROPHONE = 'microphone',
  CLIPBOARD_READ = 'clipboard-read',
  CLIPBOARD_WRITE = 'clipboard-write',
  DISPLAY_CAPTURE = 'display-capture',
}

/**
 * WARNING: This function should:
 *   - Not remove properties from the window object since other places may depend on them.
 *   - Still work if called more than once for a certain window.
 */
export function buildFrontWindow(window: Window): FrontWindow {
  if (isInstrumentedCallbacksProvider(window)) {
    // We've already added the instrumented callback providers to this window.
    return window;
  }

  const instrumentedTimeoutProvider = buildInstrumentedTimeoutProvider(window);
  const instrumentedAnimationFrameProvider = buildInstrumentedAnimationFrameProvider(window);
  const instrumentedIdleCallbackProvider = buildInstrumentedIdleCallbackProvider(window);

  return Object.assign(
    window,
    instrumentedTimeoutProvider,
    instrumentedAnimationFrameProvider,
    instrumentedIdleCallbackProvider,
  );
}

export function buildWindow(frontWindow: FrontWindow): Window {
  // WARNING: It is unsafe to use Window in our app but we may need to convert a FrontWindow to Window
  // to work with external libraries that take in a window.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return frontWindow as any as Window;
}

/*
 * Helpers.
 */

function isInstrumentedCallbacksProvider<T extends object>(
  window: T,
): window is T & InstrumentedCallbacksProvider {
  return (
    isInstrumentedAnimationFrameProvider(window) &&
    isInstrumentedIdleCallbackProvider(window) &&
    isInstrumentedTimeoutProvider(window)
  );
}

export function reloadBrowser() {
  globalWindow.location.reload();
}
