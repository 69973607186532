import {CancelToken} from 'axios';
import React, {FC, ReactNode, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {BookingCancelledError, BookingDisabledError, HttpResponseError} from '../../helpers/errors';
import {fetchBookingAsync} from '../../helpers/model/booking';
import {AsyncRenderer} from '../common/asyncRenderer';
import {FullScreenError} from '../common/error/fullScreenError';
import {renderFullScreenLoader} from '../common/fullScreenLoader';
import {defaultLocale} from '../common/locale/localeContext';
import {MainContentWrapper} from '../common/mainContentWrapper';
import {StepData} from '../common/step/step';
import {StepController} from '../common/step/stepController';
import {cancelStepConfirmCancel} from './confirmCancel/cancelStepConfirmCancel';
import {cancelStepViewConfirmation} from './viewConfirmation/cancelStepViewConfirmation';

/*
 * Component.
 */

export const CancelPage: FC = () => {
  const {bookingToken = ''} = useParams();

  const asyncOperation = useCallback(
    (cancelToken: CancelToken) => makeInitialCancelDataAsync(bookingToken, cancelToken),
    [bookingToken],
  );

  return (
    <MainContentWrapper>
      <AsyncRenderer
        asyncOperation={asyncOperation}
        render={(cancelData) => (
          <StepController steps={cancelData.steps} initialStepData={cancelData.initialStepData} />
        )}
        renderLoading={renderFullScreenLoader}
        renderError={renderAsyncError}
      />
    </MainContentWrapper>
  );
};

/*
 * Helpers.
 */

interface InitialCancelData {
  steps: any;
  initialStepData: StepData;
}

export async function makeInitialCancelDataAsync(
  bookingToken: string,
  cancelToken?: CancelToken,
): Promise<InitialCancelData> {
  // Fetch the booking.
  const booking = await fetchBookingAsync(bookingToken, cancelToken);
  const {disabled, cancelled, schedulingLink} = booking;

  if (disabled) {
    throw new BookingDisabledError(booking);
  }

  if (cancelled) {
    throw new BookingCancelledError(booking);
  }

  return {
    steps: [cancelStepConfirmCancel, cancelStepViewConfirmation],
    initialStepData: {
      schedulingLink,
      booking,
      locale: defaultLocale,
    },
  };
}

function renderAsyncError(error: any) {
  return <FullScreenError description={renderErrorDescription(error)} />;
}

function renderErrorDescription(error: any): ReactNode {
  if (error instanceof BookingDisabledError) {
    return 'This meeting can no longer be modified. Please contact the owner of this scheduling link to reschedule or cancel this meeting.';
  }

  if (error instanceof BookingCancelledError) {
    return 'This meeting is already cancelled.';
  }

  if (error instanceof HttpResponseError && error.status === 404) {
    return 'This meeting does not exist.';
  }

  return 'An unexpected error occurred.';
}
