import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';

import error from '../../../assets/images/error.png';
import {DeviceTypesEnum, PlatformProps, withPlatform} from '../../platform/platformContext';
import {Header} from '../header';

/*
 * Props.
 */

export interface FullScreenErrorProps {
  description: ReactNode;
}

/*
 * Style.
 */

const StyledWrapperDiv = withPlatform(styled.div<PlatformProps>`
  display: grid;
  align-content: center;
  align-items: center;
`);

const StyledImage = withPlatform(styled.img<PlatformProps>`
  width: 100%;
  margin-top: ${(props) => (props.device === DeviceTypesEnum.DESKTOP ? '-40px' : '20px')};
`);

const StyledDescriptionDiv = withPlatform(styled.div<PlatformProps>`
  margin-top: 8px;
  ${(props) => (props.device === DeviceTypesEnum.DESKTOP ? 'max-width: 50%;' : '')}
`);

/*
 * Component.
 */

export const FullScreenError: FC<FullScreenErrorProps> = (props) => (
  <StyledWrapperDiv>
    <Header>Oops!</Header>
    <StyledDescriptionDiv>{props.description}</StyledDescriptionDiv>
    <StyledImage src={error} />
  </StyledWrapperDiv>
);
