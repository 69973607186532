import React, {FC, ReactNode, RefObject, useRef} from 'react';
import styled from 'styled-components';

import {InteractionComponentProps} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {Renderer, RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {PopoverCoordinator, PopoverTransferProps} from '../popovers/popoverCoordinator';
import {TooltipCoordinator} from '../tooltips/tooltipCoordinator';
import {DropdownBox, DropdownBoxProps} from './dropdownBox';

/*
 * Props.
 */

export interface DropdownBoxCoordinatorProps extends DropdownBoxProps, InteractionComponentProps {
  /** Render the dropdown to show when clicked. */
  renderDropdown: RendererOf<PopoverTransferProps>;
  /** Render the dropdown button. */
  renderButton?: (isOpen: boolean, props: DropdownBoxCoordinatorProps) => ReactNode;
  /** Render a tooltip under the button. */
  renderTooltip?: Renderer;
  /** Should PreventDefault on MouseDown events. */
  shouldPreventMouseDown?: boolean;
  className?: string;
  onPopoverToggle?: (arg: boolean) => void;
  onRemove?: () => void;
  backgroundColor?: string;
  isModalInitiallyOpen?: boolean;
  shouldDisablePadding?: boolean;
}

/*
 * Style.
 */

interface WrapperStyleProps {
  $hasLabel?: boolean;
  $shouldDisablePadding?: boolean;
}
const StyledWrapperDiv = styled.div<WrapperStyleProps>`
  padding: ${(p) => {
    if (p.$shouldDisablePadding) {
      return '0';
    }
    return p.$hasLabel ? '0 0 12px 0' : '12px 0';
  }};
`;

/*
 * Component.
 */

export const DropdownBoxCoordinator: FC<DropdownBoxCoordinatorProps> = (props) => {
  const boxRef = useRef<HTMLDivElement>(null);
  return (
    <StyledWrapperDiv
      $hasLabel={Boolean(props.label)}
      $shouldDisablePadding={props.shouldDisablePadding}
      className={props.className}
    >
      <PopoverCoordinator
        targetRef={boxRef}
        renderButton={(isOpen) => renderButton(isOpen, props, boxRef)}
        renderModal={props.renderDropdown}
        shouldPreventMouseDown={props.shouldPreventMouseDown}
        isDisabled={props.isDisabled}
        interactionId={props.interactionId}
        onPopoverToggle={props.onPopoverToggle}
        isModalInitiallyOpen={props.isModalInitiallyOpen}
      />
    </StyledWrapperDiv>
  );
};

/*
 * DropdownBox.
 */

function renderButton(
  isOpen: boolean,
  props: DropdownBoxCoordinatorProps,
  boxRef: RefObject<HTMLDivElement>,
) {
  if (props.renderButton) {
    return props.renderButton(isOpen, props);
  }

  return (
    <TooltipCoordinator render={props.renderTooltip}>
      <DropdownBox {...props} hasHover={isOpen} noInnerMargin ref={boxRef} />
    </TooltipCoordinator>
  );
}
