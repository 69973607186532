import React, {FC} from 'react';
import styled from 'styled-components';

import {CheckBox} from '../../../../../libs/shared/ui/src/components/booleanInputs/checkBox';
import {TimezoneDropdownBox} from '../../../../../libs/shared/ui/src/components/datetime/timezoneDropdownBox';
import {VisualSizesEnum} from '../../../../../libs/shared/ui/src/styles/commonStyles';
import {WeekdaysEnum} from '../../../helpers/weekday';
import {LocaleProps} from './localeContext';

/*
 * Props.
 */

export interface LocaleSettingsProps {
  locale: LocaleProps;
  onChangeLocale: (props: LocaleProps) => void;
}

/*
 * Style.
 */

const StyledDiv = styled.div`
  padding: 4px;
  display: grid;
  grid-column-gap: 7px;
  grid-template-columns: 1fr auto;
`;

const StyledTimeFormatWrapperDiv = styled.div`
  margin-top: 4px;
`;

const StyledTimezoneDiv = styled.div`
  flex-grow: 0;

  > div {
    padding: 0px;
  }
`;

/*
 * Component.
 */

export const LocaleSettings: FC<LocaleSettingsProps> = (props) => (
  <StyledDiv>
    <StyledTimeFormatWrapperDiv>
      <CheckBox
        isChecked={props.locale.is24HourTime}
        onChange={(is24HourTime) =>
          props.onChangeLocale({
            ...props.locale,
            is24HourTime,
            weekStartDay: is24HourTime ? WeekdaysEnum.MONDAY : WeekdaysEnum.SUNDAY,
          })
        }
        size={VisualSizesEnum.SMALL}
      >
        24hr format
      </CheckBox>
    </StyledTimeFormatWrapperDiv>
    <StyledTimezoneDiv>
      <TimezoneDropdownBox
        timezone={props.locale.timezone}
        onTimezoneSelect={(timezone) => {
          props.onChangeLocale({...props.locale, timezone});
        }}
        showUtc={false}
      />
    </StyledTimezoneDiv>
  </StyledDiv>
);
