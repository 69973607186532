import type {SvgAsset} from '*.svg';

export enum IconSizesEnum {
  TINY = 'TINY',
  ACTIVITY = 'ACTIVITY',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
}

export const iconSizePx: {[key in IconSizesEnum]: number} = {
  [IconSizesEnum.TINY]: 12,
  [IconSizesEnum.ACTIVITY]: 14,
  [IconSizesEnum.SMALL]: 16,
  [IconSizesEnum.MEDIUM]: 20,
};

export function buildIconAttributes(attributes: SvgAsset['attributes'], size?: number) {
  // The `dimensionsFromViewbox` is computed at build time, see the webpack config.
  const {viewBox, dimensionsFromViewbox, stroke} = attributes;
  const widthFromViewBox = dimensionsFromViewbox && dimensionsFromViewbox.width;
  const heightFromViewBox = dimensionsFromViewbox && dimensionsFromViewbox.height;
  const width = size || widthFromViewBox || attributes.width;
  const height = size || heightFromViewBox || attributes.height;
  const fill = attributes.fill || undefined;

  return {height, width, viewBox, stroke, fill};
}

type SvgAssetMapSchema = Record<string, any>;

export type SvgAssetMap<T extends SvgAssetMapSchema> = {[K in keyof T]: SvgAsset};

// Identity function used to add types to icons, since the result of require('...') is
// typed as "any."
export function buildSvgAssetMap<T extends SvgAssetMapSchema>(schema: T): SvgAssetMap<T> {
  return schema;
}
