import React, {ComponentType, createContext, FC, useContext} from 'react';
import {Diff} from 'utility-types';

import {FrontWindow, globalWindow} from '../../../../core/src/helpers/browser/browserHelpers';
import {isDevelopment, isTest} from '../../../../core/src/helpers/environment/envHelpers';

/*
 * Props.
 */

export interface LayerDefinition {
  node: HTMLElement;
}

export type LayerDestroyer = () => void;
export type LayerCreator = (definition: LayerDefinition) => LayerDestroyer;

export interface LayerContextProps {
  createLayer: LayerCreator;
  window: FrontWindow;
  container: HTMLElement;
}

/*
 * Context.
 */

const globalWindowLayerContext = createWindowLayerContext(globalWindow);

export const LayerContext = createContext<LayerContextProps>({
  ...globalWindowLayerContext,
  createLayer: (...args) => {
    // We fall back to the global window only for legacy reasons and for integration tests.
    if (isDevelopment() && !isTest()) {
      throw new Error('Every top-level <Layer> requires a window prop to ensure we target the right window.');
    }

    return globalWindowLayerContext.createLayer(...args);
  },
});

export function createWindowLayerContext(window: FrontWindow): LayerContextProps {
  const container = window.document.body;

  return {
    createLayer: ({node}) => {
      // Append it to the body, because we don't want it to be managed by React.
      container.appendChild(node);

      return () => {
        node.remove();
      };
    },
    window,
    container,
  };
}

/*
 * HOC.
 */

export function withLayer<T extends object>(
  Component: ComponentType<T & LayerContextProps>,
): FC<Diff<T, LayerContextProps>> {
  return function (props) {
    return (
      <LayerContext.Consumer>
        {(context) => <Component {...(props as any)} {...context} />}
      </LayerContext.Consumer>
    );
  };
}

/*
 * Hook.
 */

export function useLayer() {
  return useContext(LayerContext);
}
