import React, {FC, useCallback, useMemo, useState} from 'react';

import {useSelectedBlocks} from '../../../helpers/availabilityHooks';
import {reportToBugsnag} from '../../../helpers/bugsnag';
import {SchedulingLinkAvailabilityBlockModel} from '../../../models/schedulingLinkAvailabilityModel';
import {useLocale} from '../../common/locale/localeContext';
import {LocaleSettings} from '../../common/locale/localeSettings';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepProps} from '../../common/step/step';
import {makeLocaleUpdater} from '../../common/step/stepLocaleUpdater';
import {BookingSummary} from '../../common/summary/bookingSummary';
import {DateTimePickerLayout} from '../../common/ui/dateTimePickerLayout';
import {AvailabilityDatePicker} from '../../datePicker/availabilityDatePicker';
import {TimePicker} from '../../timePicker/timePicker';
import {EditStepTypesEnum} from '../editStep';

/*
 * Component.
 */

export const EditStepChooseSlot: FC<StepProps> = (props) => {
  const {onNext, metadata, booking, availability, selectedDate} = props;
  const locale = useLocale();
  const [selectedBlock, setSelectedBlock] = useState<SchedulingLinkAvailabilityBlockModel>();

  const onNextClick = useMemo(() => {
    // If there isn't another step, disable next.
    if (!onNext) {
      return undefined;
    }

    // If there is no availability block selected, return undefined.
    if (!selectedBlock) {
      return undefined;
    }

    return () =>
      onNext((data) => ({
        ...data,
        booking: {
          ...data.booking,
          start: selectedBlock.start,
          end: selectedBlock.end,
        },
      }));
  }, [onNext, selectedBlock]);

  const selectedBlocks = useSelectedBlocks(selectedDate, availability);

  const renderSidebar = useCallback(() => <BookingSummary booking={booking} />, [booking]);

  if (!availability) {
    reportToBugsnag(new Error('Missing availability when rescheduling.'));
    return null;
  }

  return (
    <StepFrame
      nextButton={{onClick: onNextClick, title: 'Reschedule meeting'}}
      title="Pick a new date and time"
      metadata={metadata}
      renderSidebar={renderSidebar}
    >
      <DateTimePickerLayout
        datePicker={
          <AvailabilityDatePicker
            schedulingLink={props.schedulingLink}
            onSelectDate={(date) =>
              props.onUpdateStepData((data) => ({
                ...data,
                selectedDate: date,
              }))
            }
            availability={availability}
            selectedDate={selectedDate}
          />
        }
        timePicker={
          <TimePicker
            availabilityBlocks={selectedBlocks}
            selectedTimeSlot={selectedBlock}
            setSelectedTimeSlot={setSelectedBlock}
          />
        }
        localeSettings={
          <LocaleSettings
            locale={locale}
            onChangeLocale={(newLocale) => props.onUpdateStepData(makeLocaleUpdater(newLocale))}
          />
        }
      />
    </StepFrame>
  );
};

/*
 * Step.
 */

export const editStepChooseSlot: Step<EditStepTypesEnum> = {
  type: EditStepTypesEnum.CHOOSE_SLOT,
  renderStep: (props) => React.createElement(EditStepChooseSlot, props),
};
