import {DateTime, IntervalMaybeValid} from 'luxon';
import React, {FC, useState} from 'react';
import styled from 'styled-components';

import {CalendarDate, CalendarMonth} from '../../helpers/date';
import {DatePickerGrid} from './datePickerGrid';
import {DatePickerHeader} from './datePickerHeader';

/*
 * Props.
 */

export interface DatePickerProps {
  startMonth: CalendarMonth;
  availableTimes: ReadonlyArray<DateTime>;
  onSelectDate: (date: CalendarDate) => void;
  selectedDate: CalendarDate | undefined;
  selectableInterval: IntervalMaybeValid;
}

/*
 * Style.
 */

const StyledDiv = styled.div`
  width: 100%;
`;

/*
 * Component.
 */

export const DatePicker: FC<DatePickerProps> = (props) => {
  const [focusedMonth, setFocusedMonth] = useState(props.startMonth);

  return (
    <StyledDiv>
      <DatePickerHeader
        focusedMonth={focusedMonth}
        onUpdateMonth={setFocusedMonth}
        selectableInterval={props.selectableInterval}
      />
      <DatePickerGrid
        focusedMonth={focusedMonth}
        availableTimes={props.availableTimes}
        onSelectDate={props.onSelectDate}
        selectedDate={props.selectedDate}
      />
    </StyledDiv>
  );
};
