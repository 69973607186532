import {Placement, SideObject} from '@popperjs/core';
import {concat, without} from 'lodash';
import React, {Component, createRef, ReactNode, RefObject} from 'react';
import styled from 'styled-components';

import {globalWindow} from '../../../../core/src/helpers/browser/browserHelpers';
import {getWindow} from '../../../../core/src/helpers/browser/domHelpers';
import {
  buildInteractionIdProps,
  InteractionComponentProps,
} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {withUserTheme} from '../../theme/userThemeConsumer';
import {ThemeContextType} from '../../theme/userThemeProvider';
import {Icon} from '../icon/icon';
import {Anchor} from '../reposition/repositionHelpers';
import {Tooltip} from './tooltip';
import {ConditionRegistrator, TooltipCondition, TooltipContext, TooltipContextProps} from './tooltipContext';
import {tooltipDefaultDelay} from './tooltipHelpers';

/*
 * Props.
 */

interface TooltipCoordinatorRenderProps {
  anchor: Anchor;
  onRequestTooltipClose: () => void;
}

export interface TooltipCoordinatorProps extends InteractionComponentProps, ThemeContextType {
  className?: string;
  isInline?: boolean;
  isMultiline?: boolean;
  customMargin?: number | string;
  maxWidth?: number;
  minWidth?: number;
  onRender?: () => void;
  /** Renders the target with additional information, will ignore children passed in */
  renderTarget?: RendererOf<boolean>;
  placement?: Placement;
  preventOverflowPadding?: Partial<SideObject>;
  render?: RendererOf<TooltipCoordinatorRenderProps> | false;
  hideOnMouseDown?: boolean;
  /** Persist the tooltip for a few hundred ms after the container loses focus. */
  persistent?: boolean;
  iconColor?: string;
  iconHoverColor?: string;
  tooltipColor?: string;
  delay?: number;
  /** Handler for showing the tooltip. */
  onShow?: () => void;
  borderRadius?: number;
  children?: ReactNode;
  isBoxShadowDisabled?: boolean;
  tooltipZIndex?: number;
}

/*
 * Style.
 */

export const StyledBlockWrapper = styled.div`
  min-width: 0;
`;

/*
 * Component.
 */

interface TooltipCoordinatorState {
  isTooltipVisible: boolean;
}

class TooltipCoordinatorBase extends Component<TooltipCoordinatorProps, TooltipCoordinatorState> {
  constructor(props: TooltipCoordinatorProps) {
    super(props);
    this.state = {isTooltipVisible: false};
    this.wrapperRef = createRef<HTMLDivElement>();
    this.contextValue = {registerCondition: this.registerCondition};
    this.conditions = [];
  }

  private readonly wrapperRef: RefObject<HTMLDivElement>;
  private readonly contextValue: TooltipContextProps;
  private conditions: ReadonlyArray<TooltipCondition>;
  private displayTimeout?: number;
  private hideTimeout?: number;

  /*
   * Lifecycle.
   */

  componentWillUnmount() {
    this.unregisterEvents();
  }

  /*
   * Event handlers.
   */

  private readonly onMouseEnter = () => {
    if (!this.props.render || (this.conditions.length > 0 && this.conditions.every((c) => !c()))) {
      return;
    }

    if (this.props.persistent) {
      globalWindow.clearTimeout(this.hideTimeout);
    }

    this.registerEvents();

    globalWindow.clearTimeout(this.displayTimeout);
    this.displayTimeout = globalWindow.setInstrumentedTimeout(
      this.onDisplayTimeout,
      this.props.delay ?? tooltipDefaultDelay,
    );
  };

  private readonly onMouseLeave = () => {
    this.unregisterEvents();

    if (!this.state.isTooltipVisible) {
      return;
    }

    if (this.props.persistent) {
      globalWindow.clearTimeout(this.hideTimeout);
      this.hideTimeout = globalWindow.setInstrumentedTimeout(this.onHideTimeout, 300);
      return;
    }

    this.setState({isTooltipVisible: false});
  };

  private readonly onMouseDown = () => {
    if (!this.props.hideOnMouseDown) {
      return;
    }

    this.setState({isTooltipVisible: false});
    this.unregisterEvents();
  };

  private readonly onDisplayTimeout = () => {
    this.setState({isTooltipVisible: true});

    if (this.props.onShow) {
      this.props.onShow();
    }
  };

  private readonly onHideTimeout = () => {
    this.setState({isTooltipVisible: false});
  };

  private registerEvents() {
    const window = getWindow(this.wrapperRef.current);
    if (!window) {
      return;
    }

    window.addEventListener('mouseleave', this.onMouseLeave);
  }

  private unregisterEvents() {
    globalWindow.clearTimeout(this.displayTimeout);
    globalWindow.clearTimeout(this.hideTimeout);

    const window = getWindow(this.wrapperRef.current);
    if (!window) {
      return;
    }

    window.removeEventListener('mouseleave', this.onMouseLeave);
  }

  private readonly registerCondition: ConditionRegistrator = (condition) => {
    this.conditions = concat(this.conditions, condition);
    return () => {
      this.conditions = without(this.conditions, condition);
    };
  };

  private computeIconColor() {
    const {theme} = this.props;

    // If we have an iconColor, use that.
    if (this.props.iconColor) {
      return this.props.iconColor;
    }

    // Otherwise, use the defaults for the visible or non-visible states.
    return this.state.isTooltipVisible ? theme.greys.shade90 : theme.greys.shade70;
  }

  /*
   * Render.
   */

  render() {
    const {className, isInline, interactionId} = this.props;
    const Wrapper = isInline === true ? 'span' : StyledBlockWrapper;

    return (
      <Wrapper
        ref={this.wrapperRef}
        className={className}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        onMouseDown={this.onMouseDown}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buildInteractionIdProps(interactionId)}
      >
        <TooltipContext.Provider value={this.contextValue}>{this.renderTarget()}</TooltipContext.Provider>
        {this.maybeRenderTooltip()}
      </Wrapper>
    );
  }

  private renderTarget() {
    // If we have a custom on render, ignore children
    if (this.props.renderTarget) {
      return this.props.renderTarget(this.state.isTooltipVisible);
    }

    // If we have children, render them instead of an info icon.
    if (this.props.children) {
      return this.props.children;
    }

    const iconColor = this.computeIconColor();

    // If the tooltip is visible, render the filled info icon.
    if (this.state.isTooltipVisible) {
      return <Icon name="infoFilledSmall" color={this.props.iconHoverColor ?? iconColor} />;
    }

    return <Icon name="infoSmall" color={iconColor} />;
  }

  private maybeRenderTooltip() {
    if (!this.state.isTooltipVisible) {
      return null;
    }

    const {render, onRender} = this.props;
    const tooltipContent =
      render &&
      render({
        anchor: this.wrapperRef,
        onRequestTooltipClose: () => {
          this.setState({isTooltipVisible: false});
        },
      });
    if (!tooltipContent) {
      return null;
    }

    if (onRender) {
      onRender();
    }

    return (
      <Tooltip
        anchor={this.wrapperRef}
        placement={this.props.placement}
        isMultiline={this.props.isMultiline}
        customMargin={this.props.customMargin}
        maxWidth={this.props.maxWidth}
        minWidth={this.props.minWidth}
        preventOverflowPadding={this.props.preventOverflowPadding}
        persistent={this.props.persistent}
        color={this.props.tooltipColor}
        borderRadius={this.props.borderRadius}
        isBoxShadowDisabled={this.props.isBoxShadowDisabled}
        zIndex={this.props.tooltipZIndex}
      >
        {tooltipContent}
      </Tooltip>
    );
  }
}

export const TooltipCoordinator = withUserTheme(TooltipCoordinatorBase);
