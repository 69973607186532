import React from 'react';
import {Route} from 'react-router-dom';

import {CalendarInvitePage} from './calendarInvitePage';

export const calendarInviteRoutes = (
  <Route
    key="calendarInvite"
    path="/:companySlug/calendar-invite/:token/:response"
    element={<CalendarInvitePage />}
  />
);
