import type {TimeZone} from '@vvo/tzdb';
import {IANAZone} from 'luxon';
import React, {FC, useState} from 'react';

import {noShortcutsMapping} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {Trans} from '../../../../core/src/helpers/internationalization/react';
import {DropdownPlacement} from '../dropdowns/dropdown';
import {DropdownItemTwoLines} from '../dropdowns/items/dropdownItemTwoLines';
import {SearchDropdown} from '../dropdowns/search/searchDropdown';
import {KeyboardProvider} from '../keyboard/keyboardProvider';
import {PopoverTransferProps} from '../popovers/popoverCoordinator';

/*
 * Props.
 */

interface TimezoneDropdownProps extends PopoverTransferProps, TimezoneDropdownInheritableProps {}

export interface TimezoneDropdownInheritableProps {
  timezonesList: TimeZone[];
  timezone: string;
  onTimezoneSelect: (arg: string) => void;
  showUtc: boolean;
  placement?: DropdownPlacement;
}

/*
 * Component.
 */

export const TimezoneDropdown: FC<TimezoneDropdownProps> = (props) => {
  const {timezonesList, showUtc, timezone, onTimezoneSelect} = props;
  const [query, setQuery] = useState('');
  const filteredZones = timezonesList.filter((zone) => {
    if (!showUtc && zone.abbreviation === 'UTC') {
      return false;
    }
    const queryLower = query.toLowerCase();
    return [
      zone.alternativeName,
      zone.name,
      ...zone.group,
      ...zone.mainCities,
      getTimezoneRawAbbreviation(zone) || zone.abbreviation,
      zone.countryName,
      renderTimezone(zone, 'long'),
      renderTimezone(zone, 'short'),
    ].some((str) => str.toLowerCase().includes(queryLower));
  });

  return (
    <KeyboardProvider mapping={noShortcutsMapping}>
      <SearchDropdown<TimeZone>
        width={500}
        maxContentHeight={300}
        anchor={props.anchor}
        placement={props.placement}
        onRequestClose={props.onRequestClose}
        data={filteredZones}
        headerTitle={<Trans id="timezone-dropdownbox-title">Time zones</Trans>}
        query={query}
        onQueryChange={setQuery}
        findRowHeight={() => 50}
        renderRow={(item) => {
          const isChecked = item.name === timezone;
          const offsetString = getOffsetString(item.currentTimeOffsetInMinutes);
          return (
            <DropdownItemTwoLines
              isChecked={isChecked}
              key={item.currentTimeFormat}
              onSelect={() => onTimezoneSelect(item.name)}
              title={renderTimezone(item, 'long')}
              description={`(UTC${offsetString}) ${getTimezoneFormattedAbbreviation(
                item,
              )}${item.mainCities.join(', ')}`}
            />
          );
        }}
      />
    </KeyboardProvider>
  );
};

function renderTimezone(timezone: TimeZone, format: 'long' | 'short') {
  if (format === 'short') {
    return timezone.abbreviation;
  }

  return `${timezone.alternativeName} (${timezone.countryName})`;
}

function getOffsetString(offsetInMinutes: number) {
  const absOffsetInMinutes = Math.abs(offsetInMinutes);
  const [hours, minutes] = [Math.floor(absOffsetInMinutes / 60), absOffsetInMinutes % 60].map((v) =>
    v.toString().padStart(2, '0'),
  );
  const durationInHoursMinutes = `${hours}:${minutes}`;

  return `${offsetInMinutes >= 0 ? '+' : '-'}${durationInHoursMinutes}`;
}

function getTimezoneFormattedAbbreviation(timezone: TimeZone) {
  const maybeAbbreviation = getTimezoneRawAbbreviation(timezone);

  return maybeAbbreviation ? `${maybeAbbreviation} - ` : '';
}

function getTimezoneRawAbbreviation(timezone: TimeZone) {
  // If both the raw and current time offset match (if no daylight savings), then the abbreviation should be correct.
  if (timezone.rawOffsetInMinutes === timezone.currentTimeOffsetInMinutes) {
    return timezone.abbreviation;
  }

  const zone = IANAZone.create(timezone.name);

  // If we don't have a valid zone or Luxon doesn't recognize it, nothing we can really do.
  if (!zone.isValid) {
    return '';
  }

  // If everything went well, we can render the zone's abbreviation for the current time.
  return zone.offsetName(Date.now(), {
    format: 'short',
  });
}
