import {globalWindow} from '../../../libs/shared/core/src/helpers/browser/browserHelpers';

export function isAbsoluteUrl(url: string) {
  return url.indexOf('://') > 0 || url.indexOf('//') === 0;
}

export function replaceUrlHost(url: string, newHost: string = globalWindow.location.host) {
  const parsedUrl = new URL(url);
  parsedUrl.host = newHost;
  return parsedUrl.toString();
}
