import React, {FC, ReactNode} from 'react';

import {Trans} from '../../../../../core/src/helpers/internationalization/react';
import {DropdownItemRightIcon} from './dropdownItemRightIcon';

/*
 * Props.
 */

interface DropdownItemCheckmarkProps {
  label?: ReactNode;
  isChecked: boolean;
}

/*
 * Component.
 */

export const DropdownItemCheckmark: FC<DropdownItemCheckmarkProps> = ({isChecked, label}) => {
  if (!isChecked) {
    return null;
  }

  return (
    <DropdownItemRightIcon
      label={label || <Trans id="dropdownitemcheckmark-label">Checked</Trans>}
      rightIconName="checkmarkSelectedSmall"
      isChecked
    />
  );
};
