import {annotateTaskType, TaskMetadata, TaskTypesEnum} from './eventLoopPerformanceHelpers';

/*
 * Types.
 */

export interface TimeoutProvider {
  setTimeout: Window['setTimeout'];
  setInterval: Window['setInterval'];
  clearTimeout: Window['clearTimeout'];
  clearInterval: Window['clearInterval'];
}

/**
 * We can technically pass a string literal in TimerHandler, which uses eval() behavior.
 * https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#passing_string_literals
 * Since this is generally unsafe, let's just disable that in our implementation.
 */
type InstrumentedTimerHandler = Exclude<TimerHandler, string>;
export interface InstrumentedTimeoutProvider {
  setInstrumentedTimeout: (
    handler: InstrumentedTimerHandler,
    timeout: number,
    // TODO: Make metadata required so we're forced to pass it in with every timeout.
    metadata?: TaskMetadata,
    ...args: any[]
  ) => number;
  setInstrumentedInterval: (
    handler: InstrumentedTimerHandler,
    timeout: number,
    // TODO: Make metadata required so we're forced to pass it in with every timeout.
    metadata?: TaskMetadata,
    ...args: any[]
  ) => number;
  clearTimeout: TimeoutProvider['clearTimeout'];
  clearInterval: TimeoutProvider['clearInterval'];
}

/*
 * Api.
 */

export function buildInstrumentedTimeoutProvider(
  timeoutProvider: TimeoutProvider,
): InstrumentedTimeoutProvider {
  const setInstrumentedTimeout = (
    handler: InstrumentedTimerHandler,
    timeout: number,
    metadata?: TaskMetadata,
    ...args: any[]
  ) =>
    timeoutProvider.setTimeout(
      () => {
        annotateTaskType(TaskTypesEnum.TIMEOUT, metadata);
        return handler();
      },
      timeout,
      ...args,
    );
  const setInstrumentedInterval = (
    handler: InstrumentedTimerHandler,
    timeout: number,
    metadata?: TaskMetadata,
    ...args: any[]
  ) =>
    timeoutProvider.setInterval(
      () => {
        annotateTaskType(TaskTypesEnum.INTERVAL, metadata);
        return handler();
      },
      timeout,
      ...args,
    );

  const {clearTimeout, clearInterval} = timeoutProvider;

  return {
    setInstrumentedTimeout,
    setInstrumentedInterval,
    clearTimeout,
    clearInterval,
  };
}

export function isInstrumentedTimeoutProvider<T extends object>(
  window: T,
): window is T & InstrumentedTimeoutProvider {
  return (
    'setInstrumentedTimeout' in window &&
    'setInstrumentedInterval' in window &&
    'clearTimeout' in window &&
    'clearInterval' in window
  );
}
