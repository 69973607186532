import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';

import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {fontWeights, secondaryFontSizes} from '../../styles/fontStyles';
import {flexCenter} from '../../styles/mixins';
import {boxedInputColorReaders} from '../boxedInputs/boxedInputConstants';

/*
 * Props.
 */

interface FieldLabelProps {
  children: ReactNode;
  additionalLabelInfo?: ReactNode;
  size?: VisualSizesEnum;
  noMargin?: boolean;
  isDisabled?: boolean;
  htmlFor?: string;
  isRequired?: boolean;
}

/*
 * Style.
 */

interface StyleProps {
  $size: VisualSizesEnum;
  $isDisabled?: boolean;
  $noMargin?: boolean;
}

export const StyledTextLabel = styled.label<StyleProps>`
  font-weight: ${fontWeights.semibold};
  font-size: ${(p) => secondaryFontSizes[p.$size]};
  color: ${boxedInputColorReaders.textLabel};
  opacity: ${(p) => (p.$isDisabled ? '0.5' : '1')};
  grid-area: title;
  margin-bottom: ${(p) => (p.$noMargin ? '8px' : '-4px')};
  ${flexCenter}
`;

export const StyledRequiredSpan = styled.span`
  color: ${(p) => p.theme.palette[PaletteColorsEnum.RED].shade40};
  margin-left: 3px;
`;

const StyledAdditionalLabelInfoSpan = styled.span`
  font-weight: ${fontWeights.normal};
  font-style: italic;
  margin-left: 3px;
`;

/*
 * Component.
 */

export const FieldLabel: FC<FieldLabelProps> = (props) => (
  <StyledTextLabel
    htmlFor={props.htmlFor}
    $size={props.size ?? VisualSizesEnum.MEDIUM}
    $noMargin={props.noMargin}
    $isDisabled={props.isDisabled}
  >
    {props.children}
    {props.isRequired && <StyledRequiredSpan>*</StyledRequiredSpan>}
    {props.additionalLabelInfo && (
      <StyledAdditionalLabelInfoSpan>{props.additionalLabelInfo}</StyledAdditionalLabelInfoSpan>
    )}
  </StyledTextLabel>
);
