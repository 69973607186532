import React, {FC} from 'react';
import styled from 'styled-components';
import {Key} from 'ts-key-enum';

import {useLocalize} from '../../../../../core/src/helpers/internationalization/internationalizationContext';
import {defineMessage} from '../../../../../core/src/helpers/internationalization/message';
import {FrontKeyboardEvent} from '../../../../../core/src/helpers/react/reactHelpers';
import {VisualSizesEnum} from '../../../styles/commonStyles';
import {useUserTheme} from '../../../theme/userThemeProvider';
import {BoxedInput} from '../../boxedInputs/boxedInput';
import {BoxedInputElement} from '../../boxedInputs/boxedInputElement';
import {BoxedInputIcon} from '../../boxedInputs/boxedInputIcon';
import {InputTypesEnum} from '../../inputs/input';
import {DropdownHeader, DropdownHeaderProps} from '../dropdownHeader';

const inputStyles: React.CSSProperties = {
  marginBottom: '12px',
};

/*
 * Props.
 */

export interface SearchDropdownHeaderOptionalProps {
  placeholder?: string;
  shouldIncludeSearchIcon?: boolean;
  isDisabled?: boolean;
  onSearchInputEnter?: (e: FrontKeyboardEvent, query: string) => void;
}

interface SearchDropdownHeaderProps extends DropdownHeaderProps, SearchDropdownHeaderOptionalProps {
  value: string;
  onChange?: (arg: string) => void;
}

/*
 * Constants.
 */

export const searchDropdownHeaderHeight = 79;

/*
 * Style.
 */

const StyledBoxedInputIcon = styled(BoxedInputIcon)`
  flex: 0 0 16px;
  margin: 5px -4px 0 8px;
  height: 16px;
  padding: 2px;
`;

/*
 * Component.
 */

export const SearchDropdownHeader: FC<SearchDropdownHeaderProps> = (props) => {
  const theme = useUserTheme();
  const {value, placeholder, shouldIncludeSearchIcon, onChange, isDisabled} = props;
  const localize = useLocalize();

  return (
    <DropdownHeader {...props}>
      <BoxedInput size={VisualSizesEnum.SMALL} noMargin boxStyles={inputStyles} isDisabled={isDisabled}>
        {(childProps) => (
          <>
            {shouldIncludeSearchIcon && (
              <StyledBoxedInputIcon {...childProps} iconName="searchTiny" color={theme.greys.shade70} />
            )}
            <BoxedInputElement
              {...childProps}
              value={value}
              onChange={onChange}
              type={InputTypesEnum.TEXT}
              size={VisualSizesEnum.SMALL}
              onKeyDown={(e) => {
                if (!props.onSearchInputEnter || e.key !== Key.Enter) {
                  return;
                }

                props.onSearchInputEnter(e, value);
              }}
              placeholder={
                placeholder ??
                localize(
                  defineMessage({
                    id: 'dropdown-search-placeholder',
                    message: 'Search',
                  }),
                )
              }
              shouldFocus
            />
          </>
        )}
      </BoxedInput>
    </DropdownHeader>
  );
};
