import {SVGAttributes} from 'react';
import {css, keyframes} from 'styled-components';

import {computeHasVisibleScrollbars} from '../../../core/src/helpers/browser/domHelpers';
import {isMacOS} from '../../../core/src/helpers/environment/platformHelpers';
import {ThemeContextType} from '../theme/userThemeProvider';

export const textSelection = () => css`
  user-select: text;
  cursor: text;

  * {
    user-select: initial;
    cursor: inherit;
  }

  [role='button'] {
    cursor: default;
  }
`;

export const noTextSelection = () => css`
  &,
  * {
    user-select: none;
    cursor: default;
  }
`;

/** Wraps the text on one-line with ellipsis. */
export const ellipsis = (noWrap = true) => css`
  ${noWrap && 'white-space: nowrap;'};
  overflow: hidden;
  text-overflow: ellipsis;
`;

/** Truncates the text after `numberOfLines` with an ellipsis.
 *  NOTE: Only works for plain text content.
 *       e.g.
 *            <div>This will work fine</div>
 *            <div>This <b>might</b> have some </br> trouble</div>
 */
export const ellipsisAfterNumberOfLines = (numberOfLines: number, inline = false) => css`
  display: ${inline ? '-webkit-inline-box' : '-webkit-box'};
  -webkit-box-orient: vertical;
  -webkit-line-clamp: ${numberOfLines};
  ${ellipsis(false)}
`;

/** Cross-browser reset of the appearance property. */
export function appearanceNone() {
  return css`
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-progress-appearance: none;
  `;
}

/** If for some reason you're using floats and need your parent element to have the right dimensions. */
export const clearfix = () => css`
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
  zoom: 1;
`;

/** Center the content both horizontally and vertically. */
export function center() {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;
}

/** Stretch a single child inside this element. */
export function stretch() {
  return css`
    display: flex;
    align-items: stretch;

    & > * {
      flex: 1;
    }
  `;
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

/** Rotate an element 360 degrees every "period" seconds, indefinitely. */
export function rotate360Animation(period: number) {
  return css`
    animation: ${rotate360} ${period}s linear infinite;
  `;
}

function scrollbar(trackColor: string, thumbColor: string, thumbHoverColor: string) {
  // No need for custom scrollbars on Mac, when using the floating/disappearing scrollbars setting.
  if (isMacOS() && !computeHasVisibleScrollbars()) {
    return '';
  }

  return css`
    scrollbar-color: ${thumbColor} ${trackColor};

    &::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background: transparent;
    }

    &::-webkit-scrollbar-track {
      background: ${trackColor};
      border: 0 solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 0 solid transparent;
      transition: background-color 200ms linear;
      background-color: ${thumbColor};
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: ${thumbHoverColor};
    }

    &::-webkit-scrollbar-track-piece {
      background-clip: padding-box;
    }

    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner,
    &::-webkit-scrollbar-resizer {
      display: none;
    }
  `;
}

export function addThemedScrollbarStyles() {
  return css`
    ${(p) => scrollbar(p.theme.scrollbarTrack, p.theme.scrollbarThumb, p.theme.scrollbarThumbHover)};
    color-scheme: ${(p) => p.theme.colorScheme};
  `;
}

// https://webaim.org/techniques/css/invisiblecontent/
const visuallyHiddenCss = css`
  clip: rect(1px, 1px, 1px, 1px);
  /* This causes "repaints on scroll" in Chrome and reduces rendering performance! */
  /* clip-path: inset(50%); */
  height: 1px;
  width: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

/** Visually hide, but allow it to be read by screen readers. */
export const visuallyHidden = () => visuallyHiddenCss;

const highlightPulseAnimation = (p: ThemeContextType) => keyframes`
  0% {
    box-shadow: 0 0 0 0px ${p.theme.palette.pink.shade40}, 0 0 0 2px ${p.theme.palette.pink.shade40};
  }

  50% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0), 0 0 0 2px ${p.theme.palette.pink.shade40};
  }

  100% {
    box-shadow: 0 0 0 15px rgba(0, 0, 0, 0), 0 0 0 2px ${p.theme.palette.pink.shade40};
  }
`;

/** Pulsing border effect to highlight a component. */
export const getHighlightPulseCss = (shouldWrapTight: boolean) =>
  css`
    &:before {
      content: '';
      position: absolute;
      top: ${shouldWrapTight ? '-4px' : '4px'};
      left: ${shouldWrapTight ? '-4px' : '4px'};
      right: ${shouldWrapTight ? '-4px' : '4px'};
      bottom: ${shouldWrapTight ? '-4px' : '4px'};
      pointer-events: none;
      border-radius: 20px;
    }
  `;

export const getHighlighterCss = () => css`
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    pointer-events: none;
    animation: ${highlightPulseAnimation} 2s infinite;
  }
`;

const shakeAnimation = keyframes`
  10%, 90% {
    transform: translateX(-1px);
  }

  20%, 80% {
    transform: translateX(2px);
  }

  30%, 50%, 70% {
    transform: translateX(-4px);
  }

  40%, 60% {
    transform: translateX(4px);
  }
`;

/** Shake effect to draw attention to a component. */
export const shakeCss = css`
  animation: ${shakeAnimation} 1s;
  transform: translateX(0px);
`;

/** Flex display with vertically aligned elements */
export const flexCenter = css`
  display: flex;
  align-items: center;
`;

// Based off https://github.com/kovart/dashed-border-generator
interface BuildDashedBorderBackgroundOptions {
  strokeColor: string;
  dashArray: string;
  strokeWidth: number;
  dashOffset?: number;
  borderRadius?: number;
  strokeLinecap?: SVGAttributes<SVGRectElement>['strokeLinecap'];
  strokeLinejoin?: SVGAttributes<SVGRectElement>['strokeLinejoin'];
}
export function buildDashedBorderBackground(options: BuildDashedBorderBackgroundOptions) {
  const baseString = `<svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
  <rect
    width="100%"
    height="100%"
    fill="none"
    rx="${options.borderRadius ?? 0}"
    ry="${options.borderRadius ?? 0}"
    stroke="${options.strokeColor}"
    stroke-width="${options.strokeWidth * 2}"
    stroke-dasharray="${options.dashArray}"
    ${(options.dashOffset && `stroke-dashoffset="${options.dashOffset}"`) || ''}
    ${(options.strokeLinecap && `stroke-linecap="${options.strokeLinecap}"`) || ''}
    ${(options.strokeLinejoin && `stroke-linejoin="${options.strokeLinejoin}"`) || ''}
  />
</svg>
`;

  const compressed = encodeURIComponent(baseString.replace(/\s+/g, ' ').replace(/"/g, "'"));

  return css`
    // prettier-ignore
    background-image: url("data:image/svg+xml,${compressed}");
    border-radius: ${options.borderRadius ?? 0}px;
  `;
}

export const userHtmlAnchorStyles = css`
  a,
  a:visited,
  a:active {
    color: ${(p) => p.theme.interactiveText};
  }

  a,
  a * {
    cursor: pointer;
  }

  a:hover {
    color: ${(p) => p.theme.interactiveTextHover};
  }
`;

export function transparent(isTransparent?: boolean) {
  return css`
    opacity: ${isTransparent ?? true ? '0.5' : '1'};
  `;
}

export function addCardStyles(background?: string) {
  return css`
    border-radius: 16px;
    background: ${(p) => background ?? p.theme.foreground};
    border: 2px solid ${(p) => p.theme.alphas.black20};
  `;
}
