import {lighten} from 'polished';

import {alphas, greysDark, paletteDark} from '../../styles/colorStyles';
import {darkAnnotationSemanticStyles} from '../semantic/annotationSemanticStyles';
import {darkAvatarSemanticStyles} from '../semantic/avatarSemanticStyles';
import {darkBannerSemanticStyles} from '../semantic/bannerSemanticStyles';
import {darkButtonSemanticStyles} from '../semantic/buttonSemanticStyles';
import {darkCellSemanticStyles} from '../semantic/cellSemanticStyles';
import {darkCommentComposerSemanticStyles} from '../semantic/commentComposerSemanticStyles';
import {darkMessageSemanticStyles} from '../semantic/messageSemanticStyles';
import {sharedSearchBarSemanticStyles} from '../semantic/searchBarSemanticStyles';
import {darkTagSemanticStyles} from '../semantic/tagSemanticStyles';
import {sharedTopBarSemanticStyles} from '../semantic/topBarSemanticStyles';
import {themeConstants} from '../themeConstants';
import {SemanticStyles} from '../themeTypes';

export const darkSemanticStyles: SemanticStyles = {
  brand: themeConstants.black,

  appBackground: greysDark.white,
  foreground: greysDark.shade20,

  text: greysDark.shade80,
  composerText: greysDark.black,
  composerPlaceholder: greysDark.shade70,

  interactiveContainer: paletteDark.blue.shade40,
  interactiveText: paletteDark.blue.shade50,
  // TODO: Get a hover state color from design, since we don't have
  // a shade60 we can lighten these links to.
  interactiveTextHover: lighten(0.06, paletteDark.blue.shade50),
  interactiveIcon: paletteDark.blue.shade50,
  interactiveBorders: paletteDark.blue.shade50,

  invertedText: greysDark.black,
  invertedSubtext: alphas.white50,
  invertedIcon: alphas.white50,

  modalBackdrop: alphas.black60,

  colorScheme: 'dark',

  urlTooltipMinorText: alphas.black60,
  inputBackground: greysDark.white,
  windowsButtonHover: alphas.white20,

  highlightText: greysDark.shade20,

  refinedP2ResultsIndicator: paletteDark.blue.shade50,

  cell: darkCellSemanticStyles,
  topBar: sharedTopBarSemanticStyles,
  searchBar: sharedSearchBarSemanticStyles,
  tag: darkTagSemanticStyles,
  avatar: darkAvatarSemanticStyles,
  message: darkMessageSemanticStyles,
  banner: darkBannerSemanticStyles,
  button: darkButtonSemanticStyles,
  annotation: darkAnnotationSemanticStyles,
  commentComposer: darkCommentComposerSemanticStyles,
};
