import {css} from 'styled-components';

import {collectEnumValues} from '../../../../libs/shared/core/src/models/api/types/enumTypes';
import {palette} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {sendAsync} from '../../helpers/http';
import {BaseUrlsEnum, RequestMethodsEnum} from '../../helpers/httpConstants';
import {CalendarInviteResponsesEnum} from './calendarType';

export const responseToButtonText = {
  [CalendarInviteResponsesEnum.ACCEPTED]: 'Yes',
  [CalendarInviteResponsesEnum.TENTATIVE]: 'Maybe',
  [CalendarInviteResponsesEnum.DECLINED]: 'No',
};

export const anchorStyle = css`
  color: ${palette.blue.shade40};
  font-weight: ${fontWeights.semibold};
  word-break: break-all;
  :hover {
    cursor: pointer;
    color: ${palette.blue.shade50};
  }
`;

export function getCalendarInvitePath(companySlug: string, token: string) {
  return `companies/${companySlug}/calendar_invite/${token}`;
}

export const calendarInviteCampaignLink = `${BaseUrlsEnum.HOMEPAGE_URL}why-front?utm_medium=in_app&utm_source=frontapp&utm_campaign_calendar-invite`;

function parseCalendarResponse(result: any) {
  return {
    id: result.id,
    summary: result.summary,
    description: result.description,
    responseStatus: result.response_status,
    numberOfAttendees: result.number_of_attendees,
    eventDateTime: result.event_date_time,
    locationList: result.location_list,
  };
}

export async function postCalendarInviteReponseAsync(path: string, response: CalendarInviteResponsesEnum) {
  if (!collectEnumValues(CalendarInviteResponsesEnum).includes(response)) {
    throw new Error('Invalid response');
  }

  const res = await sendAsync({
    baseURL: BaseUrlsEnum.NOAUTH_API_BASE_URL,
    path,
    body: {response},
    method: RequestMethodsEnum.POST,
  });
  return parseCalendarResponse(res);
}
