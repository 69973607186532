import React from 'react';

import {altKey, metaKey} from '../../../../core/src/helpers/browser/modifierKeyPlatformHelpers';
import {isMacOS} from '../../../../core/src/helpers/environment/platformHelpers';
import {Trans} from '../../../../core/src/helpers/internationalization/react';
import {Renderer} from '../../../../core/src/helpers/react/reactHelpers';

/*
 * Platform awareness.
 */

export function splitAndFixKeys(shortcut: string) {
  const keys = shortcut.split('+');
  return keys.map(fixKeysByPlatform);
}

export function fixKeysByPlatform(key: string) {
  const platformSpecificKey = platformSpecificKeys[key];
  return platformSpecificKey || key;
}

type PlatformSpecificKeys = {[name: string]: string | undefined};
const platformSpecificKeys: PlatformSpecificKeys = {
  mod: metaKey,
  meta: metaKey,
  alt: altKey,
};

/*
 * Localization map.
 */

export const knownKeyRenderers = {
  shift: () => <Trans id="key-shift">shift</Trans>,
  ctrl: () => <Trans id="key-ctrl">control</Trans>,
  command: () => <Trans id="key-command">cmd</Trans>,
  alt: () => <Trans id="key-alt">alt</Trans>,
  option: () => <Trans id="key-option">option</Trans>,
  backspace: () => <Trans id="key-backspace">backspace</Trans>,
  tab: () => <Trans id="key-tab">tab</Trans>,
  enter: () => <Trans id="key-enter">enter</Trans>,
  esc: () => <Trans id="key-esc">escape</Trans>,
  space: () => <Trans id="key-space">space</Trans>,
  pageup: () => <Trans id="key-pageup">page-up</Trans>,
  pagedown: () => <Trans id="key-pagedown">page-down</Trans>,
  end: () => <Trans id="key-end">end</Trans>,
  home: () => <Trans id="key-home">home</Trans>,
  left: () => <Trans id="key-left">left</Trans>,
  up: () => <Trans id="key-up">up</Trans>,
  right: () => <Trans id="key-right">right</Trans>,
  down: () => <Trans id="key-down">down</Trans>,
  ins: () => <Trans id="key-ins">insert</Trans>,
  del: () =>
    isMacOS() ? <Trans id="key-forward-delete">forward delete</Trans> : <Trans id="key-del">delete</Trans>,
} as const;

type KeyRenderers = {[name: string]: Renderer | undefined};
export const keyRenderers: KeyRenderers = knownKeyRenderers;
