import React, {FC, PropsWithChildren, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import emptySearch from '../../../../../../src/assets/images/emptySearch70.svg';
import {Trans} from '../../../../core/src/helpers/internationalization/react';
import {center} from '../../styles/mixins';

/*
 * Props.
 */

interface DropdownEmptyProps {
  imageSource?: string;
  className?: string;
  maxWidth?: string | number;
  shouldHideImage?: boolean;
}

/*
 * Style.
 */

const StyledEmptyDiv = styled.div`
  flex: 1;
  padding: 20px 0 22px 0;
  ${center()};
`;

interface ImageStyleProps {
  $imageUrl: string;
}
const StyledImageDiv = styled.div<ImageStyleProps>`
  width: 70px;
  height: 70px;

  background-image: url(${(p) => p.$imageUrl});
  background-position: center center;
`;

interface StyledLabelDivProps {
  $maxWidth?: string | number;
}
const StyledLabelDiv = styled.div<StyledLabelDivProps>`
  color: ${(p) => p.theme.greys.shade70};
  margin-top: 12px;

  ${(p) =>
    p.$maxWidth &&
    css`
      max-width: ${typeof p.$maxWidth === 'number' ? `${p.$maxWidth}px` : p.$maxWidth};
    `}
`;

/*
 * Component.
 */

export const DropdownEmpty: FC<PropsWithChildren<DropdownEmptyProps>> = ({
  imageSource,
  className,
  children,
  maxWidth,
  shouldHideImage,
}) => (
  <StyledEmptyDiv className={className}>
    {!shouldHideImage && <StyledImageDiv $imageUrl={imageSource || emptySearch} />}
    <StyledLabelDiv $maxWidth={maxWidth}>{renderLabel(children)}</StyledLabelDiv>
  </StyledEmptyDiv>
);

function renderLabel(children?: ReactNode) {
  return children || <Trans id="dropdown-noresults">No results</Trans>;
}
