import React, {FC, ReactNode, useContext, useLayoutEffect, useMemo, useRef} from 'react';
import {createPortal} from 'react-dom';
import styled from 'styled-components';

import {FrontWindow} from '../../../../core/src/helpers/browser/browserHelpers';
import {isElement} from '../../../../core/src/helpers/browser/domHelpers';
import {useForceUpdate} from '../../../../core/src/helpers/react/hooks/useForceUpdate';
import {EventHandler} from '../../../../core/src/helpers/react/reactHelpers';
import {createWindowLayerContext, LayerContext, LayerContextProps, useLayer} from './layerContext';
import {LayerVisibilityContext} from './layerVisibilityContext';

/*
 * Props.
 */

interface LayerProps {
  window?: FrontWindow;
  isExclusive?: boolean;
  isInteractive?: boolean;
  children: ReactNode;
  /** Feeds into a data-testid attribute to make it easier to inspect the DOM. */
  testId?: string;
}

interface LayerChildrenProps extends LayerProps {
  window: FrontWindow;
  nodeWrapper: HTMLElement;
  nodeContent: HTMLElement;
}

/*
 * Constants.
 */

export const rootLayerClassName = 'layer--root';
export const layerClassName = 'layer';

/*
 * Style.
 */

interface LayerStyleProps {
  $isExclusive: boolean;
  $isInteractive: boolean;
}
const StyledLayerDiv = styled.div<LayerStyleProps>`
  /** Catch pointer events if and only if we are an exclusive layer. */
  pointer-events: ${(p) => (p.$isExclusive ? 'auto' : 'none')};

  & > * {
    pointer-events: ${(p) => (p.$isInteractive ? 'auto' : 'none')};
  }
`;

/*
 * Component.
 */

export const Layer: FC<LayerProps> = (props) => {
  // If a window prop is provided, create a new root context.
  const createdRootContext = useMemo(
    () => props.window && createWindowLayerContext(props.window),
    [props.window],
  );
  const inheritedContext = useLayer();
  const {window, createLayer} = createdRootContext ?? inheritedContext;

  const {isVisible} = useContext(LayerVisibilityContext);

  const nodesRef = useRef<{nodeWrapper: HTMLElement; nodeContent: HTMLElement} | undefined>(undefined);
  const forceUpdate = useForceUpdate();

  /*
   * Lifecycle.
   */

  const {testId} = props;
  useLayoutEffect(() => {
    const {document} = window;

    // Create the outer container, which is where we add child layers.
    const nodeWrapper = document.createElement('div');
    nodeWrapper.classList.add(layerClassName);

    if (testId !== undefined) {
      nodeWrapper.setAttribute('data-testid', testId);
    }

    // Create our inner container, which is where we render layer content using a React portal.
    const nodeContent = document.createElement('div');
    nodeWrapper.appendChild(nodeContent);

    const destroyLayer = createLayer({
      node: nodeWrapper,
    });

    // Set the nodes and render.
    nodesRef.current = {nodeWrapper, nodeContent};
    forceUpdate();

    return () => {
      nodesRef.current = undefined;
      destroyLayer();
    };
  }, [window, createLayer, forceUpdate, testId]);

  /*
   * Render.
   */

  if (!isVisible) {
    return null;
  }

  // With concurrent rendering, we may unmount the component, which removes the nodes when we clean up the
  // layout effect. Then, we may try to restore this component from the state, which would result in an
  // error since the nodes have been detached from the DOM tree. Unfortunately, we can't reset the state
  // back to undefined in the layout effect cleanup function. As a workaround, we hold the nodes in a ref
  // and manually trigger a render.
  const nodes = nodesRef.current;
  if (!nodes) {
    return null;
  }

  return (
    <LayerChildren
      window={window}
      nodeWrapper={nodes.nodeWrapper}
      nodeContent={nodes.nodeContent}
      isExclusive={props.isExclusive}
      isInteractive={props.isInteractive}
    >
      {props.children}
    </LayerChildren>
  );
};

const LayerChildren: FC<LayerChildrenProps> = (props) => {
  const {window, nodeWrapper, nodeContent, isExclusive = false, isInteractive = true, children} = props;

  /*
   * Event handlers.
   */

  const onEvent: EventHandler = (event) => {
    const isInsideFullCalendar = Boolean(
      isElement(event.target) && event.target.closest('.fullcalendar-wrapper'),
    );
    const isInsideTiptapEditor = Boolean(isElement(event.target) && event.target.closest('.tiptap'));

    // TipTip listens for raw DOM events on the document, so we can't stop propagation.
    // This may cause weird behavior for popovers/modals. (https://github.com/facebook/react/issues/11387)
    if (isInsideTiptapEditor) {
      return;
    }

    // Unless we're at the root level, prevent from bubbling up in the React tree.
    if (nodeWrapper.parentElement !== nodeWrapper.ownerDocument.body) {
      event.stopPropagation();
    }

    if (isInsideFullCalendar) {
      // Forward the event to the document.
      const newEvent = new Event(event.type, event);
      const targetDocument = window.document;
      targetDocument.dispatchEvent(newEvent);
    }
  };

  /*
   * Render.
   */

  const container = nodeWrapper;
  const context: LayerContextProps = useMemo(
    () => ({
      createLayer: ({node}) => {
        container.appendChild(node);

        return () => {
          node.remove();
        };
      },
      window,
      container,
    }),
    [window, container],
  );

  const content = (
    <LayerContext.Provider value={context}>
      <StyledLayerDiv
        className={layerClassName}
        $isExclusive={isExclusive}
        $isInteractive={isInteractive}
        onClick={onEvent}
        onDoubleClick={onEvent}
        onMouseDown={onEvent}
        onMouseUp={onEvent}
      >
        {children}
      </StyledLayerDiv>
    </LayerContext.Provider>
  );

  return createPortal(content, nodeContent);
};
