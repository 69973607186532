import {FrontEnvironmentsEnum, isFrontEnvironment} from './environments';
import {FrontEnvironmentGlobals} from './envTypes';
import {isMobile} from './mobileEnvHelpers';

declare const FRONT_CONFIG: FrontEnvironmentGlobals;

export function findFrontEnvironment() {
  const env = FRONT_CONFIG.FRONT_ENV;
  if (isMobile()) {
    return findFrontMobileEnvironment(env);
  }
  return env;
}

export function findFrontMobileEnvironment(env: string) {
  if (isFrontEnvironment(env)) {
    return env;
  }
  return FrontEnvironmentsEnum.PRODUCTION;
}
