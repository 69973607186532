import {DateTime} from 'luxon';

import {WeekdaysEnum, weekEndDayForWeekStartDay} from './weekday';

/*
 * Api.
 */

export function findWeekStartForDate(date: DateTime, weekStartDay: WeekdaysEnum): DateTime {
  const weekStartNumber = weekStartDay;
  let weekStart = date;
  while (weekStart.weekday !== weekStartNumber) {
    weekStart = weekStart.minus({days: 1});
  }

  return weekStart;
}

export function findWeekEndForDate(date: DateTime, weekStartDay: WeekdaysEnum): DateTime {
  const weekEndDay = weekEndDayForWeekStartDay[weekStartDay];
  const weekEndNumber = weekEndDay;
  let weekEnd = date;
  while (weekEnd.weekday !== weekEndNumber) {
    weekEnd = weekEnd.plus({days: 1});
  }

  return weekEnd;
}
