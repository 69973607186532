import React, {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';

import {Layer} from '../layers/layer';
import {Reposition, RepositionProps} from '../reposition/reposition';
import {PopoverProps} from './popoverTypes';

/*
 * Props.
 */

export interface RepositionPopoverProps extends PopoverProps, RepositionProps {
  hasBackground?: boolean;
}

/*
 * Style.
 */

interface BackgroundStyleProps {
  $shouldBeVisible?: boolean;
}
const StyledBackgroundDiv = styled.div<BackgroundStyleProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p) => (p.$shouldBeVisible ? p.theme.modalBackdrop : p.theme.alphas.transparent)};
`;

/*
 * Component.
 */

export const RepositionPopover: FC<PropsWithChildren<RepositionPopoverProps>> = (props) => {
  const content = (
    <>
      {props.isExclusive && (
        <StyledBackgroundDiv $shouldBeVisible={props.hasBackground} onClick={props.onRequestClose} />
      )}
      <Reposition {...props}>{props.children}</Reposition>
    </>
  );

  if (props.isInline) {
    return content;
  }

  return <Layer {...props}>{content}</Layer>;
};
