import {transparentize} from 'polished';

import {greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';

export const lightAnnotationSemanticStyles = {
  commentAnnotationBackground: transparentize(0.8, '#FF5C00'),
  hoverAnnotationBackground: transparentize(0.7, '#FF5C00'),
  selectedAnnotationBackground: transparentize(0.6, '#FF5C00'),
  focusedAnnotationBackground: transparentize(0.8, palette.blue.shade40),

  topicAnnotationBorder: greys.shade40,
  topicAnnotationHoverBackground: greys.shade30,
  topicAnnotationSelectedBackground: greys.shade40,
};

export type AnnotationSemanticStyles = typeof lightAnnotationSemanticStyles;

export const darkAnnotationSemanticStyles: AnnotationSemanticStyles = {
  commentAnnotationBackground: transparentize(0.8, '#FF5C00'),
  hoverAnnotationBackground: transparentize(0.7, '#FF5C00'),
  selectedAnnotationBackground: transparentize(0.6, '#FF5C00'),
  focusedAnnotationBackground: transparentize(0.8, paletteDark.blue.shade40),

  topicAnnotationBorder: greysDark.shade60,
  topicAnnotationHoverBackground: greysDark.shade50,
  topicAnnotationSelectedBackground: greysDark.shade60,
};
