import {take} from 'lodash';
import {DateTime} from 'luxon';
import React, {FC, useMemo} from 'react';
import styled from 'styled-components';

import {greys} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {
  areCalendarDatesEqual,
  buildDateTimeFromCalendarDate,
  CalendarDate,
  CalendarMonth,
} from '../../helpers/date';
import {useLocale} from '../common/locale/localeContext';
import {DatePickerGridItem} from './datePickerGridItem';
import {findGridDaysInMonth} from './datePickerHelpers';

/*
 * Props.
 */

export interface DatePickerGridProps {
  focusedMonth: CalendarMonth;
  availableTimes: ReadonlyArray<DateTime>;
  onSelectDate: (date: CalendarDate) => void;
  selectedDate: CalendarDate | undefined;
}

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 4px;
`;

const StyledHeaderDiv = styled.div`
  text-align: center;
  color: ${greys.shade60};
  font-size: ${fontSizes.verySmall};
  padding: 14px 0px 24px;
`;

/*
 * Component.
 */

export const DatePickerGrid: FC<DatePickerGridProps> = (props) => {
  const {focusedMonth, availableTimes} = props;
  const {timezone, weekStartDay} = useLocale();

  const selectableDays = useMemo(
    () =>
      new Set(
        availableTimes.filter((time) => doesMonthContainTime(focusedMonth, time)).map((time) => time.day),
      ),
    [availableTimes, focusedMonth],
  );
  const gridDays = useMemo(
    () => findGridDaysInMonth(focusedMonth, weekStartDay),
    [focusedMonth, weekStartDay],
  );

  // Render the headers for the first 7 days.
  const headers = take(gridDays, 7).map((date) => renderHeader(date, timezone));

  const days = gridDays.map((day) => renderDay(props, day, selectableDays, timezone));

  return (
    <StyledWrapperDiv>
      {headers}
      {days}
    </StyledWrapperDiv>
  );
};

/*
 * Helpers.
 */

function renderHeader(date: CalendarDate, timezone: string) {
  const datetime = buildDateTimeFromCalendarDate(date, timezone);
  return <StyledHeaderDiv key={`header ${datetime.weekday}`}>{datetime.weekdayShort}</StyledHeaderDiv>;
}

function renderDay(
  props: DatePickerGridProps,
  date: CalendarDate,
  selectableDays: Set<number>,
  timezone: string,
) {
  const gridItemDateTime = buildDateTimeFromCalendarDate(date, timezone);
  const isSelectable = selectableDays.has(date.day);
  const isSelected = Boolean(props.selectedDate && areCalendarDatesEqual(date, props.selectedDate));
  const onClick = isSelectable && !isSelected ? () => props.onSelectDate(date) : undefined;
  return (
    <DatePickerGridItem
      label={`${date.day}`}
      // Render this item as empty if the date isn't in the selected month.
      isEmpty={date.month !== props.focusedMonth.month}
      isSelected={isSelected}
      isSelectable={isSelectable}
      key={`day ${gridItemDateTime.weekday} ${gridItemDateTime.weekNumber}`}
      onClick={onClick}
    />
  );
}

function doesMonthContainTime(month: CalendarMonth, time: DateTime) {
  return time.month === month.month && time.year === month.year;
}
