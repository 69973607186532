import React, {FC, PropsWithChildren, useCallback, useMemo, useRef} from 'react';

import {useLayer} from '../components/layers/layerContext';
import {CssContextProvider} from './cssContext';

/*
 * State.
 */

interface CssState {
  readonly instances: number;
  readonly element: HTMLElement;
}

interface CssProviderState {
  [K: string]: CssState;
}

/*
 * Component.
 */

export const CssManager: FC<PropsWithChildren<unknown>> = ({children}) => {
  const {window} = useLayer();
  const {document} = window;
  const stateRef = useRef<CssProviderState>({});

  /*
   * Context API.
   */

  const registerCss = useCallback(
    (css: string) => {
      const currentState = stateRef.current;

      // If we already have a state, just increment.
      const existingCssState = currentState[css];
      if (existingCssState) {
        currentState[css] = {
          instances: existingCssState.instances + 1,
          element: existingCssState.element,
        };
        return;
      }

      // Otherwise, create a new element and new state.
      const element = document.createElement('style');
      element.type = 'text/css';
      element.appendChild(document.createTextNode(css));
      document.head.appendChild(element);

      currentState[css] = {
        instances: 1,
        element,
      };
    },
    [document],
  );

  const unregisterCss = useCallback((css: string) => {
    const currentState = stateRef.current;
    const cssState = currentState[css];
    if (!cssState) {
      throw new Error('A state could not be found for this CSS.');
    }

    // If there are more instances, just decrement.
    const {instances, element} = cssState;
    if (instances > 1) {
      currentState[css] = {
        instances: instances - 1,
        element,
      };
      return;
    }

    // Otherwise, remove the element and remove the state.
    element.remove();
    delete currentState[css];
  }, []);

  const addCssToDocument = useCallback(
    (css: string) => {
      registerCss(css);
      return () => unregisterCss(css);
    },
    [registerCss, unregisterCss],
  );

  /*
   * Render.
   */

  const context = useMemo(() => ({addCssToDocument}), [addCssToDocument]);
  return <CssContextProvider value={context}>{children}</CssContextProvider>;
};
