import React, {FC} from 'react';
import {Route, Routes} from 'react-router-dom';

import {GlobalStyles} from '../../../libs/shared/ui/src/styles/globalStyles';
import {bookRoutes} from './book/bookRoutes';
import {calendarInviteRoutes} from './calendarInvite/calendarInviteRoutes';
import {cancelRoutes} from './cancel/cancelRoutes';
import {FullScreenError} from './common/error/fullScreenError';
import {MainContentWrapper} from './common/mainContentWrapper';
import {editRoutes} from './edit/editRoutes';

/*
 * Component.
 */

export const App: FC = () => (
  <>
    <GlobalStyles />
    <Routes>
      {bookRoutes}
      {editRoutes}
      {cancelRoutes}
      {calendarInviteRoutes}
      <Route path="*" element={render404()} />
    </Routes>
  </>
);

/*
 * 404 page.
 */

function render404() {
  return (
    <MainContentWrapper>
      <FullScreenError
        description={
          <>
            <span role="img" aria-label="sad face">
              😢
            </span>{' '}
            No such route.
          </>
        }
      />
    </MainContentWrapper>
  );
}
