import {createContext} from 'react';

import {FrontEvent} from '../../../../core/src/helpers/react/reactHelpers';

/*
 * Props.
 */

interface ListItem {
  props: any;
  index: number;
}

export interface InteractiveListContextProps {
  /** Register a single list item to be selectable. */
  registerListItem(onSelect?: (event: FrontEvent) => void, index?: number): ListItem;
  /** Index of the highlighted item. */
  highlightedIndex: number | null;
}

/*
 * Context.
 */

export const InteractiveListContext = createContext<InteractiveListContextProps>({
  registerListItem: () => ({props: {}, index: 0}),
  highlightedIndex: null,
});
