import {useReducer} from 'react';

/**
 * Forces a function component to be re-evaluated.
 * Mostly useful when used together with refs for more control compared to useState alone.
 */
export function useForceUpdate(): () => void {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  return forceUpdate;
}
