import {DateTime, Interval} from 'luxon';

import {
  buildCalendarDateFromDateTime,
  buildDateTimeFromCalendarMonth,
  CalendarDate,
  CalendarMonth,
} from '../../helpers/date';
import {findWeekEndForDate, findWeekStartForDate} from '../../helpers/datetime';
import {WeekdaysEnum} from '../../helpers/weekday';

/*
 * Api.
 */

export function findGridDaysInMonth(
  month: CalendarMonth,
  weekStartDay: WeekdaysEnum,
): ReadonlyArray<CalendarDate> {
  const datetime = buildDateTimeFromCalendarMonth(month);
  const start = findWeekStartForDate(datetime.startOf('month'), weekStartDay);
  const end = findWeekEndForDate(datetime.endOf('month'), weekStartDay);
  const fullInterval = Interval.fromDateTimes(start, end);
  return fullInterval
    .splitBy({days: 1})
    .map((i) => i.start)
    .filter((i): i is DateTime<true> => Boolean(i?.isValid))
    .map(buildCalendarDateFromDateTime);
}
