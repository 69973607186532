import React, {FC} from 'react';
import styled from 'styled-components';

import {greys} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes, fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {renderAvailabilityBlockDateWithLocale} from '../../helpers/model/availability';
import {SchedulingLinkAvailabilityBlockModel} from '../../models/schedulingLinkAvailabilityModel';
import {LocaleProps, useLocale} from '../common/locale/localeContext';
import {TimePickerList} from './timePickerList';

/*
 * Props.
 */

export interface TimePickerProps {
  availabilityBlocks: ReadonlyArray<SchedulingLinkAvailabilityBlockModel>;
  selectedTimeSlot: SchedulingLinkAvailabilityBlockModel | undefined;
  setSelectedTimeSlot: (model: SchedulingLinkAvailabilityBlockModel) => void;
}

/*
 * Style.
 */

const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr;
`;

const StyledTitleDiv = styled.div`
  padding: 5px 0px;
  margin-bottom: 7px;

  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.semibold};
  color: ${greys.shade80};
`;

/*
 * Component.
 */

export const TimePicker: FC<TimePickerProps> = (props) => {
  const locale = useLocale();

  return (
    <StyledDiv>
      {renderTitle(props, locale)}
      <TimePickerList
        availabilityBlocks={props.availabilityBlocks}
        selectedTimeSlot={props.selectedTimeSlot}
        setSelectedTimeSlot={props.setSelectedTimeSlot}
      />
    </StyledDiv>
  );
};

/*
 * Helpers.
 */

function renderTitle(props: TimePickerProps, locale: LocaleProps) {
  if (props.availabilityBlocks.length === 0) {
    return null;
  }

  return (
    <StyledTitleDiv>
      {renderAvailabilityBlockDateWithLocale(props.availabilityBlocks[0], locale)}
    </StyledTitleDiv>
  );
}
