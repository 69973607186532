import React, {FC, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {useSelectedBlocks} from '../../../helpers/availabilityHooks';
import {SchedulingLinkAvailabilityBlockModel} from '../../../models/schedulingLinkAvailabilityModel';
import {useLocale} from '../../common/locale/localeContext';
import {LocaleSettings} from '../../common/locale/localeSettings';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepProps} from '../../common/step/step';
import {makeLocaleUpdater} from '../../common/step/stepLocaleUpdater';
import {TimePicker} from '../../timePicker/timePicker';
import {EditStepTypesEnum} from '../editStep';

/*
 * Style.
 */

const StyledContentDiv = styled.div`
  display: flex;
  flex-direction: row;

  padding: 8px 0px;
`;

/*
 * Component.
 */

export const EditStepChooseTime: FC<StepProps> = (props) => {
  const {onNext, onPrevious, metadata, selectedDate, availability, onUpdateStepData} = props;
  const locale = useLocale();
  const [selectedBlock, setSelectedBlock] = useState<SchedulingLinkAvailabilityBlockModel>();

  const onNextClick = useMemo(() => {
    // If there isn't another step, do nothing.
    if (!onNext) {
      return undefined;
    }

    // If there isn't a selected block, do nothing.
    if (!selectedBlock) {
      return undefined;
    }

    // Go to the next step.
    return () =>
      onNext((data) => ({
        ...data,
        booking: {
          ...data.booking,
          start: selectedBlock.start,
          end: selectedBlock.end,
        },
      }));
  }, [onNext, selectedBlock]);

  const onBackClick = useMemo(() => {
    // If there isn't a previous step, do nothing.
    if (!onPrevious) {
      return undefined;
    }

    // Go to the next step.
    return () =>
      onPrevious((data) => ({
        ...data,
        selectedDate: undefined,
      }));
  }, [onPrevious]);

  const selectedBlocks = useSelectedBlocks(selectedDate, availability);

  const renderFooter = useCallback(
    () => (
      <LocaleSettings
        locale={locale}
        onChangeLocale={(newLocale) => onUpdateStepData(makeLocaleUpdater(newLocale))}
      />
    ),
    [locale, onUpdateStepData],
  );

  return (
    <StepFrame
      onPrevious={onBackClick}
      nextButton={{onClick: onNextClick, title: 'Rescheduling meeting'}}
      title="Pick a new date and time"
      metadata={metadata}
      renderFooter={renderFooter}
    >
      <StyledContentDiv>
        <TimePicker
          availabilityBlocks={selectedBlocks}
          selectedTimeSlot={selectedBlock}
          setSelectedTimeSlot={setSelectedBlock}
        />
      </StyledContentDiv>
    </StepFrame>
  );
};

/*
 * Step.
 */

export const editStepChooseTime: Step<EditStepTypesEnum> = {
  type: EditStepTypesEnum.CHOOSE_TIME,
  renderStep: (props) => React.createElement(EditStepChooseTime, props),
};
