import React, {FC} from 'react';

import {patchBookingAsync} from '../../../helpers/model/booking';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepData, StepProps} from '../../common/step/step';
import {ConfirmedBookingSummary} from '../../common/summary/confirmedBookingSummary';
import {EditStepTypesEnum} from '../editStep';

/*
 * Component.
 */

export const EditStepViewConfirmation: FC<StepProps> = (props) => (
  <StepFrame
    metadata={props.metadata}
    title={
      <>
        Meeting rescheduled!{' '}
        <span role="img" aria-label="thumbs up">
          👍
        </span>
      </>
    }
  >
    <ConfirmedBookingSummary booking={props.booking} showChangedHeading />
  </StepFrame>
);

async function preStepAction(data: StepData) {
  // Update the booking with the new start time.
  const {token, start} = data.booking;
  if (!token) {
    throw new Error('Missing booking token for reschedule.');
  }

  if (!start) {
    throw new Error('Missing booking start for reschedule.');
  }

  const serverBooking = await patchBookingAsync(token, start);

  return {
    ...data,
    booking: serverBooking,
  };
}

/*
 * Step.
 */

export const editStepViewConfirmation: Step<EditStepTypesEnum> = {
  type: EditStepTypesEnum.VIEW_CONFIRMATION,
  renderStep: (props) => React.createElement(EditStepViewConfirmation, props),
  preStepAction,
};
