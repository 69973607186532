import {css} from 'styled-components';

import RoobertBoldWoff from '../../../../assets/src/fonts/Roobert/bold/RoobertBold.woff';
import RoobertBoldWoff2 from '../../../../assets/src/fonts/Roobert/bold/RoobertBold.woff2';
import RoobertWoff from '../../../../assets/src/fonts/Roobert/normal/Roobert.woff';
import RoobertWoff2 from '../../../../assets/src/fonts/Roobert/normal/Roobert.woff2';
/** Custom font collections. */

export const customFonts = css`
  /* Roobert Font */
  // Roobert Normal
  @font-face {
    font-family: 'Roobert';
    src: url(${RoobertWoff2}) format('woff2'), url(${RoobertWoff}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  // Roobert Bold
  @font-face {
    font-family: 'Roobert';
    src: url(${RoobertBoldWoff2}) format('woff2'), url(${RoobertBoldWoff}) format('woff');
    font-weight: bold;
    font-style: normal;
  }
`;
