/*
 * Enum.
 */

export enum WeekdaysEnum {
  MONDAY = 1,
  TUESDAY = 2,
  WEDNESDAY = 3,
  THURSDAY = 4,
  FRIDAY = 5,
  SATURDAY = 6,
  SUNDAY = 7,
}

export const weekEndDayForWeekStartDay = {
  [WeekdaysEnum.MONDAY]: WeekdaysEnum.SUNDAY,
  [WeekdaysEnum.TUESDAY]: WeekdaysEnum.MONDAY,
  [WeekdaysEnum.WEDNESDAY]: WeekdaysEnum.TUESDAY,
  [WeekdaysEnum.THURSDAY]: WeekdaysEnum.WEDNESDAY,
  [WeekdaysEnum.FRIDAY]: WeekdaysEnum.THURSDAY,
  [WeekdaysEnum.SATURDAY]: WeekdaysEnum.FRIDAY,
  [WeekdaysEnum.SUNDAY]: WeekdaysEnum.SATURDAY,
};
