import React, {FC} from 'react';
import styled from 'styled-components';

import {CalendarError} from './calendarError';
import {CalendarSuccess} from './calendarSuccess';
import {CalendarContainerProps} from './calendarType';
import {GradientHeaderBarDiv} from './gradientHeaderBar';

/*
 * Style.
 */

const StyledCalendarContentDiv = styled.div`
  @media screen and (max-width: 650px) {
    margin: 5%;
  }
  padding-bottom: 32px;
  margin: 32px 32px 16px 32px;
`;

/*
 * Component.
 */

export const CalendarContent: FC<CalendarContainerProps> = ({calendarEventData, updateResponse}) => {
  const hasError = calendarEventData.error;
  return (
    <>
      <GradientHeaderBarDiv hasError={hasError} response={calendarEventData.responseStatus} />
      <StyledCalendarContentDiv>
        {hasError ? (
          <CalendarError />
        ) : (
          <CalendarSuccess calendarEventData={calendarEventData} updateResponse={updateResponse} />
        )}
      </StyledCalendarContentDiv>
    </>
  );
};
