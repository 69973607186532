import {createContext} from 'react';

/*
 * Types.
 */

export interface LayerVisibilityContextValue {
  /** Whether or not this layer subtree is visible. */
  isVisible: boolean;
}

/*
 * Constants.
 */

const defaultContextValue: LayerVisibilityContextValue = Object.freeze({isVisible: true});

/*
 * Component.
 */

export const LayerVisibilityContext = createContext<LayerVisibilityContextValue>(defaultContextValue);
