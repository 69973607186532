import React, {FC, PropsWithChildren, useContext} from 'react';
import {ThemeContext, ThemeProvider} from 'styled-components';

import {lightTheme} from './light/lightTheme';
import {userThemeMap} from './themeHelpers';
import {UserTheme} from './themeTypes';
import {UserThemesEnum} from './userThemesEnum';

/*
 * Types.
 */

export interface ThemeContextType {
  theme: UserTheme;
}

export type ThemeContextReader = (props: ThemeContextType) => string;
export type ThemeReader = (theme: UserTheme) => string;

/*
 * Props.
 */

interface UserThemeProviderProps {
  theme: UserThemesEnum;
}

/*
 * Component.
 */

export const UserThemeProvider: FC<PropsWithChildren<UserThemeProviderProps>> = ({theme, children}) => (
  <ThemeProvider theme={userThemeMap[theme]}>{children}</ThemeProvider>
);

export const LightUserThemeProvider: FC<PropsWithChildren<unknown>> = ({children}) => (
  <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
);

/*
 * Hook.
 */

export function useUserTheme(): UserTheme {
  const theme = useContext(ThemeContext);
  return theme;
}
