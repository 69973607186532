import {
  findColorShade10,
  findColorShade20,
  findColorShade30,
  findColorShade40,
  findColorShade50,
} from '../themeColorHelpers';

export const lightTagSemanticStyles = {
  findColor: findColorShade40,
  findHoverColor: findColorShade40,

  findBackgroundColor: findColorShade10,
  findHoverBackgroundColor: findColorShade20,

  findBorderColor: findColorShade40,
  findHoverBorderColor: findColorShade50,
};

export type TagSemanticStyles = typeof lightTagSemanticStyles;

export const darkTagSemanticStyles: TagSemanticStyles = {
  findColor: findColorShade50,
  findHoverColor: findColorShade50,

  findBackgroundColor: findColorShade10,
  findHoverBackgroundColor: findColorShade20,

  findBorderColor: findColorShade30,
  findHoverBorderColor: findColorShade40,
};
