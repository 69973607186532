import React, {FC, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {IconButton} from '../../../../../libs/shared/ui/src/components/buttons/iconButton';
import {Icon} from '../../../../../libs/shared/ui/src/components/icon/icon';
import {palette} from '../../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes, fontWeights} from '../../../../../libs/shared/ui/src/styles/fontStyles';
import {DeviceTypesEnum, PlatformProps, withPlatform} from '../../platform/platformContext';

/*
 * Props.
 */

export interface BannerErrorProps {
  description: ReactNode;
  onDismiss: () => void;
}

/*
 * Style.
 */

const StyledWrapperDiv = withPlatform(styled.div<PlatformProps>`
  position: absolute;
  bottom: ${(props) => (props.device === DeviceTypesEnum.DESKTOP ? '10%' : '10px')};
  left: 0px;
  right: 0px;

  display: grid;
  justify-content: center;
`);

const StyledErrorDiv = withPlatform(styled.div<PlatformProps>`
  display: grid;
  grid-template-areas: 'icon content buttons';
  grid-template-columns: 16px 1fr auto;
  grid-column-gap: 10px;

  background-color: ${palette.orange.shade20};
  color: ${palette.orange.shade50};

  padding: 9px;
  ${(props) => errorDivPlatformStyle(props)}
`);

function errorDivPlatformStyle(props: PlatformProps) {
  switch (props.device) {
    case DeviceTypesEnum.MOBILE:
      return css`
        border-radius: 8px;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
        width: 100%;
      `;

    case DeviceTypesEnum.DESKTOP:
    default:
      return css`
        border-radius: 100px;
      `;
  }
}

const StyledWarningIcon = styled(Icon)`
  color: ${palette.orange.shade40};
  margin: 0px 3px;
`;

const StyledDescriptionDiv = styled.div`
  font-size: ${fontSizes.verySmall};
  line-height: 16px;
  font-weight: ${fontWeights.medium};
`;

const StyledCloseButtonWrapper = styled.div`
  margin: -5px;
`;

/*
 * Component.
 */

export const BannerError: FC<BannerErrorProps> = (props) => (
  <StyledWrapperDiv>
    <StyledErrorDiv>
      <StyledWarningIcon name="warningFilledSmall" />
      <StyledDescriptionDiv>{props.description}</StyledDescriptionDiv>
      <StyledCloseButtonWrapper>
        <IconButton
          onClick={props.onDismiss}
          iconName="closeSmall"
          label="Close"
          color={palette.orange.shade50}
          hoverColor={palette.orange.shade50}
          isRounded
          renderTooltip={() => null}
        />
      </StyledCloseButtonWrapper>
    </StyledErrorDiv>
  </StyledWrapperDiv>
);
