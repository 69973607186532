import React, {FC, PropsWithChildren} from 'react';

import {globalWindow} from '../../../../libs/shared/core/src/helpers/browser/browserHelpers';
import {DeviceTypesEnum, PlatformContext} from './platformContext';

/*
 * Component.
 */

export const PlatformProvider: FC<PropsWithChildren<{}>> = (props) => {
  const device = globalWindow.screen.width < 600 ? DeviceTypesEnum.MOBILE : DeviceTypesEnum.DESKTOP;
  return <PlatformContext.Provider value={{device}}>{props.children}</PlatformContext.Provider>;
};
