import {IANAZone} from 'luxon';
import React, {FC} from 'react';

import {memoizedListBrowserSupportedTimeZones} from '../../../../core/src/helpers/datetime/dateTimeHelpers';
import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {DropdownBoxCoordinator, DropdownBoxCoordinatorProps} from '../boxedInputs/dropdownBoxCoordinator';
import {TimezoneDropdown, TimezoneDropdownInheritableProps} from './timezoneDropdown';

/*
 * Props.
 */

interface TimezoneDropdownBoxProps
  extends Omit<DropdownBoxCoordinatorProps, 'renderDropdown'>,
    Omit<TimezoneDropdownInheritableProps, 'timezonesList'> {
  renderItem?: RendererOf<string>;
}

/*
 * Component.
 */

export const TimezoneDropdownBox: FC<TimezoneDropdownBoxProps> = (props) => {
  const {timezone} = props;

  const renderValue = props.renderItem ?? renderShortTimezone;

  return (
    <DropdownBoxCoordinator
      value={renderValue(timezone)}
      size={VisualSizesEnum.SMALL}
      renderDropdown={(popoverProps) => (
        <TimezoneDropdown
          {...popoverProps}
          timezonesList={memoizedListBrowserSupportedTimeZones()}
          timezone={timezone}
          onTimezoneSelect={props.onTimezoneSelect}
          showUtc={props.showUtc}
          placement={props.placement}
        />
      )}
      {...props}
    />
  );
};

/*
 * Helpers.
 */

// Quicker way to render the timezone abbreviation without having to wait for the data to be loaded.
function renderShortTimezone(timezone: string) {
  try {
    return IANAZone.create(timezone).name;
  } catch (e) {
    return '';
  }
}
