import {isString} from 'lodash';
import {BehaviorSubject, EMPTY, fromEvent, merge, of, Subject} from 'rxjs';
import {delay, filter, map, mapTo, share, switchAll, throttleTime} from 'rxjs/operators';
import {Key} from 'ts-key-enum';

import {isInputLike} from '../../../../core/src/helpers/browser/domHelpers';

/*
 * Constants.
 */

const typingTimeout = 2000;

// Ignore keydown events within 200 ms of each other.
const keyDownThrottleDuration = 200;

// When the user (re)started typing, immediately emit true, then after a timeout emit false.
const startTyping$ = merge(of(true), of(false).pipe(delay(typingTimeout)));

// When the user is continuing to type, extend the timeout for emitting false.
const continueTyping$ = of(false).pipe(delay(typingTimeout));

// When the user stopped typing, immediately emit false.
const stopTyping$ = of(false);

/*
 * Service.
 */

export interface TypingMonitor {
  readonly isTyping: boolean;
  suspendForTextInputIntent(): void;
  stop(): void;
}

export function startMonitoringTyping(document: Document): TypingMonitor {
  // Start typing when we expect the focus to move to an input field soon.
  const onTextInputIntent$ = new Subject<void>();
  const startTypingOnTextInputIntent$ = onTextInputIntent$.pipe(mapTo(startTyping$));

  // Start typing when the focus did move to an input field.
  const startTypingOnInputFocus$ = fromEvent<FocusEvent>(document, 'focusin').pipe(
    filter((event) => isInputLike(event.target)),
    mapTo(startTyping$),
  );

  const onDocumentKeyDownCapture$ = fromEvent<KeyboardEvent>(document, 'keydown', {
    capture: true,
    passive: true,
  }).pipe(
    // Attach only one event handler.
    share(),
  );

  // Start/continue typing on typing events.
  const startOrContinueTypingOnKeyDown$ = onDocumentKeyDownCapture$.pipe(
    filter(isTypingEvent),
    // Avoid the overhead of stopping/starting timers while typing.
    throttleTime(keyDownThrottleDuration, undefined, {leading: true, trailing: false}),
    map((event) => (isInputLike(event.target) ? startTyping$ : continueTyping$)),
  );

  // Stop typing on an control event.
  const stopTypingOnKeyDown$ = onDocumentKeyDownCapture$.pipe(filter(isControlEvent), mapTo(stopTyping$));

  // Stop typing on mouse events outside an input field.
  const onDocumentMouseDownCapture$ = fromEvent<MouseEvent>(document, 'mousedown', {
    capture: true,
    passive: true,
  });
  const stopTypingOnMouseDown$ = onDocumentMouseDownCapture$.pipe(
    filter((event) => !isInputLike(event.target)),
    mapTo(stopTyping$),
  );

  // Stop typing when the window blurs, just in case.
  const stopTypingOnWindowBlur$ = document.defaultView
    ? fromEvent<FocusEvent>(document.defaultView, 'blur').pipe(mapTo(stopTyping$))
    : EMPTY;

  // Compute the typing state.
  const isTyping$ = new BehaviorSubject(false);
  const nextIsTyping$ = merge(
    startTypingOnTextInputIntent$,
    startTypingOnInputFocus$,
    startOrContinueTypingOnKeyDown$,
    stopTypingOnKeyDown$,
    stopTypingOnMouseDown$,
    stopTypingOnWindowBlur$,
  ).pipe(switchAll());

  // Continuously update the typing state.
  const subscription = nextIsTyping$.subscribe(isTyping$);

  return {
    get isTyping() {
      return isTyping$.getValue();
    },
    suspendForTextInputIntent() {
      onTextInputIntent$.next(undefined);
    },
    stop() {
      subscription.unsubscribe();
    },
  };
}

/*
 * Helpers.
 */

/** Determines whether the user is typing. */
function isTypingEvent(event: KeyboardEvent): boolean {
  return isAlphanumericTypingEvent(event) || isNonAlphanumericTypingEvent(event);
}

/** Determines whether the user is typing characters. */
function isAlphanumericTypingEvent(event: KeyboardEvent): boolean {
  // Check for undefined just in case, because we have encountered that in the new composer.
  if (!isString(event.key)) {
    return false;
  }

  // The altKey may be used for text entry, for example on Polish keyboard layouts.
  return event.key.length === 1 && !event.ctrlKey && !event.metaKey;
}

const nonAlphanumericTypingKeys = new Set<string>([
  Key.Enter,
  Key.Backspace,
  Key.Delete,
  Key.ArrowDown,
  Key.ArrowLeft,
  Key.ArrowRight,
  Key.ArrowUp,
]);

/** Determines whether the user is moving around and deleting things. */
function isNonAlphanumericTypingEvent(event: KeyboardEvent): boolean {
  return nonAlphanumericTypingKeys.has(event.key) && !event.ctrlKey && !event.metaKey;
}

/** Determines whether the user is performing some kind of operation. */
function isControlEvent(event: KeyboardEvent): boolean {
  return !isModifierEvent(event) && !isTypingEvent(event);
}

const modifierKeys = new Set<string>([Key.Control, Key.Meta, Key.Shift, Key.Alt]);

/** Determines whether the user is pressing/releasing a modifier key. */
function isModifierEvent(event: KeyboardEvent): boolean {
  return modifierKeys.has(event.key);
}
