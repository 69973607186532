import React, {FC} from 'react';
import styled from 'styled-components';

import loader from '../../../../assets/src/images/loader.png';
import loaderGrey from '../../../../assets/src/images/loaderGrey.png';
import loaderGreySmall from '../../../../assets/src/images/loaderGreySmall.png';
import loaderSmall from '../../../../assets/src/images/loaderSmall.png';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {rotate360Animation} from '../../styles/mixins';
import {makeSizeConstants} from '../../styles/styleHelpers';

/*
 * Props.
 */

export enum LoaderVariantsEnum {
  BASE = 'BASE',
  GREY = 'GREY',
}

export interface LoaderProps {
  className?: string;
  size?: VisualSizesEnum;
  variant?: LoaderVariantsEnum;
  isEnabled?: boolean;
}

const defaultProps = {
  size: VisualSizesEnum.LARGE,
  variant: LoaderVariantsEnum.BASE,
  isEnabled: true,
};

/*
 * Style.
 */

const sizes = makeSizeConstants(16, 20, 40);
const images = {
  [LoaderVariantsEnum.BASE]: makeSizeConstants(loaderSmall, loaderSmall, loader),
  [LoaderVariantsEnum.GREY]: makeSizeConstants(loaderGreySmall, loaderGreySmall, loaderGrey),
};

interface LoaderStyleProps {
  $size: VisualSizesEnum;
  $variant: LoaderVariantsEnum;
  $isEnabled: boolean;
}
const StyledLoaderDiv = styled.div<LoaderStyleProps>`
  width: ${(p) => sizes[p.$size]}px;
  height: ${(p) => sizes[p.$size]}px;
  background-image: url(${(p) => images[p.$variant][p.$size]});
  background-size: ${(p) => sizes[p.$size]}px;

  ${(p) => maybeAnimate(p.$isEnabled)};
`;

function maybeAnimate(isEnabled: boolean) {
  if (!isEnabled) {
    return '';
  }

  return rotate360Animation(0.6);
}

/*
 * Component.
 */

export const Loader: FC<LoaderProps> = (props) => (
  <StyledLoaderDiv
    $size={props.size ?? defaultProps.size}
    $variant={props.variant ?? defaultProps.variant}
    $isEnabled={props.isEnabled ?? defaultProps.isEnabled}
    className={props.className}
    data-testid="loader"
  />
);
