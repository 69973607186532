export enum BaseUrlsEnum {
  SCHEDULING_API_BASE_URL = '/api/1/scheduling/',
  NOAUTH_API_BASE_URL = '/api/1/noauth/',
  HOMEPAGE_URL = 'https://front.com/',
  EXTRA_IMG_URL = 'https://frontapp.com/extra/',
}

export enum RequestMethodsEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
