/*
 * Types.
 */

export enum EditStepTypesEnum {
  CHOOSE_SLOT,
  CHOOSE_DATE,
  CHOOSE_TIME,
  VIEW_CONFIRMATION,
}
