import {range} from 'lodash';
import React, {FC} from 'react';
import styled from 'styled-components';

import {greys, palette} from '../../../../../../libs/shared/ui/src/styles/colorStyles';

/*
 * Props.
 */

export interface StepFrameDotsProps {
  stepIndex: number;
  numSteps: number;
}

/*
 * Style.
 */

const StyledDotWrapperDiv = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-auto-flow: column;

  justify-content: center;
  justify-items: center;

  margin: 2px 0px 12px;
`;

interface StyledDotProps {
  isSelected?: boolean;
}

const StyledDotDiv = styled.div<StyledDotProps>`
  height: 10px;
  width: 10px;

  border: 1px solid ${(props) => (props.isSelected ? palette.blue.shade40 : greys.shade40)};
  border-radius: 6px;

  ${(props) => props.isSelected && `background-color: ${palette.blue.shade40};`}
`;

/*
 * Component.
 */

export const StepFrameDots: FC<StepFrameDotsProps> = (props) => {
  const {numSteps, stepIndex} = props;

  // We don't show a dot for the last step since it's a confirmation.
  const numDots = numSteps - 1;
  // We don't need to render dots if there's only 1 or we're on the last step.
  if (numDots <= 1 || stepIndex >= numDots) {
    return null;
  }

  return (
    <StyledDotWrapperDiv>
      {range(numDots).map((index) => (
        <StyledDotDiv key={index} isSelected={index === stepIndex} />
      ))}
    </StyledDotWrapperDiv>
  );
};
