import {createContext} from 'react';

import {CleanHtml} from '../../../../core/src/helpers/typescript/cleanHtml';

/*
 * Types.
 */

export interface SvgManagerContextValue {
  canAddSvgContent: boolean;
  addSvgContent(id: string, loadContent: SvgContentLoader): void;
}

export type SvgContentLoader = () => CleanHtml;

/*
 * Context.
 */

export const SvgManagerContext = createContext<SvgManagerContextValue>({
  canAddSvgContent: false,
  addSvgContent: () => {
    throw new Error('Shared SVG content not supported');
  },
});
