import React, {memo, PropsWithChildren} from 'react';

import {VisualSizesEnum} from '../../styles/commonStyles';
import {Icon} from '../icon/icon';
import {BooleanInputProps} from './booleanInputTypes';
import {BooleanInputWithLabel} from './booleanInputWithLabel';

/*
 * Props.
 */

export type CheckBoxProps = BooleanInputProps;

/*
 * Component.
 */

/** Checkbox controlled input. */
export const CheckBox = memo<PropsWithChildren<CheckBoxProps>>((props) => {
  const {onChange, className, isChecked, isDisabled, children, isIndeterminate} = props;

  return (
    <BooleanInputWithLabel
      className={className}
      onChange={onChange}
      isChecked={isChecked}
      isIndeterminate={isIndeterminate}
      isDisabled={isDisabled}
      checkedIndicator={<Icon name="checkmarkBoxSmall" />}
      size={props.size || VisualSizesEnum.MEDIUM}
      type="checkbox"
    >
      {children}
    </BooleanInputWithLabel>
  );
});
