import React, {forwardRef, PropsWithChildren} from 'react';
import styled from 'styled-components';

import {visuallyHidden} from '../../styles/mixins';

/*
 * Props.
 */

export interface VisuallyHiddenProps {
  id?: string;
  className?: string;
}

/*
 * Style.
 */

const StyledWrapperSpan = styled.span`
  ${visuallyHidden()}
`;

/*
 * Component.
 */

export const VisuallyHidden = forwardRef<HTMLElement, PropsWithChildren<VisuallyHiddenProps>>(
  ({id, className, children}, ref) => {
    if (!children) {
      return null;
    }

    return (
      <StyledWrapperSpan ref={ref} id={id} className={className}>
        {children}
      </StyledWrapperSpan>
    );
  },
);
