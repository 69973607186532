import {DateTime} from 'luxon';
import React, {FC, useMemo} from 'react';

import {
  buildCalendarDateFromDateTime,
  buildCalendarDateFromTimestamp,
  buildCalendarMonthFromCalendarDate,
  CalendarDate,
  CalendarMonth,
} from '../../helpers/date';
import {makeFullAvailabilityInterval} from '../../helpers/model/availability';
import {SchedulingLinkNonEmptyAvailabilityModel} from '../../models/schedulingLinkAvailabilityModel';
import {SchedulingLinkModel} from '../../models/schedulingLinkModel';
import {useLocale} from '../common/locale/localeContext';
import {DatePicker} from './datePicker';

/*
 * Props.
 */

export interface AvailabilityDatePickerProps {
  schedulingLink: SchedulingLinkModel;
  selectedDate: CalendarDate | undefined;
  onSelectDate: (date: CalendarDate) => void;
  availability: SchedulingLinkNonEmptyAvailabilityModel;
}

/*
 * Component.
 */

export const AvailabilityDatePicker: FC<AvailabilityDatePickerProps> = (props) => {
  const {onSelectDate, availability} = props;
  const {timezone} = useLocale();

  const availableTimes = useMemo(
    () => availability.timeSlots.map((slot) => DateTime.fromMillis(slot.start).setZone(timezone)),
    [availability, timezone],
  );

  const selectableInterval = useMemo(() => makeFullAvailabilityInterval(availability), [availability]);

  return (
    <DatePicker
      startMonth={buildStartMonth(props)}
      availableTimes={availableTimes}
      onSelectDate={onSelectDate}
      selectedDate={props.selectedDate}
      selectableInterval={selectableInterval}
    />
  );
};

/*
 * Helpers.
 */

function buildStartMonth(props: AvailabilityDatePickerProps): CalendarMonth {
  if (props.selectedDate) {
    return buildCalendarMonthFromCalendarDate(props.selectedDate);
  }

  if (props.schedulingLink.availabilityRangeStart) {
    return buildCalendarMonthFromCalendarDate(
      buildCalendarDateFromTimestamp(props.schedulingLink.availabilityRangeStart),
    );
  }

  return buildCalendarMonthFromCalendarDate(buildCalendarDateFromDateTime(DateTime.local()));
}
