import {themeConstants} from '../themeConstants';
import {ThemeTypesEnum, UserTheme} from '../themeTypes';
import {lightColors} from './lightColors';
import {lightLegacySemanticStyles} from './lightLegacySemanticStyles';
import {lightSemanticStyles} from './lightSemanticStyles';

export const lightTheme: UserTheme = {
  ...lightSemanticStyles,
  ...lightLegacySemanticStyles,
  ...lightColors,
  constants: themeConstants,
  type: ThemeTypesEnum.LIGHT,
};
