import {createContext, useContext} from 'react';

import {BugsnagSeveritiesEnum} from '../../../core/src/helpers/bugsnag/bugsnagSeverities';
import {withContextBuilder} from '../../../core/src/helpers/react/reactHelpers';

export type ReportError = (error: Error, severity?: BugsnagSeveritiesEnum) => void;

export interface ErrorReporterContextType {
  reportError: ReportError;
}

/*
 * This error reporter is used in shared components which don't have direct
 * access to frontapp's application code. If you're developing outside of libs,
 * (e.g. src, srcCommon, or srcMobile) you should import Bugsnag directly:
 *
 * import {reportToBugsnag} from "srcCommon/services/bugsnag/bugsnag";
 */
export const ErrorReporterContext = createContext<ErrorReporterContextType>({
  reportError: () => {},
});

export function useErrorReporter() {
  return useContext(ErrorReporterContext);
}

export const withErrorReporter = withContextBuilder(ErrorReporterContext);
