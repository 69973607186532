import React, {FC} from 'react';

import {DeviceTypesEnum, usePlatform} from '../../../platform/platformContext';
import {StepFrameDesktop} from './stepFrameDesktop';
import {StepFrameMobile} from './stepFrameMobile';
import {StepFrameProps} from './stepFrameProps';

/*
 * Component.
 */

export const StepFrame: FC<StepFrameProps> = (props) => {
  const {device} = usePlatform();
  if (device === DeviceTypesEnum.MOBILE) {
    return React.createElement(StepFrameMobile, props);
  }

  return React.createElement(StepFrameDesktop, props);
};
