/*
 * Http errors.
 */

import {SchedulingLinkBookingModel} from '../models/schedulingLinkBookingModel';
import {SchedulingLinkModel} from '../models/schedulingLinkModel';

export class CancelError extends Error {
  constructor() {
    super('Cancel Error');
  }
}

export class HttpResponseError extends Error {
  readonly status: number;
  readonly path: string;

  constructor(path: string, status: number) {
    super(`The request failed — ${status} — ${path}`);

    this.status = status;
    this.path = path;
  }
}

/*
 * Scheduling errors.
 */

export class SchedulingLinkMissingAvailabilityError extends Error {
  readonly schedulingLink: SchedulingLinkModel;

  constructor(schedulingLink: SchedulingLinkModel) {
    super('Scheduling link missing availability.');
    this.schedulingLink = schedulingLink;
  }
}

export class BookingCancelledError extends Error {
  readonly booking: SchedulingLinkBookingModel;

  constructor(booking: SchedulingLinkBookingModel) {
    super('Booking is cancelled');
    this.booking = booking;
  }
}

export class BookingDisabledError extends Error {
  readonly booking: SchedulingLinkBookingModel;

  constructor(booking: SchedulingLinkBookingModel) {
    super('Booking is disabled');
    this.booking = booking;
  }
}
