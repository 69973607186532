import styled, {css} from 'styled-components';

import {DeviceTypesEnum, PlatformProps, withPlatform} from '../platform/platformContext';

/*
 * Style.
 */

const StyledMainContentWrapper = styled.div<PlatformProps>`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
  ${(props) => platformStyle(props)}
`;

function platformStyle(props: PlatformProps) {
  if (props.device === DeviceTypesEnum.DESKTOP) {
    return css`
      overflow-y: scroll;
    `;
  }

  return css`
    height: 100%;
    margin: 0px 16px;
  `;
}

/*
 * Component.
 */

export const MainContentWrapper = withPlatform(StyledMainContentWrapper);
