import _ from 'lodash';
import React, {FC} from 'react';
import styled from 'styled-components';

import {buildCleanReactInnerHtml} from '../../../../libs/shared/core/src/helpers/typescript/cleanHtml';
import {collectEnumValues} from '../../../../libs/shared/core/src/models/api/types/enumTypes';
import {fontSizes, fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {anchorStyle} from './calendarInviteHelpers';
import {ResponseButton} from './calendarResponseButton';
import {CalendarTitle} from './CalendarTitle';
import {CalendarContainerProps, CalendarInviteResponsesEnum, LocationItemProps} from './calendarType';

/*
 * Style.
 */

const StyledSuccessDiv = styled.div`
  @media screen and (max-width: 650px) {
    overflow: scroll;
  }
  font-size: ${fontSizes.medium};
  line-height: 18px;
  font-weight: ${fontWeights.medium};
  display: grid;
  grid-template-columns: 1fr 5fr;
  grid-columns-gap: 16px;
  grid-row-gap: 24px;
`;

const StyledColumnHeaderDiv = styled.div`
  @media screen and (max-width: 650px) {
    margin-right: 5px;
  }
  color: #808080;
`;

const StyledDescriptionDiv = styled.div`
  a {
    ${anchorStyle}
  }
`;

const StyledButtonGroupDiv = styled.div`
  display: flex;
  flex: 1 1 auto;
`;

const LocationWithUrl: FC<LocationItemProps> = ({locationItem}) => (
  <StyledDescriptionDiv>
    <a href={locationItem.url || ''} target="_blank" rel="noreferrer noopener">
      {locationItem.name}
    </a>
  </StyledDescriptionDiv>
);

/*
 * Component.
 */

export const CalendarSuccess: FC<CalendarContainerProps> = ({calendarEventData, updateResponse}) => (
  <>
    <CalendarTitle title={calendarEventData.summary} />
    <StyledSuccessDiv>
      <StyledColumnHeaderDiv>Reply</StyledColumnHeaderDiv>
      <StyledButtonGroupDiv>
        {collectEnumValues(CalendarInviteResponsesEnum).map((responseOption) => (
          <ResponseButton
            responseOption={responseOption}
            updateResponse={updateResponse}
            selected={responseOption === calendarEventData.responseStatus}
          />
        ))}
      </StyledButtonGroupDiv>
      {!_.isEmpty(calendarEventData.locationList) && (
        <>
          <StyledColumnHeaderDiv>Location</StyledColumnHeaderDiv>
          <div>
            {calendarEventData.locationList.map((locationItem) =>
              locationItem.url ? (
                <LocationWithUrl locationItem={locationItem} />
              ) : (
                <div>{locationItem.name}</div>
              ),
            )}
          </div>
        </>
      )}
      <StyledColumnHeaderDiv>When</StyledColumnHeaderDiv>
      <div>{calendarEventData.eventDateTime}</div>
      <StyledColumnHeaderDiv>Who</StyledColumnHeaderDiv>
      <div>{calendarEventData.numberOfAttendees} participants</div>
      {calendarEventData.description && (
        <>
          <StyledColumnHeaderDiv>Description</StyledColumnHeaderDiv>
          <StyledDescriptionDiv
            dangerouslySetInnerHTML={buildCleanReactInnerHtml(calendarEventData.description)}
          />
        </>
      )}
    </StyledSuccessDiv>
  </>
);
