import React, {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';

import {InteractiveList, InteractiveListProps} from '../interactiveList/interactiveList';

/*
 * Constants.
 */

export const dropdownListHorizontalPadding = 8;

/*
 * Style.
 */

const StyledMainList = styled(InteractiveList)`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: ${dropdownListHorizontalPadding}px 0;
  overflow: auto;
  position: relative; /* PB-12793 */
`;

/*
 * Component.
 */

export const DropdownList: FC<PropsWithChildren<InteractiveListProps>> = (props) => (
  <StyledMainList {...props}>{props.children}</StyledMainList>
);
