import React, {FC, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {VisualSizesEnum} from '../../styles/commonStyles';
import {fontWeights, tertiaryFontSizes} from '../../styles/fontStyles';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {boxedInputColorReaders} from '../boxedInputs/boxedInputConstants';

/*
 * Props.
 */

interface FieldDescriptionProps {
  size?: VisualSizesEnum;
  message?: ReactNode;
  errorMessage?: ReactNode;
  shouldDisableBottomTextMargin?: boolean;
}

/*
 * Style.
 */

interface StyleProps {
  $size: VisualSizesEnum;
  $shouldDisableBottomTextMargin?: boolean;
}

const errorMarginBottom = makeSizeConstants('10px', '12px', '14px');
const StyledErrorDiv = styled.div<StyleProps>`
  font-weight: ${fontWeights.medium};
  font-size: ${(p) => tertiaryFontSizes[p.$size]};
  margin-bottom: ${(p) => errorMarginBottom[p.$size]};
  color: ${boxedInputColorReaders.textError};
  line-height: 1.2;

  ${(p) =>
    p.$shouldDisableBottomTextMargin &&
    css`
      margin-bottom: 0;
    `}
`;

const StyledMessageDiv = styled.div<StyleProps>`
  font-weight: ${fontWeights.normal};
  font-size: ${(p) => tertiaryFontSizes[p.$size]};
  margin-bottom: ${(p) => errorMarginBottom[p.$size]};
  color: ${boxedInputColorReaders.textLabel};
  line-height: 1.2;

  ${(p) =>
    p.$shouldDisableBottomTextMargin &&
    css`
      margin-bottom: 0;
    `}
`;

/*
 * Component.
 */

export const FieldDescription: FC<FieldDescriptionProps> = (props) => {
  const size = props.size ?? VisualSizesEnum.MEDIUM;

  if (props.errorMessage) {
    return (
      <StyledErrorDiv $size={size} $shouldDisableBottomTextMargin={props.shouldDisableBottomTextMargin}>
        {props.errorMessage}
      </StyledErrorDiv>
    );
  }

  if (props.message) {
    return (
      <StyledMessageDiv $size={size} $shouldDisableBottomTextMargin={props.shouldDisableBottomTextMargin}>
        {props.message}
      </StyledMessageDiv>
    );
  }

  return null;
};
