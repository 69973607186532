import React, {FC, PropsWithChildren} from 'react';
import styled from 'styled-components';

import {fontSizes} from '../../styles/fontStyles';

/*
 * Props.
 */

interface DropdownFooterProps {
  className?: string;
}

/*
 * Style.
 */

const StyledFooterDiv = styled.div`
  border-top: 1px solid ${(p) => p.theme.greys.shade30};
  padding: 12px;

  color: ${(p) => p.theme.greys.shade60};
  font-size: ${fontSizes.small};
`;

/*
 * Component.
 */

export const DropdownFooter: FC<PropsWithChildren<DropdownFooterProps>> = ({children, className}) => (
  <StyledFooterDiv className={className}>{children}</StyledFooterDiv>
);
