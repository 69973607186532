import {Placement, SideObject} from '@popperjs/core';
import maxSize from 'popper-max-size-modifier';
import React, {FC, PropsWithChildren} from 'react';
import {Modifier, Popper} from 'react-popper';
import styled from 'styled-components';

import {SizeListener} from '../../hooks/sizeHooks';
import {Anchor, buildReferenceObject} from './repositionHelpers';
import {useRepositionBoundary} from './useRepositionBoundary';

/*
 * Constants.
 */

const defaultPreventOverflowPadding = 10;

/*
 * Props.
 */

export interface RepositionProps {
  /** Ref to the anchor, or bounds provider. */
  anchor: Anchor;
  /** Position of the element relative to the anchor. */
  placement?: Placement;
  preventOverflowPadding?: Partial<SideObject>;
  /** Whether the popover is allowed to leave its anchor. */
  shouldUntether?: boolean;
  /** Whether we should skip the max size modifier. The modifier causes issues
   *  when the height of the dropdown increases since it is constrained. */
  shouldSkipApplyMaxSize?: boolean;
  isInline?: boolean;
}

/*
 * Styles.
 */

const StyledWrapperDiv = styled.div`
  // If a popover overlaps P0, turn off Electron's window dragging to keep buttons clickable on Windows.
  -webkit-app-region: no-drag;
`;

/*
 * Component.
 */

// Create your own apply modifier that adds the styles to the state
const applyMaxSize: Modifier<any> = {
  name: 'applyMaxSize',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['maxSize'],
  fn({state}) {
    // The `maxSize` modifier provides this data
    const {width, height} = state.modifiersData.maxSize;

    // eslint-disable-next-line no-param-reassign
    state.styles.popper = {
      ...state.styles.popper,
      maxWidth: `${width}px`,
      maxHeight: `${height}px`,
    };
  },
};

/** Position content next to an existing element. */
export const Reposition: FC<PropsWithChildren<RepositionProps>> = (props) => {
  const boundary = useRepositionBoundary();
  const {anchor, children, placement, preventOverflowPadding} = props;
  const popperPlacement: Placement = placement || 'bottom-start';
  const popperPreventOverflowPadding: Partial<SideObject> = {
    top: preventOverflowPadding?.top ?? defaultPreventOverflowPadding,
    left: preventOverflowPadding?.left ?? defaultPreventOverflowPadding,
    bottom: preventOverflowPadding?.bottom ?? defaultPreventOverflowPadding,
    right: preventOverflowPadding?.right ?? defaultPreventOverflowPadding,
  };

  const maxSizeModifiers = props.shouldSkipApplyMaxSize
    ? []
    : [
        {
          ...maxSize,
          options: {
            padding: popperPreventOverflowPadding,
            boundary,
          },
        },
        applyMaxSize,
      ];

  return (
    <Popper
      referenceElement={buildReferenceObject(anchor)}
      placement={popperPlacement}
      modifiers={[
        ...maxSizeModifiers,
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: popperPreventOverflowPadding,
            boundary,
            tether: !props.shouldUntether,
          },
        },
        {
          name: 'flip',
          options: {
            boundary,
          },
        },
        {
          name: 'arrow',
          enabled: false,
        },
        {
          name: 'computeStyles',
          options: {
            gpuAcceleration: false,
          },
        },
      ]}
    >
      {({ref, style, forceUpdate, placement: finalPlacement}) => (
        <StyledWrapperDiv
          ref={ref}
          style={{...style, zIndex: props.isInline ? 99 : undefined}}
          data-placement={finalPlacement}
        >
          <SizeListener
            onSize={forceUpdate}
            // Needed to make dropdown conform to our final allowed max-height
            style={{
              maxHeight: style.maxHeight,
              maxWidth: style.maxWidth,
              display: 'flex',
            }}
          >
            {children}
          </SizeListener>
        </StyledWrapperDiv>
      )}
    </Popper>
  );
};
