import {transparentize} from 'polished';
import {css} from 'styled-components';

import {toReadonly} from '../../../core/src/helpers/typescript/typeHelpers';
import {PaletteColorsEnum} from '../../../core/src/models/api/paletteModel';
import {boxShadow} from '../theme/themeStyleHelpers';

/*
 * Brand.
 */

export const brandColor = '#001B38';

/*
 * Greys.
 */

/** Shades of grey. Light theme. */
export const greys = toReadonly({
  white: '#ffffff',
  shade10: '#f7f8fa',
  shade20: '#f3f4f6',
  shade30: '#e8eaed',
  shade40: '#d4d6d9',
  shade50: '#bbbdbf',
  shade60: '#9b9c9e',
  shade70: '#77787a',
  shade80: '#3e3e40',
  shade90: '#19191a',
  shade100: '#000',
  black: '#001B38',
});

/** Shades of grey. Dark theme. */
export const greysDark = toReadonly({
  white: '#0d0f15',
  shade10: '#12161d',
  shade20: '#1e212a',
  shade30: '#353841',
  shade40: '#3d4148',
  shade50: '#52535b',
  shade60: '#6d6f74',
  shade70: '#9c9895',
  shade80: '#bcb8b4',
  shade90: '#ebe8e7',
  shade100: '#fff',
  black: '#faf8f7',
});

/** Shades of transparent greys. Light theme. */
export const alphas = toReadonly({
  transparent: 'transparent',
  gray10: transparentize(0.9, '#5d6985'),
  gray20: transparentize(0.82, '#5d6985'), // "selected state Gray 18%" in Figma.
  gray90: transparentize(0.1, '#5d6985'),
  black10: alphaBlack(0.04),
  black20: alphaBlack(0.08),
  black30: alphaBlack(0.1),
  black40: alphaBlack(0.15),
  black50: alphaBlack(0.3),
  black60: alphaBlack(0.6),
  black70: alphaBlack(0.7),
  white10: alphaWhite(0.08),
  white20: alphaWhite(0.15),
  white30: alphaWhite(0.3),
  white40: alphaWhite(0.6),
  white50: alphaWhite(0.8),
  white60: alphaWhite(0.95),
});

/** Shades of transparent greys. Dark theme. */
export const alphasDark = toReadonly({
  transparent: 'transparent',
  gray10: transparentize(0.9, '#B6BFC8'),
  gray20: transparentize(0.82, '#B6BFC8'),
  gray90: transparentize(0.1, '#B6BFC8'),
  white10: alphaBlack(0.04),
  white20: alphaBlack(0.08),
  white30: alphaBlack(0.1),
  white40: alphaBlack(0.15),
  white50: alphaBlack(0.3),
  white60: alphaBlack(0.6),
  black10: alphaWhite(0.08),
  black20: alphaWhite(0.15),
  black30: alphaWhite(0.3),
  black40: alphaWhite(0.6),
  black50: alphaWhite(0.8),
  black60: alphaWhite(0.95),
  black70: alphaBlack(0.7),
});

/**
 * Convenience helper to get a rgba(black, N) equivalent
 * @param n opacity (from 0 to 1)
 */
function alphaBlack(n: number) {
  return `rgba(0, 0, 0, ${n})`;
}

/**
 * Convenience helper to get a rgba(white, N) equivalent
 * @param n opacity (from 0 to 1)
 */
export function alphaWhite(n: number) {
  return `rgba(255, 255, 255, ${n})`;
}

export interface PaletteColor {
  readonly highlight?: string;

  readonly shade10: string;
  readonly shade20: string;
  readonly shade30: string;
  readonly shade40: string;
  readonly shade50: string;

  readonly hover30?: string;
}

type Palette = {readonly [T in PaletteColorsEnum]: PaletteColor};

/** Main color palette. Light theme. */
export const palette: Palette = {
  [PaletteColorsEnum.GREY]: {
    shade10: greys.shade10,
    shade20: greys.shade30,
    shade30: greys.shade50,
    shade40: greys.shade70,
    shade50: greys.shade80,
  },
  [PaletteColorsEnum.PINK]: {
    shade10: '#fdf0f8',
    shade20: '#fbe1f2',
    shade30: '#f8cae8',
    shade40: '#ca3e99',
    shade50: '#9f3079',
  },
  [PaletteColorsEnum.RED]: {
    shade10: '#fff1f1',
    shade20: '#ffe1de',
    shade30: '#fac1bb',
    shade40: '#e9483a',
    shade50: '#b72215',
  },
  [PaletteColorsEnum.ORANGE]: {
    highlight: '#ffa927',
    shade10: '#fff7f0',
    shade20: '#ffe9d1',
    shade30: '#fadab9',
    shade40: '#f2830b',
    shade50: '#cc6000',
  },
  [PaletteColorsEnum.YELLOW]: {
    highlight: '#fff627',
    shade10: '#fefbef',
    shade20: '#fef6dc',
    shade30: '#faebbb',
    shade40: '#e3b51e',
    shade50: '#91691b',
  },
  [PaletteColorsEnum.GREEN]: {
    shade10: '#eefbf3',
    shade20: '#d9f6e3',
    shade30: '#b6f0ca',
    shade40: '#10aa40',
    shade50: '#0c8331',
  },
  [PaletteColorsEnum.TEAL]: {
    shade10: '#f0fdff',
    shade20: '#dcfbff',
    shade30: '#b9f2fa',
    shade40: '#15acc0',
    shade50: '#0d7482',
  },
  [PaletteColorsEnum.BLUE]: {
    shade10: '#f1f6fd',
    shade20: '#e0edff',
    shade30: '#bed7fa',
    shade40: '#367fee',
    shade50: '#2356b3',
    hover30: '#b4ccee',
  },
  [PaletteColorsEnum.PURPLE]: {
    shade10: '#f8f1ff',
    shade20: '#f0dfff',
    shade30: '#ddbbfa',
    shade40: '#9235e4',
    shade50: '#681aad',
  },
};

/** Main color palette. Dark theme. */
export const paletteDark: Palette = {
  [PaletteColorsEnum.GREY]: {
    shade10: greysDark.shade10,
    shade20: greysDark.shade30,
    shade30: greysDark.shade50,
    shade40: greysDark.shade70,
    shade50: greysDark.shade80,
  },
  [PaletteColorsEnum.PINK]: {
    shade10: '#2a0e1c',
    shade20: '#4c1a32',
    shade30: '#662343',
    shade40: '#bd407d',
    shade50: '#e1589a',
  },
  [PaletteColorsEnum.RED]: {
    shade10: '#1e0705',
    shade20: '#4c1310',
    shade30: '#661915',
    shade40: '#a72720',
    shade50: '#e33c33',
  },
  [PaletteColorsEnum.ORANGE]: {
    highlight: '#ffa927',
    shade10: '#231006',
    shade20: '#4c230c',
    shade30: '#662e11',
    shade40: '#c65012',
    shade50: '#dc6c32',
  },
  [PaletteColorsEnum.YELLOW]: {
    highlight: palette[PaletteColorsEnum.YELLOW].shade40,
    shade10: '#1f1800',
    shade20: '#4C3D00',
    shade30: '#665100',
    shade40: '#a37f14',
    shade50: '#c6970b',
  },
  [PaletteColorsEnum.GREEN]: {
    shade10: '#002415',
    shade20: '#004025',
    shade30: '#005532',
    shade40: '#207b45',
    shade50: '#29a471',
  },
  [PaletteColorsEnum.TEAL]: {
    shade10: '#002928',
    shade20: '#003e3c',
    shade30: '#005250',
    shade40: '#228683',
    shade50: '#23a09d',
  },
  [PaletteColorsEnum.BLUE]: {
    shade10: '#091e33',
    shade20: '#0e2d4c',
    shade30: '#133c66',
    shade40: '#2867c3',
    shade50: '#568cdc',

    hover30: '#254c72',
  },
  [PaletteColorsEnum.PURPLE]: {
    shade10: '#1f122b',
    shade20: '#38214c',
    shade30: '#4b2c66',
    shade40: '#8a4ad5',
    shade50: '#aa70dd',
  },
};

export enum NewPaletteColorsEnum {
  RED = 'red',
  ORANGE = 'orange',
  YELLOW = 'yellow',
  GREEN = 'green',
  BLUE = 'blue',
  GREY = 'grey',
}

export interface NewPaletteColor {
  readonly lowlight: string;
  readonly regular: string;
  readonly highlight: string;
  readonly tint: string;
}

type NewPalette = {readonly [T in NewPaletteColorsEnum]: NewPaletteColor};

export const newPalette: NewPalette = {
  [NewPaletteColorsEnum.RED]: {
    lowlight: '#CC0049',
    regular: '#FF0057',
    highlight: '#FFD4E6',
    tint: '#FFE8F0',
  },
  [NewPaletteColorsEnum.ORANGE]: {
    lowlight: '#DD3C00',
    regular: '#FF5800',
    highlight: '#FFE1CB',
    tint: '#FFF0E3',
  },
  [NewPaletteColorsEnum.YELLOW]: {
    lowlight: '#FFB700',
    regular: '#FFCF00',
    highlight: '#FFFADD',
    tint: '#FFFCEC',
  },
  [NewPaletteColorsEnum.GREEN]: {
    lowlight: '#008B4A',
    regular: '#00D47D',
    highlight: '#ACF8E2',
    tint: '#DCFFF3',
  },
  [NewPaletteColorsEnum.BLUE]: {
    lowlight: '#006EB2',
    regular: '#00BFFF',
    highlight: '#B0F1FC',
    tint: '#D9F8FF',
  },
  [NewPaletteColorsEnum.GREY]: {
    lowlight: '#001B38',
    regular: '#808080',
    highlight: '#F3F2F0',
    tint: '#FAFAFA',
  },
};

/*
 * Shadows.
 */

/** Standard shadows. */
export const shadows = toReadonly({
  dialogsAndDropdowns: css`
    ${(p) =>
      boxShadow(
        p.theme,
        `0 0 1px ${alphas.black50}, 0 3px 10px ${alphas.black30}`,
        // In dark mode, an inset box shadow is used to create the border effect.
        // We specifically do not use a border because some components (e.g. Dropdown)
        // rely on pixel height/width dimensions being passed in. Changing the box model
        // values by setting a border can cause unexpected behavior.
        // See https://front.atlassian.net/browse/PB-39772
        `box-shadow: inset 0 0 0 1px ${p.theme.greys.shade30}`,
      )};
  `,
});
