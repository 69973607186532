/*
 * Types.
 */

interface UncompiledMessageDescriptor {
  /** The unique identifier of the message. */
  id: string;
  /** The message in English. */
  message: string;
  // We don't support message descriptors with placeholders.
  values?: never;
}

export interface MessageDescriptor extends UncompiledMessageDescriptor {
  readonly CompiledMessageDescriptor: unique symbol;
}

/*
 * Helpers.
 */

/** Defines a translatable message outside of React. */
export function defineMessage(descriptor: UncompiledMessageDescriptor): MessageDescriptor {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return descriptor as MessageDescriptor;
}

export function translateMessage(descriptor: MessageDescriptor): string {
  return descriptor.message;
}
