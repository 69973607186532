import React, {FC, useCallback, useState} from 'react';
import {Helmet} from 'react-helmet';
import {useParams} from 'react-router-dom';
import styled from 'styled-components';

import {greys} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {AsyncRenderer} from '../common/asyncRenderer';
import {Footer} from '../common/footer';
import {renderFullScreenLoader} from '../common/fullScreenLoader';
import {CalendarContent} from './calendarContent';
import {getCalendarInvitePath, postCalendarInviteReponseAsync} from './calendarInviteHelpers';
import {CalendarEventData, CalendarInviteResponsesEnum} from './calendarType';

const StyledMainContainerDiv = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-top: 68px;
  padding-bottom: 32px;
  color: #001b38;
  background-color: #f3f2f0;
  font-family: 'Roobert', Helvetica, Arial, sans-serif;
`;

const StyledCalendarContainerDiv = styled.div`
  @media screen and (max-width: 650px) {
    width: 95%;
  }
  width: 600px;
  margin: auto;
  border-radius: 16px;
  background-color: ${greys.white};
`;

export const CalendarInvitePage: FC = () => {
  // Temporarily type casting these to support monorepo migration. The
  // application should be updated to validate these inputs and gracefully error.
  const {companySlug, token, response} = useParams();

  const [currentResponse, setResponse] = useState(response as CalendarInviteResponsesEnum);
  const updateResponse = async (updatedResponse: CalendarInviteResponsesEnum) => {
    await postCalendarInviteReponseAsync(path, currentResponse);
    setResponse(updatedResponse);
  };

  const path = getCalendarInvitePath(companySlug || '', token || '');
  // TODO: Parse and display the response when it's finalized on the backend.
  const asyncOperation = useCallback(
    () => postCalendarInviteReponseAsync(path, currentResponse),
    [path, currentResponse],
  );

  return (
    <>
      <Helmet>
        <title>Front | Calendar Invite</title>
      </Helmet>
      <StyledMainContainerDiv>
        <AsyncRenderer
          asyncOperation={asyncOperation}
          render={(calendarEventData: CalendarEventData) => (
            <>
              <StyledCalendarContainerDiv>
                <CalendarContent calendarEventData={calendarEventData} updateResponse={updateResponse} />
              </StyledCalendarContainerDiv>
              <Footer />
            </>
          )}
          renderLoading={renderFullScreenLoader}
          renderError={renderError}
        />
      </StyledMainContainerDiv>
    </>
  );
};

const renderError = (error: any) => {
  // @ts-expect-error Temporarily suppress error for front-client migration
  const content = <CalendarContent calendarEventData={{error}} />;
  return (
    <>
      <StyledCalendarContainerDiv>{content}</StyledCalendarContainerDiv>
      <Footer />
    </>
  );
};
