/*
 * The user's theme preference is controlled by two enums.
 *
 * The LegacyUserThemesEnum was the original enum. In preferencesModel.ts,
 * the type was defined using makeEnumRuntimeType. This meant that any additions
 * to the enum would break old versions of the application (mobile) because io-ts
 * parsing would fail.
 *
 * A new preference was created to be backwards compatible and allow new values
 * to be added as needed.
 */

export enum LegacyUserThemesEnum {
  LIGHT = 'light',
  DARK = 'dark',
}

export enum UserThemesEnum {
  LIGHT = 'light',
  DARK = 'dark',
  SYSTEM = 'system',
  DARK_SIDEBAR = 'darkSidebar',
}

export function convertThemeToLegacyTheme(userTheme: UserThemesEnum): LegacyUserThemesEnum {
  if (userTheme === UserThemesEnum.DARK) {
    return LegacyUserThemesEnum.DARK;
  }

  return LegacyUserThemesEnum.LIGHT;
}

export function convertLegacyThemeToTheme(legacyUserTheme: LegacyUserThemesEnum): UserThemesEnum {
  if (legacyUserTheme === LegacyUserThemesEnum.DARK) {
    return UserThemesEnum.DARK;
  }
  return UserThemesEnum.LIGHT;
}

export function resolveUserTheme(userTheme: UserThemesEnum, legacyUserTheme: LegacyUserThemesEnum) {
  // The current and legacy theme values are both saved when the user updates their preference. If the
  // legacyUserTheme is set to dark, but the userTheme is the default value (light) it means the user
  // has not updated their preference since the new enum was released. In this case, use dark mode.
  if (legacyUserTheme === LegacyUserThemesEnum.DARK && userTheme === UserThemesEnum.LIGHT) {
    return convertLegacyThemeToTheme(legacyUserTheme);
  }

  return userTheme;
}
