import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepProps} from '../../common/step/step';
import {BookingSummary} from '../../common/summary/bookingSummary';
import {CancelStepTypesEnum} from '../cancelStep';

/*
 * Style.
 */

const StyledContentDiv = styled.div`
  display: flex;
  flex-direction: row;
`;

/*
 * Component.
 */

export const CancelStepConfirmCancel: FC<StepProps> = (props) => {
  const {onNext, booking, metadata} = props;
  const renderSidebar = useCallback(() => <BookingSummary booking={booking} />, [booking]);
  const onNextClick = onNext ? () => onNext() : undefined;

  return (
    <StepFrame
      nextButton={{onClick: onNextClick, title: 'Cancel meeting'}}
      title="Cancel this meeting"
      metadata={metadata}
      renderSidebar={renderSidebar}
      renderSidebarInlineOnMobile
    >
      <StyledContentDiv>Remove this meeting from everyone&apos;s calendar?</StyledContentDiv>
    </StepFrame>
  );
};

/*
 * Step.
 */

export const cancelStepConfirmCancel: Step<CancelStepTypesEnum> = {
  type: CancelStepTypesEnum.CONFIRM_CANCEL,
  renderStep: (props) => React.createElement(CancelStepConfirmCancel, props),
};
