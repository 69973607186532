import {useMemo} from 'react';

import {useLocale} from '../components/common/locale/localeContext';
import {SchedulingLinkAvailabilityModel} from '../models/schedulingLinkAvailabilityModel';
import {buildDateTimeFromCalendarDate, CalendarDate} from './date';
import {filterAvailabilityBlocksOnDay} from './model/availability';

/*
 * Api.
 */

export function useSelectedBlocks(
  selectedDate: CalendarDate | undefined,
  availability: SchedulingLinkAvailabilityModel | undefined,
) {
  const {timezone} = useLocale();
  return useMemo(() => {
    if (!selectedDate || !availability) {
      return [];
    }

    return filterAvailabilityBlocksOnDay(availability, buildDateTimeFromCalendarDate(selectedDate, timezone));
  }, [availability, timezone, selectedDate]);
}
