import * as t from 'io-ts';

export class OptionalType<A, O = A> extends t.Type<A | undefined, O | undefined, unknown> {
  constructor(public type: t.Type<A, O, unknown>) {
    super(
      `Optional<${type.name}>`,
      (u: unknown): u is A | undefined => u === undefined || type.is(u),
      (u, c) => (u === undefined || u === null ? t.success(undefined) : type.validate(u, c)),
      (a) => (a === undefined || a === null ? undefined : type.encode(a)),
    );
  }
}

/** Makes any io-ts runtime type accept undefined, null or missing values. Null and missing values are set to undefined.
 *  This can be seen as a replacement of `t.union([t.undefined, t.something])` but it is safer as if we receive null, it will be converted to undefined.
 *
 *  Warning: Don't use this as a replacement of `t.union([t.undefined, t.null, t.something])` as undefined and null can have semantic differences.
 */
export function makeOptional<T extends t.Mixed>(
  type: T,
): t.Type<t.TypeOf<T> | undefined, t.OutputOf<T> | undefined, t.InputOf<T>> {
  return new OptionalType(type);
}
