import type {isCleanHtml} from '@frontapp/editor-html';
import * as E from 'fp-ts/lib/Either';
import {pipe} from 'fp-ts/lib/function';
import * as t from 'io-ts';
import _ from 'lodash';

/*
 * Types.
 */

/** Sanitized HTML string literal. */
// Re-declare a CleanHtml type to make it easier to auto-import.
export type CleanHtml = string & {
  // Make this type compatible with @frontapp/editor-html.
  readonly [isCleanHtml]: true;
};

// This covers string and TrustedHTML.
interface StringLike {
  toString(): string;
}

/*
 * Runtime types.
 */

/**
 * Runtime type for sanitized HTML string literal.
 *
 * Warning: use this only if the input value was sanitized upstream (e.g. by the backend).
 */
// TODO: Provide a runtime type that serializes/deserializes the clean HTML annotation as an object.
export const RISolemnlySwearThisIsCleanHtml = new t.Type<CleanHtml, string, unknown>(
  'CleanHtml',
  (value): value is CleanHtml => t.string.is(value),
  (value, context) =>
    pipe(
      t.string.validate(value, context),
      E.chain((stringValue) => t.success(iSolemnlySwearThisHtmlIsClean(stringValue))),
    ),
  (value) => value,
);

/*
 * Constants.
 */

// Don't re-export from @frontapp/editor-html to prevent pulling in the whole library.
export const emptyCleanHtml = iSolemnlySwearThisHtmlIsClean('');

/*
 * Helpers.
 */

/**
 * Mark the given string as clean HTML.
 *
 * WARNING: THIS DOES NOT SANITIZE THE HTML. Use this only if the input string
 * comes from a safe source and/or has already been sanitized and any HTML
 * manipulation was done safely.
 */
// Don't re-export from @frontapp/editor-html to prevent pulling in the whole library.
export function iSolemnlySwearThisHtmlIsClean(html: StringLike): CleanHtml {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return String(html) as CleanHtml;
}

export function escapeHtml(html: string): CleanHtml {
  return iSolemnlySwearThisHtmlIsClean(_.escape(html));
}

/**
 * Helper function to create object for React's dangerouslySetInnerHTML prop. Ensures
 * cleanHtml is being passed instead of a raw string.
 */
export function buildCleanReactInnerHtml(cleanHtml: CleanHtml) {
  return {
    __html: cleanHtml,
  };
}

/**
 * Helper function to set innerHTML on a DOM node. Ensures cleanHtml is being passed
 * instead of a raw string.
 */
export function insertCleanInnerHtml(element: HTMLElement, cleanHtml: CleanHtml) {
  // eslint-disable-next-line
  element.innerHTML = cleanHtml;
}
