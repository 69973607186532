import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {alphas, greys, greysDark, paletteDark} from '../../styles/colorStyles';

export const lightCellSemanticStyles = {
  conversationPreviewBackground: greys.white,
  summaryUnreadText: greys.shade80,
  selectedBackgroundBubble: alphas.white30,
  selectedPrecogDots: greys.white,
  unreadPreviewBackground: '#cce1ff', // One-off color
  overlayBackground: alphas.white60,
};

export type CellSemanticStyles = typeof lightCellSemanticStyles;

export const darkCellSemanticStyles: CellSemanticStyles = {
  conversationPreviewBackground: greysDark.shade30,
  summaryUnreadText: alphas.white50,
  selectedBackgroundBubble: alphas.white30,
  selectedPrecogDots: greysDark.shade100,
  unreadPreviewBackground: paletteDark[PaletteColorsEnum.BLUE].shade30,
  overlayBackground: `rgba(0, 0, 0, .9)`, // One-off color
};
