import {alphas, greys} from '../../styles/colorStyles';
import {findColorShade20, findColorShade40, findColorShade50} from '../themeColorHelpers';

export const lightAvatarSemanticStyles = {
  background: alphas.black30,
  backgroundHover: alphas.black50,

  findTeamColor: findColorShade40,
  findTeamBackground: findColorShade20,

  findTeammateColor: () => greys.white,
  findTeammateBackground: findColorShade40,
};

export type AvatarSemanticStyles = typeof lightAvatarSemanticStyles;

export const darkAvatarSemanticStyles: AvatarSemanticStyles = {
  background: alphas.white10,
  backgroundHover: alphas.white30,

  findTeamColor: findColorShade50,
  findTeamBackground: findColorShade20,

  findTeammateColor: () => greys.white,
  findTeammateBackground: findColorShade40,
};
