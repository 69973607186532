import React, {FC, memo, PropsWithChildren, useMemo, useState} from 'react';
import styled from 'styled-components';

import {buildCleanReactInnerHtml} from '../../../../core/src/helpers/typescript/cleanHtml';
import {SvgContentLoader, SvgManagerContext, SvgManagerContextValue} from './svgManagerContext';

/*
 * Style.
 */

const StyledSpriteSvg = styled.svg`
  display: none;
`;

/*
 * Component.
 */

export const SvgManager: FC<PropsWithChildren<{}>> = memo(({children}) => {
  const [items, setItems] = useState<ReadonlyArray<SvgContentProps>>([]);
  const contextValue = useMemo<SvgManagerContextValue>(
    () => ({
      canAddSvgContent: true,
      addSvgContent: (id, loadContent) => {
        setItems((prevItems) => {
          if (prevItems.find((entry) => entry.id === id)) {
            return prevItems;
          }

          return [...prevItems, {id, loadContent}];
        });
      },
    }),
    [],
  );
  const svgContent = useMemo(() => renderSvgContent(items), [items]);

  return (
    <>
      <StyledSpriteSvg>{svgContent}</StyledSpriteSvg>
      <SvgManagerContext.Provider value={contextValue}>{children}</SvgManagerContext.Provider>
    </>
  );
});

function renderSvgContent(items: ReadonlyArray<SvgContentProps>) {
  return items.map((itemProps) => <SvgContent key={itemProps.id} {...itemProps} />);
}

/*
 * Helper component.
 */

interface SvgContentProps {
  id: string;
  loadContent: SvgContentLoader;
}

const SvgContent = memo<SvgContentProps>(
  ({id, loadContent}) => <g id={id} dangerouslySetInnerHTML={buildCleanReactInnerHtml(loadContent())} />,
  // Never re-render.
  () => true,
);
