/*
 * Types.
 */

export enum EnvironmentsEnum {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

declare const FRONT_ENVIRONMENT: EnvironmentsEnum;
declare const FRONT_BUILD_TIMESTAMP: number;

/*
 * Api.
 */

export function getEnvironment() {
  return FRONT_ENVIRONMENT;
}

export function getBuildTimestamp() {
  return FRONT_BUILD_TIMESTAMP;
}
