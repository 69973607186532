import React, {FC, useCallback, useMemo, useState} from 'react';
import styled from 'styled-components';

import {useSelectedBlocks} from '../../../helpers/availabilityHooks';
import {fetchAvailableSlotAsync} from '../../../helpers/model/availability';
import {createAvailabilityBlockFromBooking} from '../../../helpers/model/booking';
import {SchedulingLinkAvailabilityBlockModel} from '../../../models/schedulingLinkAvailabilityModel';
import {useLocale} from '../../common/locale/localeContext';
import {LocaleSettings} from '../../common/locale/localeSettings';
import {StepLostAvailabilityError} from '../../common/step/error/stepError';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepData, StepProps} from '../../common/step/step';
import {makeLocaleUpdater} from '../../common/step/stepLocaleUpdater';
import {TimePicker} from '../../timePicker/timePicker';
import {buildBookTitle} from '../bookHelpers';
import {BookStepTypesEnum} from '../bookStep';

/*
 * Style.
 */

const StyledContentDiv = styled.div`
  display: flex;
  flex-direction: row;

  padding: 8px 0px;
`;

/*
 * Component.
 */

export const BookStepChooseTime: FC<StepProps> = (props) => {
  const {onNext, onPrevious, metadata, selectedDate, availability, onUpdateStepData, schedulingLink} = props;
  const locale = useLocale();
  const [selectedBlock, setSelectedBlock] = useState<SchedulingLinkAvailabilityBlockModel>();

  const onSelectBlock = useCallback(
    (block: SchedulingLinkAvailabilityBlockModel) => {
      // If there isn't another step, do nothing.
      if (!onNext) {
        return;
      }

      // Update the state.
      setSelectedBlock(block);

      // Go to the next step.
      onNext((data) => ({
        ...data,
        booking: {
          ...data.booking,
          start: block.start,
          end: block.end,
        },
      }));
    },
    [onNext, setSelectedBlock],
  );

  const onBackClick = useMemo(() => {
    // If there isn't a previous step, do nothing.
    if (!onPrevious) {
      return undefined;
    }

    // Go to the next step.
    return () =>
      onPrevious((data) => ({
        ...data,
        selectedDate: undefined,
      }));
  }, [onPrevious]);

  const selectedBlocks = useSelectedBlocks(selectedDate, availability);

  const renderFooter = useCallback(
    () => (
      <LocaleSettings
        locale={locale}
        onChangeLocale={(newLocale) => onUpdateStepData(makeLocaleUpdater(newLocale))}
      />
    ),
    [locale, onUpdateStepData],
  );

  return (
    <StepFrame
      onPrevious={onBackClick}
      title={buildBookTitle(schedulingLink)}
      metadata={metadata}
      renderFooter={renderFooter}
    >
      <StyledContentDiv>
        <TimePicker
          availabilityBlocks={selectedBlocks}
          selectedTimeSlot={selectedBlock}
          setSelectedTimeSlot={onSelectBlock}
        />
      </StyledContentDiv>
    </StepFrame>
  );
};

async function postStepAction(data: StepData) {
  // Verify the selected slot is still available before moving onto the next step.
  const {schedulingLink, booking} = data;
  const selectedBlock = createAvailabilityBlockFromBooking(booking);
  if (!selectedBlock) {
    // A block must be selected upon completion of this step.
    throw new Error('Missing Selected Block');
  }

  const availableSlot = await fetchAvailableSlotAsync(schedulingLink, selectedBlock.start, selectedBlock.end);
  if (!availableSlot) {
    // The selected slot is no longer available.
    throw new StepLostAvailabilityError();
  }

  // Don't change the step data.
  return data;
}

/*
 * Step.
 */

export const bookStepChooseTime: Step<BookStepTypesEnum> = {
  type: BookStepTypesEnum.CHOOSE_TIME,
  renderStep: (props) => React.createElement(BookStepChooseTime, props),
  postStepAction,
};
