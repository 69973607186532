import Bugsnag from '@bugsnag/js';

import {BugsnagSeveritiesEnum} from '../../../libs/shared/core/src/helpers/bugsnag/bugsnagSeverities';
import {EnvironmentsEnum, getBuildTimestamp, getEnvironment} from './env';

/*
 * Constants.
 */

// Override to enable in dev.
const isBugsnagEnabled = getEnvironment() !== EnvironmentsEnum.DEVELOPMENT;

/*
 * Api.
 */

export function setupBugsnag() {
  if (!isBugsnagEnabled) {
    return;
  }

  Bugsnag.start({
    apiKey: 'ed2dde39ef27f43adebd4c9e3f309172',
    appVersion: `${getBuildTimestamp()}`,
    releaseStage: getEnvironment(),
  });
}

export function reportToBugsnag(error: Error, severity?: BugsnagSeveritiesEnum) {
  // eslint-disable-next-line no-console
  console.error(error);

  if (!isBugsnagEnabled) {
    return;
  }

  Bugsnag.notify(error, (event) => {
    if (severity) {
      // eslint-disable-next-line no-param-reassign
      event.severity = severity;
    }
  });
}
