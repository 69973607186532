import {makeEnumRuntimeType} from '../../../libs/shared/core/src/models/api/types/enumTypes';

/*
 * Runtime type.
 */

export enum ConferenceTypesEnum {
  ZOOM = 'zoom',
  EVENT_HANGOUT = 'eventHangout',
  EVENT_NAMED_HANGOUT = 'eventNamedHangout',
  HANGOUTS_MEET = 'hangoutsMeet',
  SKYPE_FOR_BUSINESS = 'skypeForBusiness',
  SKYPE_FOR_CONSUMER = 'skypeForConsumer',
  TEAMS_FOR_BUSINESS = 'teamsForBusiness',
  GOOGLE_ADD_ON = 'googleAddOn',
}

export const RConferenceType = makeEnumRuntimeType(ConferenceTypesEnum);
