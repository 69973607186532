import {kebabCase} from 'change-case';
import {isFunction, pick} from 'lodash';
import {Children, isValidElement, PropsWithChildren, ReactElement, ReactNode} from 'react';

import {isElement, isHtmlElement} from '../browser/domHelpers';
import {isDevelopment} from '../environment/envHelpers';
import {Plural, SelectOrdinal, Trans} from '../internationalization/react';
import {FrontEvent} from '../react/reactHelpers';

/*
 * Types.
 */

/**
 * Contextual information for UI tracking.
 * @deprecated Use DataEventTrackingContext instead.
 */
export interface LegacyInteractionContextValue {
  /** A stack of dash-case ancestor identifiers. */
  parentInteractionIds: ReadonlyArray<string>;
}

/** Component information for UI tracking. */
export interface InteractionComponentProps {
  /** The component dash-case identifier. Used for UI tracking, the data-testid attribute, etc. */
  interactionId?: string;

  /** Data to be passed to interaction events. Used for UI tracking */
  interactionData?: Record<string, unknown>;
}

/** Variant of InteractionComponentProps that requires an identifier. */
export interface RequiredInteractionComponentProps extends InteractionComponentProps {
  interactionId: string;
}

/*
 * Constants.
 */

const transBasedInteractionIdPrefix = '$';
const elementIdBasedInteractionIdPrefix = '#';
const elementTextBasedInteractionIdPrefix = '@';

const elementTextBasedInteractionIdMaxLength = 64;

/*
 * Helpers.
 */

export function pushParentInteractionId(
  context: LegacyInteractionContextValue,
  interactionId: string,
): LegacyInteractionContextValue {
  return {
    ...context,
    parentInteractionIds: [...context.parentInteractionIds, interactionId],
  };
}

/** Helper to forward UI instrumentation props to a child component.  */
export function forwardInteractionComponentProps(
  props: InteractionComponentProps,
  defaultInteractionId?: string,
): InteractionComponentProps {
  const forwardProps = pick(props, ['interactionId', 'integrationData']);

  if (!forwardProps.interactionId && defaultInteractionId) {
    forwardProps.interactionId = defaultInteractionId;
  }

  return forwardProps;
}

export function buildInteractionIdFromPath(path: string): string {
  return `${kebabCase(path.toLowerCase())}`;
}

/** Maps UI instrumentation props to DOM element props. */
export function buildInteractionIdProps(interactionId?: string, defaultInteractionId?: string) {
  if (!(interactionId || defaultInteractionId)) {
    return {};
  }

  return {
    'data-testid': interactionId || defaultInteractionId,
  };
}

export function forwardInteractionIdProps(props: {'data-testid'?: string}) {
  return buildInteractionIdProps(props['data-testid']);
}

/** Finds the best identifier in the given component context, props, and DOM event. */
export function buildComponentInteractionId(
  props: PropsWithChildren<InteractionComponentProps>,
  event: Pick<FrontEvent, 'target'>,
): string | undefined {
  return (
    props.interactionId ||
    findTransBasedInteractionId(props.children) ||
    findElementBasedInteractionId(event.target)
  );
}

function findTransBasedInteractionId(children: ReactNode): string | undefined {
  try {
    const transId = findTransIdUnsafe(children);
    if (transId) {
      return `${transBasedInteractionIdPrefix}${transId}`;
    }
  } catch (e) {
    if (isDevelopment()) {
      // eslint-disable-next-line no-console
      console.warn('Error while finding translation ID', e);
    }
  }
  return undefined;
}

/** Tries to find the identifier of a transformed <Trans> element. Might throw. */
function findTransIdUnsafe(children: ReactNode): string | undefined {
  let transId: string | undefined;

  Children.forEach(children, (child) => {
    if (transId || !isValidElement(child)) {
      return;
    }

    if (isTransElement(child)) {
      transId = transId || child.props.id;
    }

    if (isParentElementUnsafe(child)) {
      transId = transId || findTransIdUnsafe(child.props.children);
    }
  });

  return transId;
}

function isTransElement(element: ReactElement<unknown>): element is ReactElement<{id?: string}> {
  return element.type === Trans || element.type === Plural || element.type === SelectOrdinal;
}

function isParentElementUnsafe<P>(element: ReactElement<P>): element is ReactElement<PropsWithChildren<P>> {
  const {props} = element;
  return props && typeof props === 'object' && 'children' in props && isReactNodeUnsafe(props.children);
}

function isReactNodeUnsafe(maybeReactNode: unknown): maybeReactNode is ReactNode {
  return !isFunction(maybeReactNode);
}

function findElementBasedInteractionId(target: EventTarget | null): string | undefined {
  if (!isElement(target)) {
    return undefined;
  }

  if (target.id) {
    return `${elementIdBasedInteractionIdPrefix}${target.id}`;
  }

  if (!isHtmlElement(target)) {
    return undefined;
  }

  const text = target.innerText;
  if (text && text.length <= elementTextBasedInteractionIdMaxLength) {
    return `${elementTextBasedInteractionIdPrefix}${kebabCase(text)}`;
  }

  return undefined;
}
