import {isUndefined, uniqueId} from 'lodash';
import {Key} from 'ts-key-enum';

import {annotateTaskType, TaskTypesEnum} from '../instrumentation/eventLoopPerformanceHelpers';
import {ClickEventHandler, FrontKeyboardEvent, KeyboardEventHandler} from '../react/reactHelpers';

export interface AccessibleButtonBaseProps {
  tabIndex?: number;
  onClick?: ClickEventHandler;
  isActive?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
}

/** Builds props to make any element an accessible button. Try to use ChromelessButton instead! */
export function buildAccessibleButtonProps(props: AccessibleButtonBaseProps) {
  const {onClick} = props;

  function onKeyPress(event: FrontKeyboardEvent) {
    if (event.key !== Key.Enter) {
      return;
    }

    if (onClick) {
      onClick(event);
    }
  }

  return {
    tabIndex: props.tabIndex ?? 0,
    role: 'button',
    'aria-pressed': props.isActive,
    'aria-disabled': props.isDisabled,
    'aria-haspopup': !isUndefined(props.isOpen) || undefined,
    'aria-expanded': props.isOpen,
    onClick: buildTracedClickEventHandler(onClick),
    onKeyPress,
  };
}

export function generateUniqueElementId() {
  return uniqueId('fa-el-');
}

export function onEnterOrSpace(callback: KeyboardEventHandler) {
  return (e: FrontKeyboardEvent) => {
    if (e.key === Key.Enter || e.key === ' ') {
      callback(e);
    }
  };
}

function buildTracedClickEventHandler(handler?: ClickEventHandler) {
  if (!handler) {
    return undefined;
  }

  return (...args: Parameters<ClickEventHandler>) => {
    annotateTaskType(TaskTypesEnum.CLICK_EVENT);
    return handler(...args);
  };
}
