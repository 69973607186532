import React, {FC, PropsWithChildren, ReactNode} from 'react';

import {InteractiveListProps} from '../../interactiveList/interactiveList';
import {Dropdown, DropdownProps} from '../dropdown';
import {DropdownFooter} from '../dropdownFooter';
import {DropdownList} from '../dropdownList';

/*
 * Props.
 */

export interface SimpleDropdownProps extends DropdownProps, InteractiveListProps {
  footer?: ReactNode;
}

/*
 * Component.
 */

export const SimpleDropdown: FC<PropsWithChildren<SimpleDropdownProps>> = ({children, footer, ...props}) => (
  <Dropdown {...props}>
    <DropdownList {...props}>{children}</DropdownList>
    {footer && <DropdownFooter>{footer}</DropdownFooter>}
  </Dropdown>
);
