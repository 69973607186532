import {createMandatoryContext} from '../../../../core/src/helpers/react/reactHelpers';

/*
 * Props.
 */

export type TooltipCondition = () => boolean;
export type ConditionUnregistrator = () => void;
export type ConditionRegistrator = (condition: TooltipCondition) => ConditionUnregistrator;

export interface TooltipContextProps {
  /** Register a new condition to display tooltips. */
  registerCondition: ConditionRegistrator;
}

/*
 * Context.
 */

export const [TooltipContext, useTooltipContext] = createMandatoryContext<TooltipContextProps>();
