import React, {FC} from 'react';
import {Key} from 'ts-key-enum';

import {FrontEvent, RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {InteractiveListContext, InteractiveListContextProps} from './interactiveListContext';

/*
 * Props.
 */

export interface InteractiveListItemPublicProps {
  onSelect?: (event: FrontEvent, key?: Key) => void;
  index?: number;
}

export interface InteractiveListItemChildrenProps {
  itemProps: any;
  isHighlighted: boolean;
}

interface InteractiveListItemProps extends InteractiveListItemPublicProps {
  children: RendererOf<InteractiveListItemChildrenProps>;
}

/*
 * Component.
 */

export const InteractiveListItem: FC<InteractiveListItemProps> = (props) => (
  <InteractiveListContext.Consumer>
    {(context) => renderContent(context, props)}
  </InteractiveListContext.Consumer>
);

function renderContent(context: InteractiveListContextProps, props: InteractiveListItemProps) {
  // Register as a list item.
  const itemProps = context.registerListItem(props.onSelect, props.index);
  const isHighlighted = itemProps.index === context.highlightedIndex;

  // Render the children.
  return props.children({itemProps: itemProps.props, isHighlighted});
}
