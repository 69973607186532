import {isMobile} from '../environment/mobileEnvHelpers';
import {annotateTaskType, TaskMetadata, TaskTypesEnum} from './eventLoopPerformanceHelpers';

/*
 * Types.
 */

export interface IdleCallbackProvider {
  requestIdleCallback: Window['requestIdleCallback'];
  cancelIdleCallback: Window['cancelIdleCallback'];
}

export interface InstrumentedIdleCallbackProvider {
  requestInstrumentedIdleCallback: (
    callback: IdleRequestCallback,
    metadata: TaskMetadata,
    options?: IdleRequestOptions,
  ) => ReturnType<IdleCallbackProvider['requestIdleCallback']>;
  cancelIdleCallback: IdleCallbackProvider['cancelIdleCallback'];
}

/*
 * Api.
 */

export function buildInstrumentedIdleCallbackProvider(window: Window): InstrumentedIdleCallbackProvider {
  if (!isIdleCallbackProvider(window) || isMobile()) {
    return buildFallbackIdleCallbackProvider(window);
  }

  const requestInstrumentedIdleCallback = (
    callback: IdleRequestCallback,
    metadata: TaskMetadata,
    options?: IdleRequestOptions,
  ) =>
    window.requestIdleCallback((time) => {
      annotateTaskType(TaskTypesEnum.IDLE_CALLBACK, metadata);
      return callback(time);
    }, options);
  const {cancelIdleCallback} = window;

  return {
    requestInstrumentedIdleCallback,
    cancelIdleCallback,
  };
}

export function isInstrumentedIdleCallbackProvider<T extends object>(
  window: T,
): window is T & InstrumentedIdleCallbackProvider {
  return 'requestInstrumentedIdleCallback' in window && 'cancelIdleCallback' in window;
}

/*
 * Shim.
 */

const fallbackIdleTimeout = 1; // ms
const fallbackIdleIdealLimit = 50; // ms
const fallbackIdleMinLimit = 16; // ms

function buildFallbackIdleCallbackProvider(window: Window): InstrumentedIdleCallbackProvider {
  return {
    requestInstrumentedIdleCallback: (callback, metadata) => {
      // Ideally, we finish our work within the time it takes for the timer to fire + the 50 ms idle callback deadline.
      const idealDeadline = performance.now() + fallbackIdleTimeout + fallbackIdleIdealLimit;

      return window.setTimeout(() => {
        annotateTaskType(TaskTypesEnum.IDLE_CALLBACK, metadata);

        // At a minimum, give us 16 ms.
        const minDeadline = performance.now() + fallbackIdleMinLimit;
        const deadline = Math.max(minDeadline, idealDeadline);

        callback({
          didTimeout: false,
          timeRemaining: () => Math.max(0, deadline - performance.now()),
        });
      }, fallbackIdleTimeout);
    },
    cancelIdleCallback: (handle) => {
      window.clearTimeout(handle);
    },
  };
}

/*
 * Helpers.
 */

function isIdleCallbackProvider<T extends object>(window: T): window is T & IdleCallbackProvider {
  return 'requestIdleCallback' in window && 'cancelIdleCallback' in window;
}
