import React, {FC} from 'react';
import styled, {css} from 'styled-components';

import {Icon} from '../../../../libs/shared/ui/src/components/icon/icon';
import {alphas, greys, palette} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes, fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {renderAvailabilityBlockTimeWithLocale} from '../../helpers/model/availability';
import {SchedulingLinkAvailabilityBlockModel} from '../../models/schedulingLinkAvailabilityModel';
import {useLocale} from '../common/locale/localeContext';

/*
 * Props.
 */

export interface TimePickerListItemProps {
  availabilityBlock: SchedulingLinkAvailabilityBlockModel;
  isSelected: boolean;
  onClick?: () => void;
}

interface StyledTimeDivProps {
  isSelected: boolean;
}

/*
 * Style.
 */

const StyledTimeDiv = styled.div<StyledTimeDivProps>`
  border-radius: 8px;
  height: 40px;

  padding: 12px 10px 12px 14px;
  margin: 3px auto;

  display: grid;
  grid-template-columns: 1fr auto;

  ${(props) => timeStyle(props)};
`;

function timeStyle(props: StyledTimeDivProps) {
  if (props.isSelected) {
    return css`
      border: 1px solid ${palette.blue.shade50};
      color: ${greys.white};
      background-color: ${palette.blue.shade50};
    `;
  }

  return css`
    border: 1px solid ${greys.shade30};
    color: ${palette.blue.shade40};

    &:hover {
      background-color: ${alphas.gray10};
    }
  `;
}

const StyledTextDiv = styled.div`
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.semibold};
`;

/*
 * Component.
 */

export const TimePickerListItem: FC<TimePickerListItemProps> = (props) => {
  const locale = useLocale();

  return (
    <StyledTimeDiv isSelected={props.isSelected} onClick={props.onClick}>
      <StyledTextDiv>{renderAvailabilityBlockTimeWithLocale(props.availabilityBlock, locale)}</StyledTextDiv>
      {props.isSelected && <Icon name="checkmarkSelectedSmall" />}
    </StyledTimeDiv>
  );
};
