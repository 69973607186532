import {makeEnumTypeGuard} from '../../models/api/types/enumTypes';

export enum FrontEnvironmentsEnum {
  PRODUCTION = 'production',
  STAGING = 'staging',
  PREVIEW = 'preview',
  DEVELOPMENT = 'development',
}

export const isFrontEnvironment = makeEnumTypeGuard(FrontEnvironmentsEnum);
