import React from 'react';

export const CheckmarkCircle = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM11.5202 6.54024C11.8186 6.25293 11.8276 5.77814 11.5402 5.47977C11.2529 5.1814 10.7781 5.17244 10.4798 5.45976L6.8777 8.92843L5.55111 7.49129C5.27015 7.18692 4.79566 7.16795 4.49129 7.4489C4.18693 7.72985 4.16795 8.20435 4.4489 8.50871L6.29505 10.5087C6.43184 10.6569 6.62252 10.7437 6.82409 10.7497C7.02566 10.7556 7.22113 10.6801 7.36639 10.5402L11.5202 6.54024Z"
    />
  </svg>
);
