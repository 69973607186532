import React, {FC} from 'react';
import styled from 'styled-components';

import {Trans} from '../../../../core/src/helpers/internationalization/react';
import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {Icon} from '../icon/icon';
import {PopoverCoordinator, PopoverTransferProps} from '../popovers/popoverCoordinator';
import {VisuallyHidden} from '../visuallyHidden/visuallyHidden';
import {ButtonChevronContent} from './buttonChevronContent';
import {ButtonInheritableProps} from './buttonChrome';

/*
 * Props.
 */

export interface ButtonChevronProps extends ButtonInheritableProps {
  /** Render a dropdown when the chevron is clicked. */
  renderDropdown?: RendererOf<PopoverTransferProps>;
  /** Called when the popover is toggled. */
  onDropdownToggle?: (arg: boolean) => void;
  className?: string;
}

/*
 * Style.
 */

const StyledMainCoordinator = styled(PopoverCoordinator)`
  display: flex;
  align-items: stretch;
`;

/*
 * Component.
 */

export const ButtonChevron: FC<ButtonChevronProps> = (props) => {
  if (props.isDisabled) {
    return renderButton(props);
  }

  return (
    <StyledMainCoordinator
      className={props.className}
      shouldHoverWhenOpen
      renderButton={() => renderButton(props)}
      renderModal={(modalProps) => props.renderDropdown && props.renderDropdown(modalProps)}
      onPopoverToggle={props.onDropdownToggle}
    />
  );
};

/*
 * Button.
 */

function renderButton(buttonProps: ButtonInheritableProps) {
  return (
    <ButtonChevronContent {...buttonProps}>
      <Icon name="chevronDownSmall" />
      <VisuallyHidden>
        <Trans id="buttonchevron">Toggle menu</Trans>
      </VisuallyHidden>
    </ButtonChevronContent>
  );
}
