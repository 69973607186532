import React, {
  Component,
  createRef,
  FC,
  PropsWithChildren,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
} from 'react';
import styled, {css} from 'styled-components';

import {isHtmlElement} from '../../../../core/src/helpers/browser/domHelpers';
import {ellipsis} from '../../styles/mixins';
import {
  ConditionUnregistrator,
  TooltipContext,
  TooltipContextProps,
  useTooltipContext,
} from './tooltipContext';

/*
 * Props.
 */

interface TooltipOverflowConditionProps {
  overflowRef?: RefObject<HTMLDivElement>;
  className?: string;
  /** This will cause the overflow ellipsis to be on the left. */
  isRightToLeft?: boolean;
  /** For overriding the overflow condition to render the tooltip, will follow normal flow if falsy */
  overrideCondition?: boolean;
  /** Optionally look at the children to see if they shrink. Might be costly, be sure to know what you're doing. */
  checkChildrenOverflow?: boolean;
  children: ReactNode;
}

/*
 * Style.
 */

interface TooltipOverflowConditionStyleProps {
  $isRightToLeft: boolean;
}

const StyledOverflowDiv = styled.div<TooltipOverflowConditionStyleProps>`
  ${ellipsis()};
  ${(p) => addDirectionStyles(p)}
`;

function addDirectionStyles(p: TooltipOverflowConditionStyleProps) {
  if (p.$isRightToLeft) {
    return css`
      direction: rtl;
    `;
  }

  return '';
}

/*
 * Component.
 */

class TooltipOverflowConditionComponent extends Component<
  TooltipContextProps & TooltipOverflowConditionProps
> {
  constructor(props: TooltipContextProps & TooltipOverflowConditionProps) {
    super(props);
    this.overflowRef = props.overflowRef || createRef<HTMLDivElement>();
  }

  private readonly overflowRef: RefObject<HTMLDivElement>;
  private unregistrator?: ConditionUnregistrator;

  /*
   * Lifecycle.
   */

  componentDidMount() {
    this.unregistrator = this.props.registerCondition(this.isOverflowing);
  }

  componentWillUnmount() {
    if (!this.unregistrator) {
      return;
    }

    this.unregistrator();
  }

  private readonly isOverflowing = () => {
    const currentOverflow = this.overflowRef.current;
    if (this.props.overrideCondition) {
      return this.props.overrideCondition;
    }
    if (!currentOverflow) {
      return false;
    }

    const baseCheck = currentOverflow.scrollWidth > currentOverflow.offsetWidth;

    if (this.props.checkChildrenOverflow) {
      return (
        baseCheck ||
        Array.from(currentOverflow.children).some(
          (child) => isHtmlElement(child) && child.scrollWidth > child.offsetWidth,
        )
      );
    }

    return baseCheck;
  };

  /*
   * Render.
   */

  render() {
    return (
      <StyledOverflowDiv
        ref={this.overflowRef}
        className={this.props.className}
        $isRightToLeft={Boolean(this.props.isRightToLeft)}
      >
        {this.props.children}
      </StyledOverflowDiv>
    );
  }
}

export const TooltipOverflowCondition: FC<PropsWithChildren<TooltipOverflowConditionProps>> = (props) => (
  <TooltipContext.Consumer>
    {(context) => <TooltipOverflowConditionComponent {...props} {...context} />}
  </TooltipContext.Consumer>
);

export function useTooltipOverflowCondition(ref: RefObject<HTMLElement>, overrideCondition = false) {
  const {registerCondition} = useTooltipContext();

  const condition = useCallback(() => {
    if (overrideCondition) {
      return overrideCondition;
    }

    if (!ref.current) {
      return false;
    }

    return ref.current.scrollWidth > ref.current.offsetWidth;
  }, [overrideCondition, ref]);

  useEffect(() => registerCondition(condition));
}
