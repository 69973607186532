import React, {FC} from 'react';

import {useKeyboardContext} from '../keyboard/keyboardContext';
import {
  TextKeyboardShortcutsFormatter,
  TextKeyboardShortcutsFormatterProps,
} from '../keyboard/textKeyboardShortcutFormatter';

/*
 * Component.
 */

export const TooltipShortcutFormatter: FC<TextKeyboardShortcutsFormatterProps> = (props) => {
  const keyboardContext = useKeyboardContext();
  const shortcuts = keyboardContext.mapping[props.action];
  if (shortcuts.length === 0) {
    return null;
  }

  return (
    <>
      [<TextKeyboardShortcutsFormatter {...props} />]
    </>
  );
};
