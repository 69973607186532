import {css} from 'styled-components';

import {greys} from '../../../libs/shared/ui/src/styles/colorStyles';

/*
 * Api.
 */

export function roundedBorder() {
  return css`
    border: 1px solid ${greys.shade30};
    border-radius: 16px;
  `;
}
