import React, {FC} from 'react';
import styled from 'styled-components';

import confirmation from '../../../assets/images/inboxMeetingConfirmation.png';
import {roundedBorder} from '../../../helpers/style';
import {DeviceTypesEnum, usePlatform} from '../../platform/platformContext';
import {BookingSummary, BookingSummaryProps} from './bookingSummary';

/*
 * Props.
 */

export interface ConfirmedBookingSummaryProps extends BookingSummaryProps {}

/*
 * Style.
 */

interface StyledContentDivProps {
  shouldShowImage: boolean;
}

const StyledContentDiv = styled.div<StyledContentDivProps>`
  display: grid;
  grid-column-gap: 16px;
  /* Add two columns if we're showing an image. */
  grid-template-columns: ${(props) => (props.shouldShowImage ? '1fr 1fr' : '1fr')};

  margin-top: 20px;
  ${roundedBorder()};
`;

const StyledSummaryDiv = styled.div`
  margin: 20px 0px 20px 20px;
`;

const StyledImage = styled.img`
  margin: 25px 20px 60px 50px;
  height: 190px;
  width: 220px;
`;

/*
 * Component.
 */

export const ConfirmedBookingSummary: FC<ConfirmedBookingSummaryProps> = (props) => {
  const {device} = usePlatform();
  const shouldShowImage = device === DeviceTypesEnum.DESKTOP;

  return (
    <StyledContentDiv shouldShowImage={shouldShowImage}>
      <StyledSummaryDiv>
        <BookingSummary
          booking={props.booking}
          showChangedHeading={props.showChangedHeading}
          onModifyTimeSlot={props.onModifyTimeSlot}
        />
      </StyledSummaryDiv>
      {shouldShowImage && <StyledImage src={confirmation} alt="Confimed" />}
    </StyledContentDiv>
  );
};
