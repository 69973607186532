import {ButtonVariantsEnum} from '../../components/buttons/buttonConstants';
import {alphas, greys, palette} from '../../styles/colorStyles';
import {lightBetaFeaturePillSemanticStyles} from '../semantic/betaFeaturePillSemanticStyles';
import {lightNewFeaturePillSemanticStyles} from '../semantic/newFeaturePillSemanticStyles';
import {LegacySemanticStyles} from '../themeTypes';

export const lightLegacySemanticStyles: LegacySemanticStyles = {
  background: greys.white,
  separator: greys.shade30,
  itemSeparator: greys.shade30,
  header: greys.shade70,
  headerHover: greys.shade80,
  headerMore: greys.shade70,
  headerMoreHover: greys.shade90,

  item: greys.shade80,
  itemBold: greys.shade90,
  itemSelected: greys.shade90,
  icon: greys.shade70,
  iconHover: greys.shade80,
  itemBackgroundHover: alphas.gray10,
  itemBackgroundSelected: alphas.gray20,
  itemPrivateLock: alphas.black50,
  itemAdd: palette.blue.shade40,
  itemAddHover: palette.blue.shade50,
  itemAddDismiss: greys.shade60,
  itemAddDismissHover: greys.shade80,
  itemDot: greys.shade40,
  itemDotHover: greys.shade50,
  itemCheckBox: greys.shade60,

  itemIcon: greys.shade50,
  itemIconHover: greys.shade60,
  itemIconSelected: greys.white,
  itemIconBackground: greys.white,
  itemIconBackgroundHover: alphas.gray10,

  itemMoreIcon: greys.shade70,
  itemMoreIconHover: greys.shade80,
  itemMoreIconSelected: greys.white,
  itemMoreIconBackground: greys.white,
  itemMoreIconBackgroundHover: alphas.gray20,

  scrollbarTrack: alphas.black20,
  scrollbarThumb: alphas.black30,
  scrollbarThumbHover: alphas.black40,

  bannerText: greys.white,
  bannerBackground: greys.shade70,
  bannerSecondaryText: greys.shade30,
  bannerActionHover: alphas.white20,
  bannerIcon: greys.shade10,
  bannerTimeRemaining: alphas.white30,

  betaFeaturePill: lightBetaFeaturePillSemanticStyles,
  newFeaturePill: lightNewFeaturePillSemanticStyles,

  trialBackground: greys.shade30,
  trialPrompt: greys.shade80,
  trialNumber: greys.shade80,
  trialChat: palette.blue.shade40,
  trialChatHover: palette.blue.shade50,

  datePickerPrimary: greys.shade80,
  datePickerSecondary: greys.shade70,
  datePickerDisabled: greys.shade50,
  datePickerBackgroundHover: alphas.gray10,

  disabledCheckboxBoxColor: greys.shade40,
  disabledCheckboxCheckColor: greys.shade60,

  p1BannerBackground: palette.pink.shade10,
  p1BannerTitle: greys.shade90,
  p1BannerSubtitle: greys.shade80,
  p1BannerIcon: palette.pink.shade40,
  p1RarelyViewedBadgeText: greys.white,
  p1RarelyViewedBadgeBackground: palette.pink.shade40,
  p1HelpTipSubtitle: greys.shade80,
  p1HelpTipClose: greys.shade70,
  p1HelpTipCloseHover: greys.shade80,
  p1HelpTipCloseBackgroundHover: alphas.gray10,
  p1BannerButtonVariant: ButtonVariantsEnum.PRIMARY,
  p1BannerLinkVariant: ButtonVariantsEnum.LINK,

  settingsMenuSwitcherBackground: alphas.gray10,
  settingsMenuSwitcherBlackForeground: greys.shade70,
  settingsMenuSwitcherBlackForegroundHover: greys.shade80,
  settingsMenuSwitcherBlackSelectedForeground: greys.shade30,
  settingsMenuSwitcherBlackSelectedBackground: greys.black,
};
