import React, {ComponentType, FC} from 'react';
import {ThemeConsumer} from 'styled-components';
import {Subtract} from 'utility-types';

import {RendererOf} from '../../../core/src/helpers/react/reactHelpers';
import {UserTheme} from './themeTypes';
import {ThemeContextType} from './userThemeProvider';

/*
 * Props.
 */

interface UserThemeConsumerProps {
  children: RendererOf<UserTheme>;
}

/*
 * Component.
 */

export const UserThemeConsumer: FC<UserThemeConsumerProps> = ({children}) => (
  <ThemeConsumer>{(theme: UserTheme) => children(theme)}</ThemeConsumer>
);

/*
 * HOC.
 */

export function withUserTheme<T extends ThemeContextType>(
  Component: ComponentType<T>,
): FC<Subtract<T, ThemeContextType>> {
  return function (props) {
    return (
      <UserThemeConsumer>{(theme) => <Component {...(props as any)} theme={theme} />}</UserThemeConsumer>
    );
  };
}
