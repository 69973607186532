import {CancelToken} from 'axios';
import {DateTime, Interval} from 'luxon';

import {
  h12FormatOptions,
  h24FormatOptions,
} from '../../../../libs/shared/core/src/helpers/internationalization/dateTimeHelpers';
import {LocaleProps} from '../../components/common/locale/localeContext';
import {DeviceTypesEnum} from '../../components/platform/platformContext';
import {
  ApiSchedulingLinkAvailability,
  SchedulingLinkAvailabilityBlockModel,
  SchedulingLinkAvailabilityModel,
  SchedulingLinkNonEmptyAvailabilityModel,
} from '../../models/schedulingLinkAvailabilityModel';
import {SchedulingLinkModel} from '../../models/schedulingLinkModel';
import {buildCalendarDateFromTimestamp} from '../date';
import {fetchSchedulingApiAsync} from '../schedulingHttp';

/*
 * Api.
 */

export async function fetchAvailableSlotAsync(
  schedulingLink: SchedulingLinkModel,
  start: number,
  end: number,
  cancelToken?: CancelToken,
) {
  const path = buildAvailabilityApiUrl(schedulingLink.schedulingUrl, start, end);
  const response = await fetchSchedulingApiAsync(ApiSchedulingLinkAvailability, path, cancelToken);
  const [availableSlot] = response.timeSlots;
  if (availableSlot && availableSlot.start === start && availableSlot.end === end) {
    return availableSlot;
  }

  return undefined;
}

export async function fetchSchedulingLinkAvailability(
  schedulingLink: SchedulingLinkModel,
  cancelToken?: CancelToken,
) {
  const now = Date.now();
  const end = now + 60 * 24 * 60 * 60 * 1000;
  // Query for slots 60 days in the future.
  const path = buildAvailabilityApiUrl(schedulingLink.schedulingUrl, now, end, now);
  const response = await fetchSchedulingApiAsync(ApiSchedulingLinkAvailability, path, cancelToken);
  return response;
}

export function buildAvailabilityApiUrl(schedulingUrl: string, start: number, end: number, now = Date.now()) {
  return `${schedulingUrl}/availability?range_start=${start}&range_end=${end}&now=${now}`;
}

export function filterAvailabilityBlocksOnDay(availability: SchedulingLinkAvailabilityModel, date: DateTime) {
  return availability.timeSlots.filter((slot) => {
    const start = date.startOf('day').toMillis();
    const end = date.endOf('day').toMillis();

    return start <= slot.start && slot.start <= end;
  });
}

/** Return the date of the first availability. */
export function makeInitialSelectedDate(
  initialAvailability: SchedulingLinkNonEmptyAvailabilityModel,
  device: DeviceTypesEnum,
) {
  if (device === DeviceTypesEnum.MOBILE) {
    // No initial selected date on mobile.
    return undefined;
  }

  // Return the date of the first available time slot.
  const [firstAvailability] = initialAvailability.timeSlots;
  return buildCalendarDateFromTimestamp(firstAvailability.start);
}

/** Return an interval from the first to last availability. */
export function makeFullAvailabilityInterval(initialAvailability: SchedulingLinkNonEmptyAvailabilityModel) {
  const startMs = Math.min(...initialAvailability.timeSlots.map((slot) => slot.start));
  const endMs = Math.max(...initialAvailability.timeSlots.map((slot) => slot.end));
  return Interval.fromDateTimes(DateTime.fromMillis(startMs), DateTime.fromMillis(endMs));
}

export function renderAvailabilityBlockDateWithLocale(
  block: SchedulingLinkAvailabilityBlockModel,
  locale: LocaleProps,
  formatOverrides: Object = {},
) {
  const {start} = block;
  return DateTime.fromMillis(start).toLocaleString({
    timeZone: locale.timezone,
    weekday: 'long',
    month: 'long',
    day: 'numeric',
    ...formatOverrides,
  });
}

export function renderAvailabilityBlockTimeWithLocale(
  block: SchedulingLinkAvailabilityBlockModel,
  locale: LocaleProps,
) {
  const {start, end} = block;
  const formattedStart = DateTime.fromMillis(start).toLocaleString({
    timeZone: locale.timezone,
    hour: 'numeric',
    minute: '2-digit',
    ...getHourFormatOptions(locale.is24HourTime),
  });
  const formattedEnd = DateTime.fromMillis(end).toLocaleString({
    timeZone: locale.timezone,
    hour: 'numeric',
    minute: '2-digit',
    timeZoneName: 'short',
    ...getHourFormatOptions(locale.is24HourTime),
  });
  return `${formattedStart} — ${formattedEnd}`;
}

function getHourFormatOptions(is24HourTime: boolean) {
  if (is24HourTime) {
    return h24FormatOptions;
  }
  return h12FormatOptions;
}
