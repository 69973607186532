import {EffectCallback, useEffect, useLayoutEffect} from 'react';

/** One-time side effect after the first DOM render/update, with optional cleanup on unmount. */
export function useInitialEffect(effect: EffectCallback) {
  useEffect(effect, []);
}

export function useInitialLayoutEffect(effect: EffectCallback) {
  useLayoutEffect(effect, []);
}
