export enum InteractionTypesEnum {
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
  CLICK = 'click',
  FOCUS = 'focus',
  SET_VALUE = 'set_value',
  DOUBLE_CLICK = 'double_click',
  DIALOG_SHOWN = 'dialog_shown',
  BANNER_SHOWN = 'banner_shown',
  INFOBUBBLE_SHOWN = 'infobubble_shown',
  CHECKBOX_CHECKED = 'checkbox_checked',
  VIDEO_PLAYED = 'video_played',
}
