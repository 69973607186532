import {SchedulingLinkModel} from '../../models/schedulingLinkModel';

/*
 * Api.
 */

export function buildBookTitle(schedulingLink: SchedulingLinkModel) {
  if (schedulingLink.greeting) {
    return schedulingLink.greeting;
  }

  return 'Pick a time to meet';
}
