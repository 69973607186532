import React, {FC} from 'react';
import styled, {css} from 'styled-components';

import {alphas, greys, palette} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes, fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';

/*
 * Props.
 */

export interface DatePickerGridItemProps {
  label: string;
  isEmpty: boolean;
  isSelected: boolean;
  isSelectable: boolean;
  onClick?: () => void;
}

interface StyledDateDivProps {
  isSelected: boolean;
  isSelectable: boolean;
}

/*
 * Style.
 */

const StyledDateDiv = styled.div<StyledDateDivProps>`
  border-radius: 8px;
  width: 40px;
  height: 40px;

  padding: 11px;
  margin: 2px auto;

  text-align: center;
  font-size: ${fontSizes.medium};
  font-weight: ${fontWeights.semibold};

  ${(props) => dateStyle(props)};
`;

function dateStyle(props: StyledDateDivProps) {
  if (props.isSelected) {
    return css`
      border: 1px solid ${palette.blue.shade50};
      color: ${greys.white};
      background-color: ${palette.blue.shade50};
    `;
  }

  if (props.isSelectable) {
    return css`
      border: 1px solid ${greys.shade30};
      color: ${palette.blue.shade40};

      &:hover {
        background-color: ${alphas.gray10};
      }
    `;
  }

  return css`
    color: ${greys.shade60};
  `;
}

/*
 * Component.
 */

export const DatePickerGridItem: FC<DatePickerGridItemProps> = (props) => {
  // Return an empty placeholder div.
  if (props.isEmpty) {
    return <div />;
  }

  return (
    <StyledDateDiv isSelectable={props.isSelectable} isSelected={props.isSelected} onClick={props.onClick}>
      {props.label}
    </StyledDateDiv>
  );
};
