import {makeEnumRuntimeType} from '../../models/api/types/enumTypes';
import {UnreachableError} from './customErrors';

/*
 * Types.
 */

export enum AsyncStatusesEnum {
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
}

export enum AsyncMoreStatusesEnum {
  NONE = 'NONE',
  HAS_MORE = 'HAS_MORE',
  LOADING = 'LOADING',
  ALL_LOADED = 'ALL_LOADED',
  ERROR = 'ERROR',
}

/*
 * Runtime types.
 */

export const RAsyncMoreStatus = makeEnumRuntimeType(AsyncMoreStatusesEnum);
export const RAsyncStatus = makeEnumRuntimeType(AsyncStatusesEnum);

/*
 * Helpers.
 */

export function combineAsyncStatuses(...statuses: ReadonlyArray<AsyncStatusesEnum>): AsyncStatusesEnum {
  if (statuses.includes(AsyncStatusesEnum.FAILED)) {
    return AsyncStatusesEnum.FAILED;
  }

  if (statuses.includes(AsyncStatusesEnum.LOADING)) {
    return AsyncStatusesEnum.LOADING;
  }

  return AsyncStatusesEnum.LOADED;
}

export function combineAsyncMoreStatuses(...statuses: ReadonlyArray<AsyncMoreStatusesEnum>) {
  if (statuses.some((status) => status === AsyncMoreStatusesEnum.ERROR)) {
    return AsyncMoreStatusesEnum.ERROR;
  }

  if (statuses.every((status) => status === AsyncMoreStatusesEnum.NONE)) {
    return AsyncMoreStatusesEnum.NONE;
  }

  if (statuses.some((status) => status === AsyncMoreStatusesEnum.LOADING)) {
    return AsyncMoreStatusesEnum.LOADING;
  }

  if (statuses.some((status) => status === AsyncMoreStatusesEnum.HAS_MORE)) {
    return AsyncMoreStatusesEnum.HAS_MORE;
  }

  return AsyncMoreStatusesEnum.ALL_LOADED;
}

export function asyncMoreStatusToAsyncStatus(asyncMoreStatus: AsyncMoreStatusesEnum): AsyncStatusesEnum {
  switch (asyncMoreStatus) {
    case AsyncMoreStatusesEnum.ALL_LOADED:
    case AsyncMoreStatusesEnum.HAS_MORE:
    case AsyncMoreStatusesEnum.NONE:
      return AsyncStatusesEnum.LOADED;

    case AsyncMoreStatusesEnum.LOADING:
      return AsyncStatusesEnum.LOADING;

    case AsyncMoreStatusesEnum.ERROR:
      return AsyncStatusesEnum.FAILED;

    default:
      throw new UnreachableError(asyncMoreStatus);
  }
}

export function asyncStatusToAsyncMoreStatus(asyncStatus: AsyncStatusesEnum): AsyncMoreStatusesEnum {
  switch (asyncStatus) {
    case AsyncStatusesEnum.LOADING:
      return AsyncMoreStatusesEnum.LOADING;

    case AsyncStatusesEnum.LOADED:
      return AsyncMoreStatusesEnum.ALL_LOADED;

    case AsyncStatusesEnum.FAILED:
      return AsyncMoreStatusesEnum.ERROR;

    default:
      throw new UnreachableError(asyncStatus);
  }
}
