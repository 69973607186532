import {compute} from 'compute-scroll-into-view';
import Downshift, {ControllerStateAndHelpers, DownshiftProps} from 'downshift';
import {isNull, isNumber, isUndefined} from 'lodash';
import React, {FC, PropsWithChildren, ReactNode, useCallback, useEffect, useRef} from 'react';
import {Key} from 'ts-key-enum';

import {KeyboardActionsEnum} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {
  annotateTaskType,
  TaskTypesEnum,
} from '../../../../core/src/helpers/instrumentation/eventLoopPerformanceHelpers';
import {FrontEvent, KeyboardEventHandler} from '../../../../core/src/helpers/react/reactHelpers';
import {KeyboardEventHandlers} from '../keyboard/keyboardContext';
import {KeyboardShortcuts} from '../keyboard/keyboardShortcuts';
import {useLayer} from '../layers/layerContext';
import {InteractiveListContext, InteractiveListContextProps} from './interactiveListContext';

/*
 * Props.
 */

export interface InteractiveListItem {
  index: number;
  selectAtIndex: (arg: number) => void;
}
export type PreselectHandler = (event: FrontEvent, item: InteractiveListItem) => void;

export enum InteractiveListBoundaryDirectionsEnum {
  TOP = 0,
  BOTTOM = 1,
}
export interface InteractiveListProps {
  itemCount?: number;
  onRequestClose?: () => void;
  onItemPreselect?: PreselectHandler;
  onBoundaryLoop?: (arg: InteractiveListBoundaryDirectionsEnum) => void;
  shouldSelectFirst?: boolean;
  defaultHighlightedIndex?: number | null;
  shouldSelectWithTab?: boolean;
  shouldCenterHighlightedItem?: boolean;
  filterValue?: string;
  className?: string;
}

/*
 * Downshift types.
 */

interface DownshiftItem {
  index: number;
  onSelect?: (event: FrontEvent, key?: Key) => void;
}

/*
 * Component.
 */

export const InteractiveList: FC<PropsWithChildren<InteractiveListProps>> = (props) => {
  const {
    filterValue,
    shouldSelectFirst,
    defaultHighlightedIndex = null,
    shouldCenterHighlightedItem = false,
    onBoundaryLoop,
  } = props;

  // When the filter value changes, reset the highlighted index.
  const setStateRef = useRef<ControllerStateAndHelpers<DownshiftItem>['setState']>();
  const layerContext = useLayer();

  // Keep track of the key that lead to an item being selected.
  const selectKeyRef = useRef<Key>();
  const writeSelectKey = (key: Key) => {
    selectKeyRef.current = key;
  };
  const readSelectKey = (): Key | undefined => {
    const selectKey = selectKeyRef.current;
    selectKeyRef.current = undefined;
    return selectKey;
  };

  const scrollIntoViewForProps = useCallback(
    (node: HTMLElement, menuNode: HTMLElement) => scrollIntoView(node, menuNode, shouldCenterHighlightedItem),
    [shouldCenterHighlightedItem],
  );

  useEffect(() => {
    const currentSetState = setStateRef.current;
    if (!currentSetState) {
      return;
    }

    const highlightedIndex = shouldSelectFirst ? 0 : defaultHighlightedIndex;
    currentSetState({highlightedIndex});
  }, [filterValue, shouldSelectFirst, defaultHighlightedIndex]);

  const stateReducer: DownshiftProps<DownshiftItem>['stateReducer'] = (state, changes) => {
    const currentHighlightedIndex = state.highlightedIndex;
    const newHighlightedIndex = changes.highlightedIndex;

    if (!onBoundaryLoop || !isNumber(currentHighlightedIndex) || !isNumber(newHighlightedIndex)) {
      return changes;
    }

    // If we went up but our new index if bigger than before, then we looped.
    if (
      changes.type === '__autocomplete_keydown_arrow_up__' &&
      newHighlightedIndex > currentHighlightedIndex
    ) {
      onBoundaryLoop(InteractiveListBoundaryDirectionsEnum.TOP);
    }
    // If we went down but our new index if bigger than before, then we looped.
    else if (
      changes.type === '__autocomplete_keydown_arrow_down__' &&
      currentHighlightedIndex > newHighlightedIndex
    ) {
      onBoundaryLoop(InteractiveListBoundaryDirectionsEnum.BOTTOM);
    }

    return changes;
  };

  return (
    <Downshift
      environment={layerContext.window}
      itemCount={props.itemCount}
      onSelect={(item: DownshiftItem, options) => onItemSelect(props, item, options, readSelectKey())}
      itemToString={(item) => item && item.index}
      scrollIntoView={scrollIntoViewForProps}
      stateReducer={stateReducer}
      isOpen
    >
      {(options) => {
        setStateRef.current = options.setState;
        return renderContent(options, props, writeSelectKey, props.children);
      }}
    </Downshift>
  );
};

/** Handler for the selection of a single item Downshift. */
export function onItemSelect(
  props: InteractiveListProps,
  item: DownshiftItem,
  options: ControllerStateAndHelpers<DownshiftItem>,
  key?: Key,
) {
  annotateTaskType(TaskTypesEnum.CLICK_EVENT);

  const {onItemPreselect, onRequestClose} = props;
  const {index, onSelect} = item;

  // Make sure the item remains highlighted.
  options.setHighlightedIndex(item.index);

  // Give a chance to the parent to handle.
  const event = new CustomEvent('DropdownEvent', {cancelable: true});
  if (onItemPreselect) {
    const selectAtIndex: (arg: number) => void = (selectIndex) => options.selectItemAtIndex(selectIndex);
    onItemPreselect(event, {index, selectAtIndex});
  }

  // Check if there's anything for us to do.
  if (event.defaultPrevented || !onSelect) {
    return;
  }

  try {
    onSelect(event, key);
  } catch (err) {
    rethrowAsync(err);
  }

  if (event.defaultPrevented || !onRequestClose) {
    return;
  }

  try {
    onRequestClose();
  } catch (err) {
    rethrowAsync(err);
  }
}

/** Rethrows the error outside of the React rendering phase, which is where Downshift calls its event handlers. */
async function rethrowAsync(err: any) {
  await Promise.resolve();
  throw err;
}

/** Provide a context to children for Downshift registration. */
function renderContent(
  options: ControllerStateAndHelpers<DownshiftItem>,
  props: InteractiveListProps,
  writeSelectKey: (key: Key) => void,
  children?: ReactNode,
) {
  // Add keyboard shortcuts.
  const downshiftInputProps = options.getInputProps();
  const {selectHighlightedItem} = options;

  const onMoveKeyDown: KeyboardEventHandler = (event) => {
    downshiftInputProps.onKeyDown(event);

    // TODO: Remove stopPropagation?
    event.stopPropagation();
    event.preventDefault();
  };

  const onEnterKeyDown: KeyboardEventHandler = (event) => {
    // Prevent repeating select callbacks when the Enter key is held down.
    if (!event.repeat) {
      writeSelectKey(Key.Enter);
      downshiftInputProps.onKeyDown(event);
    }

    // TODO: Remove stopPropagation?
    event.stopPropagation();
    event.preventDefault();
  };

  const onTabKeyDown: KeyboardEventHandler = (event) => {
    writeSelectKey(Key.Tab);
    selectHighlightedItem();

    // TODO: Remove stopPropagation?
    event.stopPropagation();
    event.preventDefault();
  };

  const onEscape: KeyboardEventHandler = (event) => {
    if (!onRequestClose) {
      return;
    }

    onRequestClose();
    event.preventDefault();
  };

  const {onRequestClose, shouldSelectWithTab} = props;
  const hasHighlightedItem = !isNull(options.highlightedIndex);
  const keyHandlers: KeyboardEventHandlers = {
    [KeyboardActionsEnum.MOVE_UP]: onMoveKeyDown,
    [KeyboardActionsEnum.MOVE_DOWN]: onMoveKeyDown,
    [KeyboardActionsEnum.CONFIRM]: (hasHighlightedItem && onEnterKeyDown) || undefined,
    [KeyboardActionsEnum.TAB]: (shouldSelectWithTab && hasHighlightedItem && onTabKeyDown) || undefined,
    [KeyboardActionsEnum.CLOSE]: onEscape,
  };

  // Build the context.
  let lastIndex = 0;
  const registerListItem = (onSelect?: (event: FrontEvent) => void, index?: number) => {
    // Create the Downshift and register it.
    const item: DownshiftItem = {index: isUndefined(index) ? lastIndex++ : index, onSelect};
    const itemProps = options.getItemProps({index: item.index, item});

    // Return the props and the index.
    return {props: itemProps, index: item.index};
  };

  const context: InteractiveListContextProps = {
    registerListItem,
    highlightedIndex: options.highlightedIndex,
  };

  return (
    <div className={props.className} onMouseLeave={() => options.setHighlightedIndex(-1)}>
      <KeyboardShortcuts handlers={keyHandlers} shouldAllowRepeats>
        <InteractiveListContext.Provider value={context}>{children}</InteractiveListContext.Provider>
      </KeyboardShortcuts>
    </div>
  );
}

// Adapted from https://github.com/downshift-js/downshift/blob/2f50b9150fb0bcaedf353f3a598c23a23bae90cb/src/utils.js#L25
function scrollIntoView(node: HTMLElement, menuNode: HTMLElement, shouldCenter: boolean) {
  if (!node) {
    return;
  }

  const actions = compute(node, {
    boundary: menuNode,
    block: shouldCenter ? 'center' : 'nearest',
    scrollMode: shouldCenter ? 'always' : 'if-needed',
  });

  actions.forEach(({el, top, left}) => {
    /* eslint-disable no-param-reassign */
    el.scrollTop = top;
    el.scrollLeft = left;
    /* eslint-enable no-param-reassign */
  });
}
