import React, {FC, PropsWithChildren, ReactNode} from 'react';
import styled, {css} from 'styled-components';

import {directHoverSelector} from '../../../../../core/src/helpers/browser/hoverHelpers';
import {FrontEvent, Renderer} from '../../../../../core/src/helpers/react/reactHelpers';
import {UserTheme} from '../../../theme/themeTypes';
import {ChromelessButton} from '../../buttons/chromelessButton';
import {Icon, IconName} from '../../icon/icon';
import {TooltipCoordinator} from '../../tooltips/tooltipCoordinator';
import {VisuallyHidden} from '../../visuallyHidden/visuallyHidden';

/*
 * Props.
 */

export interface DropdownItemRightIconProps {
  /** Label to use for accessibility. */
  label: ReactNode;
  rightIconName: IconName;
  rightIconColor?: string;
  rightIconHoverColor?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  onRightIconClick?: () => void;
  renderTooltip?: Renderer;
}

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  grid-area: right-icon;
  align-self: center;
  margin-left: 8px;
`;

interface DropdownItemRightIconStyleProps {
  $rightIconColor?: string;
  $rightIconHoverColor?: string;
  $isChecked?: boolean;
  $isDisabled?: boolean;
  $onRightIconClick?: () => void;
}

const StyledIconDiv = styled.div<DropdownItemRightIconStyleProps>`
  width: 16px;
  color: ${(p) => getColor(p, p.theme)};

  ${(p) => addHoverStyle(p)};
`;

function getColor(props: DropdownItemRightIconStyleProps, theme: UserTheme) {
  if (props.$isChecked) {
    return theme.palette.blue.shade40;
  }

  return props.$rightIconColor || theme.greys.shade40;
}

function addHoverStyle(props: DropdownItemRightIconStyleProps) {
  // If this is not clickable, no hover state.
  if (!props.$onRightIconClick) {
    return '';
  }

  return css`
    ${directHoverSelector} {
      color: ${(p) => getHoverColor(props, p.theme)};
    }
  `;
}

function getHoverColor(props: DropdownItemRightIconStyleProps, theme: UserTheme) {
  if (props.$isChecked) {
    return theme.interactiveTextHover;
  }

  return props.$rightIconHoverColor || theme.greys.shade70;
}

/*
 * Component.
 */

export const DropdownItemRightIcon: FC<PropsWithChildren<DropdownItemRightIconProps>> = (props) => (
  <StyledWrapperDiv onClick={onWrapperClick}>
    {maybeWrapInTooltip(
      props,
      <ChromelessButton
        onClick={props.onRightIconClick}
        isActive={props.isChecked}
        isDisabled={props.isDisabled}
      >
        <StyledIconDiv
          $rightIconColor={props.rightIconColor}
          $rightIconHoverColor={props.rightIconHoverColor}
          $isChecked={props.isChecked}
          $isDisabled={props.isDisabled}
          $onRightIconClick={props.onRightIconClick}
        >
          <Icon name={props.rightIconName} />
          <VisuallyHidden>{props.label}</VisuallyHidden>
        </StyledIconDiv>
      </ChromelessButton>,
    )}
  </StyledWrapperDiv>
);

function maybeWrapInTooltip(props: DropdownItemRightIconProps, children: ReactNode) {
  if (!props.renderTooltip) {
    return children;
  }

  return (
    <TooltipCoordinator hideOnMouseDown render={props.renderTooltip}>
      {children}
    </TooltipCoordinator>
  );
}

function onWrapperClick(event: FrontEvent) {
  // Prevent the right icon from causing the dropdown item to be selected, regardless of disabled state.
  event.stopPropagation();
}
