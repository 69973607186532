const isHourCycleSupported =
  'hourCycle' in new Intl.DateTimeFormat('en-US', {hour: 'numeric'}).resolvedOptions();

// lib.dom.ts typings don't have `hourCycle` yet
// https://github.com/microsoft/TypeScript/issues/34399
export const h24FormatOptions: Intl.DateTimeFormatOptions & {hourCycle?: string} = isHourCycleSupported
  ? {
      // As of Chromium 80, the default `hourCycle` is 'h24' so 'hour12: false` isn't actually the right way to do this.
      // This is according to the spec
      // https://bugs.chromium.org/p/chromium/issues/detail?id=1045791#c7
      hourCycle: 'h23',
      hour12: undefined,
    }
  : {
      hour12: false,
    };

export const h12FormatOptions: Intl.DateTimeFormatOptions = isHourCycleSupported
  ? {
      hourCycle: 'h12',
      hour12: undefined,
    }
  : {
      hour12: true,
    };
