import {CancelToken} from 'axios';
import React, {FC, ReactNode, useCallback} from 'react';
import {useParams} from 'react-router-dom';

import {
  BookingCancelledError,
  BookingDisabledError,
  SchedulingLinkMissingAvailabilityError,
} from '../../helpers/errors';
import {fetchSchedulingLinkAvailability, makeInitialSelectedDate} from '../../helpers/model/availability';
import {fetchBookingAsync} from '../../helpers/model/booking';
import {isNonEmptyAvailability} from '../../models/schedulingLinkAvailabilityModel';
import {SchedulingLinkDraftBookingModel} from '../../models/schedulingLinkBookingModel';
import {AsyncRenderer} from '../common/asyncRenderer';
import {FullScreenError} from '../common/error/fullScreenError';
import {renderFullScreenLoader} from '../common/fullScreenLoader';
import {defaultLocale} from '../common/locale/localeContext';
import {MainContentWrapper} from '../common/mainContentWrapper';
import {StepData} from '../common/step/step';
import {StepController} from '../common/step/stepController';
import {DeviceTypesEnum, usePlatform} from '../platform/platformContext';
import {editStepChooseDate} from './chooseSlot/editStepChooseDate';
import {editStepChooseSlot} from './chooseSlot/editStepChooseSlot';
import {editStepChooseTime} from './chooseSlot/editStepChooseTime';
import {editStepViewConfirmation} from './viewConfirmation/editStepViewConfirmation';

/*
 * Component.
 */

export const EditPage: FC = () => {
  const {bookingToken = ''} = useParams();
  const {device} = usePlatform();

  const asyncOperation = useCallback(
    (cancelToken: CancelToken) => makeInitialEditDataAsync(bookingToken, device, cancelToken),
    [bookingToken, device],
  );

  return (
    <MainContentWrapper>
      <AsyncRenderer
        asyncOperation={asyncOperation}
        render={(editData) => (
          <StepController steps={editData.steps} initialStepData={editData.initialStepData} />
        )}
        renderLoading={renderFullScreenLoader}
        renderError={renderAsyncError}
      />
    </MainContentWrapper>
  );
};

/*
 * Helpers.
 */

interface InitialEditData {
  steps: any;
  initialStepData: StepData;
}

export async function makeInitialEditDataAsync(
  bookingToken: string,
  device: DeviceTypesEnum,
  cancelToken?: CancelToken,
): Promise<InitialEditData> {
  // Fetch the booking.
  const booking = await fetchBookingAsync(bookingToken, cancelToken);
  const {disabled, cancelled, schedulingLink} = booking;

  if (disabled) {
    throw new BookingDisabledError(booking);
  }

  if (cancelled) {
    throw new BookingCancelledError(booking);
  }

  // Check availability for scheduling link.
  const availability = await fetchSchedulingLinkAvailability(schedulingLink, cancelToken);
  if (!isNonEmptyAvailability(availability)) {
    throw new SchedulingLinkMissingAvailabilityError(schedulingLink);
  }

  // Create a draft booking without the start and edit time.
  const draftBooking: SchedulingLinkDraftBookingModel = {
    ...booking,
    start: undefined,
    end: undefined,
    isDraft: true,
  };
  return {
    steps: [...makeChooseSlotSteps(device), editStepViewConfirmation],
    initialStepData: {
      schedulingLink,
      booking: draftBooking,
      availability,
      locale: defaultLocale,
      selectedDate: makeInitialSelectedDate(availability, device),
    },
  };
}

function makeChooseSlotSteps(device: DeviceTypesEnum) {
  if (device === DeviceTypesEnum.MOBILE) {
    return [editStepChooseDate, editStepChooseTime];
  }

  return [editStepChooseSlot];
}

function renderAsyncError(error: any) {
  return <FullScreenError description={renderErrorDescription(error)} />;
}

function renderErrorDescription(error: any): ReactNode {
  if (error instanceof BookingDisabledError) {
    return 'This meeting can no longer be modified. Please contact the owner of this scheduling link to reschedule or cancel this meeting.';
  }

  if (error instanceof BookingCancelledError) {
    return 'This meeting is cancelled.';
  }

  if (error instanceof SchedulingLinkMissingAvailabilityError) {
    return `Times no longer available. Try contacting ${error.schedulingLink.organizerAddress} to find a new time to meet.`;
  }

  return 'An unexpected error occurred.';
}
