import {createMandatoryContext} from '../../../../../libs/shared/core/src/helpers/react/reactHelpers';
import {PaletteColorsEnum} from '../../../../../libs/shared/core/src/models/api/paletteModel';

/*
 * Props.
 */

export interface ThemeProps {
  color: PaletteColorsEnum;
}

/*
 * Constants.
 */

export const defaultTheme: ThemeProps = {
  color: PaletteColorsEnum.BLUE,
};

/*
 * Context.
 */

export const [ThemeContext, useTheme] = createMandatoryContext<ThemeProps>();
