import {transparentize} from 'polished';

import {blendColors} from '../../../../core/src/models/helpers/colorHelpers';
import {ButtonVariantsEnum} from '../../components/buttons/buttonConstants';
import {alphas, greys, greysDark, palette, paletteDark} from '../../styles/colorStyles';
import {darkBetaFeaturePillSemanticStyles} from '../semantic/betaFeaturePillSemanticStyles';
import {darkNewFeaturePillSemanticStyles} from '../semantic/newFeaturePillSemanticStyles';
import {LegacySemanticStyles} from '../themeTypes';

// Overrides for Legacy semantic styles in expanded p2/p3 dark mode.
export const darkLegacySemanticStyleOverrides: Partial<LegacySemanticStyles> = {
  background: greysDark.shade20,
  item: greysDark.shade80,
  itemBold: greysDark.shade90,

  bannerText: greysDark.shade80,
  bannerBackground: greysDark.shade20,
  bannerSecondaryText: greysDark.shade70,
  bannerActionHover: alphas.gray20,
  bannerIcon: greysDark.shade80,
  bannerTimeRemaining: alphas.black30,

  p1BannerBackground: paletteDark.pink.shade40,
  p1BannerButtonVariant: ButtonVariantsEnum.WHITE_ON_BLACK,
};

export const darkLegacySemanticStyles: LegacySemanticStyles = {
  background: blendColors(palette.blue.shade40, transparentize(0.15, greys.shade90)),
  separator: blendColors(palette.blue.shade40, transparentize(0.15, greys.shade90)),
  itemSeparator: alphas.white20,
  header: alphas.white50,
  headerHover: alphas.white60,
  headerMore: alphas.white40,
  headerMoreHover: alphas.white60,

  item: alphas.white60,
  itemBold: greys.white,
  itemSelected: greys.white,
  icon: alphas.white40,
  iconHover: alphas.white60,
  itemBackgroundHover: alphas.white10,
  itemBackgroundSelected: alphas.white20,
  itemPrivateLock: alphas.white40,
  itemAdd: alphas.white50,
  itemAddHover: greys.white,
  itemAddDismiss: alphas.white30,
  itemAddDismissHover: alphas.white50,
  itemDot: alphas.white20,
  itemDotHover: alphas.white30,
  itemCheckBox: alphas.white40,

  itemIcon: greys.shade50,
  itemIconHover: greys.shade30,
  itemIconSelected: greys.white,
  itemIconBackground: alphas.white20,
  itemIconBackgroundHover: alphas.white30,

  itemMoreIcon: alphas.white40,
  itemMoreIconHover: alphas.white50,
  itemMoreIconSelected: greys.white,
  itemMoreIconBackground: alphas.white20,
  itemMoreIconBackgroundHover: alphas.white30,

  scrollbarTrack: alphas.transparent,
  scrollbarThumb: alphas.white30,
  scrollbarThumbHover: alphas.white40,

  bannerText: greys.shade80,
  bannerBackground: greys.shade20,
  bannerSecondaryText: greys.shade70,
  bannerActionHover: alphas.gray20,
  bannerIcon: greys.shade80,
  bannerTimeRemaining: alphas.black30,

  betaFeaturePill: darkBetaFeaturePillSemanticStyles,
  newFeaturePill: darkNewFeaturePillSemanticStyles,

  trialBackground: alphas.white20,
  trialPrompt: greys.white,
  trialNumber: alphas.white50,
  trialChat: alphas.white50,
  trialChatHover: greys.white,

  datePickerPrimary: greys.white,
  datePickerSecondary: alphas.white50,
  datePickerDisabled: alphas.white30,
  datePickerBackgroundHover: alphas.white10,

  disabledCheckboxBoxColor: alphas.white30,
  disabledCheckboxCheckColor: alphas.white60,

  p1BannerBackground: palette.pink.shade50,
  p1BannerTitle: greys.white,
  p1BannerSubtitle: greys.white,
  p1BannerIcon: greys.white,
  p1RarelyViewedBadgeBackground: palette.pink.shade10,
  p1RarelyViewedBadgeText: palette.pink.shade50,
  p1HelpTipSubtitle: transparentize(0.05, greys.white),
  p1HelpTipClose: transparentize(0.2, greys.white),
  p1HelpTipCloseHover: transparentize(0.05, greys.white),
  p1HelpTipCloseBackgroundHover: transparentize(0.85, greys.white),
  p1BannerButtonVariant: ButtonVariantsEnum.SECONDARY,
  p1BannerLinkVariant: ButtonVariantsEnum.DARK_MODE_LINK,

  settingsMenuSwitcherBackground: alphas.white20,
  settingsMenuSwitcherBlackForeground: alphas.white40,
  settingsMenuSwitcherBlackForegroundHover: alphas.white50,
  settingsMenuSwitcherBlackSelectedForeground: greys.black,
  settingsMenuSwitcherBlackSelectedBackground: greys.white,
};
