import {CleanHtml} from '../../../../libs/shared/core/src/helpers/typescript/cleanHtml';

export enum CalendarInviteResponsesEnum {
  ACCEPTED = 'accepted',
  TENTATIVE = 'tentative',
  DECLINED = 'declined',
}

export interface CalendarDataProps {
  hasError: boolean;
  response: CalendarInviteResponsesEnum | null;
}

export interface CalendarTitleProps {
  title: string;
}

type UpdateResponseType = (response: CalendarInviteResponsesEnum) => void;

export interface CalendarButtonProps {
  responseOption: CalendarInviteResponsesEnum;
  selected: boolean;
}

export interface CalendarButtonContainerProps extends CalendarButtonProps {
  updateResponse: UpdateResponseType;
}

export interface LocationItem {
  name: string;
  url: string | null;
}

export interface LocationItemProps {
  locationItem: LocationItem;
}

export interface CalendarEventData {
  id: number;
  summary: string;
  description: CleanHtml;
  responseStatus: CalendarInviteResponsesEnum | null;
  numberOfAttendees: number;
  eventDateTime: string;
  locationList: Array<LocationItem>;
  error?: any;
}
export interface CalendarContainerProps {
  calendarEventData: CalendarEventData;
  updateResponse: UpdateResponseType;
}

export enum CalendarColorsEnum {
  GREEN = '#008B4A',
  YELLOW = '#FFB700',
  MAGENTA = '#CC0049',
}
