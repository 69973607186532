import React, {FC, PropsWithChildren, ReactNode} from 'react';
import styled from 'styled-components';

import {fontWeights} from '../../styles/fontStyles';
import {ellipsis} from '../../styles/mixins';

/*
 * Props.
 */

export interface DropdownHeaderProps {
  title?: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

/*
 * Style.
 */

const StyledHeaderDiv = styled.div`
  /* Avoids it from shrinking when used in a dropdown with a maxHeight */
  flex-shrink: 0;

  display: flex;
  flex-direction: column;

  padding: 0 7px;
  border-bottom: 1px solid ${(p) => p.theme.greys.shade30};
`;

const StyledTopDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
`;

const StyledLeftDiv = styled.div`
  grid-row: 1;
  grid-column: 1;
  justify-self: start;
`;

const StyledRightDiv = styled.div`
  grid-row: 1;
  grid-column: 3;
  justify-self: end;
`;

const StyledTitleDiv = styled.div`
  grid-row: 1;
  grid-column: 2;
  padding: 12px 6px;

  color: ${(p) => p.theme.greys.shade80};
  text-align: center;
  font-weight: ${fontWeights.semibold};
  ${ellipsis()};
`;

const StyledContentDiv = styled.div`
  padding: 0 6px;
`;

/*
 * Component.
 */

export const DropdownHeader: FC<PropsWithChildren<DropdownHeaderProps>> = (props) => (
  <StyledHeaderDiv>
    {maybeRenderTop(props)}
    <StyledContentDiv>{props.children}</StyledContentDiv>
  </StyledHeaderDiv>
);

function maybeRenderTop(props: DropdownHeaderProps) {
  const {leftIcon, title, rightIcon} = props;
  if (!leftIcon && !title && !rightIcon) {
    return null;
  }

  return (
    <StyledTopDiv>
      <StyledLeftDiv>{props.leftIcon}</StyledLeftDiv>
      <StyledTitleDiv>{props.title}</StyledTitleDiv>
      <StyledRightDiv>{props.rightIcon}</StyledRightDiv>
    </StyledTopDiv>
  );
}
