/* eslint-disable no-underscore-dangle */
import {en} from 'make-plural';

/*
 * Types.
 */

interface UncompiledPluralMessageDescriptor {
  /** The unique identifier of the message. */
  id: string;
  /** The count. */
  value: number;
  /** The message for zero in English. (optional) */
  _0?: string;
  /** The message for one in English. */
  one: string;
  /** The message for zero or many in English. */
  other: string;
}

export interface PluralMessageDescriptor extends UncompiledPluralMessageDescriptor {
  readonly CompiledPluralMessageDescriptor: unique symbol;
}

/*
 * Helpers.
 */

/** Defines a translatable plural message outside of React. */
export function definePluralMessage(descriptor: UncompiledPluralMessageDescriptor): PluralMessageDescriptor {
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return descriptor as PluralMessageDescriptor;
}

export function translatePluralMessage(descriptor: PluralMessageDescriptor): string {
  return replacePluralValue(descriptor.value, findPluralMessage(descriptor));
}

export function findPluralMessage<T>(descriptor: {value: number; _0?: T; one: T; other: T}): T {
  if (descriptor.value === 0 && descriptor._0) {
    return descriptor._0;
  }

  const key = en(descriptor.value, false);
  return key === 'one' ? descriptor.one : descriptor.other;
}

const octothorpeRegExp = /#/g;

export function replacePluralValue(value: number, message: string): string {
  return message.replace(octothorpeRegExp, value.toLocaleString('en'));
}
