import React, {ReactElement, useCallback, useMemo, useState} from 'react';
import {unstable_batchedUpdates as batchedUpdates} from 'react-dom';

import {AsyncStatusesEnum} from '../../../../../libs/shared/core/src/helpers/types/asyncStatuses';
import {reportToBugsnag} from '../../../helpers/bugsnag';
import {LocaleContext} from '../locale/localeContext';
import {defaultTheme, ThemeContext} from '../theme/themeContext';
import {Step, StepData, StepUpdater} from './step';

/*
 * Props.
 */

interface StepControllerProps<T> {
  steps: ReadonlyArray<Step<T>>;
  initialStepData: StepData;
  initialError?: Error;
}

/*
 * Component.
 */

export const StepController: <T>(props: StepControllerProps<T>) => ReactElement<StepControllerProps<T>> = (
  props,
) => {
  const {initialStepData, initialError, steps} = props;
  const [firstStep] = steps;
  if (!firstStep) {
    throw new Error('No steps provided.');
  }

  const [stepData, setStepData] = useState<StepData>(initialStepData);
  const [currentStep, setCurrentStep] = useState(firstStep);
  const [asyncStatus, setAsyncStatus] = useState(AsyncStatusesEnum.LOADED);
  const [error, setError] = useState<Error | undefined>(initialError);

  const stepIndex = useMemo(() => steps.indexOf(currentStep), [steps, currentStep]);

  const onUpdateStepData = useCallback(
    (updater: StepUpdater) => {
      try {
        const newData = updater(stepData);
        setStepData(newData);
      } catch (err) {
        setError(err);
        reportToBugsnag(err);
        setAsyncStatus(AsyncStatusesEnum.FAILED);
      }
    },
    [stepData],
  );

  const onNext = useMemo(() => {
    const nextStep = steps[stepIndex + 1];

    if (!nextStep) {
      // There isn't another step.
      return undefined;
    }

    return async (updater?: StepUpdater) => {
      setAsyncStatus(AsyncStatusesEnum.LOADING);

      const newStepData = updater ? updater(stepData) : stepData;

      try {
        // Apply action after current step.
        const postCurrentStepData = currentStep.postStepAction
          ? await currentStep.postStepAction(newStepData)
          : newStepData;

        // Apply action before next step.
        const preNextStepData = nextStep.preStepAction
          ? await nextStep.preStepAction(postCurrentStepData)
          : postCurrentStepData;

        batchedUpdates(() => {
          setStepData(preNextStepData);
          setCurrentStep(nextStep);
          setAsyncStatus(AsyncStatusesEnum.LOADED);
        });
      } catch (err) {
        setError(err);
        reportToBugsnag(err);
        setAsyncStatus(AsyncStatusesEnum.FAILED);
      }
    };
  }, [steps, stepData, setStepData, currentStep, setCurrentStep, stepIndex]);

  const onPrevious = useMemo(() => {
    const previousStep = stepIndex - 1 >= 0 ? steps[stepIndex - 1] : undefined;

    if (!previousStep) {
      // There isn't a previous step.
      return undefined;
    }

    return (updater?: StepUpdater) => {
      batchedUpdates(() => {
        setStepData(updater ? updater(stepData) : stepData);
        setCurrentStep(previousStep);
      });
    };
  }, [steps, stepData, setStepData, setCurrentStep, stepIndex]);

  const onDismissError = useCallback(() => setError(undefined), [setError]);

  return (
    <LocaleContext.Provider value={stepData.locale}>
      <ThemeContext.Provider value={{color: stepData.schedulingLink.color || defaultTheme.color}}>
        {currentStep.renderStep({
          ...stepData,
          metadata: {
            asyncStatus,
            stepIndex,
            numSteps: steps.length,
            error,
            onDismissError,
          },
          onUpdateStepData,
          onNext,
          onPrevious,
        })}
      </ThemeContext.Provider>
    </LocaleContext.Provider>
  );
};
