import {CancelToken} from 'axios';

import {SchedulingLinkAvailabilityBlockModel} from '../../models/schedulingLinkAvailabilityModel';
import {
  ApiSchedulingLinkBooking,
  SchedulingLinkBookingModel,
  SchedulingLinkDraftBookingModel,
} from '../../models/schedulingLinkBookingModel';
import {deleteSchedulingApiAsync, fetchSchedulingApiAsync, patchSchedulingApiAsync} from '../schedulingHttp';

export async function fetchBookingAsync(token: string, cancelToken?: CancelToken) {
  return fetchSchedulingApiAsync(ApiSchedulingLinkBooking, buildBookingApiUrl(token), cancelToken);
}

export async function patchBookingAsync(token: string, newStart: number, cancelToken?: CancelToken) {
  const options = {
    path: buildBookingApiUrl(token),
    body: {start: newStart},
    cancelToken,
  };
  return patchSchedulingApiAsync(ApiSchedulingLinkBooking, options);
}

export async function deleteBookingAsync(token: string, cancelToken?: CancelToken) {
  return deleteSchedulingApiAsync(buildBookingApiUrl(token), cancelToken);
}

export function createAvailabilityBlockFromBooking(
  booking: SchedulingLinkBookingModel | SchedulingLinkDraftBookingModel,
): SchedulingLinkAvailabilityBlockModel | undefined {
  const {start, end} = booking;
  if (start && end) {
    return {start, end};
  }

  return undefined;
}

export function buildBookingApiUrl(token: string) {
  return `booking/${token}`;
}
