import {MessageDescriptor, translateMessage} from './message';
import {PluralMessageDescriptor, translatePluralMessage} from './pluralMessage';

/*
 * Types.
 */

type Locale = 'en';

/** Internationalization service. Currently only supports English. */
export interface I18n {
  readonly locale: Locale;
  /** Translates a message. */
  _(descriptor: MessageDescriptor | PluralMessageDescriptor): string;
}

/*
 * Constants.
 */

/** @deprecated Use the i18n instance provided by the getI18n selector or the useI18n hook. */
export const i18n = createI18n();

/*
 * Helpers.
 */

export function createI18n(locale = 'en' as const): I18n {
  return {
    locale,
    _: (descriptor) => {
      if ('message' in descriptor) {
        return translateMessage(descriptor);
      }

      return translatePluralMessage(descriptor);
    },
  };
}
