import {legacyDarkTheme} from './dark/darkTheme';
import {lightTheme} from './light/lightTheme';
import {UserTheme} from './themeTypes';
import {UserThemesEnum} from './userThemesEnum';

export const userThemeMap: Record<UserThemesEnum, UserTheme> = {
  [UserThemesEnum.LIGHT]: lightTheme,

  // The initial dark mode implementation, which only included P1 styles.
  [UserThemesEnum.DARK]: legacyDarkTheme,

  // If a user's preference is set to system, we still use the lightTheme as the top level
  // theme, since the entire application hasn't been converted to dark mode yet. The
  // ExpandedDarkThemeProvider will pass the dark theme to parts of the application
  // which support it.
  [UserThemesEnum.SYSTEM]: lightTheme,
  [UserThemesEnum.DARK_SIDEBAR]: legacyDarkTheme,
};

export const findTheme = (themeSetting: UserThemesEnum) => userThemeMap[themeSetting];
