import {reportToBugsnag} from '../../../helpers/bugsnag';
import {buildCalendarDateFromTimestamp, buildDateTimeFromCalendarDate} from '../../../helpers/date';
import {filterAvailabilityBlocksOnDay} from '../../../helpers/model/availability';
import {LocaleProps} from '../locale/localeContext';
import {StepData, StepUpdater} from './step';

/*
 * Api.
 */

export function makeLocaleUpdater(locale: LocaleProps): StepUpdater {
  return (data) => ({
    ...data,
    locale,
    selectedDate: maybeUpdateSelectedDateForLocale(data, locale),
  });
}

function maybeUpdateSelectedDateForLocale(data: StepData, newLocale: LocaleProps) {
  if (!data.selectedDate || !data.availability) {
    // No date is selected, so no need to update the selected date.
    return data.selectedDate;
  }

  const newAvailabilityBlocks = filterAvailabilityBlocksOnDay(
    data.availability,
    buildDateTimeFromCalendarDate(data.selectedDate, newLocale.timezone),
  );
  if (newAvailabilityBlocks.length > 0) {
    // A date is selected and that date still has availability with the same timezone.
    return data.selectedDate;
  }

  // Use the date of the first old availability block.
  const oldAvailabilityBlocks = filterAvailabilityBlocksOnDay(
    data.availability,
    buildDateTimeFromCalendarDate(data.selectedDate, data.locale.timezone),
  );
  const [availabilityBlock] = oldAvailabilityBlocks;
  if (!availabilityBlock) {
    reportToBugsnag(new Error('Selected date without availability'));
    return undefined;
  }

  return buildCalendarDateFromTimestamp(availabilityBlock.start, newLocale.timezone);
}
