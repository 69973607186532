import {findBrowserTimeZone} from '../../../../../libs/shared/core/src/helpers/datetime/dateTimeHelpers';
import {createMandatoryContext} from '../../../../../libs/shared/core/src/helpers/react/reactHelpers';
import {WeekdaysEnum} from '../../../helpers/weekday';

/*
 * Props.
 */

export interface LocaleProps {
  is24HourTime: boolean;
  timezone: string;
  weekStartDay: WeekdaysEnum;
}

/*
 * Constants.
 */

export const defaultLocale: LocaleProps = {
  is24HourTime: false,
  timezone: findBrowserTimeZone(),
  weekStartDay: WeekdaysEnum.SUNDAY,
};

/*
 * Context.
 */

export const [LocaleContext, useLocale] = createMandatoryContext<LocaleProps>();
