import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {globalDocument} from '../../libs/shared/core/src/helpers/browser/browserHelpers';
import {App} from './components/app';
import {Providers} from './components/providers';
import {setupBugsnag} from './helpers/bugsnag';

const container = globalDocument.getElementById('front-scheduling-root');

setupBugsnag();

createRoot(container!).render(
  <React.StrictMode>
    <BrowserRouter>
      <Providers>
        <App />
      </Providers>
    </BrowserRouter>
  </React.StrictMode>,
);
