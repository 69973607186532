import {abbreviations, getTimeZones, RawTimeZone, rawTimeZones} from '@vvo/tzdb';
import _, {flatten, memoize} from 'lodash';
import {DateTime, DateTimeFormatOptions, Duration, ToHumanDurationOptions} from 'luxon';

import {h12FormatOptions, h24FormatOptions} from '../internationalization/dateTimeHelpers';
import {Localizer} from '../internationalization/localizeTypes';

export function formatDateTime(
  localize: Localizer,
  dateTime: DateTime,
  dateFormat: Intl.DateTimeFormatOptions,
  timeFormat: TimeFormatsEnum,
) {
  const timeFormatOptions = build12Or24hOptionsForFormat(timeFormat);
  const finalFormatOptions = {
    ...dateFormat,
    ...timeFormatOptions,
  };

  return localize(dateTime, finalFormatOptions);
}

/*
 * Date helpers.
 */

export function formatDate(localize: Localizer, date?: DateTime, dateFormat?: Intl.DateTimeFormatOptions) {
  return (date && localize(date, dateFormat || DateTime.DATE_SHORT)) || '';
}

/*
 * Time helpers.
 */

export enum TimeFormatsEnum {
  H12 = '12h',
  H24 = '24h',
}

export function formatTime(localize: Localizer, timeFormat: TimeFormatsEnum, time?: DateTime) {
  const format = build12Or24hFullOptionsForFormat(timeFormat);

  return (time && localize(time, format)) || '';
}

export function areTimesEqual(time1: DateTime, time2?: DateTime) {
  return Boolean(time2 && time1.hour === time2.hour && time1.minute === time2.minute);
}

/*
 * Timezone helpers.
 */

export const patchDateTimeFormat = memoize(
  (format: DateTimeFormatOptions): DateTimeFormatOptions => ({
    ...format,
    timeZone: findBrowserTimeZone(),
  }),
);

const findMemoizedNamesSupportedByIntl: () => Set<string> = memoize(
  () => new Set(Intl.supportedValuesOf ? Intl.supportedValuesOf('timeZone') : []),
);

export const isSupportedTimeZoneName = memoize((timezoneName: string) => {
  const inIntlList = findMemoizedNamesSupportedByIntl().has(timezoneName);
  // This is a shortcut for _most_ "main" timezone names, but this will fail for "alias" names like `Europe/Kiev` being replaced by `Europe/Kyiv` but Intl only list `Europe/Kiev` as a supported value.
  if (inIntlList) {
    return true;
  }

  // We try instanciating an Intl.DateTimeFormat with the timezone, if we throw, it's not supported by the environment.
  try {
    // eslint-disable-next-line no-new
    new Intl.DateTimeFormat('en', {timeZone: timezoneName});
    return true;
  } catch (e) {
    return false;
  }
});

function isSupportedTimeZone(timezone: RawTimeZone) {
  return isSupportedTimeZoneName(timezone.name) || timezone.group.some((gz) => isSupportedTimeZoneName(gz));
}

export const FALLBACK_TIMEZONE_NAME = 'Etc/UTC';

// Calling getTimeZones is very expensive on mobile (>1s) so it is memoized and only called if absolutely necessary.
function listBrowserSupportedTimeZones() {
  return _(getTimeZones({includeUtc: true}))
    .filter(isSupportedTimeZone)
    .value();
}

export const memoizedListBrowserSupportedTimeZones = memoize(listBrowserSupportedTimeZones);

// Calling rawTimeZones is way more efficient than getTimeZones and should be favored if possible.
const memoizedIsValidBrowserTimeZoneName = memoize((timezoneName: string | undefined) => {
  const allTimeZonesNames = new Set(
    _(rawTimeZones)
      .flatMap((z) => [z.name, ...z.group])
      .value(),
  );
  if (!timezoneName) {
    return false;
  }
  return allTimeZonesNames.has(timezoneName) && isSupportedTimeZoneName(timezoneName);
});

/**
 * Determines the timezone used by the browser, using the Intl API and tzdb as a fallback source in case of browser bugs.
 * NOTE: this returns the zone that the *browser* uses, not the one the user has selected in their preferences. If you need the (Front) user's timezone, use the `getUserTimeZone` selector.
 * @returns A timezone name, like "Europe/Paris"
 */
export const findBrowserTimeZone = (): string => {
  // Grab the timezone from the Intl API first.
  // This can be undefined.
  const fromBrowser = new Intl.DateTimeFormat().resolvedOptions().timeZone as string | undefined;

  // Make sure the Intl API didn't send us a bogus value. Note, in this context something like `CET` is *NOT* a time zone name, it's an abbreviation.
  const isActuallyAZoneName = memoizedIsValidBrowserTimeZoneName(fromBrowser);
  if (isActuallyAZoneName && fromBrowser) {
    return fromBrowser;
  }

  // Try to find the proper timezone from the bogus/abbreviation value given by the browser as well as the current time offset in minutes.
  const fromAbbreviation = findZoneNameForAbbreviation(fromBrowser, new Date().getTimezoneOffset());

  return fromAbbreviation;
};

const invertedAbbreviations = _(abbreviations)
  .toPairs()
  .groupBy(([, value]) => value)
  .mapValues((value, key) => flatten(value).filter((v) => v !== key))
  .value();

/**
 * Tries to find the most likely zone name for a given abbreviation. This will ALWAYS be imprecise because abbreviations can match multiple zones. Use this only as a fallback.
 * @param abbr the abbreviation like "CET" or "PST"
 * @param offset the offset in minutes as given by `Date.getTimezoneOffset()`, will be used to more precisely find the zone name.
    Can be useful to differentiate "CST (China Standard Time)" from "CST (Central Standard Time)" for example.
 */
function findZoneNameForAbbreviation(abbr: string | undefined, offset: number): string {
  const longNames = (abbr && invertedAbbreviations[abbr]) || [];
  const maybeOffsetMatchFromName = abbr?.match(/Etc\/GMT([+-0-9.]+)/);
  const offsetFromMatch = maybeOffsetMatchFromName ? maybeOffsetMatchFromName[1] : undefined;
  const isPossibleMatch = (z: RawTimeZone) => {
    if (offsetFromMatch) {
      return z.rawOffsetInMinutes / 60 === Number(offsetFromMatch);
    }
    return Boolean(longNames.includes(z.alternativeName) || z.abbreviation === abbr);
  };

  try {
    const possibleMatches = memoizedListBrowserSupportedTimeZones().filter(isPossibleMatch);
    if (isNaN(offset)) {
      return possibleMatches[0]?.name ?? FALLBACK_TIMEZONE_NAME;
    }
    const morePreciseMatches = possibleMatches.filter((z) => z.currentTimeOffsetInMinutes === offset);
    return morePreciseMatches[0]?.name ?? possibleMatches[0]?.name ?? FALLBACK_TIMEZONE_NAME;
  } catch (e) {
    // getTimeZones() throws if Intl.DateTimeFormat returns a bogus timezone name, so we fallback to rawTimeZones.
    const baseFallbackMatches = _(rawTimeZones).filter(isSupportedTimeZone).filter(isPossibleMatch).value();

    // If `fallbackMatches` is empty, it can mean two things:
    // - the timezone name we received is invalid (unlikely but we never know)
    // - the timezone name we received is an empty value/undefined
    // So we need to consider all possible timezones and use the offset as a way to pick at least _one_ accurate zone.
    const fallbackMatches = baseFallbackMatches.length === 0 ? rawTimeZones : baseFallbackMatches;
    return fallbackMatches.find((z) => z.rawOffsetInMinutes === offset)?.name ?? FALLBACK_TIMEZONE_NAME;
  }
}

export function build12Or24hOptionsForFormat(timeFormat: TimeFormatsEnum) {
  return timeFormat === TimeFormatsEnum.H24 ? h24FormatOptions : h12FormatOptions;
}

export function build12Or24hFullOptionsForFormat(timeFormat: TimeFormatsEnum) {
  const shouldUse24h = timeFormat === TimeFormatsEnum.H24;
  const base = shouldUse24h ? DateTime.TIME_24_SIMPLE : DateTime.TIME_SIMPLE;
  const overrides = shouldUse24h ? h24FormatOptions : h12FormatOptions;

  return {
    ...base,
    ...overrides,
  };
}

/**
 * Handles converting a string into a valid timestamp. If it can be converted into a number, we will treat
 * that as the timestamp to return. If we convert to a number and it fails, we will try using the date object
 * to convert to a valid timestamp. If we fail to convert using the date, we will return the date value of
 * Wed Dec 31 1969, as that is the start of epoch timestamps.
 *
 * @param value Value to convert
 * @returns Timestamp
 */
export function getValidTimestampFromValue(value: string): number {
  // Try to convert it to a number
  const convertedValue = Number(value);
  if (!isNaN(convertedValue)) {
    return convertedValue;
  }

  // Try to convert it via the date object
  const dateTimeParsed = new Date(value).getTime();
  if (!isNaN(dateTimeParsed)) {
    return dateTimeParsed;
  }

  // Return the start of epoch
  return new Date(0).getTime();
}

export function formatDuration(value: Duration, options: ToHumanDurationOptions & {showZero?: boolean} = {}) {
  const {years, months, days, hours, minutes, seconds} = value.shiftTo(
    'years',
    'months',
    'days',
    'hours',
    'minutes',
    'seconds',
  );

  const {showZero, ...toHumanOptions} = options;

  return Duration.fromObject({
    years: years || (showZero ? 0 : undefined),
    months: months || (showZero ? 0 : undefined),
    days: days || (showZero ? 0 : undefined),
    hours: hours || (showZero ? 0 : undefined),
    minutes: minutes || (showZero ? 0 : undefined),
    seconds: seconds || (showZero ? 0 : undefined),
  }).toHuman(toHumanOptions);
}

/**
 * Set the timezone of the datetime if timezone is specified.
 * @param date The datetime
 * @param timezone The timezone
 */
export function withTimezone<T extends DateTime | undefined>(date: T, timezone?: string): T {
  if (!date || !timezone) {
    return date;
  }

  return date.setZone(timezone) as T;
}
