import React, {FC} from 'react';
import styled from 'styled-components';

import {AsyncStatusesEnum} from '../../../../../../libs/shared/core/src/helpers/types/asyncStatuses';
import {PaletteColorsEnum} from '../../../../../../libs/shared/core/src/models/api/paletteModel';
import {Button} from '../../../../../../libs/shared/ui/src/components/buttons/button';
import {ButtonVariantsEnum} from '../../../../../../libs/shared/ui/src/components/buttons/buttonConstants';
import {ChromelessButton} from '../../../../../../libs/shared/ui/src/components/buttons/chromelessButton';
import {Icon} from '../../../../../../libs/shared/ui/src/components/icon/icon';
import {Loader} from '../../../../../../libs/shared/ui/src/components/loaders/loader';
import {VisualSizesEnum} from '../../../../../../libs/shared/ui/src/styles/commonStyles';
import {roundedBorder} from '../../../../helpers/style';
import {Header} from '../../header';
import {useTheme} from '../../theme/themeContext';
import {StepError} from '../error/stepError';
import {StepFrameDots} from './stepFrameDots';
import {StepFrameProps} from './stepFrameProps';

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  display: grid;
  grid-template-areas:
    'header'
    'content'
    'footer';
  grid-template-rows: auto 1fr auto;
  width: 100%;
  /* Prevent view from stretching past address bar or bottom bar on mobile. */
  height: -webkit-fill-available;
  max-height: 100vh;
`;

const StyledHeaderDiv = styled.div`
  grid-area: header;
  height: 60px;
`;

const StyledContentDiv = styled.div`
  grid-area: content;
  overflow-y: scroll;

  /* Expand bounds so scrollbars render at the edge of the screen. */
  margin: 0 -16px;
  padding: 0 16px;
`;

const StyledFooterDiv = styled.div`
  grid-area: footer;
  margin-top: 10px;
`;

const StyledLoaderRow = styled.div`
  display: flex;
  height: 46px;
  margin: 0px 0px 10px;

  justify-content: center;
  align-items: center;
`;

const StyledCustomFooterRow = styled.div`
  margin: 0px 0px 10px;
`;

const StyledNextButton = styled(Button)`
  margin: 0px 0px 10px;

  & > div {
    /* Set the flex direction to column of the internal StyledButtonDiv so this button occupies full width. */
    flex-direction: column;
  }
`;

const StyledPreviousButton = styled(ChromelessButton)`
  margin: 20px 0px;
`;

const StyledSidebarDiv = styled.div`
  margin-top: 24px;
  padding: 20px;
  width: 100%;
  ${roundedBorder()};
`;

const StyledErrorDiv = styled.div`
  position: relative;
`;

/*
 * Component.
 */

export const StepFrameMobile: FC<StepFrameProps> = (props) => {
  const {title, children} = props;
  const {color} = useTheme();

  return (
    <StyledWrapperDiv>
      <StyledHeaderDiv>{renderPreviousButton(props)}</StyledHeaderDiv>
      <StyledContentDiv>
        <Header>{title}</Header>
        {children}
        {maybeRenderSidebar(props)}
      </StyledContentDiv>
      <StyledFooterDiv>
        {maybeRenderFooter(props)}
        {maybeRenderError(props)}
        {renderNextButton(props, color)}
        {renderDots(props)}
      </StyledFooterDiv>
    </StyledWrapperDiv>
  );
};

/*
 * Helpers.
 */

function renderPreviousButton(props: StepFrameProps) {
  const {onPrevious} = props;

  if (!onPrevious) {
    return null;
  }

  return (
    <StyledPreviousButton onClick={() => onPrevious()}>
      <Icon name="arrowBack" />
    </StyledPreviousButton>
  );
}

function renderNextButton(props: StepFrameProps, color: PaletteColorsEnum) {
  const {nextButton, metadata} = props;
  const {asyncStatus} = metadata;

  if (asyncStatus === AsyncStatusesEnum.LOADING) {
    return (
      <StyledLoaderRow>
        <Loader size={VisualSizesEnum.MEDIUM} />
      </StyledLoaderRow>
    );
  }

  if (!nextButton) {
    return null;
  }

  const {title, onClick} = nextButton;
  return (
    <StyledNextButton
      variant={ButtonVariantsEnum.CUSTOM_DISABLED}
      isRounded
      size={VisualSizesEnum.LARGE}
      onClick={onClick}
      isDisabled={!onClick}
      color={color}
    >
      {title}
    </StyledNextButton>
  );
}

function maybeRenderSidebar(props: StepFrameProps) {
  const {renderSidebarInlineOnMobile, renderSidebar} = props;

  if (!renderSidebarInlineOnMobile || !renderSidebar) {
    return null;
  }

  return <StyledSidebarDiv>{renderSidebar()}</StyledSidebarDiv>;
}

function maybeRenderFooter(props: StepFrameProps) {
  const {renderFooter} = props;

  if (!renderFooter) {
    return null;
  }

  return <StyledCustomFooterRow>{renderFooter()}</StyledCustomFooterRow>;
}

function maybeRenderError(props: StepFrameProps) {
  const {error, onDismissError} = props.metadata;
  if (!error) {
    return null;
  }

  return (
    <StyledErrorDiv>
      <StepError error={error} onDismiss={onDismissError} />
    </StyledErrorDiv>
  );
}

function renderDots(props: StepFrameProps) {
  const {numSteps, stepIndex} = props.metadata;
  return <StepFrameDots numSteps={numSteps} stepIndex={stepIndex} />;
}
