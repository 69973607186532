import React, {memo, ReactNode} from 'react';
import styled from 'styled-components';

import {fontSizes, fontWeights} from '../../../styles/fontStyles';
import {ellipsis} from '../../../styles/mixins';
import {DropdownItem, DropdownItemProps} from './dropdownItem';
import {DropdownItemContent} from './dropdownItemContent';

/*
 * Props.
 */

interface TwoLinesDropdownItemProps extends DropdownItemProps {
  title: ReactNode;
  description: ReactNode;
}

/*
 * Style.
 */

const StyledTextDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
`;

interface TitleStyleProps {
  $isDisabled?: boolean;
}
const StyledTitleDiv = styled.div<TitleStyleProps>`
  ${ellipsis()};
  ${(p) => p.$isDisabled && `color: ${p.theme.greys.shade60};`};
`;

const StyledDescriptionDiv = styled.div`
  color: ${(p) => p.theme.greys.shade60};
  font-size: ${fontSizes.small};
  font-weight: ${fontWeights.normal};
  ${ellipsis()};
`;

/*
 * Component.
 */

export const DropdownItemTwoLines = memo<TwoLinesDropdownItemProps>((props) => (
  <DropdownItem {...props}>
    <DropdownItemContent {...props} renderTooltip={undefined}>
      <StyledTextDiv>
        <StyledTitleDiv $isDisabled={props.isDisabled}>{props.title}</StyledTitleDiv>
        <StyledDescriptionDiv>{props.description}</StyledDescriptionDiv>
      </StyledTextDiv>
    </DropdownItemContent>
  </DropdownItem>
));
