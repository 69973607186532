import React, {FC} from 'react';

import {postSchedulingApiAsync} from '../../../helpers/schedulingHttp';
import {randomString} from '../../../helpers/string';
import {ApiSchedulingLinkBooking} from '../../../models/schedulingLinkBookingModel';
import {serializeSchedulingLinkQuestionAnswer} from '../../../models/schedulingLinkQuestionAnswerModel';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepData, StepProps} from '../../common/step/step';
import {ConfirmedBookingSummary} from '../../common/summary/confirmedBookingSummary';
import {BookStepTypesEnum} from '../bookStep';

/*
 * Component.
 */

export const BookStepViewConfirmation: FC<StepProps> = (props) => (
  <StepFrame
    metadata={props.metadata}
    onPrevious={props.onPrevious}
    title={
      <>
        All set{' '}
        <span role="img" aria-label="thumbs up">
          👍
        </span>
      </>
    }
  >
    A meeting invite has been sent to all participants.
    <ConfirmedBookingSummary booking={props.booking} />
  </StepFrame>
);

async function preStepAction(data: StepData) {
  // Use the step data to actually create the booking before dislaying this page.
  const {schedulingLink, booking} = data;
  const {name, email, start, questionAnswers} = booking;
  const path = `${schedulingLink.schedulingUrl}/book`;
  if (!name || !email || !start) {
    throw new Error('Missing required booking fields.');
  }

  const body = {
    uid: randomString(),
    name,
    email,
    start,
    question_answers: questionAnswers?.map(serializeSchedulingLinkQuestionAnswer) || [],
  };
  const serverBooking = await postSchedulingApiAsync(ApiSchedulingLinkBooking, {
    path,
    body,
  });
  return {
    ...data,
    booking: serverBooking,
  };
}

/*
 * Step.
 */

export const bookStepViewConfirmation: Step<BookStepTypesEnum> = {
  type: BookStepTypesEnum.VIEW_CONFIRMATION,
  renderStep: (props) => React.createElement(BookStepViewConfirmation, props),
  preStepAction,
};
