import * as t from 'io-ts';

import {makeApiType} from '../helpers/apiType';
import {isNonEmptyArray, NonEmptyArray} from '../helpers/types';

/*
 * Runtime type.
 */

const RSchedulingLinkAvailabilityBlock = t.readonly(
  t.type({
    start: t.number,
    end: t.number,
  }),
);

export const RSchedulingLinkAvailability = t.readonly(
  t.type({
    durationMinutes: t.number,
    timeSlots: t.readonlyArray(RSchedulingLinkAvailabilityBlock),
  }),
);

/*
 * Type.
 */

export interface SchedulingLinkAvailabilityBlockModel
  extends t.TypeOf<typeof RSchedulingLinkAvailabilityBlock> {}
export interface SchedulingLinkAvailabilityModel extends t.TypeOf<typeof RSchedulingLinkAvailability> {}

/*
 * IO methods.
 */

export function parseSchedulingLinkAvailability(src: any): SchedulingLinkAvailabilityModel {
  return {
    durationMinutes: src.duration_minutes,
    timeSlots: src.time_slots.map(parseSchedulingLinkAvailabilityBlock),
  };
}

export function parseSchedulingLinkAvailabilityBlock(src: any): SchedulingLinkAvailabilityBlockModel {
  return {
    start: src.start,
    end: src.end,
  };
}

/*
 * API type.
 */

export const ApiSchedulingLinkAvailability = makeApiType(
  'schedulingLinkAvailability',
  RSchedulingLinkAvailability,
  parseSchedulingLinkAvailability,
);

/*
 * Helpers.
 */

export interface SchedulingLinkNonEmptyAvailabilityModel extends SchedulingLinkAvailabilityModel {
  timeSlots: NonEmptyArray<SchedulingLinkAvailabilityBlockModel>;
}

export function isNonEmptyAvailability(
  availability: SchedulingLinkAvailabilityModel,
): availability is SchedulingLinkNonEmptyAvailabilityModel {
  return isNonEmptyArray(availability.timeSlots);
}
