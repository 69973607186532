import React, {FC} from 'react';

import {BannerError} from '../../error/bannerError';

/*
 * Errors.
 */

export class StepLostAvailabilityError extends Error {
  constructor() {
    super('The slot is no longer available.');
  }
}

/*
 * Props.
 */

export interface StepErrorProps {
  error: Error;
  onDismiss: () => void;
}

/*
 * Component.
 */

export const StepError: FC<StepErrorProps> = (props) => (
  <BannerError description={renderErrorDescription(props.error)} onDismiss={props.onDismiss} />
);

/*
 * Helpers.
 */

export function renderErrorDescription(error: Error) {
  if (error instanceof StepLostAvailabilityError) {
    return 'The slot is no longer available. Please pick a new time.';
  }

  return 'Something went wrong. Please try again.';
}
