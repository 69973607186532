import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

import {CalendarDate} from '../../../helpers/date';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepProps} from '../../common/step/step';
import {AvailabilityDatePicker} from '../../datePicker/availabilityDatePicker';
import {EditStepTypesEnum} from '../editStep';

/*
 * Style.
 */

const StyledContentDiv = styled.div`
  padding: 8px 0px;
`;

/*
 * Component.
 */

export const EditStepChooseDate: FC<StepProps> = (props) => {
  const {onNext, metadata, availability} = props;

  const onSelectDate = useCallback(
    (selectedDate: CalendarDate) => {
      if (!onNext) {
        return;
      }

      onNext((data) => ({
        ...data,
        selectedDate,
      }));
    },
    [onNext],
  );

  if (!availability) {
    return null;
  }

  return (
    <StepFrame title="Pick a new date and time" metadata={metadata}>
      <StyledContentDiv>
        <AvailabilityDatePicker
          schedulingLink={props.schedulingLink}
          selectedDate={props.selectedDate}
          onSelectDate={onSelectDate}
          availability={availability}
        />
      </StyledContentDiv>
    </StepFrame>
  );
};

/*
 * Step.
 */

export const editStepChooseDate: Step<EditStepTypesEnum> = {
  type: EditStepTypesEnum.CHOOSE_DATE,
  renderStep: (props) => React.createElement(EditStepChooseDate, props),
};
