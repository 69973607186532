import elementResizeDetectorMaker from 'element-resize-detector';
import React, {
  ComponentType,
  createElement,
  CSSProperties,
  FC,
  PropsWithChildren,
  RefObject,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {distinctUntilChanged, Observable, of, OperatorFunction, Subject, switchMap} from 'rxjs';

import {useInitialLayoutEffect} from '../../../core/src/helpers/react/hooks/useEffectHelpers';
import {useEventHandler} from '../../../core/src/helpers/react/hooks/useEventHandler';

/*
 * Types.
 */

export interface Size {
  width: number;
  height: number;
}

/*
 * Constants.
 */

const undefinedSize: Partial<Size> = Object.freeze({
  width: undefined,
  height: undefined,
});

/*
 * Hooks.
 */

/** Returns the content size of the given element. The width/height will be undefined on the initial render. */
export function useContentSize(ref: RefObject<HTMLElement>): Partial<Size> {
  const [contentSize, setContentSize] = useState(undefinedSize);
  useResizeEffect(ref, setContentSize);
  return contentSize;
}

/** Calls a function when an element's content size changes. */
function useResizeEffect(
  elementRef: RefObject<HTMLElement>,
  callback: (contentSize: Partial<Size>) => void,
): void {
  const [element$] = useState(() => new Subject<HTMLElement | null>());
  const contentSize$ = useMemo(() => element$.pipe(elementContentSize()), [element$]);
  const onContentSizeChange = useEventHandler(callback);

  // Subscribe to content size changes before feeding the element into the stream.
  useLayoutEffect(() => {
    const subscription = contentSize$.subscribe(onContentSizeChange);
    return () => subscription.unsubscribe();
  }, [contentSize$, onContentSizeChange]);

  // Feed the element into the stream.
  useLayoutEffect(() => {
    element$.next(elementRef.current);
  });
}

/** Operator that turns an observable of elements into an observable of element content sizes. */
function elementContentSize(): OperatorFunction<HTMLElement | null, Partial<Size>> {
  return (element$) =>
    element$.pipe(
      // Recreate the resize observer only when the element changes.
      distinctUntilChanged(),
      switchMap(fromResizeObserver),
      // Emit only when the size changes.
      distinctUntilChanged((sizeA, sizeB) => sizeA.width === sizeB.width && sizeA.height === sizeB.height),
    );
}

/** Creates an observable of content sizes for the given element. */
function fromResizeObserver(element: HTMLElement | null): Observable<Partial<Size>> {
  const window = element?.ownerDocument.defaultView;

  // If anything is missing or ResizeObserver is not supported, just emit an undefined width/height.
  if (!element || !window || !window.ResizeObserver) {
    return of({width: undefined, height: undefined});
  }

  return new Observable<Size>((subscriber) => {
    const next = () => {
      subscriber.next({
        width: element.clientWidth,
        height: element.clientHeight,
      });
    };

    // Use the instance of ResizeObserver corresponding to the element's window.
    const resizeObserver = new window.ResizeObserver(next);
    resizeObserver.observe(element);

    // Emit when subscribed to.
    next();

    return () => {
      resizeObserver.disconnect();
    };
  });
}

const ERD = elementResizeDetectorMaker({
  strategy: 'scroll',
  debug: true,
  // These empty functions are needed so this doesn't log debug information to the console.
  reporter: {
    log: () => {},
    warn: () => {},
    error: () => {},
  },
});

/**
 * @deprecated Use `useContentSize` instead.
 *
 * BEWARE: This hook adds `position: relative` to your element.
 */
export function useSize(wrapperRef: RefObject<HTMLElement>) {
  const [size, setSize] = useState<Size>();

  useSizeListener({
    wrapperRef,
    listener: setSize,
    shouldReportInitialSize: true,
  });

  return size;
}

interface SizeListenerOptions {
  wrapperRef: RefObject<HTMLElement>;
  listener: (size: Size) => void;
  shouldReportInitialSize?: boolean;
}

/**
 * Observes size changes on `options.ref` and calls `options.listener` when height/width changes.
 *
 * NOTE: This hook will NOT pick up element changes (i.e the ref being replaced by another DOM element).
 */
function useSizeListener(options: SizeListenerOptions) {
  // Always maintain a reference to the latest version of the function.
  const listenerRef = useRef(options.listener);
  listenerRef.current = options.listener;

  useInitialLayoutEffect(() => {
    const element = options.wrapperRef.current;
    if (!element) {
      return undefined;
    }

    let prevSize: Size | undefined;

    const maybeNotifyListener = () => {
      const {width, height} = element.getBoundingClientRect();
      const size = {width, height};

      if (prevSize && size.width === prevSize.width && prevSize.height === size.height) {
        return;
      }

      prevSize = size;

      const listener = listenerRef.current;
      listener(size);
    };

    // Synchronously notify the listener about our initial size.
    if (options.shouldReportInitialSize) {
      maybeNotifyListener();
    }

    // Notify the listener for future size changes.
    ERD.listenTo(element, maybeNotifyListener);

    return () => {
      ERD.uninstall(element);
    };
  });
}

/*
 * Component.
 */

interface SizeListenerProps {
  onSize: (size: Size) => void;
  style?: CSSProperties;
  className?: string;
}

export const SizeListener: FC<PropsWithChildren<SizeListenerProps>> = ({
  onSize,
  children,
  className,
  style,
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useSizeListener({
    wrapperRef,
    listener: onSize,
  });

  return (
    <div ref={wrapperRef} className={className} style={style}>
      {children}
    </div>
  );
};

/*
 * HOC.
 */

export interface WithSizeProps {
  wrapperRef: RefObject<HTMLDivElement>;
  size?: Size;
}

export function withSize<T extends WithSizeProps>(
  Component: ComponentType<PropsWithChildren<T>>,
): ComponentType<PropsWithChildren<Omit<T, keyof WithSizeProps>>> {
  return function WithSize(props) {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState<Size>();

    useSizeListener({
      wrapperRef,
      listener: setSize,
    });

    return createElement(Component, {
      ...props,
      wrapperRef,
      size,
    } as T);
  };
}
