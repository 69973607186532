import React, {FC, PropsWithChildren} from 'react';

import {BugsnagSeveritiesEnum} from '../../../../libs/shared/core/src/helpers/bugsnag/bugsnagSeverities';
import {ErrorReporterContext} from '../../../../libs/shared/ui/src/errorReporter/errorReporterContext';
import {reportToBugsnag} from '../../helpers/bugsnag';

const contextValue = {
  reportError(error: Error, severity?: BugsnagSeveritiesEnum) {
    reportToBugsnag(error, severity);
  },
};

export const ErrorReporterProvider: FC<PropsWithChildren<{}>> = ({children}) => (
  <ErrorReporterContext.Provider value={contextValue}>{children}</ErrorReporterContext.Provider>
);
