import {ColorBlendColor, normal} from 'color-blend';
import * as colorDiff from 'color-diff';
import _, {isEqual} from 'lodash';
import {parseToHsl, parseToRgb, rgba} from 'polished';
import {RgbaColor, RgbColor} from 'polished/lib/types/color';

import {paletteColors, PaletteColorsEnum} from '../api/paletteModel';
import {makeEnumTypeGuard} from '../api/types/enumTypes';

/** Blends the background color with the foreground color. An equivalent to the `blend()` method of Stylus. */
export function blendColors(background: string, foreground: string) {
  const parsedBackground = polishedToColorBlend(parseToRgb(background));
  const parsedForeground = polishedToColorBlend(parseToRgb(foreground));

  return rgba(colorBlendToPolished(normal(parsedBackground, parsedForeground)));
}

function polishedToColorBlend(color: RgbColor | RgbaColor): ColorBlendColor {
  return {
    r: color.red,
    g: color.green,
    b: color.blue,
    a: isRgbaColor(color) ? color.alpha : 1,
  };
}

function colorBlendToPolished(color: ColorBlendColor): RgbaColor {
  return {
    red: color.r,
    green: color.g,
    blue: color.b,
    alpha: color.a,
  };
}

export function isRgbaColor(src: RgbColor | RgbaColor): src is RgbaColor {
  return 'alpha' in src;
}

function toRgbaColor(color: RgbColor | RgbaColor): RgbaColor {
  return isRgbaColor(color) ? color : {...color, alpha: 1};
}

/** Try to parse a color. Return undefined if we fail. */
export function tryParseColor(src?: string | null): RgbColor | RgbaColor | undefined {
  if (!src) {
    return undefined;
  }

  try {
    return parseToRgb(src);
  } catch {
    return undefined;
  }
}

export function colorIsEqual(color1: RgbColor | RgbaColor, color2: RgbColor | RgbaColor) {
  return isEqual(toRgbaColor(color1), toRgbaColor(color2));
}

export const isPaletteColor = makeEnumTypeGuard(PaletteColorsEnum);

export function colorForInteger(src: number) {
  return paletteColors[src % paletteColors.length];
}

/**
 * Excludes yellow, blue, teal, and grey because they blend in against a light blue background.
 */
const companyPaletteColors = _(paletteColors)
  .without(PaletteColorsEnum.YELLOW, PaletteColorsEnum.BLUE, PaletteColorsEnum.TEAL, PaletteColorsEnum.GREY)
  .value();
export function colorForCompanyId(src: number) {
  const finalColor = companyPaletteColors[src % companyPaletteColors.length];

  return finalColor;
}

/**
 * Calculates the CIEDE2000 distance between the given colors.
 *
 * @returns number
 *   A distance between 0 and 100 inclusive.
 */
export function compareColors(colorA: RgbColor, colorB: RgbColor): number {
  return colorDiff.diff(colorToLabColor(colorA), colorToLabColor(colorB));
}

function colorToLabColor(color: RgbColor): colorDiff.LabColor {
  return colorDiff.rgb_to_lab({
    R: color.red,
    G: color.green,
    B: color.blue,
  });
}

export const parseToHsla = (color: string) => {
  const result = parseToHsl(color);
  return 'alpha' in result ? result : {...result, alpha: 1};
};
