import {useContext, useLayoutEffect} from 'react';

import {SvgContentLoader, SvgManagerContext} from './svgManagerContext';

/*
 * Hooks.
 */

/** Adds shared SVG content to the current document, for use with a <use link="#id" /> element. */
export function useSvgContent(id: string, loadContent: SvgContentLoader) {
  const context = useContext(SvgManagerContext);

  useLayoutEffect(() => {
    if (!context.canAddSvgContent) {
      return;
    }

    context.addSvgContent(id, loadContent);
  });

  return context.canAddSvgContent;
}
