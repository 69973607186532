import styled, {css} from 'styled-components';

import {greys} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {DeviceTypesEnum, PlatformProps, withPlatform} from '../platform/platformContext';

/*
 * Style.
 */

export const StyledHeader = styled.h1<PlatformProps>`
  color: ${greys.shade80};
  font-size: 24px;
  ${(props) => platformStyle(props)}
`;

function platformStyle(props: PlatformProps) {
  if (props.device === DeviceTypesEnum.DESKTOP) {
    return css`
      font-weight: ${fontWeights.semibold};
    `;
  }

  return css`
    font-weight: ${fontWeights.bold};
  `;
}

/*
 * Component.
 */

export const Header = withPlatform(StyledHeader);
