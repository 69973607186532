/*
 * Types.
 */

export enum BookStepTypesEnum {
  CHOOSE_SLOT = 0,
  CHOOSE_DATE = 1,
  CHOOSE_TIME = 2,
  ANSWER_QUESTIONS = 3,
  VIEW_CONFIRMATION = 4,
}
