import {pick} from 'lodash';

import {lightColors} from '../light/lightColors';
import {lightLegacySemanticStyles} from '../light/lightLegacySemanticStyles';
import {lightSemanticStyles} from '../light/lightSemanticStyles';
import {themeConstants} from '../themeConstants';
import {ThemeTypesEnum, UserTheme} from '../themeTypes';
import {darkColors} from './darkColors';
import {darkLegacySemanticStyleOverrides, darkLegacySemanticStyles} from './darkLegacySemanticStyles';
import {darkSemanticStyles} from './darkSemanticStyles';

// By default, the dark theme still uses the light palette's colors since
// it was originally implemented in that context.
export const legacyDarkTheme: UserTheme = {
  ...darkLegacySemanticStyles,
  ...lightSemanticStyles,
  ...lightColors,
  ...pick(lightLegacySemanticStyles, ['scrollbarTrack', 'scrollbarThumb', 'scrollbarThumbHover']),
  constants: themeConstants,
  type: ThemeTypesEnum.DARK,
};

// Expanded P2/P3 dark mode styles, including the new dark palette swap.
export const expandedDarkTheme: UserTheme = {
  ...darkLegacySemanticStyles,
  ...darkLegacySemanticStyleOverrides,
  ...darkSemanticStyles,
  ...darkColors,
  constants: themeConstants,
  type: ThemeTypesEnum.EXPANDED_DARK,
};

export const darkSidebarTheme: UserTheme = {
  ...expandedDarkTheme,
  type: ThemeTypesEnum.DARK_SIDEBAR,
};
