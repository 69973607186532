import React, {forwardRef, ReactNode} from 'react';

import {
  AccessibleButtonBaseProps,
  buildAccessibleButtonProps,
} from '../../../../core/src/helpers/accessibility/accessibilityHelpers';
import {buildHoverParentClassName} from '../../../../core/src/helpers/browser/hoverHelpers';
import {
  buildInteractionIdProps,
  InteractionComponentProps,
} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {InteractionTypesEnum} from '../../../../core/src/helpers/interaction/interactionTypes';
import {
  ClickEventHandler,
  DragEventHandler,
  FocusEventHandler,
  FrontClickEvent,
  FrontMouseEvent,
  MouseEventHandler,
} from '../../../../core/src/helpers/react/reactHelpers';
import {useLegacyInteractionContext} from '../../interactionTracking/interactionContext';
import {useInteractionTracking} from '../../interactionTracking/interactionTrackingContext';

/*
 * Props.
 */

export interface ChromelessButtonProps extends AccessibleButtonBaseProps, InteractionComponentProps {
  /** Called whenever the button is clicked. */
  onClick?: ClickEventHandler;
  /** Called whenever the button is double-clicked. */
  onDoubleClick?: MouseEventHandler;
  /** Called whenever the mouse is clicked down. */
  onMouseDown?: MouseEventHandler;
  /** Called whenever the mouse button is released. */
  onMouseUp?: MouseEventHandler;
  /** Called whenever the mouse enters the bounds of the button. */
  onMouseEnter?: MouseEventHandler;
  /** Called whenever the mouse leaves the bounds of the button. */
  onMouseLeave?: MouseEventHandler;
  /** Called whenever a dragged element enters a valid drop target. */
  onDragEnter?: DragEventHandler;
  onFocus?: FocusEventHandler;
  onBlur?: FocusEventHandler;
  /** Allow customization. */
  className?: string;
  /** Whether to simulate the Hover state. */
  hasHover?: boolean;
  /** If this button is a toggle, whether it is pressed (on). For accessibility only. */
  isActive?: boolean;
  /** Whether to render a disabled state. */
  isDisabled?: boolean;
  /** Whether to prevent default behavior on mousedown, prevents the button from stealing focus. */
  shouldPreventMouseDown?: boolean;
  /** If the button has a popover, whether it is open. For accessibility only. */
  isOpen?: boolean;
  children?: ReactNode;
}

/*
 * Component.
 */

export const ChromelessButton = forwardRef<HTMLDivElement, ChromelessButtonProps>((props, ref) => {
  const {isDisabled, hasHover, className} = props;
  const hoverClassName = (!isDisabled && buildHoverParentClassName(hasHover, className)) || className;

  const interactionContext = useLegacyInteractionContext();
  const {trackComponentInteraction} = useInteractionTracking();

  function onClick(event: FrontClickEvent) {
    if (props.isDisabled) {
      return;
    }

    if (props.onClick) {
      props.onClick(event);
    }

    trackComponentInteraction(InteractionTypesEnum.CLICK, interactionContext, props, event);
  }

  function onDoubleClick(event: FrontMouseEvent) {
    if (props.isDisabled) {
      return;
    }

    if (props.onDoubleClick) {
      props.onDoubleClick(event);
    }
  }

  /** If needed, prevent mouseDown events from doing anything. */
  function onMouseDown(event: FrontMouseEvent) {
    if (props.shouldPreventMouseDown) {
      event.preventDefault();
    }

    if (props.onMouseDown) {
      props.onMouseDown(event);
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      {...buildAccessibleButtonProps({...props, onClick})}
      ref={ref}
      className={hoverClassName}
      onMouseDown={onMouseDown}
      onMouseUp={props.onMouseUp}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      onDragEnter={props.onDragEnter}
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      onDoubleClick={onDoubleClick}
      {...buildInteractionIdProps(props.interactionId)}
    >
      {props.children}
    </div>
  );
});
