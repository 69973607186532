import {createContext, useContext} from 'react';

import {LegacyInteractionContextValue} from '../../../core/src/helpers/interaction/interactionHelpers';

/*
 * Context.
 */

export const InteractionContext = createContext<LegacyInteractionContextValue>({
  parentInteractionIds: [],
});

/*
 * Hook.
 */

export function useLegacyInteractionContext(): LegacyInteractionContextValue {
  return useContext(InteractionContext);
}
