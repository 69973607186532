import {DateTime} from 'luxon';

/*
 * Types.
 */

export interface CalendarMonth {
  year: number;
  month: number;
}

export interface CalendarDate extends CalendarMonth {
  day: number;
}

/*
 * Api.
 */

export function buildDateTimeFromCalendarDate(date: CalendarDate, zone?: string) {
  const zoneOptions = zone ? {zone} : {};
  return DateTime.fromObject(date, zoneOptions);
}

export function buildDateTimeFromCalendarMonth(month: CalendarMonth, zone?: string) {
  return buildDateTimeFromCalendarDate({...month, day: 1}, zone);
}

export function buildCalendarDateFromDateTime(datetime: DateTime<true>) {
  return {
    year: datetime.year,
    month: datetime.month,
    day: datetime.day,
  };
}

export function buildCalendarDateFromTimestamp(ts: number, zone?: string) {
  const datetime = DateTime.fromMillis(ts);
  const datetimeWithTimezone = zone ? datetime.setZone(zone) : datetime;
  return buildCalendarDateFromDateTime(datetimeWithTimezone as DateTime<true>);
}

export function areCalendarDatesEqual(date1: CalendarDate, date2: CalendarDate) {
  return buildDateTimeFromCalendarDate(date1).equals(buildDateTimeFromCalendarDate(date2));
}

export function buildCalendarMonthFromCalendarDate(date: CalendarDate): CalendarMonth {
  const {year, month} = date;
  return {
    year,
    month,
  };
}

export function buildCalendarMonthFromDateTime(datetime: DateTime): CalendarMonth {
  return {
    year: datetime.year,
    month: datetime.month,
  };
}
