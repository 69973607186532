import React, {FC} from 'react';
import styled from 'styled-components';

import {SchedulingLinkAvailabilityBlockModel} from '../../models/schedulingLinkAvailabilityModel';
import {TimePickerListItem} from './timePickerListItem';

/*
 * Props.
 */

export interface TimePickerListProps {
  availabilityBlocks: ReadonlyArray<SchedulingLinkAvailabilityBlockModel>;
  selectedTimeSlot: SchedulingLinkAvailabilityBlockModel | undefined;
  setSelectedTimeSlot: (model: SchedulingLinkAvailabilityBlockModel) => void;
}

/*
 * Style.
 */

const StyledDiv = styled.div`
  margin: 5px 0px;
  overflow-y: scroll;
`;

/*
 * Component.
 */

export const TimePickerList: FC<TimePickerListProps> = (props) => (
  <StyledDiv>
    {props.availabilityBlocks.map((block) => {
      const isSelected = block === props.selectedTimeSlot;
      const onClick = isSelected ? undefined : () => props.setSelectedTimeSlot(block);
      return (
        <TimePickerListItem
          availabilityBlock={block}
          key={block.start}
          isSelected={isSelected}
          onClick={onClick}
        />
      );
    })}
  </StyledDiv>
);
