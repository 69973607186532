import React from 'react';
import styled from 'styled-components';

import {fontSizes, fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {CalendarTitle} from './CalendarTitle';

/*
 * Style.
 */

const StyledErrorContainerDiv = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
`;

const StyledErrorImg = styled.img`
  @media screen and (max-width: 650px) {
    width: 90%;
  }
  padding-left: 32px;
  width: 180px;
`;

const StyledErrorDiv = styled.div`
  font-size: ${fontSizes.medium};
  line-height: 21px;
  font-weight: ${fontWeights.medium};
`;

/*
 * Component.
 */

export const CalendarError = () => (
  <StyledErrorContainerDiv>
    <div>
      <CalendarTitle title="Oops!" />
      <StyledErrorDiv>This event is no longer available.</StyledErrorDiv>
    </div>
    <StyledErrorImg src="https://frontapp.com/extra/mails/calendar/calendar_error.png" alt="Error" />
  </StyledErrorContainerDiv>
);
