import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';

import {roundedBorder} from '../../../helpers/style';

/*
 * Props.
 */

interface DateTimePickerLayoutProps {
  datePicker: ReactNode;
  timePicker: ReactNode;
  localeSettings: ReactNode;
}

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  display: grid;
  grid-template-areas:
    'datepicker timepicker'
    'datepicker localesettings';
  grid-template-rows: 1fr auto;

  padding: 8px;
  ${roundedBorder()};
`;

const StyledDateWrapperDiv = styled.div`
  grid-area: datepicker;
  margin: 0px 9px 6px 8px;
`;

const StyledTimeWrapperDiv = styled.div`
  grid-area: timepicker;
  margin: 10px 8px 0px 8px;
  width: 260px;
  position: relative;
`;

const StyledTimeInnerWrapperDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const StyledLocaleWrapperDiv = styled.div`
  grid-area: localesettings;
  margin: 0px 8px 0px 8px;
`;

/*
 * Component.
 */

export const DateTimePickerLayout: FC<DateTimePickerLayoutProps> = (props) => (
  <StyledWrapperDiv>
    <StyledDateWrapperDiv>{props.datePicker}</StyledDateWrapperDiv>
    <StyledTimeWrapperDiv>
      <StyledTimeInnerWrapperDiv>{props.timePicker}</StyledTimeInnerWrapperDiv>
    </StyledTimeWrapperDiv>
    <StyledLocaleWrapperDiv>{props.localeSettings}</StyledLocaleWrapperDiv>
  </StyledWrapperDiv>
);
