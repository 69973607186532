import React, {FC, PropsWithChildren} from 'react';

import {DropdownItem, DropdownItemProps} from '../items/dropdownItem';
import {DropdownItemContent} from '../items/dropdownItemContent';

/*
 * Component.
 */

export const SimpleDropdownItem: FC<PropsWithChildren<DropdownItemProps>> = (props) => (
  <DropdownItem {...props}>
    <DropdownItemContent isChecked={props.isChecked}>{props.children}</DropdownItemContent>
  </DropdownItem>
);
