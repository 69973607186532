import {map} from 'lodash';
import React, {forwardRef, ReactNode} from 'react';
import styled from 'styled-components';

import {Trans} from '../../../../core/src/helpers/internationalization/react';
import {ellipsis} from '../../styles/mixins';
import {IconButton} from '../buttons/iconButton';
import {IconName} from '../icon/icon';
import {BoxedInput, BoxedInputInheritableProps} from './boxedInput';
import {BoxedInputChildProps} from './boxedInputConstants';
import {BoxedInputIcon} from './boxedInputIcon';
import {BoxedInputStatic} from './boxedInputStatic';

/*
 * Props.
 */

export interface DropdownBoxProps extends Omit<BoxedInputInheritableProps, 'boxRef'> {
  possibleValues?: ReadonlyArray<ReactNode>;
  value?: ReactNode;
  leftIcon?: IconName;
  leftIconColor?: string;
  chevronColor?: string;
  noInnerMargin?: boolean;
  noInputMarginRight?: boolean;
  noInputMarginLeft?: boolean;
  onRemove?: () => void;
  /** Label override for remove button. */
  removeLabel?: ReactNode;
}

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  display: grid;
`;

const StyledValueDiv = styled.div`
  grid-column: 1;
  grid-row: 1;

  ${ellipsis()};
`;

const StyledPossibleDiv = styled(StyledValueDiv)`
  opacity: 0;
`;

const StyledRemoveButton = styled(IconButton)`
  padding: 7px;
`;

const StyledLeftBoxedInputIcon = styled(BoxedInputIcon)`
  width: 24px;
  align-items: end;
`;

/*
 * Component.
 */

/** ComboBox input. */
export const DropdownBox = forwardRef<HTMLDivElement, DropdownBoxProps>((props, ref) => (
  /*
    noMargin allows to remove boxedInput's margins so that they don't add white space in the dropdown's ref.
    Otherwise those white spaces will be added to the distance between the button and the dropdown during its positioning (done by Popper.js).
    This is quite an ugly fix, an alternative solution would be to use Popper.js's offset modifier to tweak the dropdown's positioning.
    Yet if in this process you endup making it overlap with its reference element, the dropdown will "flip" (ie: go above the button rather than bellow).
    To prevent that you need to disable Popper.js's flip modifier, but we do not want that.
   */
  <BoxedInput {...props} boxRef={ref} isButton noMargin={props.noInnerMargin}>
    {(childProps) => renderInputContent(props, childProps)}
  </BoxedInput>
));

function renderInputContent(props: DropdownBoxProps, childProps: BoxedInputChildProps) {
  return (
    <>
      {props.leftIcon && (
        <StyledLeftBoxedInputIcon {...childProps} iconName={props.leftIcon} color={props.leftIconColor} />
      )}
      <BoxedInputStatic
        {...childProps}
        noMarginRight={props.noInputMarginRight}
        noMarginLeft={props.noInputMarginLeft}
        value={renderValue(props)}
      />
      <BoxedInputIcon {...childProps} iconName="chevronDownSmall" color={props.chevronColor} />
      {maybeDisplayRemoveIconButton(props.onRemove, props.removeLabel)}
    </>
  );
}

function renderValue(props: DropdownBoxProps) {
  // Render all the possible values for sizing.
  const {value, possibleValues} = props;
  const otherValueRenders = map(possibleValues, (v, index) => (
    <StyledPossibleDiv key={index} aria-hidden>
      {v}
    </StyledPossibleDiv>
  ));

  // Render the actual value.
  return (
    <StyledWrapperDiv>
      {otherValueRenders}
      <StyledValueDiv data-testid="dropdown-box-value">{value}</StyledValueDiv>
    </StyledWrapperDiv>
  );
}

function maybeDisplayRemoveIconButton(
  onRemove?: () => void,
  label: ReactNode = <Trans id="dropdown-box-remove-label">Remove</Trans>,
) {
  if (!onRemove) {
    return null;
  }

  return (
    <StyledRemoveButton
      iconName="closeSmall"
      onClick={(e) => {
        e.stopPropagation();
        onRemove();
      }}
      label={label}
    />
  );
}
