import {useEffect, useRef} from 'react';

import {KeyboardActionsEnum, KeyboardMapping} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {LegacyInteractionContextValue} from '../../../../core/src/helpers/interaction/interactionHelpers';
import {createMandatoryContext, FrontKeyboardEvent} from '../../../../core/src/helpers/react/reactHelpers';
import {useLegacyInteractionContext} from '../../interactionTracking/interactionContext';

/*
 * Listener.
 */

export interface KeyboardActionEvent {
  /** The action that was triggered. */
  action: KeyboardActionsEnum;
  /** Call to indicate subsequent keyboard events are intended for an input field, to prevent accidental actions. */
  suspendActionsForTextInputIntent: () => void;
}

export type KeyboardActionEventHandler = (
  event: FrontKeyboardEvent,
  actionEvent: KeyboardActionEvent,
) => void;

export interface GlobalKeyboardActionEvent {
  /** The keyboard event. */
  event: FrontKeyboardEvent;
  /** The action events that were triggered. */
  actions: ReadonlyArray<KeyboardActionsEnum>;
  /** Call to indicate subsequent keyboard events are intended for an input field, to prevent accidental actions. */
  suspendActionsForTextInputIntent: () => void;
  /** Whether the event occurred within an input. */
  isInput: boolean;
}

export type GlobalKeyboardActionEventHandler = (event: GlobalKeyboardActionEvent) => void;

export type KeyboardEventHandlers = {[T in KeyboardActionsEnum]?: KeyboardActionEventHandler};

export interface KeyboardListenerProps {
  /** The keyboard shortcuts to listen for. */
  handlers: KeyboardEventHandlers;
  /** Whether to allow repeated actions when a key is held down. */
  shouldAllowRepeats?: boolean;
  /** Prevent default behavior. Useful if an input is focused by the shortcut and would otherwise receive the shortcut. */
  noPreventDefault?: boolean;
  /** Whether this component should have exclusivity to handle keyboard events. */
  requestExclusivity?: boolean;
}

export interface KeyboardListenerConfiguration extends KeyboardListenerProps {
  /** The interaction context for UI tracking. */
  interactionContext: LegacyInteractionContextValue;
}

/*
 * Props.
 */

type ListenerUpdaterOf<T> = (value?: T) => void;
type ListenerCreatorOf<T> = (initialValue: T) => ListenerUpdaterOf<T>;

export type ListenerUpdater = ListenerUpdaterOf<KeyboardListenerConfiguration>;
export type ListenerCreator = ListenerCreatorOf<KeyboardListenerConfiguration>;

export type GlobalListenerUpdater = ListenerUpdaterOf<GlobalKeyboardActionEventHandler>;
export type GlobalListenerCreator = ListenerCreatorOf<GlobalKeyboardActionEventHandler>;

export interface KeyboardContextProps {
  /** Register a new listener for keyboard events. */
  registerListener: ListenerCreator;
  /** Register a new global listener for keyboard events. */
  registerGlobalListener: GlobalListenerCreator;
  /** The keyboard mapping currently in use. */
  mapping: KeyboardMapping;
}

/*
 * Context.
 */

export const [KeyboardContext, useKeyboardContext] = createMandatoryContext<KeyboardContextProps>();

/*
 * Hooks.
 */

export function useKeyboardListener(props: KeyboardListenerProps): void {
  const {registerListener} = useKeyboardContext();
  const interactionContext = useLegacyInteractionContext();

  useKeyboardListenerBase(registerListener, {
    ...props,
    interactionContext,
  });
}

export function useKeyboardGlobalListener(handler: GlobalKeyboardActionEventHandler): void {
  const {registerGlobalListener} = useKeyboardContext();
  useKeyboardListenerBase(registerGlobalListener, handler);
}

function useKeyboardListenerBase<T>(creator: ListenerCreatorOf<T>, value: T): void {
  const updaterRef = useRef<ListenerUpdaterOf<T> | undefined>();

  // Clean up the listener before creating a new one and before unmounting.
  useEffect(
    () => () => {
      const updater = updaterRef.current;
      if (updater) {
        updater();
      }

      updaterRef.current = undefined;
    },
    [creator],
  );

  // Create or update the listener.
  useEffect(() => {
    const updater = updaterRef.current;
    if (updater) {
      updater(value);
    } else {
      updaterRef.current = creator(value);
    }
  });
}
