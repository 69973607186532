import {difference, differenceBy, findIndex, ValueIteratee, without} from 'lodash';

import {ErrorWithData} from '../types/customErrors';

// Used for `myArray.sort(c: Comparator)`
export type Comparator<T> = (a: T, b: T) => number;

export function atIndex<T>(array: ReadonlyArray<T>, index: number): T | undefined {
  return array[index];
}

export function onlyItem<T>(array: ReadonlyArray<T>): T | undefined {
  return array.length === 1 ? array[0] : undefined;
}

export function insert<T>(array: ReadonlyArray<T>, index: number, item: T) {
  return [...array.slice(0, index), item, ...array.slice(index)];
}

export function move<T>(array: ReadonlyArray<T>, newIndex: number, item: T) {
  const index = array.indexOf(item);
  if (index < 0 || index === newIndex) {
    return array;
  }

  return newIndex < index
    ? [...array.slice(0, newIndex), item, ...array.slice(newIndex, index), ...array.slice(index + 1)]
    : [...array.slice(0, index), ...array.slice(index + 1, newIndex + 1), item, ...array.slice(newIndex + 1)];
}

export function insertOrMove<T>(array: ReadonlyArray<T>, newIndex: number, item: T) {
  const index = array.indexOf(item);
  if (index < 0) {
    return insert(array, newIndex, item);
  }

  return move(array, newIndex, item);
}

export function swap<T>(array: ReadonlyArray<T>, indexA: number, indexB: number) {
  if (indexA >= array.length || indexB >= array.length) {
    return array;
  }

  const elementA = array[indexA];
  const elementB = array[indexB];

  return array.map((element, index) => {
    if (index === indexA) {
      return elementB;
    }

    if (index === indexB) {
      return elementA;
    }

    return element;
  });
}

export function splitAt<T>(array: ReadonlyArray<T>, index: number): [Array<T>, Array<T>] {
  const firstArray = array.filter((_value, position) => position < index);
  const secondArray = array.filter((_value, position) => position >= index);
  return [firstArray, secondArray];
}

export function removeAt<T>(array: ReadonlyArray<T>, index: number): ReadonlyArray<T> {
  return [...array.slice(0, index), ...array.slice(index + 1, array.length)];
}

export function intersperse<T>(array: ReadonlyArray<T>, item: T) {
  return intersperseWith(array, () => item);
}

export function intersperseWith<T>(array: ReadonlyArray<T>, makeItem: (first: T, second: T) => T) {
  const result: Array<T> = [];
  let index = 0;

  // Insert the first element.
  if (index < array.length) {
    result.push(array[index++]);
  }

  // Insert the others, with the item in between.
  while (index < array.length) {
    const newItem = makeItem(array[index - 1], array[index]);
    result.push(newItem, array[index++]);
  }

  return result;
}

export function arrayToggle<T>(array: ReadonlyArray<T>, item: T) {
  const existsInArray = array.includes(item);
  return existsInArray ? without(array, item) : [...array, item];
}

/** Adapted from https://stackoverflow.com/a/14130166 */
export function isSubsetArray<T>(entireSet: ReadonlyArray<T>, possibleSubset: ReadonlyArray<T>): boolean {
  return difference(possibleSubset, entireSet).length === 0;
}

export function areArraysShallowEqual<T>(
  a: ReadonlyArray<T>,
  b: ReadonlyArray<T>,
  compareFn?: (a: T, b: T) => boolean,
): boolean {
  if (a.length !== b.length) {
    return false;
  }

  const compare = compareFn ?? Object.is;
  return a.every((_item, i) => compare(a[i], b[i]));
}

/*
 * String arrays.
 */

/** Adapted from https://www.geeksforgeeks.org/serialize-deserialize-array-string/ */
export function serializeStringArray(array: ReadonlyArray<string>) {
  return array.map((str) => `${str.length}~${str}`).join('');
}

/** Adapted from https://www.geeksforgeeks.org/serialize-deserialize-array-string/ */
export function deserializeStringArray(string: string) {
  const array = [];
  let lastIndex = 0;
  while (lastIndex < string.length) {
    const delimiterIndex = string.indexOf('~', lastIndex);
    if (delimiterIndex === -1) {
      throw new ErrorWithData('Unable to deserialize string array. (Missing delimiter.)', {
        string,
      });
    }

    const length = Number(string.substring(lastIndex, delimiterIndex));
    if (!Number.isInteger(length) || length < 0 || length > string.length - delimiterIndex - 1) {
      throw new ErrorWithData('Unable to deserialize string array. (Invalid length serialization.)', {
        string,
      });
    }

    array.push(string.substring(delimiterIndex + 1, delimiterIndex + 1 + length));
    lastIndex = delimiterIndex + 1 + length;
  }
  return array;
}

export function countTrueValues(...booleanValues: ReadonlyArray<boolean>): number {
  return booleanValues.filter((value) => value).length;
}

export function isLastItemIndex(items: ReadonlyArray<unknown>, index: number): boolean {
  return index === items.length - 1;
}

export function areUnorderedEqual<TItem>(
  a: ReadonlyArray<TItem>,
  b: ReadonlyArray<TItem>,
  iteratee: ValueIteratee<TItem> = (item: TItem) => item,
): boolean {
  const diffA = differenceBy(a, b, iteratee);
  const diffB = differenceBy(b, a, iteratee);

  return diffA.length === 0 && diffB.length === 0;
}

export function replaceOrAppend<TItem>(
  array: ReadonlyArray<TItem>,
  item: TItem,
  compareFn: (a: TItem, b: TItem) => boolean = (a, b) => a === b,
) {
  const index = findIndex(array, (existingItem) => compareFn(existingItem, item));

  if (index < 0) {
    return [...array, item];
  }

  const copy = [...array];
  copy[index] = item;

  return copy;
}

export function replaceOrAppendArray<TItem>(
  array: ReadonlyArray<TItem>,
  items: ReadonlyArray<TItem>,
  compareFn: (a: TItem, b: TItem) => boolean = (a, b) => a === b,
) {
  return items.reduce((memo, item) => replaceOrAppend(memo, item, compareFn), array);
}

export const emptyArray = Object.freeze([] as const);
