import React from 'react';

export const Helpfilled = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15ZM7.77289 9.63867C7.40375 9.63867 7.19281 9.39258 7.19281 9.04688C7.19281 8.31445 7.43891 7.92773 8.28266 7.25391C8.88617 6.77344 9.16742 6.47461 9.16742 5.94141C9.16742 5.33203 8.64594 4.89844 7.93695 4.89844C7.31 4.89844 6.89398 5.15625 6.60102 5.72461C6.44281 5.99414 6.31391 6.09375 6.07953 6.09375C5.76312 6.09375 5.58148 5.87109 5.58148 5.57812C5.58148 5.17383 5.82172 4.72852 6.20258 4.40039C6.61859 4.04297 7.22211 3.84375 7.99555 3.84375C9.47211 3.84375 10.4916 4.64648 10.4916 5.90039C10.4916 6.73828 10.0698 7.23633 9.29047 7.83984C8.65766 8.33789 8.48773 8.54297 8.38812 9.09375C8.28852 9.43359 8.13617 9.63867 7.77289 9.63867ZM7.78461 12.1172C7.35102 12.1172 6.99359 11.7949 6.99359 11.3613C6.99359 10.9277 7.35102 10.6113 7.78461 10.6113C8.2182 10.6113 8.57562 10.9277 8.57562 11.3613C8.57562 11.7949 8.2182 12.1172 7.78461 12.1172Z"
    />
  </svg>
);
