import {CancelToken} from 'axios';
import {DateTime} from 'luxon';
import React, {FC, ReactNode, useCallback} from 'react';

import {HttpResponseError, SchedulingLinkMissingAvailabilityError} from '../../helpers/errors';
import {
  fetchAvailableSlotAsync,
  fetchSchedulingLinkAvailability,
  makeInitialSelectedDate,
} from '../../helpers/model/availability';
import {fetchSchedulingApiAsync} from '../../helpers/schedulingHttp';
import {isNonEmptyAvailability} from '../../models/schedulingLinkAvailabilityModel';
import {ApiSchedulingLink} from '../../models/schedulingLinkModel';
import {AsyncRenderer} from '../common/asyncRenderer';
import {FullScreenError} from '../common/error/fullScreenError';
import {renderFullScreenLoader} from '../common/fullScreenLoader';
import {defaultLocale} from '../common/locale/localeContext';
import {MainContentWrapper} from '../common/mainContentWrapper';
import {StepLostAvailabilityError} from '../common/step/error/stepError';
import {Step, StepData} from '../common/step/step';
import {StepController} from '../common/step/stepController';
import {DeviceTypesEnum, usePlatform} from '../platform/platformContext';
import {bookStepAnswerQuestions} from './answerQuestions/bookStepAnswerQuestions';
import {BookRouterData, useBookRouterData} from './bookRoutesHelpers';
import {BookStepTypesEnum} from './bookStep';
import {bookStepViewConfirmation} from './bookStepViewConfirmation/bookStepViewConfirmation';
import {bookStepChooseDate} from './chooseSlot/bookStepChooseDate';
import {bookStepChooseSlot} from './chooseSlot/bookStepChooseSlot';
import {bookStepChooseTime} from './chooseSlot/bookStepChooseTime';

/*
 * Component.
 */

export const BookPage: FC = () => {
  const routerData = useBookRouterData();
  const {device} = usePlatform();

  const asyncOperation = useCallback(
    (cancelToken: CancelToken) => makeInitialBookDataAsync(routerData, device, cancelToken),
    [routerData, device],
  );

  return (
    <MainContentWrapper>
      <AsyncRenderer
        asyncOperation={asyncOperation}
        render={(bookData) => (
          <StepController
            steps={bookData.steps}
            initialStepData={bookData.initialStepData}
            initialError={bookData.initialError}
          />
        )}
        renderLoading={renderFullScreenLoader}
        renderError={renderAsyncError}
      />
    </MainContentWrapper>
  );
};

/*
 * Helpers.
 */

interface InitialBookData {
  steps: ReadonlyArray<Step<BookStepTypesEnum>>;
  initialStepData: StepData;
  initialError?: any;
}

export async function makeInitialBookDataAsync(
  routerData: BookRouterData,
  device: DeviceTypesEnum,
  cancelToken?: CancelToken,
): Promise<InitialBookData> {
  const {subdomain, username, schedulingLinkAlias, slotStart} = routerData;

  // Fetch the scheduling link.
  const schedulingLink = await fetchSchedulingApiAsync(
    ApiSchedulingLink,
    `companies/${subdomain}/${username}/${schedulingLinkAlias}`,
    cancelToken,
  );

  // Try to find an availability block for the start time if provided.
  const selectedSlot = slotStart
    ? await fetchAvailableSlotAsync(
        schedulingLink,
        slotStart,
        slotStart + schedulingLink.durationMinutes * 60 * 1000,
        cancelToken,
      )
    : undefined;
  if (selectedSlot) {
    return {
      steps: [bookStepAnswerQuestions, bookStepViewConfirmation],
      initialStepData: {
        schedulingLink,
        booking: {
          schedulingLink,
          start: selectedSlot.start,
          end: selectedSlot.end,
          isDraft: true,
        },
        selectedDate: DateTime.fromMillis(selectedSlot.start).startOf('day'),
        locale: defaultLocale,
      },
    };
  }

  // If we have a start time selected but no available slot, we must have lost availability.
  const initialError = slotStart ? new StepLostAvailabilityError() : undefined;

  // Check availability for scheduling link.
  const availability = await fetchSchedulingLinkAvailability(schedulingLink, cancelToken);
  if (!isNonEmptyAvailability(availability)) {
    throw new SchedulingLinkMissingAvailabilityError(schedulingLink);
  }

  return {
    steps: [...makeChooseSlotSteps(device), bookStepAnswerQuestions, bookStepViewConfirmation],
    initialStepData: {
      schedulingLink,
      booking: {
        schedulingLink,
        isDraft: true,
      },
      selectedDate: makeInitialSelectedDate(availability, device),
      availability,
      locale: defaultLocale,
    },
    initialError,
  };
}

function makeChooseSlotSteps(device: DeviceTypesEnum) {
  if (device === DeviceTypesEnum.MOBILE) {
    return [bookStepChooseDate, bookStepChooseTime];
  }

  return [bookStepChooseSlot];
}

function renderAsyncError(error: any) {
  return <FullScreenError description={renderErrorDescription(error)} />;
}

function renderErrorDescription(error: any): ReactNode {
  if (error instanceof HttpResponseError && error.status === 404) {
    return 'This scheduling link does not exist.';
  }

  if (error instanceof SchedulingLinkMissingAvailabilityError) {
    return `Times no longer available. Try contacting ${error.schedulingLink.organizerAddress} to find a new time to meet.`;
  }

  return 'An unexpected error occurred.';
}
