import {createContext, useContext} from 'react';

import {
  InteractionComponentProps,
  LegacyInteractionContextValue,
} from '../../../core/src/helpers/interaction/interactionHelpers';
import {InteractionTypesEnum} from '../../../core/src/helpers/interaction/interactionTypes';
import {FrontEvent, withContextBuilder} from '../../../core/src/helpers/react/reactHelpers';

export type TrackInteraction = (
  type: InteractionTypesEnum,
  id: string,
  parentIds: ReadonlyArray<string>,
  extraData?: Record<string, unknown>,
) => void;
export type TrackContextInteraction = (
  type: InteractionTypesEnum,
  context: LegacyInteractionContextValue | undefined,
  interactionId: string,
  extraData?: Record<string, unknown>,
) => void;
export type TrackComponentInteraction = (
  type: InteractionTypesEnum,
  context: LegacyInteractionContextValue | undefined,
  props: InteractionComponentProps,
  event: Pick<FrontEvent, 'target'>,
  extraData?: Record<string, unknown>,
) => void;

export interface InteractionTrackingContextType {
  /** @deprecated Use trackDataEvent from DataEventTrackingContext instead. */
  trackInteraction: TrackInteraction;
  /** @deprecated Use trackDataEvent from DataEventTrackingContext instead. */
  trackContextInteraction: TrackContextInteraction;
  /** @deprecated Use trackDataEvent from DataEventTrackingContext instead. */
  trackComponentInteraction: TrackComponentInteraction;
}

export const InteractionTrackingContext = createContext<InteractionTrackingContextType>({
  trackInteraction: () => undefined,
  trackContextInteraction: () => undefined,
  trackComponentInteraction: () => undefined,
});

/** @deprecated Use useDataEventTracking() instead. */
export const useInteractionTracking = () => useContext(InteractionTrackingContext);
export const withInteractionTracking = withContextBuilder(InteractionTrackingContext);
