import {isFunction} from 'lodash';
import React, {
  ChangeEvent,
  ComponentPropsWithoutRef,
  ComponentType,
  ConsumerProps,
  Context,
  createContext,
  DragEvent as ReactDragEvent,
  ElementType,
  FC,
  FocusEvent as ReactFocusEvent,
  Fragment,
  isValidElement,
  KeyboardEvent as ReactKeyboardEvent,
  MouseEvent as ReactMouseEvent,
  Provider,
  ReactNode,
  SyntheticEvent,
  useContext,
} from 'react';
import {Key} from 'ts-key-enum';
import {Subtract} from 'utility-types';

import {intersperseWith} from '../utilities/arrayHelpers';

/*
 * Types.
 */

/** Intersecting event handler types. */
export type FrontEvent<T = HTMLElement> = Event | SyntheticEvent<T>;
export type FrontFocusEvent<T = HTMLElement> = FocusEvent | ReactFocusEvent<T>;
export type FrontMouseEvent<T = HTMLElement> = MouseEvent | ReactMouseEvent<T>;
export type FrontKeyboardEvent<T = HTMLElement> = KeyboardEvent | ReactKeyboardEvent<T>;
export type FrontClickEvent<T = HTMLElement> = FrontMouseEvent<T> | FrontKeyboardEvent<T>;
export type FrontDragEvent<T = HTMLElement> = DragEvent | ReactDragEvent<T>;
export type FrontChangeEvent<T = HTMLElement> = ChangeEvent<T>;

export type Unsubscriber = () => void;
export type Unregistrator = () => void;
export type EventHandler<T = HTMLElement> = (arg: FrontEvent<T>) => void;
export type FocusEventHandler = (event: FrontFocusEvent) => void;
export type MouseEventHandler = (event: FrontMouseEvent) => void;
export type KeyboardEventHandler = (event: FrontKeyboardEvent) => void;
export type ClickEventHandler = (event: FrontClickEvent) => void;
export type DragEventHandler = (event: FrontDragEvent) => void;
export type PredicateOf<T> = (value: T) => boolean;

/*
 * Common helpers.
 */

const keyboardEventTypes = new Set(['keydown', 'keypress', 'keyup']);

export function isKeyboardEvent(event: FrontEvent): event is FrontKeyboardEvent {
  return keyboardEventTypes.has(event.type);
}

export function isClickEvent(event: FrontEvent): boolean {
  return event.type === 'click' || (isKeyboardEvent(event) && event.key === Key.Enter);
}

/** Render function type with optional argument. */
export interface RendererOf<T> {
  (opts: T): ReactNode;
}

export interface Renderer {
  (): ReactNode;
}

export function isElementOfType(element: any, type: ComponentType<any>) {
  return isValidElement(element) && isFunction(element.type) && element.type === type;
}

/*
 * Extract the props of another component.
 */

/** @deprecated Use React.ComponentProps and friends instead. */
export type ExtractProps<T extends ElementType> = ComponentPropsWithoutRef<T>;

/*
 * Context API.
 */

export interface MandatoryContext<T> {
  Provider: Provider<T>;
  Consumer: ComponentType<ConsumerProps<T>>;
}

type MandatoryContextHook<T> = () => T;

type MissingContextValue = typeof missingContextValue;

const missingContextValue = Symbol('missingContextValue');

/** Create a context that requires a provider to instanticate consumers. */
export function createMandatoryContext<T>(): [MandatoryContext<T>, MandatoryContextHook<T>] {
  // Create the normal React context.
  const ReactContext = createContext<T | MissingContextValue>(missingContextValue);

  // Wrap the consumer to fail if no context is provided.
  const Consumer: FC<ConsumerProps<T>> = (props) => (
    <ReactContext.Consumer>{(value) => renderContextContent(value, props.children)}</ReactContext.Consumer>
  );

  // Wrap the hook to fail if no context is provided.
  function useMandatoryContext() {
    const maybeValue = useContext(ReactContext);
    return filterMissingContextValue(maybeValue);
  }

  // Return the updated context.
  return [
    {
      Provider: ReactContext.Provider as Provider<T>,
      Consumer,
    },
    useMandatoryContext,
  ];
}

function filterMissingContextValue<T>(value: T | MissingContextValue): T {
  // If no context state was provided, fail.
  if (value === missingContextValue) {
    throw new Error('This context requires a provider.');
  }

  return value;
}

function renderContextContent<T>(maybeValue: T | MissingContextValue, renderer: (value: T) => ReactNode) {
  const value = filterMissingContextValue(maybeValue);
  return renderer(value);
}

/*
 * Inputs.
 */

let inputCount = 0;
export function makeInputId(prefix: string) {
  return `${prefix}-${inputCount++}`;
}

/*
 * Intersperse.
 */

export interface IntersperseItem {
  node: ReactNode;
  key: string | number;
}
export function intersperseNodes(
  items: ReadonlyArray<IntersperseItem>,
  separator: (before: IntersperseItem, after: IntersperseItem) => ReactNode,
) {
  return intersperseWith<IntersperseItem>(items, (before, after) => ({
    node: separator(before, after),
    key: `${before.key}${after.key}`,
  })).map(({node, key}) => <Fragment key={key}>{node}</Fragment>);
}

/*
 * A generic way to create withContext higher order components.
 *
 * Example:
 * const HelloContext = React.createContext({
 *   text: 'Hello world!'
 * });
 *
 * const withHelloContext = withContextBuilder(HelloContext);
 *
 * const MyComponentBase = ({text}) => <div>{text}</div>;
 * const MyComponent = withHelloContext(MyComponentBase);
 */
export function withContextBuilder<U extends Object>(ContextInstance: Context<U>) {
  return function withContext<T extends U>(Component: ComponentType<T>): FC<Subtract<T, U>> {
    return function (props) {
      return (
        <ContextInstance.Consumer>
          {(context) => React.createElement(Component, {...(props as any), ...context})}
        </ContextInstance.Consumer>
      );
    };
  };
}
