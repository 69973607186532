import React, {FC, PropsWithChildren} from 'react';

import {KeyboardListenerProps, useKeyboardListener} from './keyboardContext';

/*
 * Props.
 */

interface KeyboardShortcutsProps extends KeyboardListenerProps {
  /** Whether these shortcuts should be disabled. */
  isDisabled?: boolean;
}

/*
 * Component.
 */

export const KeyboardShortcuts: FC<PropsWithChildren<KeyboardShortcutsProps>> = (props) => {
  useKeyboardListener({
    handlers: props.isDisabled ? {} : props.handlers,
    requestExclusivity: props.requestExclusivity,
    shouldAllowRepeats: props.shouldAllowRepeats,
    noPreventDefault: props.noPreventDefault,
  });

  return props.children ? <>{props.children}</> : null;
};
