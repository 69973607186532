import {kebabCase} from 'change-case';
import _, {concat, eachRight, forEach, without} from 'lodash';
import Mousetrap from 'mousetrap';
import React, {Component, createRef, FC, PropsWithChildren, ReactNode, RefObject} from 'react';

import {isInputLike} from '../../../../core/src/helpers/browser/domHelpers';
import {
  isGmailSequence,
  KeyboardActionsEnum,
  KeyboardMapping,
} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {metaKey} from '../../../../core/src/helpers/browser/modifierKeyPlatformHelpers';
import {BugsnagSeveritiesEnum} from '../../../../core/src/helpers/bugsnag/bugsnagSeverities';
import {InteractionTypesEnum} from '../../../../core/src/helpers/interaction/interactionTypes';
import {FrontKeyboardEvent} from '../../../../core/src/helpers/react/reactHelpers';
import {ErrorReporterContextType, withErrorReporter} from '../../errorReporter/errorReporterContext';
import {
  InteractionTrackingContextType,
  withInteractionTracking,
} from '../../interactionTracking/interactionTrackingContext';
import {
  GlobalKeyboardActionEventHandler,
  GlobalListenerCreator,
  KeyboardContext,
  KeyboardListenerConfiguration,
  ListenerCreator,
} from './keyboardContext';
import {startMonitoringTyping, TypingMonitor} from './typingMonitor';

/*
 * Props.
 */

interface KeyboardProviderBaseProps {
  mapping: KeyboardMapping;
  children?: ReactNode;
}

type KeyboardProviderProps = KeyboardProviderBaseProps &
  InteractionTrackingContextType &
  ErrorReporterContextType;

/*
 * Component.
 */

interface KeyboardAction {
  sequence: string;
  action: KeyboardActionsEnum;
  shouldCaptureInputs: boolean;
}

type GenericHandler = (
  sequence: string,
  actions: ReadonlyArray<KeyboardAction>,
  event?: FrontKeyboardEvent,
) => void;

interface KeyboardListener {
  configuration: KeyboardListenerConfiguration;
}

interface GlobalKeyboardListener {
  onInterceptAction: GlobalKeyboardActionEventHandler;
}

class KeyboardProviderBase extends Component<KeyboardProviderProps> {
  constructor(props: KeyboardProviderProps) {
    super(props);

    this.listeners = [];
    this.globalListeners = [];
    this.elementRef = createRef<HTMLDivElement>();
  }

  private listeners: ReadonlyArray<KeyboardListener>;
  private globalListeners: ReadonlyArray<GlobalKeyboardListener>;
  private readonly elementRef: RefObject<HTMLDivElement>;

  private mousetrap?: MousetrapInstance;
  private typingMonitor?: TypingMonitor;

  /*
   * Lifecycle.
   */

  componentDidMount() {
    // We need a ref to our element at this point.
    const currentElement = this.elementRef.current;
    if (!currentElement || !currentElement.ownerDocument) {
      return;
    }

    // Create a new mousetrap instance using our element, and bind the keys.
    this.mousetrap = new Mousetrap(currentElement.ownerDocument, true);
    this.mousetrap.stopCallback = () => false;
    this.bindKeys();

    this.typingMonitor = startMonitoringTyping(currentElement.ownerDocument);
  }

  componentDidUpdate(previousProps: Readonly<KeyboardProviderProps>) {
    // If the mapping didn't change, nothing to do.
    if (this.props.mapping === previousProps.mapping) {
      return;
    }

    // Otherwise, update it.
    this.unbindKeys(previousProps.mapping);
    this.bindKeys();
  }

  componentWillUnmount() {
    if (this.typingMonitor) {
      this.typingMonitor.stop();
    }

    this.unbindKeys(this.props.mapping);
  }

  private bindKeys() {
    // We need a mousetrap instance at this point.
    const {mousetrap} = this;
    if (!mousetrap) {
      return;
    }

    // Bind all the sequences supported in the provided mapping.
    _(this.props.mapping)
      .map((sequences, action) =>
        sequences.map((s) => ({
          sequence: toPlatformSequence(s.sequence),
          action: action as KeyboardActionsEnum,
          shouldCaptureInputs: s.shouldCaptureInputs,
        })),
      )
      .flatten()
      .groupBy((sa) => sa.sequence)
      .forEach((group, sequence) => {
        mousetrap.bind(sequence, (e) => this.handleKeyboardEvent(sequence, group, e));
      });
  }

  private unbindKeys(previousMapping: KeyboardMapping) {
    // We need a mousetrap instance at this point.
    const {mousetrap} = this;
    if (!mousetrap) {
      return;
    }

    // Unbind all the sequences provided in the mapping.
    forEach(previousMapping, (shortcuts) => {
      mousetrap.unbind(shortcuts.map((s) => s.sequence));
    });
  }

  private readonly suspendActionsForTextInputIntent = () => {
    if (!this.typingMonitor) {
      return;
    }

    this.typingMonitor.suspendForTextInputIntent();
  };

  /*
   * Context.
   */

  private readonly registerListener: ListenerCreator = (configuration) => {
    // Create the listener.
    const listener: KeyboardListener = {
      configuration,
    };

    // Add it to the stack.
    this.listeners = concat(this.listeners, listener);

    // Return a method to update the listener.
    return (newConfiguration) => {
      // If a configuration was provided, update it.
      if (newConfiguration) {
        listener.configuration = newConfiguration;
        return;
      }

      // Otherwise, remove it from the stack.
      this.listeners = without(this.listeners, listener);
    };
  };

  private readonly registerGlobalListener: GlobalListenerCreator = (onInterceptAction) => {
    // Create the listener.
    const listener: GlobalKeyboardListener = {
      onInterceptAction,
    };

    // Add it to the stack.
    this.globalListeners = concat(this.globalListeners, listener);

    return (newOnInterceptAction) => {
      if (newOnInterceptAction) {
        listener.onInterceptAction = newOnInterceptAction;
        return;
      }

      this.globalListeners = without(this.globalListeners, listener);
    };
  };

  /*
   * Keyboard event handling.
   */

  private readonly handleKeyboardEvent: GenericHandler = (sequence, shortcuts, event) => {
    // Nothing to do if an event wasn't provided.
    if (!event) {
      return;
    }

    const isGmailShortcut = isGmailSequence(sequence);

    // Check if the event target is an input.
    const isInput = isInputLike(event.target);
    const isRepeat = Boolean(event.repeat);

    // Ignore Gmail (single letter) shortcuts if we're supposed to be typing.
    if (isGmailShortcut && this.typingMonitor && this.typingMonitor.isTyping) {
      if (!isInput) {
        this.props.reportError(
          new Error(`Unexpected Gmail shortcut while typing`),
          BugsnagSeveritiesEnum.INFO,
        );
      }

      return;
    }

    // Check if we have any global listeners.
    const actions = shortcuts.map((s) => s.action);

    eachRight(this.globalListeners, ({onInterceptAction}) => {
      if (event.defaultPrevented) {
        return false;
      }

      onInterceptAction({
        event,
        actions,
        suspendActionsForTextInputIntent: this.suspendActionsForTextInputIntent,
        isInput,
      });

      return true;
    });

    // Find a suitable listener.
    eachRight(this.listeners, ({configuration: c}) => {
      // If the default was prevented, we're done.
      if (event.defaultPrevented) {
        return false;
      }

      // Otherwise, check this listener can handle the current event.
      const shortcut = shortcuts.find((s) => Boolean(c.handlers[s.action]));

      if (!shortcut) {
        return !c.requestExclusivity;
      }

      const canHandleInput = shortcut.shouldCaptureInputs || !isInput;
      const canHandleRepeat = Boolean(c.shouldAllowRepeats) || !isRepeat;
      const canHandle = canHandleInput && canHandleRepeat;
      const handler = canHandle && c.handlers[shortcut.action];

      if (!handler) {
        return !c.requestExclusivity;
      }

      // Call the handler.
      handler(event, {
        action: shortcut.action,
        suspendActionsForTextInputIntent: this.suspendActionsForTextInputIntent,
      });

      // Track the UI event.
      this.props.trackContextInteraction(
        InteractionTypesEnum.KEYBOARD_SHORTCUT,
        c.interactionContext,
        kebabCase(shortcut.action),
      );

      if (canHandle && !c.noPreventDefault) {
        event.preventDefault();
      }

      return !c.requestExclusivity;
    });
  };

  /*
   * Render.
   */

  render() {
    return (
      <>
        <div ref={this.elementRef} />
        <KeyboardContext.Provider
          // eslint-disable-next-line react/jsx-no-constructed-context-values
          value={{
            registerListener: this.registerListener,
            registerGlobalListener: this.registerGlobalListener,
            mapping: this.props.mapping,
          }}
        >
          {this.props.children}
        </KeyboardContext.Provider>
      </>
    );
  }
}

function toPlatformSequence(sequence: string) {
  return sequence.replace(/\bmeta\b/g, metaKey);
}

export const KeyboardProvider: FC<PropsWithChildren<KeyboardProviderBaseProps>> = withInteractionTracking(
  withErrorReporter(KeyboardProviderBase),
);
