import React, {FC, useCallback, useState} from 'react';

import {useSelectedBlocks} from '../../../helpers/availabilityHooks';
import {reportToBugsnag} from '../../../helpers/bugsnag';
import {fetchAvailableSlotAsync} from '../../../helpers/model/availability';
import {createAvailabilityBlockFromBooking} from '../../../helpers/model/booking';
import {SchedulingLinkAvailabilityBlockModel} from '../../../models/schedulingLinkAvailabilityModel';
import {useLocale} from '../../common/locale/localeContext';
import {LocaleSettings} from '../../common/locale/localeSettings';
import {StepLostAvailabilityError} from '../../common/step/error/stepError';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepData, StepProps} from '../../common/step/step';
import {makeLocaleUpdater} from '../../common/step/stepLocaleUpdater';
import {BookingSummary} from '../../common/summary/bookingSummary';
import {DateTimePickerLayout} from '../../common/ui/dateTimePickerLayout';
import {AvailabilityDatePicker} from '../../datePicker/availabilityDatePicker';
import {TimePicker} from '../../timePicker/timePicker';
import {buildBookTitle} from '../bookHelpers';
import {BookStepTypesEnum} from '../bookStep';

/*
 * Component.
 */

export const BookStepChooseSlot: FC<StepProps> = (props) => {
  const {onNext, metadata, booking, availability, selectedDate, schedulingLink} = props;
  const locale = useLocale();
  const [selectedBlock, setSelectedBlock] = useState<SchedulingLinkAvailabilityBlockModel>();

  const onSelectBlock = useCallback(
    (block: SchedulingLinkAvailabilityBlockModel) => {
      // If there isn't another step, do nothing.
      if (!onNext) {
        return;
      }

      // Update the state.
      setSelectedBlock(block);

      // Go to the next step.
      onNext((data) => ({
        ...data,
        booking: {
          ...data.booking,
          start: block.start,
          end: block.end,
        },
      }));
    },
    [onNext, setSelectedBlock],
  );

  const selectedBlocks = useSelectedBlocks(selectedDate, availability);

  const renderSidebar = useCallback(() => <BookingSummary booking={booking} />, [booking]);

  if (!availability) {
    reportToBugsnag(new Error('Missing initial availability when booking.'));
    return null;
  }

  return (
    <StepFrame title={buildBookTitle(schedulingLink)} metadata={metadata} renderSidebar={renderSidebar}>
      <DateTimePickerLayout
        datePicker={
          <AvailabilityDatePicker
            schedulingLink={props.schedulingLink}
            selectedDate={selectedDate}
            onSelectDate={(date) =>
              props.onUpdateStepData((data) => ({
                ...data,
                selectedDate: date,
              }))
            }
            availability={availability}
          />
        }
        timePicker={
          <TimePicker
            availabilityBlocks={selectedBlocks}
            selectedTimeSlot={selectedBlock}
            setSelectedTimeSlot={onSelectBlock}
          />
        }
        localeSettings={
          <LocaleSettings
            locale={locale}
            onChangeLocale={(newLocale) => props.onUpdateStepData(makeLocaleUpdater(newLocale))}
          />
        }
      />
    </StepFrame>
  );
};

async function postStepAction(data: StepData) {
  // Verify the selected slot is still available before moving onto the next step.
  const {schedulingLink, booking} = data;
  const selectedBlock = createAvailabilityBlockFromBooking(booking);
  if (!selectedBlock) {
    // A block must be selected upon completion of this step.
    throw new Error('Missing Selected Block');
  }

  const availableSlot = await fetchAvailableSlotAsync(schedulingLink, selectedBlock.start, selectedBlock.end);
  if (!availableSlot) {
    // The selected slot is no longer available.
    throw new StepLostAvailabilityError();
  }

  // Don't change the step data.
  return data;
}

/*
 * Step.
 */

export const bookStepChooseSlot: Step<BookStepTypesEnum> = {
  type: BookStepTypesEnum.CHOOSE_SLOT,
  renderStep: (props) => React.createElement(BookStepChooseSlot, props),
  postStepAction,
};
