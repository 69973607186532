/* eslint-disable react/jsx-props-no-spreading */
import {DateTime, DateTimeFormatOptions} from 'luxon';
import React, {ComponentType, createContext, useContext} from 'react';

import {patchDateTimeFormat} from '../datetime/dateTimeHelpers';
import {createI18n, I18n} from './i18n';
import {Localizer} from './localizeTypes';
import {MessageDescriptor} from './message';
import {PluralMessageDescriptor} from './pluralMessage';

/*
 * Props.
 */

export interface InternationalizationContextProps {
  i18n: I18n;
  localize: Localizer;
}

export type LocalizeRenderer = (localize: Localizer) => string;

/*
 * Context.
 */

const fallbackI18n = createI18n();

export const fallbackInternationalizationContextProps: InternationalizationContextProps = {
  i18n: fallbackI18n,
  localize: (
    descriptorOrDateTime: MessageDescriptor | PluralMessageDescriptor | DateTime,
    format?: DateTimeFormatOptions,
  ) => {
    // DateTime localization.
    if (DateTime.isDateTime(descriptorOrDateTime)) {
      return descriptorOrDateTime.toLocaleString({
        ...patchDateTimeFormat(format || DateTime.DATETIME_FULL),
        // We need to pass the zoneName to `toLocaleString` because Luxon defers to `Intl.DateTimeFormat` and it will NOT be aware of the timezone set on `descriptorOrDateTime`
        ...(descriptorOrDateTime.zoneName ? {timeZone: descriptorOrDateTime.zoneName} : {}),
      });
    }

    // String localization.
    return fallbackI18n._(descriptorOrDateTime);
  },
};

const InternationalizationContext = createContext<InternationalizationContextProps>(
  fallbackInternationalizationContextProps,
);

export const InternationalizationContextProvider = InternationalizationContext.Provider;

/*
 * Hook.
 */

export function useLocalize() {
  return useContext(InternationalizationContext).localize;
}

export function withInternationalization<T>(
  Component: ComponentType<T>,
): ComponentType<Omit<T, keyof InternationalizationContextProps>> {
  return function (props) {
    return (
      <InternationalizationContext.Consumer>
        {(context) => <Component {...(props as T)} {...context} />}
      </InternationalizationContext.Consumer>
    );
  };
}
