import {VirtualElement} from '@popperjs/core';
import {RefObject} from 'react';

/*
 * Types.
 */

export type BoundsRect = DOMRectReadOnly;
export type BoundsRectProvider = () => BoundsRect;
export type Anchor = RefObject<HTMLElement> | BoundsRectProvider;
export type ReferenceObject = VirtualElement | Element;

/*
 * API.
 */

export function buildReferenceObject(anchor: Anchor): ReferenceObject {
  // If this is a rectangle, build the reference object.
  if (typeof anchor === 'function') {
    return {
      getBoundingClientRect: anchor,
      clientWidth: anchor().width,
      clientHeight: anchor().height,
    };
  }

  const currentAnchor = anchor.current;
  if (!currentAnchor) {
    throw new Error('The provided anchor Ref does not have a value.');
  }

  return currentAnchor;
}
