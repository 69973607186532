import {useLocation, useParams} from 'react-router-dom';

/*
 * Helpers.
 */

export interface BookQueryData {
  pathname: string;
  slotStart?: number;
}

export function useBookQueryData(): BookQueryData {
  const {pathname, search} = useLocation();
  const query = new URLSearchParams(search);
  const startParam = query.get('start');
  const slotStart = startParam ? Number(startParam) : undefined;
  return {
    pathname,
    slotStart,
  };
}

export interface BookRouterData {
  subdomain: string;
  username: string;
  schedulingLinkAlias: string;
  slotStart?: number;
}

export function useBookRouterData(): BookRouterData {
  const {subdomain, username, schedulingLinkAlias} = useParams();
  const {slotStart} = useBookQueryData();

  return {
    // Temporarily type casting to support monorepo migration. The application
    // should be updated to validate the inputs and gracefully error.
    subdomain: subdomain as string,
    username: username as string,
    schedulingLinkAlias: schedulingLinkAlias as string,
    slotStart,
  };
}
