import styled from 'styled-components';

import {CalendarDataProps, CalendarInviteResponsesEnum} from './calendarType';

const responseHeaderStyle = (props: CalendarDataProps) => {
  const {hasError, response} = props;
  if (hasError) {
    return '#FFE1CB, #FFE1CB';
  }
  if (response === CalendarInviteResponsesEnum.ACCEPTED) {
    return 'rgba(220, 255, 243, 0) 1.28%, #ACF8E2 38.69%, #00D47D 99.85%';
  }
  if (response === CalendarInviteResponsesEnum.DECLINED) {
    return '#FF0057 4.04%, #FFD4E7 60.42%, rgba(255, 232, 240, 0) 100%';
  }
  if (response === CalendarInviteResponsesEnum.TENTATIVE) {
    return 'rgba(255, 252, 236, 0) 4.04%, #FFCF00 51.56%, rgba(255, 250, 221, 0) 100%';
  }
  return '#FFE1CB, #FFE1CB';
};

export const GradientHeaderBarDiv = styled.div<CalendarDataProps>`
  border-radius: 16px 16px 0 0;
  height: 16px;
  background: linear-gradient(to left, ${(props) => responseHeaderStyle(props)});
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  animation: 0.2s fadeIn ease-in-out forwards;
`;
