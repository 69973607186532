/* eslint-disable @typescript-eslint/consistent-type-assertions */
/// <reference lib="webworker" />

function findGlobalThis() {
  if (typeof window !== 'undefined') {
    return window as unknown as Window;
  }

  if (typeof self !== 'undefined' && 'serviceWorker' in self) {
    return self as unknown as ServiceWorkerGlobalScope;
  }

  throw new Error('Expected window or service worker context');
}

export const globalThis = findGlobalThis();
