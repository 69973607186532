import {alphas, greys} from '../../styles/colorStyles';

// Styles which are shared between the dark and light themes. Most of the search
// bar does not change appearance between the themes.
export const sharedSearchBarSemanticStyles = {
  inputBackground: alphas.white30,
  text: greys.white,
  textHover: greys.shade30,
  buttonHoverBackground: alphas.black40,
  placeholder: alphas.white40,
  tokenBackground: alphas.black50,
};

export type SearchBarSemanticStyles = typeof sharedSearchBarSemanticStyles;
