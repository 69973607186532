import React, {FC} from 'react';
import styled from 'styled-components';

import {VisualSizesEnum} from '../../styles/commonStyles';
import {Input, InputInheritableProps, InputProps, InputTypesEnum} from '../inputs/input';
import {BoxedInputChildProps, boxedInputColorReaders, inputContentStyles} from './boxedInputConstants';

/*
 * Props.
 */

export const BoxedInputElementTypesEnum = InputTypesEnum;
export type BoxedInputElementInheritableProps = InputInheritableProps;
export type BoxedInputElementProps = InputProps & BoxedInputChildProps;

/*
 * Style.
 */

interface InputStyleProps {
  $size: VisualSizesEnum;
}

const StyledInput = styled(Input)<InputStyleProps>`
  flex: 1;
  width: 0;
  overflow: hidden;

  ${(p) => inputContentStyles[p.$size]};

  &::placeholder {
    color: ${boxedInputColorReaders.textPlaceholder};
  }
`;

/*
 * Component.
 */

export const BoxedInputElement: FC<BoxedInputElementProps> = (props) => (
  <StyledInput $size={props.size} {...props} />
);
