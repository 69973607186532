import * as E from 'fp-ts/lib/Either';
import {Errors, Type} from 'io-ts';

/*
 * Types.
 */

export type RuntimeType = {readonly _A: object};
export type ModelType<T extends RuntimeType> = T['_A'];

export interface ApiType<T extends RuntimeType> {
  name: string;
  runtimeType: T;
  parse(src: any): ModelType<T>;
}

/*
 * Builder.
 */

export function makeApiType<T extends RuntimeType>(
  name: string,
  runtimeType: T,
  parse: (src: any) => ModelType<T>,
): ApiType<T> {
  return {
    name,
    runtimeType,
    parse,
  };
}

/*
 * Parser.
 */

export function readApiModel<T extends Type<any>>(apiType: ApiType<T>, src: any): ModelType<T> {
  // Parse the object.
  const modelParsed = apiType.parse(src);

  // Validate it.
  const modelValidated = apiType.runtimeType.decode(modelParsed);
  return ensureValidApiModel(apiType, modelValidated);
}

function ensureValidApiModel<T extends Type<any>>(
  apiType: ApiType<T>,
  modelValidated: E.Either<Errors, any>,
) {
  if (!E.isRight(modelValidated)) {
    throw new Error(`[io-ts] Unable to parse ${apiType.name}.`);
  }

  return modelValidated.right;
}
