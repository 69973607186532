import React, {FC, Fragment, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import styled from 'styled-components';

import {Button} from '../../../../../libs/shared/ui/src/components/buttons/button';
import {ButtonVariantsEnum} from '../../../../../libs/shared/ui/src/components/buttons/buttonConstants';
import {Icon} from '../../../../../libs/shared/ui/src/components/icon/icon';
import {alphas, greys} from '../../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes, fontWeights} from '../../../../../libs/shared/ui/src/styles/fontStyles';
import {reportToBugsnag} from '../../../helpers/bugsnag';
import {
  renderAvailabilityBlockDateWithLocale,
  renderAvailabilityBlockTimeWithLocale,
} from '../../../helpers/model/availability';
import {createAvailabilityBlockFromBooking} from '../../../helpers/model/booking';
import {ConferenceTypesEnum} from '../../../models/conferenceTypeModel';
import {
  isDraftBooking,
  SchedulingLinkBookingModel,
  SchedulingLinkDraftBookingModel,
} from '../../../models/schedulingLinkBookingModel';
import {SchedulingLinkModel} from '../../../models/schedulingLinkModel';
import {SchedulingLinkQuestionAnswerModel} from '../../../models/schedulingLinkQuestionAnswerModel';
import {LocaleProps, useLocale} from '../locale/localeContext';

/*
 * Props.
 */

export interface BookingSummaryProps {
  booking: SchedulingLinkBookingModel | SchedulingLinkDraftBookingModel;
  onModifyTimeSlot?: () => void;
  showChangedHeading?: boolean;
}

/*
 * Style.
 */

const StyledRowDiv = styled.div`
  display: grid;
  grid-template-areas: 'icon content';
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  margin-top: 16px;
`;

const StyledIcon = styled(Icon)`
  grid-area: icon;
  color: ${greys.shade60};
`;

const StyledGuestIcon = styled(StyledIcon)`
  margin-top: 5px;
`;

const StyledRowContent = styled.div`
  grid-area: content;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledButtonContainer = styled.div`
  margin-left: -10px;
  display: flex;
`;

const StyledAddressBubble = styled.span`
  background: ${alphas.black30};
  border-radius: 4px;
  margin-right: 3px;
  padding: 3px 6px;
  line-height: 26px;
`;

const StyledTitleDiv = styled.div`
  font-size: ${fontSizes.veryLarge};
  font-weight: ${fontWeights.bold};
  line-height: 24px;
  color: ${greys.shade80};
`;

const SemiboldSpan = styled.span`
  font-weight: ${fontWeights.semibold};
`;

/*
 * Component.
 */

export const BookingSummary: FC<BookingSummaryProps> = (props) => {
  const locale = useLocale();
  const {booking, onModifyTimeSlot} = props;
  const {schedulingLink, questionAnswers} = booking;
  const navigate = useNavigate();

  const onModifyClick = useCallback(() => {
    // If we have a server booking, using the edit endpoint.
    if (!isDraftBooking(booking)) {
      navigate(`/edit/${booking.token}`);
    }

    // Use the handler if supplied.
    if (onModifyTimeSlot) {
      onModifyTimeSlot();
    }
  }, [booking, onModifyTimeSlot, navigate]);

  if (!schedulingLink) {
    return null;
  }

  return (
    <>
      <StyledTitleDiv>{schedulingLink.meetingSummary || 'Meeting'}</StyledTitleDiv>
      {renderGuestRow(schedulingLink)}
      {renderConferenceRow(schedulingLink)}
      {renderTimeRow(props, locale, onModifyClick)}
      {renderQuestionsRow(questionAnswers)}
    </>
  );
};

/*
 * Helpers.
 */

function renderGuestRow(schedulingLink: SchedulingLinkModel) {
  const guestsToRender = schedulingLink.displayName
    ? // If there is a display name, only render that.
      [schedulingLink.displayName]
    : // Otherwise, render the organizer's email and then everybody else's email.
      [schedulingLink.organizerAddress, ...schedulingLink.additionalGuests];

  return (
    <StyledRowDiv>
      <StyledGuestIcon name="teammatesSmall" />
      <StyledRowContent>
        {guestsToRender.map((address) => (
          <Fragment key={address}>
            <StyledAddressBubble>{address}</StyledAddressBubble>&#8203;
          </Fragment>
        ))}
      </StyledRowContent>
    </StyledRowDiv>
  );
}

function renderConferenceRow(schedulingLink: SchedulingLinkModel) {
  if (!schedulingLink.conferenceType) {
    return null;
  }

  return (
    <StyledRowDiv>
      <StyledIcon name="videoSmall" />
      <StyledRowContent>Via {renderConferenceType(schedulingLink.conferenceType)}</StyledRowContent>
    </StyledRowDiv>
  );
}

function renderConferenceType(type: ConferenceTypesEnum) {
  switch (type) {
    case ConferenceTypesEnum.EVENT_HANGOUT:
    case ConferenceTypesEnum.EVENT_NAMED_HANGOUT:
    case ConferenceTypesEnum.GOOGLE_ADD_ON:
    case ConferenceTypesEnum.HANGOUTS_MEET:
      return 'Google Meet';
    case ConferenceTypesEnum.SKYPE_FOR_BUSINESS:
    case ConferenceTypesEnum.SKYPE_FOR_CONSUMER:
      return 'Skype';
    case ConferenceTypesEnum.TEAMS_FOR_BUSINESS:
      return 'Teams';
    case ConferenceTypesEnum.ZOOM:
      return 'Zoom';
    default:
      reportToBugsnag(new Error(`Unknown Conference Type: ${type}`));
      return '';
  }
}

function renderTimeRow(props: BookingSummaryProps, locale: LocaleProps, onModifyClick: () => void) {
  const timeSlot = createAvailabilityBlockFromBooking(props.booking);
  if (!timeSlot) {
    return null;
  }

  return (
    <StyledRowDiv>
      <StyledIcon name="timeSmall" />
      <StyledRowContent>
        {props.showChangedHeading && <SemiboldSpan>Changed: </SemiboldSpan>}
        {renderAvailabilityBlockDateWithLocale(timeSlot, locale, {weekday: 'short', year: 'numeric'})}
        <br />
        {renderAvailabilityBlockTimeWithLocale(timeSlot, locale)}
        <br />
        <StyledButtonContainer>
          <Button variant={ButtonVariantsEnum.LINK} onClick={onModifyClick}>
            Modify
          </Button>
        </StyledButtonContainer>
      </StyledRowContent>
    </StyledRowDiv>
  );
}

function renderQuestionsRow(questionAnswers: ReadonlyArray<SchedulingLinkQuestionAnswerModel> | undefined) {
  if (!questionAnswers || questionAnswers.length === 0) {
    return null;
  }

  return (
    <StyledRowDiv>
      <StyledIcon name="notesSmall" />
      <StyledRowContent>
        {questionAnswers.map(({question, answer}, index) => (
          <Fragment key={question}>
            {question}
            <br />
            {answer}
            {index + 1 !== questionAnswers.length && (
              <>
                <br />
                <br />
              </>
            )}
          </Fragment>
        ))}
      </StyledRowContent>
    </StyledRowDiv>
  );
}
