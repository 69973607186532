import {isDarkTheme} from './themeTypeHelpers';
import {UserTheme} from './themeTypes';

/*
 * Helpers.
 */

/*
 * Returns a box-shadow style if the user's color palette is set to light mode. In the
 * dark palette, box shadows result in halos so they're removed.
 *
 * You can optionally provide a darkStyle string to add something in the dark mode
 * version.
 */
export const boxShadow = (theme: UserTheme, boxShadowStyle: string, darkStyle?: string) => {
  if (isDarkTheme(theme)) {
    return darkStyle || '';
  }

  return `box-shadow: ${boxShadowStyle}`;
};
