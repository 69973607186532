import {annotateTaskType, TaskMetadata, TaskTypesEnum} from './eventLoopPerformanceHelpers';

/*
 * Types.
 */

export interface InstrumentedAnimationFrameProvider {
  requestInstrumentedAnimationFrame: (
    callback: FrameRequestCallback,
    metadata: TaskMetadata,
  ) => ReturnType<AnimationFrameProvider['requestAnimationFrame']>;
  cancelAnimationFrame: AnimationFrameProvider['cancelAnimationFrame'];
}

/*
 * Api.
 */

export function buildInstrumentedAnimationFrameProvider(window: Window): InstrumentedAnimationFrameProvider {
  const requestInstrumentedAnimationFrame = (callback: FrameRequestCallback, metadata: TaskMetadata) =>
    window.requestAnimationFrame((time) => {
      annotateTaskType(TaskTypesEnum.ANIMATION_FRAME, metadata);
      return callback(time);
    });
  const {cancelAnimationFrame} = window;

  return {
    requestInstrumentedAnimationFrame,
    cancelAnimationFrame,
  };
}

export function isInstrumentedAnimationFrameProvider<T extends object>(
  window: T,
): window is T & InstrumentedAnimationFrameProvider {
  return 'requestInstrumentedAnimationFrame' in window && 'cancelAnimationFrame' in window;
}
