import {IntervalMaybeValid} from 'luxon';
import React, {FC, useCallback, useMemo} from 'react';
import styled, {css} from 'styled-components';

import {
  ChromelessButton,
  ChromelessButtonProps,
} from '../../../../libs/shared/ui/src/components/buttons/chromelessButton';
import {Icon} from '../../../../libs/shared/ui/src/components/icon/icon';
import {TooltipCoordinator} from '../../../../libs/shared/ui/src/components/tooltips/tooltipCoordinator';
import {alphas, greys, palette} from '../../../../libs/shared/ui/src/styles/colorStyles';
import {fontSizes, fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {
  buildCalendarMonthFromDateTime,
  buildDateTimeFromCalendarMonth,
  CalendarMonth,
} from '../../helpers/date';
import {useLocale} from '../common/locale/localeContext';

/*
 * Props.
 */

export interface DatePickerHeaderProps {
  focusedMonth: CalendarMonth;
  onUpdateMonth: (month: CalendarMonth) => void;
  selectableInterval: IntervalMaybeValid;
}

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: 26px 1fr 26px;
  grid-column-gap: 10px;
  padding: 10px 0px;
`;

const StyledButton = styled(ChromelessButton)<ChromelessButtonProps>`
  padding: 5px;
  border-radius: 4px;
  height: 26px;
  width: 26px;

  ${(p) => maybeAddHoverStyle(p)};
`;

function maybeAddHoverStyle(props: ChromelessButtonProps) {
  if (props.isDisabled) {
    return undefined;
  }

  return css`
    &:hover {
      background-color: ${alphas.gray10};
    }
  `;
}

const StyledTitleDiv = styled.div`
  color: ${greys.shade80};
  font-size: ${fontSizes.large};
  font-weight: ${fontWeights.semibold};
  padding: 5px 0px;
  text-align: center;
`;

/*
 * Component.
 */

export const DatePickerHeader: FC<DatePickerHeaderProps> = (props) => {
  const {focusedMonth, onUpdateMonth, selectableInterval} = props;
  const {timezone} = useLocale();

  const monthDatetime = useMemo(
    () => buildDateTimeFromCalendarMonth(focusedMonth, timezone),
    [focusedMonth, timezone],
  );

  const monthTitle = `${monthDatetime.monthLong} ${monthDatetime.year}`;

  const isPrevMonthEnabled = useMemo(() => {
    if (!selectableInterval.start?.isValid) {
      return false;
    }

    return selectableInterval.start.toMillis() < monthDatetime.startOf('month').toMillis();
  }, [monthDatetime, selectableInterval]);
  const isNextMonthEnabled = useMemo(() => {
    if (!selectableInterval.end?.isValid) {
      return false;
    }
    return selectableInterval.end.toMillis() > monthDatetime.endOf('month').toMillis();
  }, [monthDatetime, selectableInterval]);

  const onPrevMonthClick = useCallback(
    () => onUpdateMonth(buildCalendarMonthFromDateTime(monthDatetime.minus({months: 1}))),
    [monthDatetime, onUpdateMonth],
  );
  const onNextMonthClick = useCallback(
    () => onUpdateMonth(buildCalendarMonthFromDateTime(monthDatetime.plus({months: 1}))),
    [monthDatetime, onUpdateMonth],
  );
  return (
    <StyledWrapperDiv>
      <TooltipCoordinator render={isPrevMonthEnabled && (() => 'Previous month')}>
        <StyledButton onClick={onPrevMonthClick} isDisabled={!isPrevMonthEnabled}>
          <Icon name="chevronLeftSmall" color={isPrevMonthEnabled ? palette.blue.shade40 : greys.shade40} />
        </StyledButton>
      </TooltipCoordinator>
      <StyledTitleDiv>{monthTitle}</StyledTitleDiv>
      <TooltipCoordinator render={isNextMonthEnabled && (() => 'Next month')}>
        <StyledButton onClick={onNextMonthClick} isDisabled={!isNextMonthEnabled}>
          <Icon name="chevronRightSmall" color={isNextMonthEnabled ? palette.blue.shade40 : greys.shade40} />
        </StyledButton>
      </TooltipCoordinator>
    </StyledWrapperDiv>
  );
};
