import React, {FC, Fragment, ReactNode} from 'react';
import styled from 'styled-components';

import {
  KeyboardActionsEnum,
  KeyboardMapping,
  KeyboardShortcut,
} from '../../../../core/src/helpers/browser/keyboardHelpers';
import {intersperse} from '../../../../core/src/helpers/utilities/arrayHelpers';
import {KeyboardContext} from './keyboardContext';
import {keyRenderers, splitAndFixKeys} from './keyboardShortcutFormatterHelpers';

/*
 * Props.
 */

export interface TextKeyboardShortcutsFormatterProps {
  /** The keyboard action to display. */
  action: KeyboardActionsEnum;
}

/*
 * Style.
 */

const StyledKeySpan = styled.span`
  text-transform: capitalize;
`;

/*
 * Component.
 */

export const TextKeyboardShortcutsFormatter: FC<TextKeyboardShortcutsFormatterProps> = (props) => (
  <KeyboardContext.Consumer>
    {(context) => renderKeyboardShortcuts(context.mapping, props.action)}
  </KeyboardContext.Consumer>
);

/** Render the shortcuts for the specified action and mapping. */
function renderKeyboardShortcuts(mapping: KeyboardMapping, action: KeyboardActionsEnum) {
  // Render each shortcut, then separate them by ",".
  const renderedShortcuts = mapping[action].map(renderKeyboardShortcut);
  return <>{intersperse<ReactNode>(renderedShortcuts, ', ')}</>;
}

/** Render a single shortcut. */
export function renderKeyboardShortcut(shortcut: KeyboardShortcut, index: number) {
  const fixedKeys = splitAndFixKeys(shortcut.sequence);

  // Find the correct translation for each.
  /* eslint react/no-array-index-key: 0 */
  const translatedKeys = fixedKeys.map((k, i) => {
    const renderer = keyRenderers[k];
    return <StyledKeySpan key={i}>{renderer ? renderer() : k}</StyledKeySpan>;
  });

  // Render, separated by "+" signs.
  return <Fragment key={index}>{intersperse<ReactNode>(translatedKeys, '+')}</Fragment>;
}
