import React, {FC} from 'react';
import styled from 'styled-components';

import {Loader} from '../../../../libs/shared/ui/src/components/loaders/loader';

/*
 * Style.
 */

const StyledWrapperDiv = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: grid;
  align-content: center;
  justify-content: center;
`;

/*
 * Component.
 */

export const FullScreenLoader: FC = () => (
  <StyledWrapperDiv>
    <Loader />
  </StyledWrapperDiv>
);

/*
 * Helpers.
 */

export function renderFullScreenLoader() {
  return <FullScreenLoader />;
}
