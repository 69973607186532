import React, {FC, useState} from 'react';
import styled from 'styled-components';

import {noShortcutsMapping} from '../../../../../libs/shared/core/src/helpers/browser/keyboardHelpers';
import {DropdownBoxCoordinator} from '../../../../../libs/shared/ui/src/components/boxedInputs/dropdownBoxCoordinator';
import {SimpleDropdown} from '../../../../../libs/shared/ui/src/components/dropdowns/simpleDropdown/simpleDropdown';
import {SimpleDropdownItem} from '../../../../../libs/shared/ui/src/components/dropdowns/simpleDropdown/simpleDropdownItem';
import {KeyboardProvider} from '../../../../../libs/shared/ui/src/components/keyboard/keyboardProvider';
import {PopoverTransferProps} from '../../../../../libs/shared/ui/src/components/popovers/popoverCoordinator';
import {greys} from '../../../../../libs/shared/ui/src/styles/colorStyles';
import {VisualSizesEnum} from '../../../../../libs/shared/ui/src/styles/commonStyles';
import {fontSizes, fontWeights} from '../../../../../libs/shared/ui/src/styles/fontStyles';
import {reportToBugsnag} from '../../../helpers/bugsnag';
import {SchedulingLinkSelectionQuestionModel} from '../../../models/schedulingLinkQuestionModel';

/*
 * Props.
 */

export interface BookSelectionQuestionProps {
  question: SchedulingLinkSelectionQuestionModel;
  onSelectAnswer: (arg: string) => void;
}

/*
 * Style.
 */

const StyledLabelDiv = styled.div`
  font-size: ${fontSizes.medium};
  color: ${greys.shade80};
  font-weight: ${fontWeights.semibold};

  margin: 2px auto -2px;
`;

/*
 * Component.
 */

export const BookSelectionQuestion: FC<BookSelectionQuestionProps> = (props) => {
  const {question} = props;
  const [firstSelectionOption] = question.selectionOptions;
  const [answer, setAnswer] = useState(firstSelectionOption);

  if (!firstSelectionOption) {
    reportToBugsnag(new Error('Missing selection options'));
    return null;
  }

  return (
    <>
      <StyledLabelDiv>{question.question}</StyledLabelDiv>
      <DropdownBoxCoordinator
        value={answer}
        size={VisualSizesEnum.LARGE}
        renderDropdown={(popoverProps) => renderDropdown(popoverProps, props, setAnswer)}
      />
    </>
  );
};

function renderDropdown(
  popoverProps: PopoverTransferProps,
  props: BookSelectionQuestionProps,
  setAnswer: (arg: string) => void,
) {
  const {question, onSelectAnswer} = props;
  const {selectionOptions} = question;

  return (
    <KeyboardProvider mapping={noShortcutsMapping}>
      <SimpleDropdown
        width={popoverProps.anchorWidth}
        anchor={popoverProps.anchor}
        onRequestClose={popoverProps.onRequestClose}
      >
        {selectionOptions.map((option) => (
          <SimpleDropdownItem
            key={option}
            onSelect={() => {
              setAnswer(option);
              onSelectAnswer(option);
            }}
          >
            {option}
          </SimpleDropdownItem>
        ))}
      </SimpleDropdown>
    </KeyboardProvider>
  );
}
