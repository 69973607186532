import axios, {CancelToken, CancelTokenSource} from 'axios';
import {ReactElement, useCallback, useEffect, useRef, useState} from 'react';

import {AsyncStatusesEnum} from '../../../../libs/shared/core/src/helpers/types/asyncStatuses';
import {reportToBugsnag} from '../../helpers/bugsnag';
import {CancelError} from '../../helpers/errors';

/*
 * Types.
 */

interface AsyncRendererProps<T> {
  asyncOperation: (cancelToken: CancelToken) => Promise<T>;
  render: (result: T) => ReactElement;
  renderLoading?: () => ReactElement;
  renderError?: (error: any) => ReactElement;
}

type Await<T> = T extends {
  then(onfulfilled?: (value: infer U) => unknown): unknown;
}
  ? U
  : T;

type RendererState<T> =
  | {
      status: AsyncStatusesEnum.LOADING;
    }
  | {
      status: AsyncStatusesEnum.LOADED;
      result: T;
    }
  | {
      status: AsyncStatusesEnum.FAILED;
      error: any;
    };

/*
 * Component.
 */

export const AsyncRenderer = <T,>(props: AsyncRendererProps<T>) => {
  const {asyncOperation, renderLoading, renderError, render} = props;

  const [state, setState] = useState<RendererState<Await<ReturnType<typeof asyncOperation>>>>({
    status: AsyncStatusesEnum.LOADING,
  });

  const lastCancelTokenSource = useRef<CancelTokenSource>();

  const cancel = useCallback(() => {
    if (lastCancelTokenSource.current) {
      lastCancelTokenSource.current.cancel();
    }
  }, [lastCancelTokenSource]);

  useEffect(() => {
    (async function doOperation() {
      const cancelTokenSource = axios.CancelToken.source();
      lastCancelTokenSource.current = cancelTokenSource;

      const cancelToken = cancelTokenSource.token;

      try {
        setState({status: AsyncStatusesEnum.LOADING});
        const result = await asyncOperation(cancelToken);
        setState({
          status: AsyncStatusesEnum.LOADED,
          result,
        });
      } catch (error) {
        if (error instanceof CancelError) {
          return;
        }

        setState({
          status: AsyncStatusesEnum.FAILED,
          error,
        });

        reportToBugsnag(error);
      }
    })();

    return () => cancel();
  }, [asyncOperation, cancel]);

  if (state.status === AsyncStatusesEnum.LOADING && renderLoading) {
    return renderLoading();
  }

  if (state.status === AsyncStatusesEnum.FAILED && renderError) {
    return renderError(state.error);
  }

  if (state.status === AsyncStatusesEnum.LOADED) {
    return render(state.result);
  }

  return null;
};
