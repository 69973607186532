import React, {ComponentType, createContext, FC, useContext} from 'react';
import {Subtract} from 'utility-types';

/*
 * Type.
 */

export enum DeviceTypesEnum {
  DESKTOP,
  MOBILE,
}

export interface PlatformProps {
  device: DeviceTypesEnum;
}

/*
 * Context.
 */

export const PlatformContext = createContext<PlatformProps>({
  device: DeviceTypesEnum.DESKTOP,
});

/*
 * Hook.
 */

export function usePlatform() {
  return useContext(PlatformContext);
}

/*
 * HOC.
 */

export function withPlatform<T extends PlatformProps>(
  Component: ComponentType<T>,
): FC<Subtract<T, PlatformProps>> {
  return function (props) {
    return (
      <PlatformContext.Consumer>
        {(context) => React.createElement(Component, {...(props as any), ...context})}
      </PlatformContext.Consumer>
    );
  };
}
