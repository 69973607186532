import React, {FC, PropsWithChildren} from 'react';

import {globalWindow} from '../../../libs/shared/core/src/helpers/browser/browserHelpers';
import {I18nProvider} from '../../../libs/shared/core/src/helpers/internationalization/components/i18nContext';
import {
  fallbackInternationalizationContextProps,
  InternationalizationContextProvider,
} from '../../../libs/shared/core/src/helpers/internationalization/internationalizationContext';
import {Layer} from '../../../libs/shared/ui/src/components/layers/layer';
import {SvgManager} from '../../../libs/shared/ui/src/components/svg/svgManager';
import {CssManager} from '../../../libs/shared/ui/src/css/cssManager';
import {LightUserThemeProvider} from '../../../libs/shared/ui/src/theme/userThemeProvider';
import {ErrorReporterProvider} from './errorReporter/errorReporterProvider';
import {PlatformProvider} from './platform/platformProvider';

export const Providers: FC<PropsWithChildren<{}>> = ({children}) => (
  <ErrorReporterProvider>
    <PlatformProvider>
      <CssManager>
        <SvgManager>
          <LightUserThemeProvider>
            <InternationalizationContextProvider value={fallbackInternationalizationContextProps}>
              <I18nProvider i18n={fallbackInternationalizationContextProps.i18n}>
                <Layer window={globalWindow}>{children}</Layer>
              </I18nProvider>
            </InternationalizationContextProvider>
          </LightUserThemeProvider>
        </SvgManager>
      </CssManager>
    </PlatformProvider>
  </ErrorReporterProvider>
);
