import React, {FC} from 'react';
import styled from 'styled-components';

import {fontWeights} from '../../../../libs/shared/ui/src/styles/fontStyles';
import {CalendarTitleProps} from './calendarType';

const StyledTitleDiv = styled.div`
  @media screen and (max-width: 650px) {
    margin-bottom: 16px;
  }
  font-size: 24px;
  line-height: 29px;
  font-weight: ${fontWeights.semibold};
  margin-bottom: 32px;
`;

export const CalendarTitle: FC<CalendarTitleProps> = ({title}) => <StyledTitleDiv>{title}</StyledTitleDiv>;
