/* eslint-disable react/jsx-props-no-spreading */
import _, {isUndefined} from 'lodash';
import React, {Component, FC, RefObject} from 'react';
import {
  ListChildComponentProps,
  ListItemKeySelector,
  ListOnScrollProps,
  VariableSizeList,
} from 'react-window';
import styled from 'styled-components';

import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {
  InteractiveList,
  InteractiveListBoundaryDirectionsEnum,
  InteractiveListProps,
} from '../interactiveList/interactiveList';
import {dropdownListHorizontalPadding} from './dropdownList';

/*
 * Constants.
 */

const defaultRowHeight = 30;
export const dropdownListDefaultRowHeight = defaultRowHeight;

/*
 * Props.
 */

export interface ScrollContext {
  clientHeight: number;
  scrollHeight: number;
  scrollTop: number;
}

export type ScrollHandler = (context: ScrollContext) => void;

export interface DropdownVirtualListProps extends InteractiveListProps {
  width: number | string;
  maxHeight: number;
  minHeight?: number;
  rowCount: number;
  selectableRowCount?: number;
  renderRow: RendererOf<number>;
  getRowHeight?: (index: number) => number | undefined;
  onScroll?: ScrollHandler;
  itemKey?: ListItemKeySelector;
}

/*
 * Style.
 */

const StyledMainList = styled(InteractiveList)`
  flex: 1;
`;

const StyledVariableSizeList = styled(VariableSizeList<RowItemData>)`
  padding: ${dropdownListHorizontalPadding}px 0;
  & > div {
    position: relative;
  }
`;

/*
 * Component.
 */

interface DropdownVirtualListState {
  scrollHeight: number;
  clientHeight: number;
}

type DropdownVariableSizeList = VariableSizeList<RowItemData>;

export const DropdownVirtualList = React.forwardRef<DropdownVariableSizeList, DropdownVirtualListProps>(
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  (props, ref) => <DropdownVirtualListForwarding {...props} innerRef={ref as any} />,
);

interface DropdownVirtualListForwardingProps extends DropdownVirtualListProps {
  innerRef: RefObject<DropdownVariableSizeList> | null;
}
class DropdownVirtualListForwarding extends Component<
  DropdownVirtualListForwardingProps,
  DropdownVirtualListState
> {
  constructor(props: DropdownVirtualListForwardingProps) {
    super(props);
    this.lastScrollTop = 0;
    this.state = {scrollHeight: 0, clientHeight: 0};
  }

  private lastScrollTop: number;

  /*
   * Lifecycle.
   */

  static getDerivedStateFromProps(
    props: Readonly<DropdownVirtualListProps>,
    state: Readonly<DropdownVirtualListState>,
  ) {
    const {maxHeight, minHeight, rowCount, getRowHeight} = props;

    const totalHorizontalPadding = 2 * dropdownListHorizontalPadding;
    const scrollHeight = getRowHeight
      ? // If we have custom row heights, calculate the total scroll height.
        totalHorizontalPadding +
        _.range(rowCount).reduce((sum, i) => sum + (getRowHeight(i) || defaultRowHeight), 0)
      : // Use the default height to get the total scroll height.
        totalHorizontalPadding + rowCount * defaultRowHeight;

    const clientHeight = Math.max(Math.min(maxHeight, scrollHeight), minHeight || 0);

    if (scrollHeight === state.scrollHeight && clientHeight === state.clientHeight) {
      return null;
    }

    return {scrollHeight, clientHeight};
  }

  componentDidUpdate() {
    const {onScroll} = this.props;
    if (!onScroll) {
      return;
    }

    onScroll({
      ...this.state,
      scrollTop: this.lastScrollTop,
    });
  }

  /*
   * Event handlers.
   */

  private readonly onScroll: (props: ListOnScrollProps) => void = (scrollParams) => {
    this.lastScrollTop = scrollParams.scrollOffset;

    const {onScroll} = this.props;
    if (!onScroll) {
      return;
    }

    onScroll({
      ...this.state,
      scrollTop: this.lastScrollTop,
    });
  };

  /*
   * Render.
   */

  private readonly onBoundaryLoop: (arg: InteractiveListBoundaryDirectionsEnum) => void = (direction) => {
    if (!this.props.innerRef?.current) {
      return;
    }

    // If we looped from the top, we need to go all the way to the bottom.
    if (direction === InteractiveListBoundaryDirectionsEnum.TOP) {
      this.props.innerRef.current.scrollToItem(this.props.rowCount - 1, 'end');
    }
    // If we looped from the bottom, we need to go all the way to the top.
    else {
      this.props.innerRef.current.scrollToItem(0, 'start');
    }
  };

  render() {
    const {selectableRowCount, rowCount} = this.props;
    const itemCount = isUndefined(selectableRowCount) ? rowCount : selectableRowCount;
    return (
      <StyledMainList {...this.props} itemCount={itemCount} onBoundaryLoop={this.onBoundaryLoop}>
        {this.renderVirtualList()}
      </StyledMainList>
    );
  }

  private renderVirtualList() {
    const {rowCount, width, renderRow, getRowHeight} = this.props;
    const itemData = {
      renderRow,
    };

    return (
      <StyledVariableSizeList
        itemCount={rowCount}
        estimatedItemSize={defaultRowHeight}
        height={this.state.clientHeight}
        width={width}
        itemData={itemData}
        onScroll={this.onScroll}
        itemSize={(index) => (getRowHeight && getRowHeight(index)) || defaultRowHeight}
        itemKey={this.props.itemKey}
        ref={this.props.innerRef}
        overscanCount={4}
      >
        {Row}
      </StyledVariableSizeList>
    );
  }
}

interface RowItemData {
  renderRow: RendererOf<number>;
}

interface RowProps extends ListChildComponentProps<RowItemData> {}

const Row: FC<RowProps> = ({index, style, data}) => <div style={style}>{data.renderRow(index)}</div>;
