import React, {forwardRef} from 'react';
import styled, {css} from 'styled-components';
import {Diff} from 'utility-types';

import {RendererOf} from '../../../../core/src/helpers/react/reactHelpers';
import {PaletteColorsEnum} from '../../../../core/src/models/api/paletteModel';
import {VisualSizesEnum} from '../../styles/commonStyles';
import {makeSizeConstants} from '../../styles/styleHelpers';
import {boxShadow} from '../../theme/themeStyleHelpers';
import {ButtonVariantsEnum, isVariantWithBorder} from './buttonConstants';

/*
 * Props.
 */

export interface ButtonChromeProps
  extends Diff<ButtonInheritableProps, typeof defaultButtonProps>,
    Partial<typeof defaultButtonProps> {
  className?: string;
  children: RendererOf<ButtonInheritableProps>;
}

export interface ButtonInheritableProps {
  /** Size of the button. */
  size: VisualSizesEnum;
  /** Style of the button. */
  variant: ButtonVariantsEnum;
  /** Override the default color of the button. */
  color: PaletteColorsEnum;
  /**
   * Whether the button is pill-shaped i.e. "rounded".
   * Please leave unspecified for all new usages as all buttons in our design system are now pill-shaped.
   * @default true
   * */
  isRounded?: boolean;
  /** Enable or disable the button. */
  isDisabled?: boolean;
  /** Whether or not the button reacts to direct hover. */
  shouldUseDirectHover?: boolean;
  /** Whether the cursor of a disabled button should be 'not-allowed'. */
  shouldDisallowCursorIfDisabled?: boolean;
}

export interface ButtonInheritableStyleProps {
  $size: VisualSizesEnum;
  $variant: ButtonVariantsEnum;
  $isRounded: boolean;
  $color: PaletteColorsEnum;
  $isDisabled?: boolean;
  $shouldDisallowCursorIfDisabled?: boolean;
  $shouldUseDirectHover?: boolean;
  $hasIcon?: boolean;
  $hasLeftIcon?: boolean;
  $hasRightIcon?: boolean;
}

/*
 * Constants.
 */

export const defaultButtonProps = {
  size: VisualSizesEnum.MEDIUM,
  variant: ButtonVariantsEnum.SECONDARY,
  color: PaletteColorsEnum.BLUE,
  isRounded: true,
};

/*
 * Style.
 */

const wrapperSizes = makeSizeConstants('auto', 'auto', '100%');

// We need another wrapper to avoid a Chrome position: relative bug.
const StyledWrapperDiv = styled.div`
  position: relative;
`;

const StyledButtonDiv = styled.div<ButtonInheritableStyleProps>`
  display: flex;
  overflow: hidden;
  align-items: stretch;
  width: ${(p) => wrapperSizes[p.$size]};
  white-space: nowrap;

  border-radius: ${(p) => (p.$isRounded ? '100px' : '4px')};
  ${(p) => buildButtonShadow(p.$variant)};
`;

export function buildButtonShadow(variant: ButtonVariantsEnum) {
  switch (variant) {
    case ButtonVariantsEnum.LINK:
    case ButtonVariantsEnum.CUSTOM_LINK:
    case ButtonVariantsEnum.DARK_MODE_LINK:
      return '';

    case ButtonVariantsEnum.SECONDARY:
    case ButtonVariantsEnum.SECONDARY_DANGER:
      return css`
        ${(p) => boxShadow(p.theme, `0 1px 1px 0 ${p.theme.alphas.black10}`)};
      `;

    default:
      return css`
        ${(p) => boxShadow(p.theme, `0 1px 3px 0 ${p.theme.alphas.black40}`)};
      `;
  }
}

const StyledBorderDiv = styled.div<ButtonInheritableStyleProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  pointer-events: none;
  border-radius: ${(p) => (p.$isRounded ? '100px' : '3px')};
  border: 1px solid ${(p) => p.theme.button.border};
`;

/*
 * Component.
 */

export const ButtonChrome = forwardRef<HTMLDivElement, ButtonChromeProps>(
  ({className, children, ...buttonProps}, ref) => (
    <StyledWrapperDiv ref={ref} className={className}>
      <StyledButtonDiv
        $size={buttonProps.size || defaultButtonProps.size}
        $variant={buttonProps.variant || defaultButtonProps.variant}
        $isRounded={buttonProps.isRounded ?? defaultButtonProps.isRounded}
        $color={buttonProps.color || defaultButtonProps.color}
        $isDisabled={buttonProps.isDisabled}
      >
        {children(buttonProps as ButtonChromeProps & Required<typeof defaultButtonProps>)}
      </StyledButtonDiv>
      {maybeRenderBorder(buttonProps as ButtonChromeProps & Required<typeof defaultButtonProps>)}
    </StyledWrapperDiv>
  ),
);
ButtonChrome.defaultProps = defaultButtonProps;

function maybeRenderBorder(props: ButtonInheritableProps) {
  if (!isVariantWithBorder(props.variant) || props.isDisabled) {
    return null;
  }

  return (
    <StyledBorderDiv
      $size={props.size}
      $variant={props.variant}
      $isRounded={props.isRounded ?? defaultButtonProps.isRounded}
      $color={props.color}
      $isDisabled={props.isDisabled}
    />
  );
}
