import React, {FC, ReactElement, ReactFragment} from 'react';

import {useEnsureI18nContext} from './i18nContext';

/*
 * Props.
 */

export type MessageReactNode = ReactElement | string | number | ReactFragment | true;

interface TransProps {
  id: string;
  children: MessageReactNode;
}

/*
 * Component.
 */

/** Defines and translates a translatable message in React. */
export const Trans: FC<TransProps> = (props) => {
  useEnsureI18nContext();

  // Lingui used to allow specifying only an `id` prop, but we don't support that.
  if (process.env.NODE_ENV !== 'production' && !props.children) {
    throw new Error(`<Trans> requires a 'children' prop`);
  }

  return <>{props.children}</>;
};
