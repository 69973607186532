import React, {FC} from 'react';
import styled from 'styled-components';

import cancellation from '../../../assets/images/cancellation.png';
import {deleteBookingAsync} from '../../../helpers/model/booking';
import {StepFrame} from '../../common/step/frame/stepFrame';
import {Step, StepData, StepProps} from '../../common/step/step';
import {CancelStepTypesEnum} from '../cancelStep';

/*
 * Style.
 */

const StyledContentDiv = styled.div`
  margin-top: 20px;
  width: 100%;
  max-width: 600px;
`;

const StyledSummaryDiv = styled.div`
  width: 360px;
`;

const StyledImage = styled.img`
  width: 100%;
`;

/*
 * Component.
 */

export const CancelStepViewConfirmation: FC<StepProps> = (props) => (
  <StepFrame metadata={props.metadata} title="Meeting cancelled">
    <StyledContentDiv>
      <StyledSummaryDiv>The meeting has been removed from everyone&apos;s calendar.</StyledSummaryDiv>
      <StyledImage src={cancellation} alt="Cancelled" />
    </StyledContentDiv>
  </StepFrame>
);

async function preStepAction(data: StepData) {
  // Update the booking with the new start time.
  const {booking} = data;
  if (!booking.token) {
    throw new Error('Missing booking token for cancellation.');
  }

  await deleteBookingAsync(booking.token);
  return {
    ...data,
    booking: {
      ...booking,
      cancelled: true,
    },
  };
}

/*
 * Step.
 */

export const cancelStepViewConfirmation: Step<CancelStepTypesEnum> = {
  type: CancelStepTypesEnum.VIEW_CONFIRMATION,
  renderStep: (props) => React.createElement(CancelStepViewConfirmation, props),
  preStepAction,
};
